/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
// import './style.css';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import './contract-modal.scss';
import { error, Position, success } from '../../../../utils/toast';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Modal from '../../../../components/common/Modals/FormikModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    createContractDetails,
    fetchContractList,
    selectContractCurrentPage,
    selectContractIsError,
    // selectContractPerPageCount,
    selectContractUser,
    selectContractError,
    selectContractSuccess,
    updateContractDetails,
    clearFailure,
    selectContractAuthor,
} from '../../../../store/reducers/contractReducer';
// import Select from 'react-select';
// import dummyData from '../SOPDetails/dummy.json';
import {
    fileTypesInfoText,
    fileUploadInfoText,
    FILE_SIZE,
    // reactSelectStyle,
    SUPPORTED_FORMATS,
} from '../../../../utils/constants';
import DatePicker from '../../../../components/common/DatePicker';
import SearchableSelectBox from '../../../../components/common/SearchableSelectBox';
import { downloadFile } from '../../../../services/apis/contractApi';
import { fetchHeaderDataClients } from 'src/store/reducers/profiles/clients';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';

interface BioProps {
    display: boolean;
    isEdit: boolean;
    isFromView: boolean;
    setIsEdit: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setIsFromView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
    mode: string;
    setMode: any;
    setData: any;
    settoastStat: any;
    settoastMsg: any;
    selectedHeader: any;
    selectedFilter: any;
    perPageCount: number;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
interface ContractForm {
    cd_docname: string;
    cd_version: string;
    cd_author: string;
    cd_forwardersignatory: string;
    cd_clientsignatory: string;
    cd_startdate: string;
    cd_enddate: string;
    cd_renewal: string;
    cd_services: string;
    cd_file?: object | null;
}

const Index: React.FC<BioProps> = (props) => {
    const [disable, _setdisable] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showRenewal, setShowRenewal] = useState(false);
    const [type, settype] = useState('');
    const initialValues: ContractForm = {
        cd_docname: props.data.cd_docname || '',
        cd_version: props.data.cd_version || '',
        cd_author: props.data.cd_author || '',
        cd_clientsignatory: props.data.cd_clientsignatory || '',
        cd_forwardersignatory: props.data.cd_forwardersignatory || '',
        cd_renewal: props.data.cd_renewal || '',
        cd_services: props.data.cd_services || '',
        cd_startdate: props.data.cd_startdate ? moment(props.data.cd_startdate).format('YYYY-MM-DD') : '',
        cd_enddate: props.data.cd_enddate ? moment(props.data.cd_enddate).format('YYYY-MM-DD') : '',
        cd_file: null,
    };
    const { clientHeader } = useSelector((state: RootState) => state.profilesClients) as any;

    const validationForAddOrEdit = () => {
        const validation =
            props.mode === 'edit'
                ? Yup.mixed().notRequired()
                : Yup.mixed()
                      .required('File is required')
                      .test({
                          name: 'fileSize',
                          exclusive: false,
                          params: {},
                          message: 'Maximum file size is 25mb',
                          test: function (value) {
                              return value && value.size <= FILE_SIZE;
                          },
                      })
                      .test({
                          name: 'fileType',
                          exclusive: false,
                          params: {},
                          message: 'File type is not supported',
                          test: function (value) {
                              return value && SUPPORTED_FORMATS.includes(value.type);
                          },
                      });

        return validation;
    };

    const ContractFormSchema = Yup.object().shape({
        cd_docname: Yup.string().required('Name is required'),
        cd_version: Yup.string().required('Version is required'),
        cd_author: Yup.string().required('Author is required'),
        cd_forwardersignatory: Yup.string().required('Forwarder signatory is required'),
        cd_clientsignatory: Yup.string().required('Client signatory is required'),
        cd_startdate: Yup.date().required('Start date is required'),
        cd_enddate: Yup.date()
            .required('End date is required')
            .min(Yup.ref('cd_startdate'), 'End date must be greater than start date')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End date must be greater than start date',
                test: function (value) {
                    const startDate = moment(this.parent.cd_startdate).format('YYYY-MM-DD');
                    const endDate = moment(value).format('YYYY-MM-DD');
                    return !moment(startDate).isSame(moment(endDate));
                },
            }),

        cd_file: validationForAddOrEdit(),
    });
    const validationSchema = Yup.object().shape({
        cd_renewal: Yup.string().required('Renewal is required'),
        cd_services: Yup.string().required('Services is required'), //agents: Yup.string().required('Agent is required'),
    });
    useEffect(() => {
        if (props.data.cd_doctype) {
            settype(props.data.cd_doctype);
        } else {
            settype('CONTRACT');
        }
    }, [props.data, props.isFromView, props.setDisplayCancel]);

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectContractIsError);
    const contract = useAppSelector(selectContractUser);
    const contractError = useAppSelector(selectContractError);
    const contractSuccess = useAppSelector(selectContractSuccess);
    const contractAuthor = useAppSelector(selectContractAuthor);

    const currentPage = useAppSelector(selectContractCurrentPage);
    // const perPageCount = useAppSelector(selectContractPerPageCount);
    // const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const { id } = useParams();

    const payload = {
        id,
        currentPage: currentPage,
        perPageCount: props.perPageCount,
        columnStatus: props.selectedHeader,
        statusFilter: props.selectedFilter,
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: type === 'QUOTATION' ? ContractFormSchema : ContractFormSchema.concat(validationSchema),
        onSubmit: (values) => {
            if (formik.isSubmitting && !props.isFromView) {
                setIsLoading(true);
                if (props.mode === 'add') {
                    const formData = new FormData();
                    const data = { ...values, cl_pk: id, cd_doctype: type, or_PK: clientHeader?.or_PK };
                    delete data['cd_file'];
                    formData.append('document', new Blob([JSON.stringify(data)], { type: 'application/json' }));
                    formData.append('file', file, file.name);
                    dispatch(createContractDetails(formData));
                } else {
                    dispatch(
                        updateContractDetails({ ...values, cd_PK: props.data.cd_PK, cl_pk: id, cd_doctype: type })
                    );
                }
                props.setIsFromView(false);
                props.settoastMsg(
                    props.mode === 'add'
                        ? `${values.cd_docname}, ${values.cd_version} has been added`
                        : `${values.cd_docname}, ${values.cd_version} has been updated`
                );
                // resetForm();
            }
            props.setIsFromView(false);
        },
    });

    useEffect(() => {
        const hiddenItems = ['cd_author', 'cd_renewal'];
        const nearestItem = ['cd_author_div', 'cd_renewal_div'];
        if (!formik.isSubmitting) {
            return;
        }

        if (Object.keys(formik.errors).length > 0) {
            const fieldName = Object.keys(formik.errors)[0];
            const index = hiddenItems.indexOf(fieldName);
            if (index !== -1) {
                document?.getElementById(nearestItem[index])?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                document?.getElementsByName(fieldName)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [formik]);

    useEffect(() => {
        setIsLoading(false);
        if (isError) {
            // if (contractError?.status != 404) {
            error(contractError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            document?.getElementById('name_div')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // }
        } else if (contractSuccess?.status === 200 || contractSuccess?.status === 201) {
            dispatch(fetchHeaderDataClients(id));
            success(
                props.mode === 'add'
                    ? `${contractSuccess?.data?.cd_docname}, ${contractSuccess?.data?.cd_version} has been added`
                    : `${contractSuccess?.data?.CD_DocName}, ${contractSuccess?.data?.CD_Version} has been updated`,
                Position.TOP_RIGHT
            );
            // dispatch(fetchContractList(payload));
            props.displayFunction(false);
            formik.resetForm();
            removeFile();
            $('.k-i-reload').click();
        }
    }, [isError, contract, contractSuccess, contractError]);

    useEffect(() => {
        if (!props.isEdit) {
            formik.resetForm();
            removeFile();
        }
    }, [props.display]);

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            formik.setFieldValue('cd_file', e.target.files[0]);
            setFile(e.target.files[0]);
        }
    };

    const removeFile = () => {
        setFile(null);
        formik.setFieldValue('cd_file', null);
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };

    const { roleName } = useUserProfile();
    const { permissions } = useSelector((state: any) => state.permissionData) as any;
    const checkPermission = (permission: any, type: any) => {
        let flag = true;
        if (roleName?.toLowerCase() === 'it admin') {
            return false;
        }
        for (let i = 0; i < permissions?.length; i++) {
            if (
                (permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    permissions[i].access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = false;
            }
        }
        return flag;
    };

    // useEffect(() => {
    //     // setTimeout(() => {
    //     //     console.log(formik.values.cd_enddate);
    //     //     if (formik.dirty && formik.values.cd_enddate) {
    //     //         formik.validateField('cd_enddate');
    //     //     }
    //     //     //     if (formik.values.cd_startdate) {
    //     //     //         formik.validateField('cd_startdate');
    //     //     //     }
    //     // }, 1000);
    //     if (formik.dirty && formik.values.cd_startdate && formik.values.cd_enddate) {
    //         const startDate = moment(formik.values.cd_startdate).format('YYYY-MM-DD');
    //         const endDate = moment(formik.values.cd_enddate).format('YYYY-MM-DD');
    //         if (!moment(startDate).isBefore(moment(endDate))) {
    //             console.log('1');
    //             formik.setFieldError('cd_enddate', 'End date must be greater than start date');
    //         } else {
    //             console.log('2');
    //             formik.setFieldError('cd_enddate', undefined);
    //         }
    //     }
    // }, [formik.values.cd_startdate, formik.values.cd_enddate]);

    return (
        <>
            <Modal
                display={props.display}
                displayFunction={props.displayFunction}
                title={'Contract Details'}
                okButtonName={props.isEdit ? 'Edit' : 'Save'}
                isEdit={props.isEdit}
                setIsEdit={props.setIsEdit}
                isFromView={props.isFromView}
                setIsFromView={props.setIsFromView}
                disable={disable}
                mode={props.mode}
                setMode={props.setMode}
                setData={props.setData}
                dirty={formik.dirty}
                setDisplayCancel={props.setDisplayCancel}
                childComp={
                    <div
                        onClick={() => {
                            setShowRenewal(false);
                        }}
                        className="modal-content"
                    >
                        <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                        <div className="modal-main-content  details-box contract-modal-main-content">
                            {/* Contract details- edit user */}
                            {props.isEdit ? (
                                <>
                                    <div className="layout-holder two-column">
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Type</h6>
                                            <div className="description-type-1" style={{ textTransform: 'capitalize' }}>
                                                {props.data.cd_doctype.toLowerCase()}
                                            </div>
                                        </div>
                                        <br />
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Name</h6>
                                            <div className="description-type-1">{props.data.cd_docname}</div>
                                        </div>
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Version</h6>
                                            <div className="description-type-1">{props.data.cd_version}</div>
                                        </div>
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Author</h6>
                                            <div className="description-type-1">{props.data.cd_author}</div>
                                        </div>
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Forwarder Signatory</h6>
                                            <div className="description-type-1">{props.data.cd_forwardersignatory}</div>
                                        </div>
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Client Signatory</h6>
                                            <div className="description-type-1">{props.data.cd_clientsignatory}</div>
                                        </div>
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Start Date</h6>
                                            <div className="description-type-1">
                                                {moment(props.data.cd_startdate).format('DD-MM-YYYY')}
                                            </div>
                                        </div>
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">End Date</h6>
                                            <div className="description-type-1">
                                                {moment(props.data.cd_enddate).format('DD-MM-YYYY')}
                                            </div>
                                        </div>
                                        {props.data.cd_renewal && (
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Renewal</h6>
                                                <div className="description-type-1">{props.data.cd_renewal}</div>
                                            </div>
                                        )}
                                        {props.data.cd_services && (
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Services</h6>
                                                <div className="description-type-1">{props.data.cd_services}</div>
                                            </div>
                                        )}
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Status</h6>
                                            <div className="description-type-1">
                                                {/* {moment(moment().format('YYYY-MM-DD')).diff(
                                                    moment(props.data.cd_enddate).format('YYYY-MM-DD')
                                                ) > 0
                                                    ? 'Expired'
                                                    : 'Active'} */}
                                                {props.data.cd_status}
                                            </div>
                                        </div>
                                        {props.data.fileName ? (
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Attachments</h6>
                                                <div className="content-with-icon">
                                                    <button type="button" className="app-btn " title="Attach file">
                                                        <svg className="svg-icon file-attachment-icon ">
                                                            <use xlinkHref="#fileAttachmentIcon"></use>
                                                            <title>Attach file</title>
                                                        </svg>
                                                    </button>
                                                    <div>
                                                        {/* file-uploaded class to higlight data with blue color */}
                                                        <a
                                                            href="#"
                                                            className="description-type-1 file-uploaded"
                                                            onClick={() => {
                                                                if (!checkPermission('download', 'commercial')) {
                                                                    downloadFile(props.data.cd_PK, props.data.fileName);
                                                                } else {
                                                                    error(
                                                                        'Download permission is not available',
                                                                        Position.TOP_RIGHT
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {props.data.fileName?.length > 20
                                                                ? `${props.data.fileName?.slice(0, 20)}...`
                                                                : props.data.fileName}
                                                        </a>
                                                        {props.data.savedFileSizeInKb ? (
                                                            <div className="heading-type-1">
                                                                {getFileSizeOfUploadedFile(
                                                                    props.data.savedFileSizeInKb
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <form id="my-form" action="" onSubmit={formik.handleSubmit} className="modal-form">
                                        <div className=" modal-input-holder">
                                            <div className="modal-input-label ">Type</div>
                                            <div className="contract-type">
                                                <div className="checkbox-item">
                                                    <label
                                                        htmlFor="name"
                                                        className="app-check-wrapper"
                                                        onClick={() =>
                                                            props.data.cd_doctype === undefined && settype('CONTRACT')
                                                        }
                                                    >
                                                        <input
                                                            type="radio"
                                                            disabled={props.data.cd_doctype !== undefined}
                                                            className="checkbox-input"
                                                            name="contact-radio-group"
                                                            checked={
                                                                props.data.cd_doctype !== undefined
                                                                    ? props.data.cd_doctype === 'CONTRACT'
                                                                    : type === 'CONTRACT'
                                                            }
                                                        />
                                                        <div className="checkmark">
                                                            <svg className="svg-icon notification-circle-icon">
                                                                <use href="#circleIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <span className="checkbox-label">Contract</span>
                                                    </label>
                                                    <label
                                                        htmlFor="name"
                                                        className="app-check-wrapper"
                                                        onClick={() =>
                                                            props.data.cd_doctype === undefined && settype('QUOTATION')
                                                        }
                                                    >
                                                        <input
                                                            type="radio"
                                                            disabled={props.data.cd_doctype !== undefined}
                                                            className="checkbox-input"
                                                            name="contact-radio-group"
                                                            checked={
                                                                props.data.cd_doctype !== undefined
                                                                    ? props.data.cd_doctype === 'QUOTATION'
                                                                    : type === 'QUOTATION'
                                                            }
                                                        />
                                                        <div className="checkmark">
                                                            <svg className="svg-icon notification-circle-icon">
                                                                <use href="#circleIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <span className="checkbox-label">Quotation</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Name <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div id="name_div" className="modal-input-field-holder">
                                                <input
                                                    placeholder="Enter name"
                                                    type="text"
                                                    className="modal-input input-text with-border"
                                                    id="cd_docname"
                                                    name="cd_docname"
                                                    onChange={(event) => {
                                                        if (/[^a-zA-Z0-9_ ]/.test(event.target.value)) {
                                                            event.preventDefault();
                                                        } else {
                                                            formik.setFieldError(event.target.name, undefined);
                                                            formik.handleChange(event);
                                                        }
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (/[^a-zA-Z0-9_ ]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                    maxLength={255}
                                                    value={formik.values.cd_docname}
                                                ></input>
                                                {!props.isFromView && formik.errors.cd_docname ? (
                                                    <div className="error-text">{formik.errors.cd_docname}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Version <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <input
                                                    placeholder="Enter version"
                                                    type="text"
                                                    className="modal-input input-text with-border"
                                                    id="cd_version"
                                                    name="cd_version"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    maxLength={16}
                                                    value={formik.values.cd_version}
                                                ></input>
                                                {!props.isFromView && formik.errors.cd_version ? (
                                                    <div className="error-text">{formik.errors.cd_version}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder ">
                                            <label htmlFor="name" className="modal-input-label">
                                                Author <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div id="cd_author_div" className="modal-input-field-holder">
                                                {/* <input
                                                    placeholder="Enter name"
                                                    type="text"
                                                    className="modal-input input-text with-border"
                                                    id="name"
                                                ></input> */}
                                                {/* <Select
                                                    options={dummyData}
                                                    isClearable
                                                    placeholder="Enter author"
                                                    onChange={(option) => {
                                                        formik.setFieldValue('cd_author', option?.value);
                                                        formik.setFieldError('cd_author', undefined);
                                                    }}
                                                    value={dummyData.filter((option: any) => {
                                                        if (option.value === formik.values.cd_author) {
                                                            return {
                                                                label: formik.values.cd_author,
                                                                value: formik.values.cd_author,
                                                            };
                                                        }
                                                    })}
                                                    name="cd_author"
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    styles={reactSelectStyle}
                                                /> */}
                                                <SearchableSelectBox
                                                    data={contractAuthor && contractAuthor.data}
                                                    // data={dummyData}
                                                    placeholder="Enter author to select"
                                                    formik={formik}
                                                    fieldName="cd_author"
                                                />
                                                {!props.isFromView && formik.errors.cd_author ? (
                                                    <div className="error-text">{formik.errors.cd_author}</div>
                                                ) : null}
                                                {/* <button
                                                    className="app-btn form-modal-icon modal-close-btn"
                                                    title="Close"
                                                >
                                                    <span>
                                                        <svg className="svg-icon close-icon">
                                                            <use xlinkHref="#closeIcon">
                                                                <title>Close</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button> */}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Forwarder Signatory <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <input
                                                    placeholder="Enter Forwarder signatory"
                                                    type="text"
                                                    className="modal-input input-text with-border"
                                                    id="cd_forwardersignatory"
                                                    name="cd_forwardersignatory"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    maxLength={150}
                                                    value={formik.values.cd_forwardersignatory}
                                                ></input>
                                                {!props.isFromView && formik.errors.cd_forwardersignatory ? (
                                                    <div className="error-text">
                                                        {formik.errors.cd_forwardersignatory}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Client Signatory <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <input
                                                    placeholder="Enter client signatory"
                                                    type="text"
                                                    className="modal-input input-text with-border"
                                                    id="cd_clientsignatory"
                                                    name="cd_clientsignatory"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    maxLength={150}
                                                    value={formik.values.cd_clientsignatory}
                                                ></input>
                                                {!props.isFromView && formik.errors.cd_clientsignatory ? (
                                                    <div className="error-text">{formik.errors.cd_clientsignatory}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Start Date <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder date-input-holder">
                                                {/* <input
                                                    required={
                                                        navigator.userAgent.indexOf('Chrome') != -1 ? false : true
                                                    }
                                                    // placeholder="Enter date"
                                                    type="date"
                                                    className={`modal-input input-text with-border date-input ${
                                                        !formik.values.cd_startdate && 'grey-text-box'
                                                    }`}
                                                    id="cd_startdate"
                                                    name="cd_startdate"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    value={formik.values.cd_startdate}
                                                ></input> */}
                                                <DatePicker
                                                    formik={formik}
                                                    fieldName="cd_startdate"
                                                    portalId="date-portal"
                                                    maxDate={moment(formik.values.cd_enddate)
                                                        .subtract(1, 'day')
                                                        .toDate()}
                                                />
                                                {!props.isFromView && formik.errors.cd_startdate ? (
                                                    <div className="error-text">{formik.errors.cd_startdate}</div>
                                                ) : null}
                                                {/* <button className="app-btn form-modal-icon active" title="Calendar">
                                                    <span> */}
                                                {/* <svg className="svg-icon calendar-icon">
                                                    <use xlinkHref="#calendarIcon">
                                                        <title>Calendar</title>
                                                    </use>
                                                </svg> */}
                                                {/* </span>
                                                </button> */}
                                            </div>
                                        </div>
                                        <div className="modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                End Date <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder date-input-holder">
                                                {/* <input
                                                    // placeholder="Enter date"
                                                    required={
                                                        navigator.userAgent.indexOf('Chrome') != -1 ? false : true
                                                    }
                                                    className={`modal-input input-text with-border date-input ${
                                                        !formik.values.cd_enddate && 'grey-text-box'
                                                    }`}
                                                    type="date"
                                                    min={new Date().toISOString().split('T')[0]}
                                                    id="cd_enddate"
                                                    name="cd_enddate"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    value={formik.values.cd_enddate}
                                                ></input> */}
                                                <DatePicker
                                                    disabled={props.data.cd_status === 'Expired'}
                                                    formik={formik}
                                                    fieldName="cd_enddate"
                                                    minDate={moment(formik.values.cd_startdate).add(1, 'day').toDate()}
                                                    portalId="date-portal"
                                                />
                                                {!props.isFromView && formik.errors.cd_enddate ? (
                                                    <div className="error-text">{formik.errors.cd_enddate}</div>
                                                ) : null}
                                                {/* <button className="app-btn form-modal-icon active" title="Calendar">
                                                    <span> */}
                                                {/* <svg className="svg-icon calendar-icon">
                                                    <use xlinkHref="#calendarIcon">
                                                        <title>Calendar</title>
                                                    </use>
                                                </svg> */}
                                                {/* </span>
                                                </button> */}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Renewal
                                                {type === 'QUOTATION' ? null : (
                                                    <span className="mandatory-field-text">*</span>
                                                )}
                                            </label>
                                            {/* <div className="modal-input-field-holder grey-option-box">
                                                <select
                                                    placeholder="Enter renewal"
                                                    className={`modal-input input-text with-border ${
                                                        !formik.values.cd_renewal && 'grey-text-box'
                                                    }`}
                                                    id="cd_renewal"
                                                    name="cd_renewal"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    value={formik.values.cd_renewal}
                                                >
                                                    <option value="" disabled hidden>
                                                        Select renewal
                                                    </option>
                                                    <option className="dark-option" value="Auto">
                                                        Auto
                                                    </option>
                                                    <option className="dark-option" value="Manual">
                                                        Manual
                                                    </option>
                                                </select>
                                                <span className="dropdown-arrow-custom">
                                                    <svg className="svg-icon dropdown-icon">
                                                        <use xlinkHref="#downarrow"></use>
                                                    </svg>
                                                </span>
                                                {!props.isFromView && formik.errors.cd_renewal ? (
                                                    <div className="error-text">{formik.errors.cd_renewal}</div>
                                                ) : null}
                                            </div> */}
                                            <div
                                                id="cd_renewal_div"
                                                className={
                                                    showRenewal
                                                        ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                        : 'dropdown-wrap  menu-down modal-dropdown'
                                                }
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowRenewal((prev) => !prev);
                                                    }}
                                                    type="button"
                                                    className="menu-btn app-btn select-modal"
                                                >
                                                    <span className="btn-text">{formik.values.cd_renewal}</span>
                                                    <span className="placeholder-text">Select Renewal</span>
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>

                                                <div className="dropdown-menu">
                                                    {/* <a
                                                        onClick={() => {
                                                            formik.setFieldError('cd_renewal', undefined);
                                                            formik.setFieldValue('cd_renewal', 'Auto');
                                                        }}
                                                        className="menu-item app-btn"
                                                    >
                                                        <span className="main-content">Auto</span>
                                                    </a> */}
                                                    <a
                                                        onClick={() => {
                                                            formik.setFieldError('cd_renewal', undefined);
                                                            formik.setFieldValue('cd_renewal', 'Manual');
                                                        }}
                                                        className="menu-item app-btn"
                                                    >
                                                        <span className="main-content">Manual</span>
                                                    </a>
                                                </div>
                                            </div>
                                            {!props.isFromView && formik.errors.cd_renewal ? (
                                                <div className="error-text">{formik.errors.cd_renewal}</div>
                                            ) : null}
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label htmlFor="name" className="modal-input-label">
                                                Services
                                                {type === 'QUOTATION' ? null : (
                                                    <span className="mandatory-field-text">*</span>
                                                )}
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <input
                                                    placeholder="Enter services"
                                                    type="text"
                                                    className="modal-input input-text with-border"
                                                    id="cd_services"
                                                    name="cd_services"
                                                    onChange={(event) => {
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    value={formik.values.cd_services}
                                                ></input>
                                                {!props.isFromView && formik.errors.cd_services ? (
                                                    <div className="error-text">{formik.errors.cd_services}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            {props.mode === 'add' || (props.mode === 'edit' && props.data.fileName) ? (
                                                <label htmlFor="name" className="modal-input-label">
                                                    Attachments <span className="mandatory-field-text">*</span>
                                                </label>
                                            ) : null}
                                            <div className="modal-input-layout-item">
                                                {props.mode === 'add' ? (
                                                    <>
                                                        <div className="browse-btn-wrapper">
                                                            <input
                                                                type={'file'}
                                                                id={'cd_file'}
                                                                name={'cd_file'}
                                                                accept={
                                                                    '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                }
                                                                className="browse-input"
                                                                onClick={(e) => {
                                                                    (e.target as HTMLInputElement).value = '';
                                                                }}
                                                                onChange={(e) => {
                                                                    formik.setFieldError(e.target.name, undefined);
                                                                    fileUpload(e);
                                                                }}
                                                            ></input>
                                                            <button
                                                                className="app-btn app-btn-secondary icon-button browse-btn"
                                                                title="Upload file"
                                                            >
                                                                <svg className="svg-icon plus-icon">
                                                                    <use xlinkHref="#plusIcon">
                                                                        <title>Upload File</title>
                                                                    </use>
                                                                </svg>
                                                                <span className="button-text upload-btn">
                                                                    Upload File
                                                                </span>
                                                            </button>
                                                            {!props.isFromView && formik.errors.cd_file ? (
                                                                <div className="error-text">
                                                                    {formik.errors.cd_file}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="heading-type-1 file-size">
                                                            <p>{fileUploadInfoText}</p>
                                                            <p
                                                                dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}
                                                            ></p>
                                                        </div>
                                                    </>
                                                ) : null}

                                                {file && props.mode === 'add' ? (
                                                    <div className="content-with-icon  attachment-holder">
                                                        <button type="button" className="app-btn " title="Attach file">
                                                            <svg className="svg-icon file-attachment-icon ">
                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                <title>Attach file</title>
                                                            </svg>
                                                        </button>
                                                        <div>
                                                            {/* file-uploaded class to higlight data with blue color */}
                                                            <a
                                                                href="#"
                                                                className="description-type-1 file-uploaded"
                                                                title="File"
                                                            >
                                                                {file?.name?.length > 20
                                                                    ? `${file?.name?.slice(0, 20)}...`
                                                                    : file?.name}
                                                            </a>
                                                            <div className="heading-type-1">
                                                                {getFileSize(file?.size)}
                                                            </div>
                                                        </div>
                                                        <a
                                                            href="#"
                                                            className="file-remove-link"
                                                            title="Remove"
                                                            onClick={() => removeFile()}
                                                        >
                                                            Remove
                                                        </a>
                                                    </div>
                                                ) : null}
                                                {props.mode === 'edit' && props.data.fileName ? (
                                                    <div className="content-with-icon  attachment-holder">
                                                        <button type="button" className="app-btn " title="Attach file">
                                                            <svg className="svg-icon file-attachment-icon ">
                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                <title>Attach file</title>
                                                            </svg>
                                                        </button>
                                                        <div>
                                                            {/* file-uploaded class to higlight data with blue color */}
                                                            <a
                                                                href="#"
                                                                className="description-type-1 file-uploaded"
                                                                title="File"
                                                                onClick={() => {
                                                                    if (!checkPermission('download', 'commercial')) {
                                                                        downloadFile(
                                                                            props.data.cd_PK,
                                                                            props.data.fileName
                                                                        );
                                                                    } else {
                                                                        error(
                                                                            'Download permission is not available',
                                                                            Position.TOP_RIGHT
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {props.data.fileName?.length > 20
                                                                    ? `${props.data.fileName?.slice(0, 20)}...`
                                                                    : props.data.fileName}
                                                            </a>
                                                            {props.data.savedFileSizeInKb ? (
                                                                <div className="heading-type-1">
                                                                    {getFileSizeOfUploadedFile(
                                                                        props.data.savedFileSizeInKb
                                                                    )}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                        <div style={{ zIndex: 9999 }} id="date-portal"></div>
                    </div>
                }
            ></Modal>
        </>
    );
};

export default Index;
