/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface FlightScheduleState {
    isLoading: boolean;
    flightScheduleSuccess: any;
    flightScheduleError: any;
    isError?: boolean;
}

const initialState: FlightScheduleState = {
    isLoading: false,
    flightScheduleSuccess: undefined,
    flightScheduleError: undefined,
    isError: false,
};

export const flightScheduleSlice = createSlice({
    name: 'flightSchedule',
    initialState,
    reducers: {
        saveSellQuote(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        saveSellQuoteSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.flightScheduleSuccess = action.payload;
            state.flightScheduleError = {};
        },
        saveSellQuoteFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.flightScheduleSuccess = {};
            state.flightScheduleError = action.payload;
        },
        updateSellQuote(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateSellQuoteSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.flightScheduleSuccess = action.payload;
            state.flightScheduleError = {};
        },
        updateSellQuoteFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.flightScheduleSuccess = {};
            state.flightScheduleError = action.payload;
        },
        selectSchedule(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        selectScheduleSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.flightScheduleSuccess = action.payload;
            state.flightScheduleError = {};
        },
        selectScheduleFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.flightScheduleSuccess = {};
            state.flightScheduleError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.flightScheduleError = undefined;
            state.flightScheduleSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    saveSellQuote,
    saveSellQuoteSuccess,
    saveSellQuoteFailure,
    updateSellQuote,
    updateSellQuoteSuccess,
    updateSellQuoteFailure,
    selectSchedule,
    selectScheduleSuccess,
    selectScheduleFailure,
    isLoadingFn,
    clearFailure,
} = flightScheduleSlice.actions;

export const selectFlightScheduleLoading = (state: RootState) => state.flightScheduleData.isLoading;
export const selectFlightScheduleSuccess = (state: RootState) => state.flightScheduleData.flightScheduleSuccess;
export const selectFlightScheduleError = (state: RootState) => state.flightScheduleData.flightScheduleError;
export const selectFlightScheduleIsError = (state: RootState) => state.flightScheduleData.isError;

export default flightScheduleSlice.reducer;
