import React from 'react';
// import './style.css';
import '../../../../../assets/scss/components/_modal.scss';
import { useDispatch } from 'react-redux';
import { changeStatus, deactivateFailed } from '../../../../../store/reducers/productReducer';
import { getOrganisationData } from '../../../../../utils';
import { useUserProfile } from '../../../../../hooks/useUserProfile';

interface BioProps {
    setconfirmModal: any;
    confirmModal: any;
    payload: any;
}
const Index: React.FC<BioProps> = (props) => {
    const dispatch = useDispatch();
    const organisationData = getOrganisationData();
    const { profileType } = useUserProfile();

    const deactivateErrorMessage = props.confirmModal.data?.message ?? null;

    return (
        <>
            <div className="app-modal container">
                <div className="modal-content-holder">
                    <div className="modal-header">
                        <h2 className="title">
                            {deactivateErrorMessage
                                ? 'Activate'
                                : props.confirmModal.value === 'ACTIVE'
                                ? 'Activate'
                                : 'Deactivate'}
                        </h2>
                        <button
                            className="app-btn modal-close-btn"
                            onClick={() => props.setconfirmModal({ ...props.confirmModal, status: false })}
                        >
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use xlinkHref="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        {!deactivateErrorMessage && (
                            <p>
                                <span className="highlight-content"> {props.confirmModal?.data?.pm_sku}</span> will be
                                {props.confirmModal.value === 'ACTIVE'
                                    ? ' activated for the client. '
                                    : ' deactivated from the client profile. '}
                                Do you want to continue?
                            </p>
                        )}
                        {deactivateErrorMessage && <p>{deactivateErrorMessage}</p>}
                    </div>
                    <div className="modal-footer">
                        <button
                            className="app-btn app-btn-secondary modal-btn"
                            onClick={() => {
                                props.setconfirmModal({ ...props.confirmModal, status: false });
                                dispatch(deactivateFailed(null));
                            }}
                        >
                            <span className="button-text vendor-button-text">
                                {deactivateErrorMessage ? 'OK' : 'No'}
                            </span>
                        </button>
                        {!deactivateErrorMessage && (
                            <button
                                style={{ marginLeft: '8px' }}
                                className="app-btn app-btn-primary modal-btn"
                                onClick={() => {
                                    dispatch(
                                        changeStatus({
                                            ...props.payload,
                                            pid: props.confirmModal?.data?.pm_PK,
                                            status: props.confirmModal?.value,
                                            name: props.confirmModal?.data?.pm_sku,
                                            data: organisationData,
                                            profileType,
                                        })
                                    );

                                    props.setconfirmModal({ ...props.confirmModal, status: false });
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
