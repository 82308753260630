import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import VendorsModal from './vendorsModal';
import HeaderTabs from '../HeaderTabs';
import '../../../../assets/scss/components/_grid.scss';
import '../../../../assets/scss/components/_textfield.scss';
import '../../../../assets/scss/components/_buttons.scss';
import '../../../../assets/scss/components/_dropdown.scss';
import '../../../../assets/scss/components/_toast.scss';
import '../../../../assets/scss/components/_modal.scss';
import Table from '../../../../components/common/Table';
import TableFooter2 from '../../../../components/common/Table/tableFooter2';
import TableHeader from '../../../../components/common/Table/tableHeader';
import UnlinkModal from './unlinkModal';
import './vendor-details.scss';
import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    cancelWarning,
    clearVendors,
    fetchData,
    linkingToast,
    // searchClientVendorsData,
    searchClientVendorsData2,
} from '../../../../store/reducers/vendorReducer';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BackButton from '../../../../components/common/BackButton';
import { error, Position, success } from '../../../../utils/toast';
import ShowForPermissions from '../../../../ShowForPermissions';
import { fetchHeaderDataClients } from 'src/store/reducers/profiles/clients';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { del } from 'src/services/HttpMethods';

const Index: React.FC = () => {
    //@ts-ignore
    window.unlinkVendor = (client_id: string, vendor_id: string) => {
        try {
            //@ts-ignore
            kendo
                .confirm('Are you sure you want to unlink this vendor?')
                .done(function () {
                    //@ts-ignore
                    kendo.ui.progress($('#grid'), true);
                    /* The result can be observed in the DevTools(F12) console of the browser. */
                    const res = del(`api/mgt/clients/unlink/vendor?cl_pk=${client_id}&ve_pk=${vendor_id}`, null)
                        .then(function (e) {
                            success(`Vendor has been unlinked`, Position.TOP_RIGHT);
                            $('.k-i-reload').click();
                        })
                        .catch(function (e) {
                            error(`${e.response.data.message}`, Position.TOP_RIGHT);
                        })
                        .finally(function () {
                            //@ts-ignore
                            kendo.ui.progress($('#grid'), false);
                        });
                    return res;
                })
                .fail(function () {
                    /* The result can be observed in the DevTools(F12) console of the browser. */
                    console.log('User rejected');
                });
        } catch (er) {
            return er;
        }
    };

    const {
        linkedVendors,
        unlinkDat,
        vendors,
        activeVendors,
        totalActiveVendors,
        totalPages,
        successFetch /*isLoading*/,
        loading,
        linkToast,
        unlinkToast,
        vendorLoading,
    } = useSelector((state: RootState) => state.vendorDat);

    const [modalView, setmodalView] = useState(false);
    const [modalViewUnlink, setmodalViewUnlink] = useState(false);
    const [currentPage, setcurrentPage] = useState(0);
    const [perPageCount, setperPageCount] = useState(10);
    const [columnStatus, setcolumnStatus] = useState('vn_vendorcode');
    const [currentOrder, setcurrentOrder] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [_toastStat, settoastStat] = useState(false);
    const [_failedToast, setfailedToast] = useState(false);
    const [search, setSearch] = useState('');
    const firstUpdate = useRef(true);

    const dispatch = useDispatch();

    const headerData = [
        {
            title: 'Vendor Code',
            sorting: true,
            key: 'vn_vendorcode',
        },
        {
            title: 'Vendor Name',
            sorting: true,
            key: 'vn_vendorname',
        },
        {
            title: 'Country',
            sorting: true,
            key: 'vn_vendorcountry',
        },
    ];

    const { id } = useParams();

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnOrder: currentOrder,
        columnStatus: columnStatus,
        id: id,
    };

    useEffect(() => {
        dispatch(clearVendors([]));
    }, []);

    useEffect(() => {
        dispatch(fetchData(payload));
    }, [columnStatus, currentOrder, currentPage, perPageCount]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        setPagenumbers(pageNumbers);
    }, [totalPages, vendors]);

    const reloadKendoGrid = () => {
        const element: HTMLElement = document.getElementsByClassName('k-pager-refresh')[0] as HTMLElement;
        if (element) {
            element.click();
        }
    };

    useEffect(() => {
        if (linkToast) {
            success(
                `${linkedVendors} new ${linkedVendors <= 1 ? 'vendor' : 'vendors'} linked successfully`,
                Position.TOP_RIGHT
            );
            dispatch(linkingToast(false));
            settoastStat(false);
            setfailedToast(false);
            reloadKendoGrid();
        } else if (unlinkToast.success) {
            success('Vendor unlinked successfully', Position.TOP_RIGHT);
            dispatch(cancelWarning(null));
            reloadKendoGrid();
        } else if (unlinkToast.failed) {
            error('Client should have atleast one vendor', Position.TOP_RIGHT);
            dispatch(cancelWarning(null));
        }
        dispatch(fetchHeaderDataClients(id));
    }, [linkToast, unlinkToast]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (search?.trim()?.length > 2) {
            dispatch(searchClientVendorsData2({ ...payload, keyword: search }));
        } else if (search?.trim()?.length === 0) {
            dispatch(fetchData(payload));
        }
    }, [search]);

    return (
        <>
            {/*{!unlinkToast.success && success('Vendor unlinked successfully oiii', Position.TOP_RIGHT)}*/}
            {/*<Toast
                view={!unlinkToast.success && unlinkToast.failed}
                type={!unlinkToast.success && 'failed'}
                text={`${!unlinkToast.success && 'Client should have atleast one vendor'}`}
                closeBtnFn={unlinkingtoast}
            />*/}

            <div className="main-wrapper container" onClick={() => setPageCountDisplay(false)}>
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />
                    <HeaderTabs />
                </div>
                <div className="grid-section vendor-grid loader-container grid-p-0">
                    {loading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}

                    <div className="grid-holder">
                        <TableHeader
                            setmodalView={setmodalView}
                            search={search}
                            setSearch={setSearch}
                            totalActive={totalActiveVendors}
                            hideSearch={true}
                        />
                        <>
                            <VendorsModal
                                display={modalView}
                                displayFunction={setmodalView}
                                clientId={id}
                                vendorList={activeVendors}
                                assignedVendors={vendors}
                                payload={payload}
                                settoastStat={settoastStat}
                                vendorLoading={vendorLoading}
                            />
                            <UnlinkModal
                                displayUnlink={modalViewUnlink}
                                displayUnlinkFun={setmodalViewUnlink}
                                unlinkData={unlinkDat}
                                clientId={id}
                                payload={payload}
                                data={vendors}
                                setfailedToast={setfailedToast}
                            />
                            {/* content when No data found in grid*/}
                            {/* <ShowForPermissions permission="edit" type="client">
                                {!successFetch && (
                                    <div className="grid-no-content ">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No vendors linked to this profile</p>
                                        <a
                                            className="add-content-link"
                                            href="javascript:void(0)"
                                            onClick={() => setmodalView(true)}
                                        >
                                            Link Vendor
                                        </a>
                                    </div>
                                )}
                                {search?.length > 0 && vendors?.length === 0 && (
                                    <div className="grid-no-content ">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No records found</p>
                                    </div>
                                )}
                            </ShowForPermissions> */}
                            {/* <div className="grid-scroll-content">
                                <Table
                                    header={headerData}
                                    data={vendors}
                                    action={setmodalViewUnlink}
                                    displayUnlink={modalViewUnlink}
                                    setcurrentOrder={setcurrentOrder}
                                    setcolumnStatus={setcolumnStatus}
                                    currentOrder={currentOrder}
                                    columnStatus={columnStatus}
                                />
                            </div> */}
                        </>
                        {/* <TableFooter2
                            setcurrentPage={setcurrentPage}
                            setperPageCount={setperPageCount}
                            perPageCount={perPageCount}
                            currentPage={currentPage}
                            displayCountfn={setPageCountDisplay}
                            pageCountDisplay={PageCount}
                            totalItems={totalActiveVendors}
                            items={vendors}
                            totalPages={totalPages - 1}
                        /> */}
                        <LayoutWithColumns
                            style={'vendor'}
                            customProp={{
                                keyword: `admin/organisation/client/${id}/vendor`,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                {/* toast message */}

                <div className="footer-button-holder ">
                    <Link
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                        to={`/profile/clients/${id}/contract_details`}
                    >
                        {/* <button className="app-btn app-btn-secondary footer-btn" title="Previous"> */}
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <Link
                        className="app-btn app-btn-primary footer-btn"
                        title="Next"
                        style={{ textDecoration: 'none' }}
                        to={`/profile/clients/${id}/product_master`}
                    >
                        {/* <button
                            // style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary footer-btn"
                            title="Next"
                        > */}
                        <span className="button-text footer-button-text">Next</span>
                        {/* </button> */}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Index;
