import { useFormik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import '../../BookingProposal/BookingAdditionalInfo/add-modal.scss';
import * as Yup from 'yup';
import { FILE_SIZE, SUPPORTED_FORMATS_FOR_FAK, fileTypesInfoText, fileUploadInfoText } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { addFakDetails, editFakDetails } from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import { useAppDispatch } from '../../../hooks';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import CancelModal from '../../../components/common/CancelModal';

interface FakForm {
    bookingContractNo: string;
    totalOriginCost: string;
    currencyCode: string;
    quotationFile?: any;
}

interface FakProps {
    data: any;
    setShowAddModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const AddModal = ({ data, setShowAddModal }: FakProps): ReactElement => {
    const [_isLoading, setIsLoading] = useState(false);
    const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const { currency } = useSelector((state: RootState) => state.additionalDetailsData);
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<any>(null);
    const [validation, setValidation] = useState(false);
    const initialValues: FakForm = {
        bookingContractNo: data.bookingContractNo || '',
        totalOriginCost: data.totOriginCost || '',
        currencyCode: data.totOriginCostCurrency || '',
        quotationFile: null,
    };

    useEffect(() => {
        if (data?.savedFakFileName) {
            const file = {
                size: data?.savedFakFileSizeInKb * 1024,
                name: data?.savedFakFileName,
            };
            setFile(file);
        }
    }, [data]);
    const validationSchema = Yup.object().shape({
        bookingContractNo: Yup.string().required('Booking Contract No. is required'),
        totalOriginCost: Yup.number()
            .typeError('Please provide a number')
            .min(1)
            .required('Total Origin Cost is required'),
        currencyCode: Yup.string().required('Currency code is required'),
        quotationFile:
            data?.savedFakFileName && file && !file.type
                ? Yup.mixed().notRequired()
                : Yup.mixed()
                      .required('File is required')
                      .test({
                          name: 'fileSize',
                          exclusive: false,
                          params: {},
                          message: 'Maximum file size is 25mb',
                          test: function (value) {
                              return value && value.size <= FILE_SIZE;
                          },
                      })
                      .test({
                          name: 'fileType',
                          exclusive: false,
                          params: {},
                          message: 'File type is not supported',
                          test: function (value) {
                              return value && SUPPORTED_FORMATS_FOR_FAK.includes(value.type);
                          },
                      }),
    });
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                const formData = new FormData();
                const data = { ...values, bookingProposalId: id };
                delete data['quotationFile'];
                formData.append('document', new Blob([JSON.stringify(data)], { type: 'application/json' }));
                if (file.type) {
                    formData.append('file', file, file.name);
                }
                if (data?.bookingContractNo) {
                    dispatch(editFakDetails(formData));
                } else {
                    dispatch(addFakDetails(formData));
                }
            }
        },
    });

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            formik.setFieldValue('quotationFile', e.target.files[0]);
            setFile(e.target.files[0]);
        }
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const removeFile = () => {
        setFile(null);
        formik.setFieldValue('quotationFile', null);
    };

    const setShowConfirmModalView = (data: boolean) => {
        setShowAddModal(data);
        formik.resetForm();
    };
    return (
        <div
            className="app-modal additional-add-modal"
            style={{ zIndex: 80 }}
            onClick={() => {
                setDropDownOpen(false);
            }}
        >
            <div className="modal-content-holder medium-popup">
                <div className="modal-header">
                    <div className="title">Additional Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            if (formik.dirty) {
                                setCancelModalVisibility(true);
                            } else {
                                setShowAddModal(false);
                                formik.resetForm();
                            }
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content agent-commercial-detail-modal-main-content">
                        <form
                            id="my-form"
                            action=""
                            className="modal-form layout-holder one-column"
                            onSubmit={formik.handleSubmit}
                        >
                            <div id="name_div" className=" modal-input-holder ">
                                <label htmlFor="name" className="modal-input-label">
                                    Booking Contract No.<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Enter Booking Contract No."
                                        type="text"
                                        className="modal-input input-text"
                                        id={'bookingContractNo'}
                                        name={'bookingContractNo'}
                                        onChange={(event) => {
                                            // eslint-disable-next-line no-undefined
                                            formik.setFieldError(event.target.name, undefined);
                                            formik.handleChange(event);
                                        }}
                                        value={formik.values.bookingContractNo}
                                    ></input>
                                </div>
                                {validation && formik.errors.bookingContractNo ? (
                                    <div className="error-text">{formik.errors.bookingContractNo}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Total Origin Cost (Buy)<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <div className="total-term-wrapper">
                                        <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                            <span className="unit-style"></span>
                                            <input
                                                placeholder="Enter Total Origin Cost"
                                                type="text"
                                                id={'totalOriginCost'}
                                                name={'totalOriginCost'}
                                                className="modal-input input-text"
                                                onChange={(event) => {
                                                    // eslint-disable-next-line no-undefined
                                                    formik.setFieldError(event.target.name, undefined);
                                                    formik.handleChange(event);
                                                }}
                                                value={formik.values.totalOriginCost}
                                            ></input>
                                            {validation && formik.errors.totalOriginCost ? (
                                                <div className="error-text">{formik.errors.totalOriginCost}</div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div
                                                className={`dropdown-wrap menu-down currency-dropdown ${
                                                    dropDownOpen ? 'dropdown-open' : ''
                                                } modal-dropdown`}
                                            >
                                                {/* <button
                                                    type="button"
                                                    className="menu-btn app-btn modal-input select-modal"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setDropDownOpen(!dropDownOpen);
                                                    }}
                                                >
                                                    <span className="button-text">{formik.values.currencyCode}</span>
                                                    {!formik.values.currencyCode ? (
                                                        <span className="placeholder-text">Currency code</span>
                                                    ) : null}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {currency?.map((crr: any, indx: number) => (
                                                        <a
                                                            className={`menu-item app-btn ${
                                                                formik.values.currencyCode === crr.code
                                                            }`}
                                                            key={indx}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // eslint-disable-next-line no-undefined
                                                                formik.setFieldError('currencyCode', undefined);
                                                                formik.setFieldValue('currencyCode', crr.code);
                                                                setDropDownOpen(false);
                                                            }}
                                                        >
                                                            {crr.code}
                                                        </a>
                                                    ))}
                                                </div>
                                                {formik.errors.currencyCode ? (
                                                    <div className="error-text">{formik.errors.currencyCode}</div>
                                                ) : null} */}
                                                <SearchableSelectBox
                                                    data={
                                                        currency &&
                                                        currency.map((item: any) => {
                                                            return { label: item.code, value: item.code };
                                                        })
                                                    }
                                                    placeholder="Currency code"
                                                    formik={formik}
                                                    fieldName="currencyCode"
                                                />{' '}
                                                {validation && formik.errors.currencyCode ? (
                                                    <div className="error-text">{formik.errors.currencyCode}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label htmlFor="name" className="modal-input-label">
                                    Quotation (Buy)<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <div className="browse-btn-wrapper">
                                        <input
                                            type={'file'}
                                            id={'quotationFile'}
                                            name={'quotationFile'}
                                            accept={'.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'}
                                            className="browse-input"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                (e.target as HTMLInputElement).value = '';
                                            }}
                                            onChange={(e) => {
                                                // eslint-disable-next-line no-undefined
                                                formik.setFieldError(e.target.name, undefined);
                                                fileUpload(e);
                                            }}
                                        ></input>
                                        <button
                                            className="app-btn app-btn-secondary icon-button browse-btn"
                                            title="Upload file"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            <svg className="svg-icon plus-icon">
                                                <use xlinkHref="#plusIcon">
                                                    <title>Upload File</title>
                                                </use>
                                            </svg>
                                            <span className="button-text upload-btn">Upload File</span>
                                        </button>
                                        {validation && formik.errors.quotationFile ? (
                                            <div className="error-text">{formik.errors?.quotationFile as any}</div>
                                        ) : null}
                                    </div>

                                    <div className="heading-type-1 file-size">
                                        <p>{fileUploadInfoText}</p>
                                        <p dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}></p>
                                    </div>
                                    {file ? (
                                        <div className="content-with-icon  attachment-holder">
                                            <button
                                                type="button"
                                                className="app-btn "
                                                title="Attach file"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <svg className="svg-icon file-attachment-icon ">
                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                    <title>Attach file</title>
                                                </svg>
                                            </button>
                                            <div>
                                                {/* file-uploaded class to higlight data with blue color */}
                                                <a className="description-type-1 file-uploaded" title="File">
                                                    {file?.name?.length > 20
                                                        ? `${file?.name?.slice(0, 20)}...`
                                                        : file?.name}
                                                </a>
                                                <div className="heading-type-1">{getFileSize(file?.size)}</div>
                                            </div>
                                            <a
                                                className="file-remove-link"
                                                title="Remove"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeFile();
                                                }}
                                            >
                                                Remove
                                            </a>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            if (formik.dirty) {
                                setCancelModalVisibility(true);
                            } else {
                                setShowAddModal(false);
                                formik.resetForm();
                            }
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="my-form"
                        title="Save"
                        onClick={() => setValidation(true)}
                        // disabled={!formik.dirty}
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
            <CancelModal
                displayFunction={setShowConfirmModalView}
                mode={'edit'}
                cancelModalVisibility={cancelModalVisibility}
                setCancelModalVisibility={setCancelModalVisibility}
                header={''}
            />
        </div>
    );
};
export default AddModal;
