import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface PackingList {
    packingId: string;
    packingCode: string;
    packingName: string;
}
export interface BpAirShippingDetails {
    containerType: string;
    deliveryMode: string;
    packing: string;
    vgm: string;
    dropOffDate: string;
    pickUpDate: string;
}

export const defaultBpAirShippingDetail = {
    containerType: '',
    deliveryMode: '',
    packing: '',
    vgm: '',
    dropOffDate: '',
    pickUpDate: '',
};

interface ShippingDetailsState {
    isLoading: boolean;
    shippingDetails: BpAirShippingDetails;
    packingList: PackingList[];
    shippingDetailsUpdateSuccess: string | null;
    shippingDetailsUpdateError: string | null;
    isError?: boolean;
    containerType: { containerTypeId: string; containerType: string }[];
    deliveryMode: { deliveryModeId: string; deliveryMode: string }[];
    containerMode: { containerModeId: string; containerMode: string }[];
}

const initialState: ShippingDetailsState = {
    isLoading: true,
    containerType: [],
    deliveryMode: [],
    containerMode: [],
    packingList: [],
    shippingDetails: defaultBpAirShippingDetail,
    shippingDetailsUpdateSuccess: null,
    shippingDetailsUpdateError: null,
    isError: false,
};

export const containerAllocationSlice = createSlice({
    name: 'shippingDetails',
    initialState,
    reducers: {
        fetchShippingDetail: (state, _action) => {
            state.isLoading = true;
        },
        fetchShippingDetailSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.shippingDetails = action.payload?.data?.info;
        },
        fetchShippingDetailFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.shippingDetailsUpdateError = action.payload;
        },

        updateShippingDetail(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateShippingDetailSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.shippingDetailsUpdateSuccess = action.payload;
            state.shippingDetailsUpdateError = null;
        },
        updateShippingDetailFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.shippingDetailsUpdateSuccess = null;
            state.shippingDetailsUpdateError = action.payload;
        },
        fetchPacking: (_state, _action) => {
            // console.log('Fetching packing');
        },
        fetchPackingSuccess: (state, action) => {
            state.packingList = action.payload?.data?.info;
        },
        clearFailure: (state, _action) => {
            state.shippingDetailsUpdateError = null;
            state.shippingDetailsUpdateSuccess = null;
            state.isError = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const {
    fetchShippingDetail,
    fetchShippingDetailFailed,
    fetchShippingDetailSuccess,
    fetchPacking,
    fetchPackingSuccess,
    updateShippingDetail,
    updateShippingDetailSuccess,
    updateShippingDetailFailure,
    clearFailure,
    isLoadingFn,
} = containerAllocationSlice.actions;

export const selectShippingDetail = (state: RootState) => state.shippingDetailsAir.shippingDetails;
export const selectPacking = (state: RootState) => state.shippingDetailsAir.packingList;

export default containerAllocationSlice.reducer;
