import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from 'src/hooks';
import { logout } from 'src/store/reducers/authReducer';

const Index: React.FC = () => {
    const dispatch = useAppDispatch();
    const { instance } = useMsal();
    useEffect(() => {
        localStorage.removeItem('roleIndex');
        dispatch(logout(instance));
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }, [instance]);

    return (
        <div className="app-loader loading">
            <svg className="svg-icon loader-icon">
                <use xlinkHref="#loaderIcon">
                    <title>Loading</title>
                </use>
            </svg>
        </div>
    );
};

export default Index;
