import { AxiosResponse } from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { profilesHeaderVendorApi } from 'src/services/apis/profiles/vendors';
import { fetchHeaderDataVendors, saveHeaderDataVendors } from 'src/store/reducers/profiles/vendors';

// eslint-disable-next-line

function* getHeaderData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(profilesHeaderVendorApi, payload);
        yield put({ type: saveHeaderDataVendors, payload: response });
    } catch (er) {
        console.log(er);
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line

function* profileVendorsSaga() {
    yield takeLatest(fetchHeaderDataVendors, getHeaderData);
}

export default profileVendorsSaga;
