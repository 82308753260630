import axios from 'axios';
import moment from 'moment';
import { getOrganisationData } from '../../../utils';
import { del, get, patch, post, put } from '../../HttpMethods';

export const fetchProductPoConfirmationListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/po/enrichdata?poId=${payload.poId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const confirmPoDetailsApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (const key in payload) {
            if (key === 'confirmedGoodsdate') {
                payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
            }
        }
        const newPayload = { data: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/po/confirm', newPayload, null);
        data.type = 'confirmPo';
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const confirmAllPoDetailsApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (const key in payload) {
            if (key === 'goodsReadyDate' || key === 'projWhouseETA') {
                payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
            }
        }
        for (let index = 0; index < payload.poLines.length; index++) {
            const element = payload.poLines[index];
            for (const key in element) {
                if (key === 'confirmedGoodsdate') {
                    element[key] = element[key] ? moment(element[key]).format('DD-MM-YYYY') : null;
                }
            }
        }
        const newPayload = { data: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/po/confirmall', newPayload, null);
        data.type = 'confirmAllPo';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitPoDetailsApi = async (payload: any) => {
    try {
        const payloadData = {
            shortShippedPoLines: [],
        };
        const data = await patch(`api/ord/po/postatus/${payload.poId}`, payloadData);
        data.type = 'submitPo';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitPoBlpDetailsApi = async (payload: any) => {
    try {
        // const payloadData = {
        //     shortShippedPoLines: [],
        // };
        const data = await get(`api/ord/po/inboundadvice/${payload.poId}`, null);
        data.type = 'submitPo';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const changePoLineStatusApi = async (payload: any) => {
    try {
        const data = await patch(`api/ord/po/polinestatus/${payload.splitLineId}/${payload.poId}`, null);
        data.type = 'changePoLineStatus';
        data.poLineNumber = payload.poLineNumber;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editPoLineStatusApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (const key in payload) {
            if (key === 'confirmedGoodsdate') {
                payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
            }
        }
        const newPayload = { data: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await put(`api/ord/po/poline/${payload.splitLineId}`, newPayload);
        data.type = 'editPoLineStatus';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const removeProductPoConfirmationDetailsApi = async (payload: any) => {
    try {
        const data = await del(`api/ord/po/line/${payload.splitLineId}`, null);
        data.type = 'removePo';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
