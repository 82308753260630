import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'availablePoLinesAir',
    initialState: {
        poId: null,
        availablePoLineAir: [],
        isLoading: true,
        error: false,
        success: false,
        poData: {},
        isHeaderOnlyPo: null,
    },
    reducers: {
        fetchAvailablePoLineDataAir: (state, action) => {
            state.isLoading = true;
            state.availablePoLineAir = action.payload?.response?.confirmationGroupViewPoLineList;
            state.isHeaderOnlyPo = action.payload?.response?.isHeaderOnlyPo;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        setPoIdAir: (state, action) => {
            state.poId = action.payload?.cgId;
        },
        resetAvailablePoLineListAir: (state, _action) => {
            state.availablePoLineAir = [];
        },
        searchAvailablePoLineAirData: (state, _action) => {
            state.isLoading = true;
        },
        searchAvailablePoLineAirDataSuccess: (state, action) => {
            state.availablePoLineAir = action.payload?.response?.confirmationGroupViewPoLineList;
            state.isLoading = false;
        },
    },
});

export const {
    fetchAvailablePoLineDataAir,
    isLoadingFn,
    setPoIdAir,
    resetAvailablePoLineListAir,
    searchAvailablePoLineAirData,
    searchAvailablePoLineAirDataSuccess,
} = dataSlice.actions;
export default dataSlice.reducer;
