import axios from 'axios';
// import { ClientDocumentsDtoList } from '../../store/reducers/historyReducer';
import { get } from '../HttpMethods';

export const fetchHistoryListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
    keyword: string;
}) => {
    try {
        let queryStr = `api/mgt/search/historylist?limit=${payload.perPageCount}&page=${payload.currentPage}&sortby=${
            payload.columnStatus || ''
        }&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
            payload.statusFilter &&
            payload.statusFilterColumn &&
            payload.statusFilter != '' &&
            payload.statusFilterColumn != ''
                ? `&filterby=${payload.statusFilter}`
                : ''
        }${
            payload.statusFilter &&
            payload.statusFilterColumn &&
            payload.statusFilter != '' &&
            payload.statusFilterColumn != ''
                ? `&filterByColumn=${payload.statusFilterColumn}`
                : ''
        }`;
        if (payload.keyword) {
            queryStr = queryStr + '&keyword=' + payload.keyword;
        }

        const queryStr2 = `api/mgt/user/history/filter?limit=${payload.perPageCount}&page=${
            payload.currentPage
        }&sortby=${payload.columnStatus || ''}&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
            payload.statusFilter &&
            payload.statusFilterColumn &&
            payload.statusFilter != '' &&
            payload.statusFilterColumn != ''
                ? `&filterby=${payload.statusFilter}`
                : ''
        }${
            payload.statusFilter &&
            payload.statusFilterColumn &&
            payload.statusFilter != '' &&
            payload.statusFilterColumn != ''
                ? `&filterByColumn=${payload.statusFilterColumn}`
                : ''
        }`;

        const data = await get(payload.keyword ? queryStr : queryStr2, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchHistoryColumnListData = async (payload: { columnName: string }) => {
    try {
        const data = await get(`api/mgt/user/history/distinct?columnName=${payload.columnName}`, null);
        return { ...data, columnName: payload.columnName };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
