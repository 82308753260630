import React, { useEffect, useState } from 'react';
import './image-view.scss';
import '../../../../assets/scss/components/_modal.scss';

interface imageDataProp {
    fileUrl: string;
    fileName: string;
}
interface ImageViewerProps {
    imageViewerModalVisibility: boolean;
    setImageViewerModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    originalData: imageDataProp[];
    currentImageIndex: number;
    setCurrentImageIndex: (value: number | ((prevVar: number) => number)) => void;
}
const Index: React.FC<ImageViewerProps> = ({
    imageViewerModalVisibility,
    setImageViewerModalVisibility,
    originalData,
    currentImageIndex,
    setCurrentImageIndex,
}) => {
    const [data, setData] = useState<any>([]);

    const getThumbnailStatus = (index: number) => {
        if (currentImageIndex >= 3) {
            if (originalData?.length - 1 === currentImageIndex) {
                if (index === currentImageIndex - 1 || index === currentImageIndex || index === currentImageIndex - 2) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (index === currentImageIndex - 1 || index === currentImageIndex || index === currentImageIndex + 1) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if (index < 3) {
                return true;
            }
            return false;
        }
    };

    useEffect(() => {
        const tempData = JSON.parse(JSON.stringify(originalData));
        const dataWithThumbnailView = tempData?.map((res: any, index: number) => ({
            ...res,
            showThumbnail: getThumbnailStatus(index),
        }));
        setData(dataWithThumbnailView);
        if (!imageViewerModalVisibility) {
            setData([]);
        }
    }, [imageViewerModalVisibility]);

    const onNext = (currentImageIndex: number) => {
        const current = currentImageIndex + 1;
        setCurrentImageIndex((prev: number) => prev + 1);
        const tempData = JSON.parse(JSON.stringify(data));
        if (current >= 3) {
            tempData[current].showThumbnail = true;
            tempData[current - 3].showThumbnail = false;
            setData(tempData);
        }
    };
    const onPrev = (currentImageIndex: number) => {
        const current = currentImageIndex - 1;
        setCurrentImageIndex((prev: number) => prev - 1);
        const tempData = JSON.parse(JSON.stringify(data));
        if (data?.length - current > 3) {
            tempData[current].showThumbnail = true;
            tempData[current + 3].showThumbnail = false;
            setData(tempData);
        }
    };

    return (
        <>
            {imageViewerModalVisibility ? (
                <div className="app-modal image-modal container">
                    <div className="modal-content-holder image-popup image-view-modal">
                        <div className="modal-header">
                            <h2 title={data[currentImageIndex]?.fileName} className="title">
                                {data[currentImageIndex]?.fileName}
                            </h2>
                            <button
                                onClick={() => setImageViewerModalVisibility(false)}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="image-slider">
                                <div className="images">
                                    {data?.length > 0 &&
                                        data?.map((res: any, index: number) => (
                                            <img
                                                key={index}
                                                className={currentImageIndex === index ? 'active' : ''}
                                                src={res?.fileUrl}
                                                alt={res?.fileName}
                                            />
                                        ))}
                                </div>
                                <div className="slider-nav">
                                    <div className="thumbnails">
                                        <a>
                                            {data?.map((res: any, index: number) => (
                                                <img
                                                    key={index}
                                                    onClick={() => setCurrentImageIndex(index)}
                                                    className={`${currentImageIndex === index ? 'active ' : ''}${
                                                        res.showThumbnail ? 'show-thumbnail' : 'hide-thumbnail'
                                                    }`}
                                                    src={res?.fileUrl}
                                                    alt={res?.fileName}
                                                />
                                            ))}
                                        </a>
                                    </div>
                                    {currentImageIndex > 0 && (
                                        <button onClick={() => onPrev(currentImageIndex)} className="back-btn app-btn">
                                            <svg className="svg-icon left-arrow-icon">
                                                <use xlinkHref="#leftArrowIcon">
                                                    <title>dropdown</title>
                                                </use>
                                            </svg>
                                        </button>
                                    )}
                                    {currentImageIndex < originalData?.length - 1 && (
                                        <button onClick={() => onNext(currentImageIndex)} className="next-btn app-btn">
                                            <svg className="svg-icon left-arrow-icon">
                                                <use xlinkHref="#rightArrowIcon">
                                                    <title>dropdown</title>
                                                </use>
                                            </svg>
                                        </button>
                                    )}
                                </div>
                                <div className="slider-number">
                                    <span className="slider-active-num">
                                        {currentImageIndex + 1}/{originalData?.length}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
