import axios from 'axios';
import { get, post } from '../../HttpMethods';

export const fetchShippingDetails = async (payload: { shipmentId: string }) => {
    try {
        const data = await get(`api/bkg/bp/air-shipment/${payload.shipmentId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateShippingDetails = async (payload: any) => {
    const mode = payload.mode;
    delete payload.mode;
    try {
        const data = await post('api/bkg/bp/air-shipment/details', payload, null);
        data.type = 'basicDetail';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchPackingApi = async (_payload: any) => {
    try {
        const data = await get('api/bkg/bp/packing', null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
