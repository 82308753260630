import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface Currency {
    id: string;
    name: string;
    code: string;
}

export interface BpEnrichBooking {
    bookingRefNum: string;
    soNumber: string;
    houseBillNo: string;
    originCartageCompany: string;
}

const defaultBpEnrichBooking = {
    bookingRefNum: '',
    soNumber: '',
    houseBillNo: '',
    originCartageCompany: '',
};

interface AdditionalDetailsState {
    isLoading: boolean;
    enrichBooking: BpEnrichBooking;
    currency: Currency[];
    additionalDetailsSuccess: any;
    additionalDetailsError: any;
    isError?: boolean;
    gateInDateFailed?: boolean;
    submitBPSuccess: boolean;
    submitBPError: boolean;
    isBillError?: boolean;
    isBillSuccess?: boolean;
    billDetails: any;
    billErrorMessage?: any;
}

const initialState: AdditionalDetailsState = {
    isLoading: true,
    enrichBooking: defaultBpEnrichBooking,
    currency: [],
    additionalDetailsSuccess: null,
    additionalDetailsError: null,
    isError: false,
    gateInDateFailed: false,
    submitBPSuccess: false,
    submitBPError: false,
    isBillError: false,
    isBillSuccess: false,
    billDetails: {},
    billErrorMessage: null,
};

export const bookingDetailsSlice = createSlice({
    name: 'bookingDetails',
    initialState,
    reducers: {
        approveBp(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        approveBpSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        gateInDateValidation: (state, action) => {
            state.gateInDateFailed = action.payload;
        },
        approveBpFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        rejectBp(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        rejectBpSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        rejectBpFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        assignToAgent(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        assignToAgentSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        assignToAgentFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        fetchCurrency: (state, _action) => {
            state.isError = false;
        },
        fetchCurrencySuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.currency = action.payload?.data?.info;
        },
        fetchCurrencyFailure(state, action) {
            state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        updateViewStatus(state, _action) {
            // state.isLoading = true;
            state.isError = false;
        },
        updateViewStatusSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateViewStatusFailure(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        updateModalStatus(state, _action) {
            // state.isLoading = true;
            state.isError = false;
        },
        updateModalStatusSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateModalStatusFailure(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        fetchEnrichBooking: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchEnrichBookingSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.enrichBooking = action.payload?.data?.info;
        },
        fetchEnrichBookingFailure(state, action) {
            // state.isLoading = false;
            // state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        updateEnrichBooking(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateEnrichBookingSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateEnrichBookingFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        clearFailure: (state, _action) => {
            state.additionalDetailsError = null;
            state.additionalDetailsSuccess = null;
            state.isError = false;
            state.submitBPSuccess = false;
            state.submitBPError = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        updateBP: (state, action) => {
            if (action.payload) {
                state.submitBPSuccess = true;
            } else {
                state.submitBPError = true;
            }
        },
        fetchHouseBills: (state, _action) => {
            state.isLoading = true;
        },
        fetchHouseBillSuccess(state, action) {
            state.isLoading = false;
            state.billDetails = action.payload;
        },
        fetchHouseBillError(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        updateHouseBills: (state, _action) => {
            state.isLoading = true;
        },
        updateHouseBillSuccess(state, _action) {
            state.isLoading = false;
            state.isBillSuccess = true;
        },
        updateHouseBillError(state, action) {
            state.isLoading = false;
            state.isBillError = true;
            state.billErrorMessage = action.payload;
        },
        resetBillFlags: (state, _action) => {
            state.isBillError = false;
            state.isBillSuccess = false;
        },
    },
});

export const {
    approveBp,
    approveBpSuccess,
    approveBpFailure,
    rejectBp,
    rejectBpSuccess,
    rejectBpFailure,
    assignToAgent,
    assignToAgentSuccess,
    assignToAgentFailure,
    fetchCurrency,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    updateViewStatus,
    updateViewStatusSuccess,
    updateViewStatusFailure,
    updateModalStatus,
    updateModalStatusSuccess,
    updateModalStatusFailure,
    fetchEnrichBooking,
    fetchEnrichBookingSuccess,
    fetchEnrichBookingFailure,
    updateEnrichBooking,
    updateEnrichBookingSuccess,
    updateEnrichBookingFailure,
    clearFailure,
    isLoadingFn,
    gateInDateValidation,
    updateBP,
    fetchHouseBills,
    fetchHouseBillSuccess,
    fetchHouseBillError,
    updateHouseBills,
    updateHouseBillSuccess,
    updateHouseBillError,
    resetBillFlags,
} = bookingDetailsSlice.actions;

export const selectAdditionalDetailsLoading = (state: RootState) => state.additionalDetailsData.isLoading;
export const selectQuotation = (state: RootState) => state.additionalDetailsData.quotation;
export const selectQuotationList = (state: RootState) => state.additionalDetailsData.quotationList;
export const selectCurrency = (state: RootState) => state.additionalDetailsData.currency;
export const selectEnrichBooking = (state: RootState) => state.additionalDetailsData.enrichBooking;
export const selectAdditionalDetailsSuccess = (state: RootState) =>
    state.additionalDetailsData.additionalDetailsSuccess;
export const selectAdditionalDetailsError = (state: RootState) => state.additionalDetailsData.additionalDetailsError;
export const selectAdditionalDetailsIsError = (state: RootState) => state.additionalDetailsData.isError;
export const selectgateInDateValidation = (state: RootState) => state.additionalDetailsData.gateInDateFailed;
export const selectHouseBillDetails = (state: RootState) => state.additionalDetailsData.billDetails;
export const selectIsBillErro = (state: RootState) => state.additionalDetailsData.isBillError;
export const selectIsBillSuccess = (state: RootState) => state.additionalDetailsData.isBillSuccess;
export const selectBillErrorMessage = (state: RootState) => state.additionalDetailsData.billErrorMessage;

export default bookingDetailsSlice.reducer;
