import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import '@elfalem/leaflet-curve';
import { getCurveMidPoint } from '../utils';

export type Coordinates = [number, number];

interface Params {
    startPoint: [number, number];
    endPoint: [number, number];
}
export const useCurvedLine = ({ startPoint, endPoint }: Params) => {
    const context = useLeafletContext();

    const pathOptionCurve = { color: 'black', weight: 1, dashArray: '4', name: 'dottedCurve' };

    const { midPoint } = getCurveMidPoint({
        startPoint,
        endPoint,
    });

    if (midPoint) {
        const curveLayer = L.curve(['M', startPoint, 'Q', midPoint, endPoint], pathOptionCurve);
        curveLayer.addTo(context.map);
    }

    return true;
};
