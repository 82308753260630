import React from 'react';
import '../TrendGraph/trend-graph.scss';
interface Props {
    addedForCompare: any;
    setAddedForCompare?: any;
    setActiveTrend?: any;
    getCount?: any;
}
const Index: React.FC<Props> = ({ addedForCompare, setAddedForCompare, setActiveTrend, getCount }) => {
    const onDelete = (item: any) => {
        const filtered = addedForCompare?.filter((res: any) => res.id !== item.id);
        setAddedForCompare(filtered);
    };

    return (
        <>
            <div className="badge-btn-wrapper active">
                {addedForCompare?.map((res: any, index: number) => {
                    return (
                        <div key={res?.id}>
                            <div
                                className={`badge-btn trend-${index + 1}`}
                                onMouseOver={() => setActiveTrend(index + 1)}
                                onMouseOut={() => setActiveTrend(0)}
                            >
                                {/* <div className="badge-sign">
                                    #<span className="badge-sign-no">{index + 1}</span>
                                </div> */}
                                <div className="badge-name">{res?.agentName}</div>
                                <div className="badge-num-icon">
                                    {getCount(res?.id, res?.agentName)}
                                    {/* <span className="icon-holder">
                                        <svg className="svg-icon container-icon ">
                                            <use xlinkHref="#containerIcon"></use>
                                        </svg>
                                    </span> */}
                                </div>
                                <button className="app-btn text-close-btn" onClick={() => onDelete(res)}>
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default Index;
