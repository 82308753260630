import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { RootState } from 'src/store/reducers';
import { finalisePacking, resetFinaliseResult } from 'src/store/reducers/purchaseOrder/vendorPortalReducer';
import { Form, FormField, TextArea } from 'semantic-ui-react';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    poStatusChanged: boolean;
    setPoStatusChanged: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ModalProps> = ({ modalView, setModalView, poStatusChanged, setPoStatusChanged }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [buttonDisplay, setButtonDisplay] = useState('Confirm');
    const { finaliseResult, packingStatus } = useSelector((state: RootState) => state.vendorPortalData);
    const [reason, setReason] = useState('');

    const resetPackData = () => {
        setButtonDisplay('Please Wait...');
        dispatch(finalisePacking({ poPk: id, action: 'VPR', reason: reason }));
    };

    useEffect(() => {
        if (finaliseResult.length > 0) {
            setModalView(false);
            setPoStatusChanged(!poStatusChanged);
            dispatch(resetFinaliseResult({}));
        }
    }, [finaliseResult]);

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder">
                        <div className="modal-header">
                            <div className="title">Confirm Reset</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="description-type-1">
                                            Please Confirm You Would Like to Reset{' '}
                                            {packingStatus[0].purchaseOrderNumber}
                                        </div>
                                    </div>
                                </div>
                                <Form>
                                    <FormField
                                        id="form-textarea-control-opinion"
                                        control={TextArea}
                                        label="Reason"
                                        placeholder="Enter the reason to reset packing"
                                        value={reason}
                                        onChange={(e: any) => {
                                            setReason(e.target.value);
                                        }}
                                        required
                                    />
                                </Form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Reset"
                                onClick={() => {
                                    resetPackData();
                                }}
                                disabled={buttonDisplay === 'Please Wait...' || reason?.length === 0}
                            >
                                <span className="button-text footer-button-text">{buttonDisplay}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
