import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../store/reducers';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { getCarrierList } from 'src/store/reducers/bookingProposalAir/addSchedule';
import { InputActionMeta } from 'react-select';

interface carrierDropdownPropsType {
    type?: string;
    carrier: string;
    setCarrier: { (carrier: string): void };
    isErrorMessageShow: boolean;
}

const Index: React.FC<carrierDropdownPropsType> = (props) => {
    let prevValue = '';
    const { carrierList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const dispatch = useDispatch();
    const convertDataForSelect = () => {
        if (Array.isArray(carrierList)) {
            const convertedData = carrierList?.map((option: any) => {
                return { value: option.Or_UserDefinedText4, label: option.Or_UserDefinedText4 };
            });
            return convertedData;
        } else {
            return [];
        }
    };

    const NoMessageComponent = (select: any) => {
        return (
            <p>
                {select.selectProps?.inputValue?.trim()?.length < 3
                    ? 'Enter at least 3 characters to search'
                    : 'No results found'}
            </p>
        );
    };

    return (
        <div className=" modal-input-holder layout-item">
            <label className="modal-input-label">
                Carrier<span className="mandatory-field-text">*</span>
            </label>
            <SearchableSelectBox
                data={convertDataForSelect()}
                placeholder="Enter Carrier to select"
                setStateValue={props.setCarrier}
                stateValue={props.carrier}
                fieldName="carrier"
                menuPortalTarget={document.getElementById('outside_div')}
                onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                    prevValue = prevValue === 'set-value' ? '' : actionMeta.action;
                    if (value?.length > 2) {
                        dispatch(getCarrierList({ search: value, type: props?.type }));
                    } else if (actionMeta.action === 'menu-close' && actionMeta.prevInputValue !== '' && prevValue) {
                        dispatch(getCarrierList({ type: props?.type }));
                    }
                }}
                onClear={() => dispatch(getCarrierList({ type: props?.type }))}
                NoMessageComponent={NoMessageComponent}
            />
            {props.isErrorMessageShow && (
                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                    carrier is required
                </p>
            )}
        </div>
    );
};

export default Index;
