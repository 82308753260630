import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import moment from 'moment';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    eventHistory: any[];
}

const Index: React.FC<ModalProps> = ({ setModalView, eventHistory }) => {
    return (
        <div className="app-modal">
            <div className="modal-content-holder large-popup reject-booking-modal">
                <div className="modal-header">
                    <div className="title">Event</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="input-form-wrapper" style={{ flexDirection: 'column' }}>
                            <label htmlFor="name" className="modal-input-label">
                                History
                            </label>
                            <br />
                            {eventHistory?.length > 0 && (
                                <div className="grid-container history-grid-prompt">
                                    <div className="grid-scroll-content">
                                        <table className="grid-table">
                                            <thead>
                                                <tr className="grid-sticky-row">
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Code</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Description</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Details</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>User</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Date</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {eventHistory?.map((res: any, index: number) => (
                                                    <tr key={index} className={`has-child row-expanded`}>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.EV_CD_Code}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.CD_Value}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.EV_Context?.split('^Reason=')?.length > 1
                                                                    ? 'Reason: ' + res?.EV_Context.split('^Reason=')[1]
                                                                    : '-'}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.US_UserName}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {moment(res?.EV_CreatedDateTime).format('YYYY-MM-DD')}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="modal-footer prompts-footer-modal">
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Submit"
                        onClick={() => {
                            setModalView(false);
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
