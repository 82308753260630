import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import BasicDetailsModal from './BasicDetailsModal';
import { PoDetailsModal } from './PoDetailsModal';
// import PoList from './poList';
import DatesModal from './DatesModal';
// import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../../BookingProposal/POSelection.scss';
import '../../../BookingProposal/ContainerAllocations/ContainerDetails/container-details.scss';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
// import BookingProposalHeader from '../../BookingProposalHeader';
import BookingHeaderSidebar from '../../BookingHeaderSidebar';
import {
    BpContainerDetail,
    // selectContainerAllocationError,
    // selectContainerAllocationIsError,
    selectContainerDetail,
    // selectContainerAllocationSuccess,
    // selectContainerAllocationLoading,
    fetchContainerDetail,
    fetchInitialContainerDetail,
    defaultBpContainerDetail,
    fetchContainerType,
    fetchDeliveryMode,
    fetchContainerMode,
    fetchPacking,
    selectContainerType,
    // fetchContainerOnlyBasicDetail,
    selectPacking,
    selectContainerAllocationLoading,
    selectContainerDetailsId,
    // fetchAssignedPo,
    // selectAssignedPo,
    // fetchAllPo,
    // removeFromContainer,
    // clearFailure,
} from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
// import BookingProposalHeader from '../../BookingProposalHeader';
import CancelModal from '../../../../components/common/CancelModal';
// import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import moment from 'moment';
// import { error, Position, success } from '../../../../utils/toast';
import ShowForPermissions from '../../../../ShowForPermissions';
const basicData: any = [
    {
        title: 'Container Type',
        key: 'containerType',
        fields: '-',
        required: true,
    },
    {
        title: 'Delivery Mode',
        key: 'deliveryMode',
        fields: '-',
        required: true,
    },
    {
        title: 'Container Mode',
        key: 'containerMode',
        fields: '-',
        required: true,
    },
    {
        title: 'Container Number',
        key: 'containerNumber',
        fields: '-',
        required: true,
    },
    {
        title: 'Packing',
        key: 'packing',
        fields: '-',
        required: true,
    },
    {
        title: 'Seal Number',
        key: 'sealNumber',
        fields: '-',
        required: true,
    },
];
const dateData = [
    {
        title: 'Collection Date from Depot',
        key: 'collectionDate',
        fields: '-',
        required: true,
    },
    {
        title: 'Gate in Date and Time',
        key: 'gateInDate',
        fields: '-',
        required: true,
    },
];
const Index: React.FC = () => {
    const [showBasicDetails, setShowBasicDetails] = useState(false);
    const [showDatesDetail, setShowDatesDetails] = useState(false);
    const [poDetails, setPoDetails] = useState(false);
    const [cancelModalView, setCancelModalView] = useState(false);
    const [tab, _setTab] = useState(0);
    const { id, allocationId } = useParams();
    const location: any = useLocation();
    const { profileType } = useUserProfile();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    // const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [loader, setLoader] = useState(true);
    const isLoading = useAppSelector(selectContainerAllocationLoading);
    const [containerData, setContainerData] = useState<BpContainerDetail>(defaultBpContainerDetail);
    const dispatch = useAppDispatch();
    // const isError = useAppSelector(selectContainerAllocationIsError);
    // const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const containerDetail: any = useAppSelector(selectContainerDetail);
    // const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    // const isLoading = useAppSelector(selectContainerAllocationLoading);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const packingData = useAppSelector(selectPacking);
    // const assignedPo = useAppSelector(selectAssignedPo);
    const containerType = useAppSelector(selectContainerType);
    const currentContainerDetailsId = useAppSelector(selectContainerDetailsId);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);

    useEffect(() => {
        // if (location?.state?.containerDetailsId) {
        // dispatch(fetchContainerDetail({ containerDetailsId: location?.state?.containerDetailsId }));
        // dispatch(fetchAssignedPo({ containerDetailsId: location?.state?.containerDetailsId, bpId: id }));
        // dispatch(fetchAllPo({ bpId: id }));
        // } else {
        // dispatch(fetchContainerOnlyBasicDetail({ containerId: allocationId }));
        // }
        dispatch(fetchDeliveryMode({}));
        dispatch(fetchContainerMode({}));
        dispatch(fetchPacking({}));
    }, []);

    const dataFetchedRef = useRef(true);
    const wasCalled = useRef(false);

    React.useEffect(() => {
        if (headerData?.containerDetailsId && dataFetchedRef.current) {
            dispatch(fetchContainerDetail({ containerDetailsId: headerData?.containerDetailsId }));
            dataFetchedRef.current = false;
        }
        if (headerData?.containerDetailsId === null) {
            if (wasCalled.current) {
                return;
            }
            wasCalled.current = true;
            dispatch(fetchInitialContainerDetail({ bookingProposalId: id }));
        }
    }, [headerData?.containerDetailsId, dataFetchedRef]);

    useEffect(() => {
        if (currentContainerDetailsId) {
            dispatch(fetchContainerDetail({ containerDetailsId: currentContainerDetailsId }));
        }
    }, [currentContainerDetailsId]);

    const getPackageName = (code: any[]) => {
        if (!code) {
            return '-';
        }
        const newPackage = [...code];
        if (newPackage?.length) {
            for (let index = 0; index < code.length; index++) {
                newPackage[index] = packingData.find((el: any) => el.packingCode === code[index])?.packingName;
            }
            return newPackage.join(', ');
        } else {
            return '-';
        }
    };

    useEffect(() => {
        if (containerDetail) {
            if (containerDetail?.measurement >= 0) {
                !containerType?.length &&
                    dispatch(
                        fetchContainerType({
                            measurement: containerDetail?.measurement ? containerDetail?.measurement : 0,
                        })
                    );
            }
            setContainerData(containerDetail);
            for (let index = 0; index < basicData.length; index++) {
                if (basicData[index].key === 'packing') {
                    basicData[index].fields = getPackageName(containerDetail[basicData[index].key]);
                } else {
                    basicData[index].fields = containerDetail[basicData[index].key]
                        ? containerDetail[basicData[index].key]
                        : '-';
                }
            }
            for (let index = 0; index < dateData.length; index++) {
                if (dateData[index].key === 'gateInDate') {
                    dateData[index].fields = containerDetail[dateData[index].key]
                        ? moment.utc(containerDetail[dateData[index].key]).local().format('DD-MM-YYYY, HH:mm')
                        : '-';
                } else {
                    dateData[index].fields = containerDetail[dateData[index].key]
                        ? moment(containerDetail[dateData[index].key]).format('DD-MM-YYYY')
                        : '-';
                }
            }
        }
    }, [containerDetail]);

    // const removePoFromContainer = () => {
    //     setLoader(true);
    //     const bsId: any[] = [];
    //     for (let i = 0; i < selectedItemsAdded.length; i++) {
    //         bsId.push(...selectedItemsAdded[i].subPolineIds);
    //     }
    //     dispatch(removeFromContainer({ containerDetailsId: location?.state?.containerDetailsId, bsId }));
    //     setSelectedItemsAdded([]);
    // };

    // const getCount = () => {
    //     let total = 0;
    //     selectedItemsAdded.forEach((element: any) => {
    //         // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //         element.subPolineIds.forEach((element2: any) => {
    //             total++;
    //         });
    //     });
    //     return total;
    // };

    // useEffect(() => {
    //     setLoader(false);
    //     if (isError && ContainerAllocationError?.data?.path?.includes('dates')) {
    //         error(ContainerAllocationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
    //         dispatch(clearFailure([]));
    //     } else if (containerAllocationSuccess?.status === 200 || containerAllocationSuccess?.status === 201) {
    //         if (containerAllocationSuccess?.data?.type === 'addToContainer') {
    //             success('AO lines have been added to Container', Position.TOP_RIGHT);
    //             dispatch(fetchAssignedPo({ containerDetailsId: location?.state?.containerDetailsId, bpId: id }));
    //             setPoDetails(false);
    //         } else if (containerAllocationSuccess?.data?.type === 'removeFromContainer') {
    //             success('AO lines have been removed from Container', Position.TOP_RIGHT);
    //             dispatch(fetchAssignedPo({ containerDetailsId: location?.state?.containerDetailsId, bpId: id }));
    //         }
    //         dispatch(fetchAllPo({ bpId: id }));
    //         dispatch(clearFailure([]));
    //     }
    // }, [isError, containerAllocationSuccess, ContainerAllocationError]);

    const ContainerDetailTab = () => {
        return (
            <div className="main-content-holder">
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Basic Details</h6>
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                                headerData?.bpStatus !== 'Booked' &&
                                containerData.containerNumber && (
                                    <ShowForPermissions permission="edit" type="bp">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Basic Details"
                                            onClick={() => setShowBasicDetails(true)}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    </ShowForPermissions>
                                )}
                        </div>
                        {(profileType === 'agent' || profileType === 'forwarder') &&
                            headerData?.bpStatus !== 'Booked' &&
                            !containerData.containerNumber && (
                                <ShowForPermissions permission="edit" type="bp">
                                    <div className="btn-holder">
                                        <button
                                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                                            title="Add Details"
                                            onClick={() => setShowBasicDetails(true)}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Details</span>
                                        </button>
                                    </div>
                                </ShowForPermissions>
                            )}
                    </div>
                    {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div> */}
                    <div className="card-detail-col">
                        {basicData?.map((element: any, index: number) => {
                            if (profileType === 'client') {
                                return (
                                    <>
                                        {(element?.key === 'containerMode' || element?.key === 'containerNumber') && (
                                            <div className="details-col box-content-holder" key={`basicData ${index}`}>
                                                <h6 className="details-box-heading">
                                                    {element.title}
                                                    {element.required && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                                </h6>
                                                <div className="details-box-content">{element.fields}</div>
                                            </div>
                                        )}
                                    </>
                                );
                            } else {
                                return (
                                    <div className="details-col box-content-holder" key={`basicData ${index}`}>
                                        <h6 className="details-box-heading">
                                            {element.title}
                                            {element.required && <span className="mandatory-field-text">*</span>}
                                        </h6>
                                        <div className="details-box-content">{element.fields}</div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                                headerData?.bpStatus !== 'Booked' &&
                                containerData.collectionDate && (
                                    <ShowForPermissions permission="edit" type="bp">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Dates Details"
                                            onClick={() => setShowDatesDetails(true)}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    </ShowForPermissions>
                                )}
                        </div>
                        {(profileType === 'agent' || profileType === 'forwarder') &&
                            headerData?.bpStatus !== 'Booked' &&
                            !containerData.collectionDate && (
                                <ShowForPermissions permission="edit" type="bp">
                                    <div className="btn-holder">
                                        <button
                                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                                            title={
                                                !containerData.containerNumber
                                                    ? 'Please complete the basic details'
                                                    : 'Add Details'
                                            }
                                            disabled={!containerData.containerNumber}
                                            onClick={() => setShowDatesDetails(true)}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Dates</span>
                                        </button>
                                    </div>
                                </ShowForPermissions>
                            )}
                    </div>
                    {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div> */}
                    <div className="card-detail-col">
                        {dateData?.map((element, index) => {
                            return (
                                <div className="details-col box-content-holder" key={`dateData ${index}`}>
                                    <h6 className="details-box-heading">
                                        {element.title}
                                        {element.required && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    <div className="details-box-content">{element.fields}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    const viewContainerRenderer = () => {
        switch (tab) {
            case 0:
                return <ContainerDetailTab />;
        }
    };
    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            <div className="main-header-content-holder large-header  -container-details"></div>
            <div className="content-section main-wrapper po-selection-grid">
                <div className="main-wrapper">{viewContainerRenderer()}</div>
            </div>
            {showBookingSideBar && <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar} />}

            {/* <BasicDetailsModal
                modalView={showBasicDetails}
                setModalView={setShowBasicDetails}
                containerData={containerData}
                bpId={id}
                allocationId={allocationId}
                containerDetailsId={
                    currentContainerDetailsId ? currentContainerDetailsId : headerData?.containerDetailsId
                }
                setCancelModalView={setCancelModalView}
            />

            <DatesModal
                modalView={showDatesDetail}
                setModalView={setShowDatesDetails}
                containerData={containerData}
                bpId={id}
                allocationId={allocationId}
                containerDetailsId={
                    currentContainerDetailsId ? currentContainerDetailsId : headerData?.containerDetailsId
                }
                setCancelModalView={setCancelModalView}
            /> */}
            <CancelModal
                displayFunction={() => {
                    showBasicDetails && setShowBasicDetails(false);
                    showDatesDetail && setShowDatesDetails(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
            {poDetails && (
                <PoDetailsModal
                    isLoading={loader}
                    setLoader={setLoader}
                    handle={setPoDetails}
                    containerDetailsId={location?.state?.containerDetailsId}
                    setPoDetails={setPoDetails}
                />
            )}
        </div>
    );
};
export default Index;
