import { useState } from 'react';
import ShowForPermissions from 'src/ShowForPermissions';
import './cancel-discard-modal.scss';

interface Props {
    aoNumber: string;
    blp: string;
    clientName: string;
    onClose: () => void;
    onConfirm: (reason: string) => void;
    type: 'cancel' | 'discard';
    warningText: string;
}

const Index: React.FC<Props> = ({ aoNumber, blp, clientName, onClose, onConfirm, type, warningText }) => {
    const [reason, setReason] = useState('');
    const onSubmit = () => {
        onConfirm(reason);
    };
    return (
        <div className="app-modal">
            <div className="modal-content-holder medium-popup ao-cancellation-modal">
                <div className="modal-header">
                    <div className="title">{type === 'cancel' ? 'Cancel' : 'Discard'} Allocation Order</div>
                    <button className="app-btn modal-close-btn" onClick={onClose}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">{warningText}</div>
                        </div>
                        <div className="layout-item">
                            <h6 className="heading-type-1">Allocation Order No.</h6>
                            <div className="description-type-1">{aoNumber}</div>
                        </div>
                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Client Name</h6>
                                <div className="description-type-1">{clientName}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">BLP</h6>
                                <div className="description-type-1">{blp}</div>
                            </div>
                        </div>
                        <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder={`Enter the reason to ${type} allocation order`}
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            // to limit the reason's length to 255 and allow delete characters using backspace
                                            if (reason.length >= 255 && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="app-btn app-btn-secondary modal-btn footer-btn" title="Back" onClick={onClose}>
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <ShowForPermissions permission="edit" type="ao">
                        <button
                            className="app-btn app-btn-primary modal-btn footer-btn "
                            title={`${type === 'cancel' ? 'Confirm Cancellation' : 'Discard AO'}`}
                            onClick={onSubmit}
                            disabled={!reason?.trim()?.length}
                        >
                            <span className="button-text footer-button-text">
                                {type === 'cancel' ? 'Confirm Cancellation' : 'Discard AO'}
                            </span>
                        </button>
                    </ShowForPermissions>
                </div>
            </div>
        </div>
    );
};
export default Index;
