import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// import './agent-list.scss';
import './index.scss';
import RateHeader from './Header';
import RateTab from './Tab';

import GridLayout from './grid';
import { getRateByPK } from 'src/services/apis/qmsApi';
import { Position, error } from 'src/utils/toast';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import { getSingleEvent } from 'src/services/apis/eventsApi';
import HistModal from './histModal';

const Index: React.FC = () => {
    const { id } = useParams();
    const [rsRateSections, setRsRateSections] = useState([] as any);
    const [rsRateSections2, setRsRateSections2] = useState('');
    const [rsRateDataObj, setRsRateDataObj] = useState({} as any);
    const [eventsData, setEventsData] = useState('');
    const [showHistModal, setShowHistModal] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if (id) {
            getRateByPK(id)
                .then((res: any) => {
                    if (res?.length > 0 && res[0].RS_RateSections?.length > 0) {
                        setRsRateSections(res[0].RS_RateSections);
                        setRsRateSections2(JSON.stringify(res[0]));
                        setRsRateDataObj(res[0]);
                    }
                })
                .catch((err: any) => {
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                });
        }
    }, [id, update]);

    const navData = [
        {
            title: 'Rate Input',
            selected: 1,
            path: id ? `/qms/rate/${id}` : '/qms/rate',
        },
    ];

    //@ts-ignore
    window.rateItemAction = (action: string, key: string) => {
        if (action === 'HIS') {
            getSingleEvent(key)
                .then((res: any) => {
                    setShowHistModal(true);
                    setEventsData(res);
                })
                .catch((err: any) => {
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                });
        }
    };

    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-header-content-holder booking-proposal-header large-header">
                <Breadcrumbs
                    firstName={'Rates'}
                    firstUrl={'/qms/rate'}
                    secondName={'Rate Input'}
                    secondUrl={'#'}
                    backUrl={'/qms/rate'}
                />
                <RateHeader setUpdate={setUpdate} update={update}></RateHeader>
                <RateTab navData={navData} />
            </div>
            {rsRateDataObj.RA_MandatoryFieldsProvided ? (
                <div className="grid-section grid-p-0">
                    <div className="main-content-holder ">
                        <div>
                            <div id="tabstrip">
                                <ul>
                                    {rsRateSections.length > 0 &&
                                        rsRateSections.map((section: any, index: number) => (
                                            <li className={index === 0 ? `k-active` : ''} data-code={section.RS_Code}>
                                                {section.RS_Name}
                                            </li>
                                        ))}
                                </ul>
                                {rsRateSections.length > 0 &&
                                    rsRateSections.map((section: any) => (
                                        <div key={section.RS_Code} className="insight-agent-list-data-trend">
                                            <div className={`container-fluid`}>
                                                <div className="">
                                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                                        {/* <div className="pageheading-holder">
                                                    <div className="back-btn-holder">
                                                        <h2 className="page-heading">{section.RS_Name}</h2>
                                                    </div>

                                                    <div className="header-container-view-eye-reason-wrapper">
                                                        <div className="header-container-view">
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">{section.RS_CD_Status}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                        <div id={section.RS_Code}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {rsRateSections2 !== '' && (
                            <HelmetProvider key={id}>
                                <GridLayout rs={rsRateSections2} />
                            </HelmetProvider>
                        )}
                    </div>
                </div>
            ) : null}
            {showHistModal ? <HistModal eventsData={eventsData} setModalView={setShowHistModal} /> : null}
        </div>
    );
};
export default Index;
