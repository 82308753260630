import moment from 'moment';
import React, { useState } from 'react';
import ShowForPermissions from '../../../ShowForPermissions';
import './list-grid.scss';
// import SailingViewModal from '../SelectedSailingSchelude/SailingScheduleViewModal';
import SailingDetailModal from '../BookingSailingSchelude/SailingScheduleDetailModal';
import BSTooltip from 'src/components/common/BSTooltip';
import { useUserProfile } from 'src/hooks/useUserProfile';
import ColoaderOptionModal from '../BookingSailingSchelude/ColoaderOptionModal';

interface Props {
    data: any;
    dates: any;
    rangeSelectFn: any;
    selectedValue: any;
    bpStatus: any;
    selectedRange: any;
    setselectedColumn: any;
    valueSelected: any;
    hideGrid?: boolean;
}
const Index: React.FC<Props> = ({
    data,
    selectedValue,
    bpStatus,
    setselectedColumn,
    selectedRange,
    valueSelected,
    hideGrid,
}) => {
    const [display, setdisplay] = useState(false);
    const [dataAll, setdata] = useState({});
    const { profileType } = useUserProfile();

    const [optionModal, setOptionModal] = useState<any>(null);
    function optionLength(res: any) {
        return (res?.options?.nacOptions?.length ?? 0) + (res?.options?.fakOption === 'FAK' ? 1 : 0);
    }

    return (
        <div className="view-list-grid">
            {(data?.length === 0 || hideGrid) && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No Sailing Schedules Available</p>
                </div>
            )}
            <div className="list-grid">
                {!hideGrid &&
                    data?.map((res: any) => (
                        <>
                            <div
                                className={`grid-child-item col-grid-checkbox-item ${
                                    res?.sailingId === valueSelected?.sailingId ? ' active' : ''
                                }`}
                            >
                                <ShowForPermissions type="bp" permission="create">
                                    {bpStatus !== 'Cancelled' &&
                                        bpStatus !== 'Withdrawn' &&
                                        bpStatus !== 'Pending_Reassignment' &&
                                        (profileType === 'forwarder' || profileType === 'agent') && (
                                            <div className="grid-child-data">
                                                <div className="checkbox-item">
                                                    <label
                                                        className="app-check-wrapper"
                                                        onClick={() => {
                                                            // selectedValue(res);
                                                            // setselectedColumn(selectedRange.startDate);

                                                            if (res?.options?.nacOptions?.length) {
                                                                if (res?.sailingId !== valueSelected?.sailingId) {
                                                                    setOptionModal(res ?? {});
                                                                }
                                                                if (
                                                                    res?.options?.nacOptions?.length === 1 &&
                                                                    !res?.options?.fakOption
                                                                ) {
                                                                    selectedValue({
                                                                        ...res,
                                                                        option: res?.options?.nacOptions[0],
                                                                    });
                                                                    setselectedColumn(selectedRange.startDate);
                                                                    setOptionModal(null);
                                                                }
                                                            } else {
                                                                selectedValue({
                                                                    ...res,
                                                                    option: res?.options?.fakOption,
                                                                });
                                                                setselectedColumn(selectedRange.startDate);
                                                            }
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            className="checkbox-input"
                                                            name="grid-radio-group"
                                                            checked={
                                                                res?.sailingId === valueSelected?.sailingId
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></input>
                                                        <div className="checkmark">
                                                            <svg className="svg-icon notification-circle-icon">
                                                                <use href="#circleIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                </ShowForPermissions>
                            </div>
                            <div
                                className={`grid-child-item col-name ${
                                    res?.sailingId === valueSelected?.sailingId ? ' active' : ''
                                }`}
                            >
                                <div className="grid-child-data list-view-items">
                                    <div className="ship-logo">
                                        <svg className="svg-icon ship-icon">
                                            <use href="#shipIcon">
                                                <title>Icon</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-heading">{res?.shippingline}</span>
                                            {/* <span className="modal-toast">FAK</span> */}
                                            {/* <div className="status-ui nac-status nac-s">
                                    <div className="status-nac-left">NAC </div>
                                    <div className="status-nac-right s-status">S</div>
                                </div> */}
                                        </div>
                                        <div className="content-detail">
                                            <span>{res?.voyageId}</span>
                                        </div>
                                        <div className="content-detail-main-wrapper">
                                            {(!valueSelected?.option || valueSelected.sailingId !== res.sailingId) &&
                                                optionLength(res) === 1 && (
                                                    <>
                                                        {!!res?.options?.nacOptions?.length && (
                                                            <div
                                                                className={`status-ui nac-status${
                                                                    res?.options?.nacOptions[0].isPrimaryClient === 1
                                                                        ? ' nac-p'
                                                                        : res?.options?.nacOptions[0]
                                                                              .isPrimaryClient === 0
                                                                        ? ' nac-s'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <div className="status-nac-left">NAC</div>
                                                                <div
                                                                    className={`status-nac-right${
                                                                        res?.options?.nacOptions[0].isPrimaryClient ===
                                                                        1
                                                                            ? ' p-status'
                                                                            : res?.options?.nacOptions[0]
                                                                                  .isPrimaryClient === 0 && ' s-status'
                                                                    }`}
                                                                >
                                                                    {res?.options?.nacOptions[0]?.isPrimaryClient === 1
                                                                        ? 'P'
                                                                        : res?.options?.nacOptions[0]
                                                                              .isPrimaryClient === 0 && 'S'}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {res?.options?.fakOption === 'FAK' && (
                                                            <div className="status-ui confirmed-status contract-pill">
                                                                <div className="status-nac-left">FAK</div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            {/* {(!valueSelected?.option ||
                                            valueSelected.sailingId !== res.sailingId) && (
                                            <>
                                                {res?.contractType === 'NAC' ? (
                                                    <div
                                                        className={`status-ui nac-status${
                                                            res.isPrimaryClient === 1
                                                                ? ' nac-p'
                                                                : res.isPrimaryClient === 0
                                                                ? ' nac-s'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className="status-nac-left">{res?.contractType} </div>
                                                        <div
                                                            className={`status-nac-right${
                                                                res.isPrimaryClient === 1
                                                                    ? ' p-status'
                                                                    : res.isPrimaryClient === 0 && ' s-status'
                                                            }`}
                                                        >
                                                            {res?.isPrimaryClient === 1
                                                                ? 'P'
                                                                : res.isPrimaryClient === 0 && 'S'}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="status-ui confirmed-status contract-pill">
                                                        <div className="status-nac-left">{res?.contractType} </div>
                                                    </div>
                                                )}
                                            </>
                                        )} */}
                                            {valueSelected?.option && valueSelected.sailingId === res.sailingId && (
                                                <>
                                                    {valueSelected?.option !== 'FAK' ? (
                                                        <div
                                                            className={`status-ui nac-status${
                                                                valueSelected?.option?.isPrimaryClient
                                                                    ? ' nac-p'
                                                                    : ' nac-s'
                                                            }`}
                                                        >
                                                            <div className="status-nac-left">NAC</div>
                                                            <div
                                                                className={`status-nac-right${
                                                                    valueSelected?.option?.isPrimaryClient
                                                                        ? ' p-status'
                                                                        : ' s-status'
                                                                }`}
                                                            >
                                                                {valueSelected?.option?.isPrimaryClient ? 'P' : 'S'}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="status-ui confirmed-status contract-pill">
                                                            <div className="status-nac-left">FAK</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {/* BS tooltip and Edit want to display when the NAC-S & NAC-P data rentered in the
                                        data means */}

                                            {valueSelected?.option &&
                                                // res?.contractType === 'NAC' &&
                                                valueSelected.sailingId === res.sailingId && (
                                                    <>
                                                        {valueSelected?.option !== 'FAK' && (
                                                            <BSTooltip
                                                                id="info"
                                                                classList="action-btn-wrapper"
                                                                position="top"
                                                                tooltipClassList="info-list-grid"
                                                                title={
                                                                    <>
                                                                        <div className="grid-tooltip layout-badge-count">
                                                                            <div className="badge-status">
                                                                                <div className="content-list-wrapper">
                                                                                    <div className="content-heading-holder">
                                                                                        {valueSelected?.option !==
                                                                                            'FAK' && (
                                                                                            <span className="tab-heading">
                                                                                                {
                                                                                                    valueSelected
                                                                                                        ?.option
                                                                                                        ?.clientName
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                        {valueSelected?.option !==
                                                                                            'FAK' && (
                                                                                            <div className="status-ui nac-status nac-s">
                                                                                                <div className="status-nac-left">
                                                                                                    NAC
                                                                                                </div>
                                                                                                <div
                                                                                                    className={`status-nac-right ${
                                                                                                        valueSelected
                                                                                                            ?.option
                                                                                                            ?.isPrimaryClient
                                                                                                            ? 'p-status'
                                                                                                            : 's-status'
                                                                                                    }`}
                                                                                                >
                                                                                                    {valueSelected
                                                                                                        ?.option
                                                                                                        ?.isPrimaryClient
                                                                                                        ? 'P'
                                                                                                        : 'S'}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                        {valueSelected?.option ===
                                                                                            'FAK' && (
                                                                                            <div className="status-ui confirmed-status contract-pill">
                                                                                                <div className="status-nac-left">
                                                                                                    FAK
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    {/* {valueSelected?.option !==
                                                                                        'FAK' && (
                                                                                        <div className="content-count">
                                                                                            {valueSelected?.option
                                                                                                ?.teuBalance || 0}
                                                                                        </div>
                                                                                    )} */}
                                                                                </div>
                                                                                {valueSelected?.option !== 'FAK' && (
                                                                                    <div className="content-list-wrapper">
                                                                                        <div className="box-content-holder">
                                                                                            {
                                                                                                valueSelected?.option
                                                                                                    ?.contractName
                                                                                            }
                                                                                            <span className="contract-count">
                                                                                                {
                                                                                                    valueSelected
                                                                                                        ?.option
                                                                                                        ?.contractVersion
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="box-content-holder">
                                                                                            {/* TEU of{' '}
                                                                                            {valueSelected?.option
                                                                                                ?.teuTotal || 0} */}
                                                                                            {
                                                                                                valueSelected?.option
                                                                                                    ?.teuTotal
                                                                                            }{' '}
                                                                                            TEU{' '}
                                                                                            {
                                                                                                valueSelected?.option
                                                                                                    ?.teuCommintmentUnit
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="badge-count"></div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            >
                                                                <button className="app-btn grid">
                                                                    <span className="reset-unit-icon">
                                                                        <svg className="svg-icon error-icon">
                                                                            <use xlinkHref="#infoIconRound">
                                                                                <title>Information</title>
                                                                            </use>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </BSTooltip>
                                                        )}{' '}
                                                        {optionLength(res) > 1 &&
                                                            res?.sailingId === valueSelected?.sailingId && (
                                                                <a
                                                                    className="more-link space-left"
                                                                    href="javascript:void(0)"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setOptionModal(res ?? {});
                                                                    }}
                                                                >
                                                                    Edit
                                                                </a>
                                                            )}
                                                    </>
                                                )}
                                            <ShowForPermissions type="bp" permission="create">
                                                {bpStatus !== 'Cancelled' &&
                                                    (profileType === 'forwarder' || profileType === 'agent') && (
                                                        <>
                                                            {valueSelected?.sailingId !== res?.sailingId &&
                                                                optionLength(res) > 1 && (
                                                                    <a
                                                                        className="more-link"
                                                                        href="javascript:void(0)"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setOptionModal(res ?? {});
                                                                        }}
                                                                    >
                                                                        {optionLength(res)} Option
                                                                        {optionLength(res) > 1 && 's'}
                                                                    </a>
                                                                )}
                                                        </>
                                                    )}
                                            </ShowForPermissions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`grid-child-item col-direction ${
                                    res?.sailingId === valueSelected?.sailingId ? ' active' : ''
                                }`}
                            >
                                <div className="grid-child-data list-view-items">
                                    <div className="ship-logo">
                                        <svg className="svg-icon ship-icon">
                                            <use href="#transhipmentIcon">
                                                <title>Icon</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-heading">
                                                {res?.serviceType === 'Direct' ? 'Direct' : 'Multi-Legged'}
                                            </span>
                                        </div>
                                        <div className="content-detail">
                                            <span>{res?.service}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="grid-child-item col-plan">
                            <div className="grid-child-data">
                                <ul className="events">
                                    <li>
                                        <span className="event-content">Planned</span>
                                        <span className="test"> {moment(res?.plannedETD).format('DD/MM/YYYY')}</span>
                                    </li>

                                    <li className="event-items-day-list">
                                        <span className="event-content">{res?.plannedTransitDays} days</span>
                                        <span className="test"> {moment(res?.plannedETA).format('DD/MM/YYYY')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="grid-child-item col-predicate">
                            <div className="grid-child-data">
                                <ul className="events">
                                    <li>
                                        <span className="event-content">Predicted</span>
                                        <span className="test">{moment(res?.predictedETD).format('DD/MM/YYYY')}</span>
                                    </li>

                                    <li className="event-items-day-list">
                                        <span className="event-content">{res?.predictedTransitDays} days</span>
                                        <span className="test">{moment(res?.predictedETA).format('DD/MM/YYYY')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                            <div
                                className={`grid-child-item -progress-section ${
                                    res?.sailingId === valueSelected?.sailingId ? ' active' : ''
                                }`}
                            >
                                <div className="des-wrapper">
                                    <div className="des-progress-bar -labelling">
                                        <div className="destination begin-journey">
                                            <div className="point12">
                                                <div className="point1">ETD</div>
                                            </div>
                                        </div>
                                        <div className="destination end-journey">
                                            <span className="point1">ETA</span>
                                        </div>
                                    </div>
                                    <div className="des-progress-bar">
                                        <div className="destination begin-journey">
                                            <div className="point12">
                                                <div
                                                    className="point1"
                                                    title={moment(res?.plannedETD).format('DD-MM-YYYY')}
                                                >
                                                    {moment(res?.plannedETD).format('DD-MM-YYYY')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="destination center-journey ">
                                            <span className="center-point planned-label">
                                                Planned, {res?.plannedTransitDays} day
                                                {res?.plannedTransitDays > 1 && 's'}
                                            </span>
                                        </div>
                                        <div className="destination end-journey">
                                            <span className="point1">
                                                {moment(res?.plannedETA).format('DD-MM-YYYY')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="des-progress-bar">
                                        <div className="destination begin-journey">
                                            <div className="point12">
                                                <div
                                                    className="point1"
                                                    title={moment(res?.predictedETD).format('DD-MM-YYYY')}
                                                >
                                                    {moment(res?.predictedETD).format('DD-MM-YYYY')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="destination center-journey">
                                            <span className="center-point">
                                                Predicted, {res?.predictedTransitDays} day
                                                {res?.predictedTransitDays > 1 && 's'}
                                            </span>
                                        </div>
                                        <div className="destination end-journey">
                                            <span className="point1">
                                                {moment(res?.predictedETA).format('DD-MM-YYYY')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`grid-child-item col-action ${
                                    res?.sailingId === valueSelected?.sailingId ? ' active' : ''
                                }`}
                            >
                                <a
                                    className="more-link grid-icon icon-nav app-btn-secondary app-btn action-btn"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        setdata(res);
                                        setdisplay(true);
                                    }}
                                >
                                    <svg className="svg-icon eye-icon ">
                                        <use xlinkHref="#eyeIcon">
                                            <title>View</title>
                                        </use>
                                    </svg>
                                </a>
                            </div>
                        </>
                    ))}
            </div>
            {display && <SailingDetailModal displayFunc={setdisplay} data={dataAll} />}
            {/*<SailingDetailModal displayFunc={setdisplay} data={dataAll} />*/}
            {!!optionModal && (
                <ColoaderOptionModal
                    data={optionModal}
                    handleClose={setOptionModal}
                    onSave={(option: any) => {
                        selectedValue({ ...optionModal, option });
                        setselectedColumn(selectedRange.startDate);
                        setOptionModal(null);
                    }}
                    selected={valueSelected?.option}
                    valueSelected={valueSelected}
                />
            )}
        </div>
    );
};
export default Index;
