import { createSlice } from '@reduxjs/toolkit';
import { clientTableHeaders } from '../../utils/constants';
/* eslint-disable no-undefined */

export const dataSlice = createSlice({
    name: 'roles',
    initialState: {
        headerTab: [],
        isLoading: true,
        currentPage: 0,
        columnStatus: clientTableHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        rolesList: [],
        headerData: [],
        wholeClients: [],
        roleChangeStat: false,
        message: '',
        roleData: {},
        roleDetail: null,
        createSuccess: false,
        roleStart: false,
        deleteSuccess: false,
        wholeShipping: [],
        wholeAgent: [],
        wholeVendor: [],
        roleAssignmentList: [],
        rowData: [],
        deletedName: '',
        editSuccess: false,
        editroleStartVal: false,
        profileChange: false,
        profileChangeName: '',
        filterValues: {},
        appliedFilters: {
            currentOrder: false,
            columnStatus: 'roleName',
            perPageCount: 10,
            statusFilterValue: null,
            statusFilterValueRoles: null,
            statusFilter: null,
            currentPage: 0,
            appliedProfile: null, //Work around to fix the filter persistence among different profiles
            roleFilterValue: {
                status: false,
                value: '',
            },
            selectedRole: '',
        },
    },
    reducers: {
        fetchData: (state, action) => {
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
        },
        saveUsersData: (state, action) => {
            state.rolesList = action.payload?.info?.content;
            state.totalPages = action.payload?.info?.totalPages;
            state.totalItems = action.payload?.info?.totalElements;
            state.filterValues = action.payload?.info?.distinctColumnValues;
        },
        fetchAppliedFilter: (state, action) => {
            state.appliedFilters = action.payload;
        },
        fetchHeaderData: (state, action) => {
            state.headerTab = action.payload.headerTab?.info;
        },
        fetchRoleList: (_state, _action) => {
            // console.log(action);
        },
        saveRoleData: (state, action) => {
            state.roleData = action.payload?.info;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        createRoleNew: (_state, _action) => {
            // console.log(action);
        },
        editaRole: (_state, _action) => {
            // console.log(action);
        },
        savedResponseRole: (state, action) => {
            state.createSuccess = action.payload;
        },
        savedEditResponseRole: (state, action) => {
            state.editSuccess = action.payload;
        },
        createRoleStart: (state, action) => {
            state.roleStart = action.payload;
        },
        editRoleStart: (state, action) => {
            state.editroleStartVal = action.payload;
        },
        deleteaRole: (_state, _action) => {
            // console.log(action);
        },
        saveDeletedResponse: (state, action) => {
            state.deleteSuccess = action.payload;
        },
        listUserRoles: (_state, _action) => {
            // console.log(action);
        },
        roleDetailsFetch: (_state, _action) => {
            // console.log(action);
        },
        saveRoleDetail: (state, action) => {
            state.roleDetail = action.payload !== undefined && action.payload;
        },
        //saveRoles: (state, action) => {
        //    state.roles = action.payload?.info;
        //},
        changeRole: (_state, _action) => {
            // console.log(action);
        },
        changeStatus: (_state, _action) => {
            // console.log(action);
        },
        getHeaderData: (_state, _action) => {
            // console.log(action);
        },
        saveUsersHeaderData: (state, action) => {
            state.headerData = action.payload?.info;
        },
        downloadFile: (_state, _action) => {
            // console.log(action);
        },
        clientLinkUser: (_state, _action) => {
            // console.log(action);
        },
        clientUnlinkUser: (_state, _action) => {
            // console.log(action);
        },
        resendEmail: (_state, _action) => {
            // console.log(action);
        },
        listAllClient: (_state, _action) => {
            // console.log(action);
        },
        saveClient: (state, action) => {
            state.wholeClients = action.payload?.info;
        },
        saveVendor: (state, action) => {
            state.wholeVendor = action.payload?.info;
        },
        saveAgent: (state, action) => {
            state.wholeAgent = action.payload?.info;
        },
        saveShipping: (state, action) => {
            state.wholeShipping = action.payload?.info;
        },
        statusChangeSuccess: (state, action) => {
            state.roleChangeStat = action.payload.status;
            state.message = `${action.payload.name} is now ${action.payload.val === 'ACTIVE' ? 'Active' : 'Inactive'}`;
        },
        searchRolesData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.rolesList = action.payload?.content;
            state.totalPages = action.payload?.totalPages;
            state.totalItems = action.payload?.totalElements;
            state.filterValues = action.payload?.info?.distinctColumnValues;
            state.isLoading = false;
        },
        fetchRowData: (_state, _action) => {
            // console.log(state, 'fetch row data123');
        },
        saveRowData: (state, action) => {
            state.rowData = action.payload?.info;
        },
        setDeletename: (state, action) => {
            state.deletedName = action.payload;
        },
        profileChangeFn: (state, action) => {
            state.profileChange = action.payload.status;
            state.profileChangeName = action.payload.name;
        },
        profileChangeUpdation: (_state, _action) => {
            //console.log(_state);
        },
        fetchRoleListWithPermission: (state, action) => {
            state.roleAssignmentList = action.payload;
        },
    },
});

export const {
    fetchData,
    fetchHeaderData,
    setPageCount,
    saveUsersData,
    isLoadingFn,
    listUserRoles,
    changeRole,
    changeStatus,
    getHeaderData,
    saveUsersHeaderData,
    downloadFile,
    clientLinkUser,
    clientUnlinkUser,
    resendEmail,
    listAllClient,
    saveClient,
    statusChangeSuccess,
    createRoleNew,
    fetchRoleList,
    saveRoleData,
    roleDetailsFetch,
    saveRoleDetail,
    savedResponseRole,
    createRoleStart,
    deleteaRole,
    saveDeletedResponse,
    editaRole,
    saveShipping,
    saveAgent,
    saveVendor,
    searchRolesData,
    fetchRowData,
    saveRowData,
    setDeletename,
    savedEditResponseRole,
    editRoleStart,
    profileChangeFn,
    profileChangeUpdation,
    fetchAppliedFilter,
    fetchRoleListWithPermission,
} = dataSlice.actions;
export default dataSlice.reducer;
