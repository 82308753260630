import React from 'react';
import 'src/assets/scss/components/_layout.scss';
import 'src/assets/scss/components/_modal.scss';
import './unsavedmodal.scss';

interface BasicProps {
    cancelText?: string;
    description: string;
    disableSave?: boolean;
    handleDiscard: () => void;
    handleClose: () => void;
    submitText?: string;
    title: string;
}
interface WithFormId extends BasicProps {
    formId: string;
    handleSubmit?: () => void;
}
interface WithHandleSubmit extends BasicProps {
    formId?: string;
    handleSubmit: () => void;
}
//Either formId or handleSubmit is required, if both present handleSubmit will get priority
type Props = WithFormId | WithHandleSubmit;

const Index: React.FC<Props> = ({
    cancelText,
    description,
    disableSave,
    formId,
    handleDiscard,
    handleClose,
    handleSubmit,
    submitText,
    title,
}) => {
    return (
        <div className="app-modal">
            <div className="modal-content-holder unsaved-modal">
                <div className="modal-header">
                    <div className="title">{title}</div>
                    <button className="app-btn modal-close-btn" onClick={handleClose}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <p>{description}</p>
                </div>
                <div className="modal-footer">
                    <button className="app-btn app-btn-secondary modal-btn footer-btn" onClick={handleDiscard}>
                        <span className="button-text footer-button-text">{cancelText || 'No'}</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form={formId ? formId : ''}
                        title={submitText || 'Yes'}
                        onClick={handleSubmit}
                        disabled={disableSave}
                    >
                        <span className="button-text vendor-button-text">{submitText || 'Yes'}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
