import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import BookingAvailableGrid from '../BookingAvailableGrid';
// import BookingAddedGrid from '../BookingAddedGrid';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import '../../BookingProposal/POSelection.scss';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSelectedSailing } from 'src/store/reducers/aobookingProposal/sailingReducer';
import { NavData } from 'src/pages/PoBookingsAir/Wrapper';
import { airOptionStatus } from '../Options';

interface Props {
    navData: NavData[];
}
const Index: React.FC<Props> = ({ navData }) => {
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    // const { selectedSailing } = useSelector((state: RootState) => state.aosailingRed);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [isAdded, setisAdded] = useState(false);
    const { id } = useParams();
    const hasBookingId = id !== 'POSelection' && id !== 'POSelectionDetails';
    const dispatch = useDispatch();
    // useEffect(() => {
    //     hasBookingId && dispatch(fetchSelectedSailing(id));
    // }, [id]);
    /* eslint-disable no-undefined */

    // const navData = [
    //     {
    //         title: 'PO Selection',
    //         selected: 1,
    //         path: id ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new',
    //         disabled: isAdded,
    //     },
    //     {
    //         title: 'Flight Schedules',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/FlightSelectionDetails`,
    //         disabled: !isAdded,
    //     },
    // ];
    // if (
    //     headerData.bpStatus === 'Ready_for_Booking' ||
    //     headerData.bpStatus === 'Booked' ||
    //     headerData.bpStatus === 'Draft_Booking'
    // ) {
    //     navData.push({
    //         title: 'Booking Details',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/booking_details`,
    //         disabled: false,
    //     });
    // }

    // if (headerData.bpStatus === 'Ready_for_Booking' || headerData.bpStatus === 'Draft_Booking') {
    //     navData.push(
    //         {
    //             title: 'Shipment Details',
    //             selected: 0,
    //             path: `/air_booking_list/${id}/shipment_details`,
    //             disabled: false,
    //         },

    //         {
    //             title: 'Attachments',
    //             selected: 0,
    //             path: `/air_booking_list/${id}/attachments`,
    //             disabled: false,
    //         }
    //     );
    // }

    const customNavData = navData.map((navItem) => {
        if (navItem.key === 'po_selection') {
            return { ...navItem, selected: 1, disabled: isAdded };
        } else if (navItem.key === 'options') {
            return { ...navItem, disabled: !(headerData?.tabValues as any)?.po };
        } else if (navItem.key === 'flight_schedules') {
            return {
                ...navItem,
                disabled:
                    !(headerData?.tabValues as any)?.airOptions ||
                    airOptionStatus?.includes(headerData?.bpStatus ?? ''),
            };
        } else if (navItem.key === 'packing') {
            return {
                ...navItem,
                disabled:
                    !(headerData?.tabValues as any)?.airSchedules ||
                    airOptionStatus?.includes(headerData?.bpStatus ?? ''),
            };
        } else {
            return navItem;
        }
    });
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder booking-proposal-header large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={hasBookingId ? 'Booking Proposal Details' : 'New Booking Proposal'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                    isAdded={isAdded}
                ></BookingProposalHeader>
                {/* <BookingTabs activeTab="po_selection" id={id} isDisabled={isAdded} /> */}
                <BookingProcessTab navData={customNavData} />
            </div>
            {/* DATA GRID SECTION */}
            <BookingAvailableGrid
                setisAdded={setisAdded}
                showBookingCancelModal={showBookingCancelModal}
                setShowBookingCancelModal={setShowBookingCancelModal}
            />
            {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )}
        </div>
    );
};
export default Index;
