import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post } from '../HttpMethods';
import axios from 'axios';

export const getNoteType = async () => {
    try {
        const response = await get('cix/scr/admin/config/NT_CD_NoteType/lst/CD_KEY/CD_Value', null, externalBaseUrl);
        return response?.data?.map((res: any) => ({
            label: res?.CD_Value,
            value: res?.CD_KEY,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getSingleNote = async (id: string) => {
    try {
        const response = await get('cix/scr/utility/note/' + id, null, externalBaseUrl);
        return response?.data?.map((res: any) => ({
            NT_PK: res?.NT_PK,
            NT_FKID: res?.NT_FKID,
            NT_Subject: res?.NT_Subject,
            NT_CD_NoteType: res?.NT_CD_NoteType,
            NT_IsImportant: res?.NT_IsImportant,
            NT_IsForwarderViewable: res?.NT_IsForwarderViewable,
            NT_IsAgentViewable: res?.NT_IsAgentViewable,
            NT_IsClientViewable: res?.NT_IsClientViewable,
            NT_IsSupplierViewable: res?.NT_IsSupplierViewable,
            NT_IsLockedToCreator: res?.NT_IsLockedToCreator,
            NT_Text: res?.NT_Text,
            US_UserName: res?.US_UserName,
        }))?.[0];
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const readEvents = async (id: string) => {
    try {
        const response = await get(
            `/cix/scr/lifecycle/events?take=99&filter[logic]=and&filter[filters][1][field]=EV_FK&filter[filters][1][operator]=eq&filter[filters][1][value]=${id}&filter[filters][2][field]=EV_CD_Code&filter[filters][2][operator]=eq&filter[filters][2][value]=REA&attributes=EV_CD_Code,EV_Context,EV_CreatedDateTime`,
            null,
            externalBaseUrl
        );
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const buyerApprovalEventHistory = async (id: string) => {
    try {
        const response = await get(
            `/cix/scr/lifecycle/events?take=99&filter[logic]=and&filter[filters][1][field]=EV_FK&filter[filters][1][operator]=eq&filter[filters][1][value]=${id}&attributes=EV_CD_Code,EV_Context,EV_CreatedDateTime,CD_Value,US_UserName`,
            null,
            externalBaseUrl
        );
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addNote = async (payload: any) => {
    try {
        delete payload?.US_UserName;
        const response = await post('cix/scr/utility/note', payload, null, false, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const updateNote = async (id: string, payload: any) => {
    try {
        delete payload?.US_UserName;
        const response = await patch('cix/scr/utility/note/' + id, payload, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
