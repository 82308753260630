import React, { useState } from 'react';
import './contract-modal.scss';
import '../../../../assets/scss/components/_modal.scss';

interface BioProps {
    setSubmitModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    handleActivateColoader: any;
}
const Index: React.FC<BioProps> = (props) => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <div className="app-modal profile-popup container">
            <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2 className="title">Shipping Line does not have an active contract</h2>
                    <button onClick={() => props.setSubmitModalVisibility(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="carrier-submit-checkbox">
                        <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                        <p>This Carrier does not have a contract attached. Do you wish to proceed with activation?</p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => props.setSubmitModalVisibility(false)}
                        className="app-btn app-btn-secondary modal-btn"
                    >
                        <span className="button-text vendor-button-text">No</span>
                    </button>
                    <button
                        onClick={() => props.handleActivateColoader()}
                        style={{ marginLeft: '8px' }}
                        className="app-btn app-btn-primary modal-btn"
                        disabled={isChecked ? false : true}
                    >
                        <span className="button-text vendor-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
