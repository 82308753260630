import React, { useEffect, useRef, useState } from 'react';
import './agent-port-detail.scss';
import '../agent-tab.scss';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    selectAgentPortDetailUser,
    fetchAgentPortDetailsList,
    // fetchAgentPortDetailsColumnList,
    selectAgentPortDetailCurrentPage,
    selectAgentPortDetailColumnStatus,
    selectAgentPortDetailtotalNoOfPages,
    selectAgentPortDetailtotalNoOfItems,
    selectAgentPortDetailLoading,
    clearAgentPortDetails,
    selectAgentPortDetailColumnUser,
    AgentPortDetailsDocumentsDtoList,
    searchAgentPortDetailsData,
    // searchAgentPortDetailsData,
} from '../../../../store/reducers/profiles/agents/portDetailsReducer';
import { paginationLimit, agentPortDetailsTableHeaders } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination/Pagination';
import { useMeasure } from 'react-use';
import HeaderTabsAgents from 'src/pages/Profiles/Agents/HeaderTabsAgents';
import { fetchHeaderDataAgents } from 'src/store/reducers/agentReducer';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
/* eslint-disable no-undefined */

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const portDetail = useAppSelector(selectAgentPortDetailUser);
    const portDetailsColumn = useAppSelector(selectAgentPortDetailColumnUser);
    const [portDetailColumnData, setPortDetailColumnData] = useState<any>([]);
    const [portDetailColumnName, setPortDetailColumnName] = useState<any>('');
    const isLoading = useAppSelector(selectAgentPortDetailLoading);
    const [selectedHeader, setSelectedHeader] = useState(agentPortDetailsTableHeaders.PortName);
    const currentPage = useAppSelector(selectAgentPortDetailCurrentPage);
    const columnStatus = useAppSelector(selectAgentPortDetailColumnStatus);
    const totalNoOfPages = useAppSelector(selectAgentPortDetailtotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectAgentPortDetailtotalNoOfItems);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [selectedFilter, _setselectedFilter] = useState<any | null>(null);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [currentSelectedFilter, setCurrentSelectedFilter] = useState('');
    const [refPortName, { width: widthPortName }] = useMeasure<HTMLTableCellElement>();
    const [refLocation, { width: widthLocation }] = useMeasure<HTMLTableCellElement>();
    const [refDirection, { width: widthDirection }] = useMeasure<HTMLTableCellElement>();
    const [refAir, { width: widthAir }] = useMeasure<HTMLTableCellElement>();
    const [refSea, { width: widthSea }] = useMeasure<HTMLTableCellElement>();
    const [refRail, { width: widthRail }] = useMeasure<HTMLTableCellElement>();
    const [refRoad, { width: _widthRoad }] = useMeasure<HTMLTableCellElement>();
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setToggleDropdown);

    const { agentHeader } = useSelector((state: RootState) => state.agent);

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
        id,
    };

    useEffect(() => {
        dispatch(clearAgentPortDetails([]));
        dispatch(fetchHeaderDataAgents(id));
    }, []);

    useEffect(() => {
        if (portDetailsColumn) {
            setPortDetailColumnData((portDetail as any)?.info?.distinctColumnValues[portDetailColumnName]);
            setStatusFilterSelected([]);
            setSearchName('');
        }
    }, [portDetailsColumn, dispatch]);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        // if (keyword) {
        // const results = portDetailsColumn?.info.filter((user: string) => {
        //     return user && user.toLowerCase().includes(keyword.toLowerCase());
        // });
        if (keyword?.trim()) {
            const list = (portDetail as any)?.info?.distinctColumnValues[portDetailColumnName];
            const filteredArray = list.filter((str: any) => str.toLowerCase().includes(keyword.toLowerCase()));
            setPortDetailColumnData(filteredArray);
        } else {
            setPortDetailColumnData((portDetail as any)?.info?.distinctColumnValues[portDetailColumnName]);
        }
        // } else {
        //     setPortDetailColumnData((portDetail as any)?.info?.distinctColumnValues[portDetailColumnName]);
        // }
        setSearchName(keyword);
    };

    useEffect(() => {
        if (statusFilterSelected?.length) {
            setCurrentSelectedFilter(portDetailColumnName);
        } else {
            setCurrentSelectedFilter('');
        }
        if (searchTerm?.trim()?.length > 1) {
            // dispatch(
            //     searchAgentPortDetailsData({
            //         ...payload,
            //         keyword: searchTerm,
            //         id,
            //         currentPage: 0,
            //         columnStatus: selectedHeader,
            //         statusFilterColumn: portDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //         columnOrder: !currentOrder,
            //     })
            // );
        } else {
            // dispatch(
            //     fetchAgentPortDetailsList({
            //         ...payload,
            //         id,
            //         currentPage: 0,
            //         columnStatus: selectedHeader,
            //         statusFilterColumn: portDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //         columnOrder: !currentOrder,
            //     })
            // );
        }
    }, [statusFilterSelected]);

    const [currentPageNumber, setCurrentPageNumber] = useState<null | number>(null);

    useEffect(() => {
        if (currentPageNumber) {
            // dispatch(
            //     fetchAgentPortDetailsList({
            //         ...payload,
            //         currentPage: currentPageNumber - 1,
            //         perPageCount: perPageCount,
            //         columnStatus: selectedHeader,
            //         // statusFilter: selectedFilter,
            //         columnOrder: !currentOrder,
            //         statusFilterColumn: portDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //     })
            // );
        }
    }, [currentPageNumber]);

    useEffect(() => {
        if (searchTerm?.trim()?.length > 2) {
            // const payload2 = { ...payload, keyword: searchTerm, perPageCount, currentPage: 0 };
            // dispatch(searchAgentPortDetailsData(payload2));
        } else if (searchTerm?.trim()?.length === 0) {
            // dispatch(
            //     fetchAgentPortDetailsList({
            //         ...payload,
            //         currentPage: 0,
            //     })
            // );
        }
    }, [searchTerm]);

    useEffect(() => {
        setPortDetailColumnData((portDetail as any)?.info?.distinctColumnValues[portDetailColumnName]);
    }, [portDetailColumnName]);

    const filterByColumn = (e: any, columnName: string) => {
        setTimeout(() => {
            setToggleDropdown(!toggleDropdown);
        }, 100);
        setPortDetailColumnName(columnName);
        setSearchName('');
        filter(e);

        e.stopPropagation();
    };

    return (
        <div
            onClick={() => {
                setPageCountDisplay(false);
            }}
            className="main-wrapper container"
        >
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/agent" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/profile" className="breadcrumb-item">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to="/agent" className="breadcrumb-item">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Agent
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    {/* <Link to="/agent">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{localStorage.getItem('agentName')}</h2>
                </div>
                <div className="arrow-tab-container">
                    <div className="arrow-tab-holder vendor-arrow-holder">
                        <HeaderTabsAgents />
                    </div>
                </div>
            </div>
            <div className="grid-section agent-port-grid loader-container grid-p-0-15">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-holder">
                    {/* {portDetail?.info?.agentPortDetailList?.length === 0 && !isLoading && !searchTerm ? (
                        <div className="mandatory-warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">
                                Mandatory fields missed while integrating. Please integrate again with all mandatory
                                fields to proceed.
                            </div>
                        </div>
                    ) : null} */}
                    {/* <div className="grid-header">
                        <div className="search-entry-holder">
                            <input
                                placeholder="Search Port Details"
                                type="text"
                                className="search-input input-text"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            ></input>
                            <div
                                className={`search-toolip ${
                                    searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>

                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                            <button
                                className="app-btn text-close-btn"
                                onClick={() => {
                                    if (searchTerm?.trim()?.length) {
                                        setSearchTerm('');
                                        dispatch(
                                            searchAgentPortDetailsData({
                                                currentPage: 0,
                                                perPageCount: perPageCount,
                                                columnStatus: selectedHeader,
                                                statusFilter: selectedFilter,
                                                columnOrder: currentOrder,
                                            })
                                        );
                                    }
                                }}
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                    </div> */}
                    <LayoutWithColumns style={'tab'} content={'agent-port-details'} />
                    {/* <div onClick={() => setToggleDropdown(false)} className="grid-container">
                        {!portDetail?.info?.agentPortDetailList.length && (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Port Details Available</p>
                            </div>
                        )}
                        <div className="grid-scroll-content">
                            <table className="grid-table">
                                <thead>
                                    <tr className="grid-sticky-row">
                                        <th
                                            ref={refPortName}
                                            className="grid-cell-header col-port-name grid-sticky-column sticky-left-aligned"
                                        >
                                            <div className="grid-cell-data ">
                                                <span className="header-title">PORT NAME</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.PortName);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.PortName,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.PortName,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.PortName
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.PortName) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.PortName
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(0);
                                                            filterByColumn(e, agentPortDetailsTableHeaders.PortName);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th ref={refLocation} className="grid-cell-header col-location">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">LOCATION</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.Location);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Location,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Location,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.Location
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.Location) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.Location
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(widthPortName);
                                                            filterByColumn(e, agentPortDetailsTableHeaders.Location);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th ref={refDirection} className="grid-cell-header col-direction">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">DIRECTION</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.Direction);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus:
                                                                        agentPortDetailsTableHeaders.Direction,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus:
                                                                        agentPortDetailsTableHeaders.Direction,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.Direction
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.Direction) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.Direction
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(widthPortName + widthLocation);
                                                            filterByColumn(e, agentPortDetailsTableHeaders.Direction);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th ref={refAir} className="grid-cell-header col-air">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">AIR</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.Air);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Air,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Air,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.Air
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.Air) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.Air
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(
                                                                widthPortName + widthLocation + widthDirection
                                                            );
                                                            filterByColumn(e, agentPortDetailsTableHeaders.Air);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th ref={refSea} className="grid-cell-header col-sea">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">SEA</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.Sea);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Sea,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Sea,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.Sea
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.Sea) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.Sea
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(
                                                                widthPortName +
                                                                    widthLocation +
                                                                    widthDirection +
                                                                    widthAir
                                                            );
                                                            filterByColumn(e, agentPortDetailsTableHeaders.Sea);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th ref={refRail} className="grid-cell-header col-rail">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">RAIL</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.Rail);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Rail,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Rail,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.Rail
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.Rail) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.Rail
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(
                                                                widthPortName +
                                                                    widthLocation +
                                                                    widthDirection +
                                                                    widthAir +
                                                                    widthSea
                                                            );
                                                            filterByColumn(e, agentPortDetailsTableHeaders.Rail);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th ref={refRoad} className="grid-cell-header col-road">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">ROAD</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.Road);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Road,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: agentPortDetailsTableHeaders.Road,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.Road
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={
                                                        (toggleDropdown &&
                                                            portDetailColumnName ===
                                                                agentPortDetailsTableHeaders.Road) ||
                                                        currentSelectedFilter === agentPortDetailsTableHeaders.Road
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            setSetPosition(
                                                                widthPortName +
                                                                    widthLocation +
                                                                    widthDirection +
                                                                    widthAir +
                                                                    widthSea +
                                                                    widthRail
                                                            );
                                                            filterByColumn(e, agentPortDetailsTableHeaders.Road);
                                                        }}
                                                        className="app-btn filter-menu-button"
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-port-address">
                                            <div className="grid-cell-data ">
                                                <span className="header-title">PORT ADDRESS</span>
                                                <button
                                                    onClick={() => {
                                                        setcurrentOrder(!currentOrder);
                                                        setSelectedHeader(agentPortDetailsTableHeaders.PortAddress);
                                                        if (searchTerm?.trim()?.length > 1) {
                                                            dispatch(
                                                                searchAgentPortDetailsData({
                                                                    ...payload,
                                                                    keyword: searchTerm,
                                                                    currentPage: 0,
                                                                    columnStatus:
                                                                        agentPortDetailsTableHeaders.PortAddress,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        } else {
                                                            dispatch(
                                                                fetchAgentPortDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus:
                                                                        agentPortDetailsTableHeaders.PortAddress,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: portDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === agentPortDetailsTableHeaders.PortAddress
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        <use href="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {portDetail?.info?.agentPortDetailList.map(
                                        (detail: AgentPortDetailsDocumentsDtoList, index: number) => (
                                            <tr key={index} className="row-expanded">
                                                <td className="grid-cell td-port-name grid-sticky-column sticky-left-aligned">
                                                    <div className="grid-cell-data">{detail.portname}</div>
                                                </td>
                                                <td className="grid-cell td-location">
                                                    <div className="grid-cell-data">{detail.location}</div>
                                                </td>
                                                <td className="grid-cell td-direction">
                                                    <div className="grid-cell-data">{detail.direction}</div>
                                                </td>
                                                <td className="grid-cell td-air">
                                                    <div
                                                        title={
                                                            detail.air === 'APP'
                                                                ? 'Appointed'
                                                                : detail.air === 'HAN'
                                                                ? 'Handling'
                                                                : ''
                                                        }
                                                        className="grid-cell-data"
                                                    >
                                                        {detail.air}
                                                    </div>
                                                </td>
                                                <td className="grid-cell td-sea">
                                                    <div
                                                        title={
                                                            detail.sea === 'APP'
                                                                ? 'Appointed'
                                                                : detail.sea === 'HAN'
                                                                ? 'Handling'
                                                                : ''
                                                        }
                                                        className="grid-cell-data"
                                                    >
                                                        {detail.sea}
                                                    </div>
                                                </td>
                                                <td className="grid-cell td-rail">
                                                    <div
                                                        title={
                                                            detail.rail === 'APP'
                                                                ? 'Appointed'
                                                                : detail.rail === 'HAN'
                                                                ? 'Handling'
                                                                : ''
                                                        }
                                                        className="grid-cell-data"
                                                    >
                                                        {detail.rail}
                                                    </div>
                                                </td>
                                                <td className="grid-cell td-road">
                                                    <div
                                                        title={
                                                            detail.road === 'APP'
                                                                ? 'Appointed'
                                                                : detail.road === 'HAN'
                                                                ? 'Handling'
                                                                : ''
                                                        }
                                                        className="grid-cell-data"
                                                    >
                                                        {detail.road}
                                                    </div>
                                                </td>
                                                <td className="grid-cell td-port-address">
                                                    <div className="grid-cell-data">{detail.portaddress}</div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                    {/* {toggleDropdown && !!portDetail?.info?.agentPortDetailList?.length && (
                        <div
                            ref={wrapperRef}
                            style={{ left: String(setPosition) + 'px' }}
                            className="filter-menu searchable-dropdown"
                        >
                            <div className="search-entry-holder">
                                <input
                                    placeholder="Search"
                                    type="text"
                                    className="search-input input-text grid-search-field"
                                    value={searchName}
                                    onChange={filter}
                                ></input>
                                <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button
                                    onClick={(e) => {
                                        setSearchName('');
                                        setPortDetailColumnData(
                                            (portDetail as any)?.info?.distinctColumnValues[portDetailColumnName]
                                        );
                                        filter(e);
                                    }}
                                    className="app-btn text-close-btn"
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>
                            <div className="checkbox-wrapper">
                                {portDetailColumnData &&
                                    portDetailColumnData?.map((value: string, index: number) => (
                                        <div key={index} className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    onChange={(e: any) => {
                                                        statusFilterSelected.filter((item: string, index: number) => {
                                                            if (statusFilterSelected.indexOf(item) == index) {
                                                                return item;
                                                            }
                                                        });

                                                        if (e.target.checked) {
                                                            setStatusFilterSelected((_prev: any) => {
                                                                return [value];
                                                            });
                                                        } else {
                                                            setStatusFilterSelected(
                                                                statusFilterSelected.filter(
                                                                    (item: string) => item != value
                                                                )
                                                            );
                                                        }
                                                        setToggleDropdown(!toggleDropdown);
                                                    }}
                                                    checked={
                                                        statusFilterSelected.includes(value) &&
                                                        portDetailColumnName === currentSelectedFilter
                                                    }
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                />
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use xlinkHref="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">{value}</div>
                                            </label>
                                        </div>
                                    ))}
                                {(portDetailColumnData?.length === 0 || portDetailColumnData === undefined) &&
                                    !isLoading && (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">
                                                {searchName === '' ? 'No Data Found' : 'No Matches Found'}
                                            </p>
                                        </div>
                                    )}
                            </div>
                            {portDetailColumnData?.length > 0 && (
                                <div className="clear-btn">
                                    <button
                                        onClick={() => {
                                            if (portDetailColumnName === currentSelectedFilter) {
                                                setStatusFilterSelected([]);
                                                setToggleDropdown(!toggleDropdown);
                                            }
                                        }}
                                        className="app-btn"
                                    >
                                        <span className="button-text">Clear All</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )} */}
                    {/* {totalNoOfItems > 0 ? (
                        <div className="grid-footer ">
                            <div className="grid-footer-left">
                                <div className="footer-text">Showing</div>
                                <div className="row-per-page">
                                    <div
                                        className={
                                            'dropdown-wrap pagination-dropdown menu-up ' +
                                            (PageCount ? 'dropdown-open' : '')
                                        }
                                    >
                                        <button
                                            className="menu-btn app-btn"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setPageCountDisplay(!PageCount);
                                            }}
                                        >
                                            <span className="btn-text">
                                                {portDetail?.info?.agentPortDetailList &&
                                                perPageCount > portDetail?.info?.agentPortDetailList.length
                                                    ? portDetail?.info?.agentPortDetailList.length
                                                    : perPageCount}
                                            </span>
                                            <span className="dropdown-arrow">
                                                <svg className="svg-icon arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                        {PageCount && (
                                            <div className="dropdown-menu">
                                                {paginationLimit.map((res, index: number) => (
                                                    <a
                                                        key={index}
                                                        onClick={(e) => {
                                                            setPageCountDisplay(false);
                                                            e.stopPropagation();
                                                            setPerPageCount(res);
                                                            if (searchTerm?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchAgentPortDetailsData({
                                                                        ...payload,
                                                                        keyword: searchTerm,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: portDetailColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchAgentPortDetailsList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: portDetailColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="menu-item app-btn"
                                                    >
                                                        {res}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <span>of</span>
                                    <span className="grid-total-row-count">{totalNoOfItems ? totalNoOfItems : 0}</span>
                                    <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                </div>
                            </div>
                            <div className="grid-footer-right">
                                <div className="grid-pagination">
                                    <Pagination
                                        currentPage={currentPage + 1}
                                        totalCount={totalNoOfItems}
                                        pageSize={perPageCount}
                                        onPageChange={(page: number) => setCurrentPageNumber(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null} */}
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/profile/agent/${id}/basic_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <Link
                        className={`app-btn app-btn-primary footer-btn${
                            agentHeader?.portDetailsTabComplete ? '' : ' disabled'
                        }`}
                        title="Next"
                        to={agentHeader?.portDetailsTabComplete ? `/profile/agent/${id}/commercial_details` : ''}
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Index;
