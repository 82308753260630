import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'addedPosLcl',
    initialState: {
        isLoading: false,
        isRemovalSuccess: false,
        isRemovalError: false,
        errorMessage: {},
        removeSuccessMessage: '',
    },
    reducers: {
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        removePo: (state, _action) => {
            state.isLoading = true;
        },
        removeSuccess: (state, action) => {
            state.isRemovalSuccess = true;
            state.removeSuccessMessage = action.payload.message;
            state.isLoading = false;
        },
        removeError: (state, action) => {
            state.isRemovalError = true;
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        resetRemovalFlags: (state, _action) => {
            state.isRemovalError = false;
            state.errorMessage = {};
            state.isRemovalSuccess = false;
        },
    },
});

export const { removePo, removeSuccess, isLoadingFn, removeError, resetRemovalFlags } = dataSlice.actions;
export default dataSlice.reducer;
