import React, { useEffect, useState } from 'react';
import './purchase-order-header.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { fetchCancelReason, fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import CancellationDetailModal from '../BasicDetail/CancellationDetailModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ClickAwayListener from 'react-click-away-listener';
import {
    setPoFilter as setFilter,
    setPoSelectedDropDown as setSelectedDropDown,
} from '../../../store/reducers/purchaseOrder/poListReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { activeStatus, allPos, awaitingEnrichment } from 'src/utils/constants';
interface HeaderProps {
    id?: string;
}

const Index: React.FC<HeaderProps> = (props) => {
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);
    const { cancelledReason } = useSelector((state: RootState) => state.addPoData);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const { profileType } = useUserProfile();

    const onClose = () => {
        setShowReasonModal(false);
    };

    useEffect(() => {
        dispatch(
            fetchHeader({
                poId: props.id,
            })
        );
    }, [props.id]);
    useEffect(() => {
        if (headerData?.poStatus === 'Cancelled') {
            dispatch(fetchCancelReason({ poId: props.id }));
        }
        document.title = headerData?.poNumber ? `ORD>${headerData?.poNumber}` : '';
    }, [headerData]);

    window.onpopstate = () => {
        const initialFilter = {
            poStatus:
                profileType === 'agent' || profileType === 'forwarder' || profileType === 'client'
                    ? awaitingEnrichment
                    : activeStatus,
        };
        const initialDropDown = {
            text:
                profileType === 'agent' || profileType === 'forwarder' || profileType === 'client'
                    ? awaitingEnrichment
                    : allPos,
            count: 0,
        };
        dispatch(setFilter(initialFilter));
        dispatch(setSelectedDropDown(initialDropDown));
    };

    return (
        <div className="purchase-order-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    {/* <Link to="/purchase_order">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">
                        {/* {localStorage.getItem('poNumber') ? localStorage.getItem('poNumber') : headerData?.poNumber} */}
                        {headerData?.poNumber}
                    </h2>
                    <div className="transport-icon-wrapper">
                        <div className="item-count-status-holder">
                            <div className="grid-item-count-status status-ui info-status">
                                <span className="count">
                                    <svg className="svg-icon aeroplane-icon">
                                        <use
                                            xlinkHref={`${
                                                headerData?.shippingMode?.shippingModeCode === 'AIR'
                                                    ? '#aeroplaneIcon'
                                                    : headerData?.shippingMode?.shippingModeCode === 'LND'
                                                    ? '#truckIcon'
                                                    : '#shipIcon'
                                            }`}
                                        >
                                            <title>
                                                {headerData?.shippingMode?.shippingModeCode === 'AIR'
                                                    ? 'Aeroplace'
                                                    : headerData?.shippingMode?.shippingModeCode === 'LND'
                                                    ? 'Land'
                                                    : 'Sea'}
                                            </title>
                                        </use>
                                    </svg>
                                </span>
                                <span className="status-text">
                                    {headerData?.shippingMode?.shippingModeCode === 'AIR'
                                        ? 'Air'
                                        : headerData?.shippingMode?.shippingModeCode === 'LND'
                                        ? 'Land'
                                        : 'Sea'}
                                </span>
                            </div>
                        </div>
                    </div>
                    {headerData?.poStatus === 'Cancelled' ? (
                        <div className="po-cancel-wrapper">
                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui error-status">
                                    <span className="count"></span>
                                    <span className="status-text">Cancelled</span>
                                </div>
                            </div>
                            <a
                                className="add-content-link"
                                href="javascript:void(0)"
                                onClick={() => setShowReasonModal(true)}
                            >
                                View Reason
                            </a>
                        </div>
                    ) : headerData?.poStatus === 'Booked' ? (
                        <div className="po-cancel-wrapper">
                            <div className="item-count-status-holder">
                                <div
                                    className="grid-item-count-status status-ui success-status ready-for-booking-status"
                                    style={
                                        //work around to fix the overlapping of status text and icon for large shipping mode(number of characters large, eg: ship(4))
                                        headerData?.shippingMode?.shippingModeCode !== 'AIR'
                                            ? { marginLeft: '4px' }
                                            : {}
                                    }
                                >
                                    <span className="count"></span>
                                    <span className="status-text">{headerData?.poStatus?.replaceAll('_', ' ')}</span>
                                </div>
                            </div>
                        </div>
                    ) : headerData?.poStatus?.toLowerCase() === 'draft' ||
                      headerData?.poStatus?.toLowerCase() === 'awaiting enrichment' ||
                      headerData?.poStatus?.toLowerCase() === 'awaiting_enrichment' ||
                      headerData?.poStatus?.toLowerCase() === 'ready for booking' ||
                      headerData?.poStatus?.toLowerCase() === 'ready_for_booking' ||
                      headerData?.poStatus?.toLowerCase() === 'partially booked' ||
                      headerData?.poStatus?.toLowerCase() === 'partially_booked' ? (
                        <div
                            className="grid-item-count-status status-ui warning-status"
                            style={
                                headerData?.shippingMode?.shippingModeCode !== 'AIR'
                                    ? { marginLeft: '4px', maxWidth: 'none' }
                                    : { maxWidth: 'none' }
                            }
                        >
                            <span className="count"></span>
                            <span className="status-text">{headerData?.poStatus?.replaceAll('_', ' ')}</span>
                        </div>
                    ) : headerData?.poStatus?.toLowerCase() === 'discarded' ? (
                        <div
                            className="grid-item-count-status status-ui disabled-status"
                            style={
                                headerData?.shippingMode?.shippingModeCode !== 'AIR'
                                    ? { marginLeft: '4px', maxWidth: 'none' }
                                    : { maxWidth: 'none' }
                            }
                        >
                            <span className="count"></span>
                            <span className="status-text">{headerData?.poStatus?.replaceAll('_', ' ')}</span>
                        </div>
                    ) : (
                        <div
                            className="grid-item-count-status status-ui info-status"
                            style={
                                headerData?.shippingMode?.shippingModeCode !== 'AIR'
                                    ? { marginLeft: '4px', maxWidth: 'none' }
                                    : { maxWidth: 'none' }
                            }
                        >
                            <span className="count"></span>
                            <span className="status-text">{headerData?.poStatus?.replaceAll('_', ' ')}</span>
                        </div>
                    )}
                    {headerData?.createdUser?.toLowerCase() === 'cix' ? (
                        <div
                            className="grid-item-count-status status-ui info-status"
                            style={{ backgroundColor: '#545aef' }}
                        >
                            <span style={{ color: '#fff', marginLeft: '8px' }} className="status-text">
                                Integrated
                            </span>
                        </div>
                    ) : (
                        <div className="box-content-holder">
                            <h6 className="details-box-heading" style={{ color: '#737373', marginLeft: '8px' }}>
                                Created by <span style={{ color: '#23272B' }}>{headerData?.createdUser}</span>
                            </h6>
                        </div>
                    )}
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">PO Number</h6>
                        <div className="details-box-content">{headerData?.poNumber}</div>
                    </div>
                    <div className="details-col box-content-holder client-name-col">
                        <h6 className="details-box-heading">Client Name</h6>
                        <div className="details-box-content">{headerData?.clientName}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Vendor Name</h6>
                        <div className="details-box-content">{headerData?.vendorName}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Destination</h6>
                        {headerData?.destinations?.length ? (
                            <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
                                <div className="details-box-content">
                                    {headerData?.destinations?.length ? headerData?.destinations[0] : '-'}
                                    {headerData?.destinations?.length > 1 ? (
                                        <span
                                            className={`dropdown-wrap menu-down ${
                                                showTooltip ? 'dropdown-open' : ''
                                            } info-dropdown modal-toast header-count header-dp`}
                                        >
                                            <div
                                                className="toast-message "
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowTooltip(!showTooltip);
                                                    if (showTooltip) {
                                                        setShowTooltip(false);
                                                    }
                                                }}
                                            >
                                                +{headerData?.destinations?.length - 1}
                                            </div>
                                            <div className="dropdown-menu ">
                                                <a href="#" className="menu-item app-btn">
                                                    {headerData?.destinations
                                                        ?.slice(1, headerData?.destinations?.length)
                                                        .map((po: any, index: number) => (
                                                            <a
                                                                href="#"
                                                                className="menu-item app-btn"
                                                                key={`${index}_tooltip`}
                                                            >
                                                                {po}
                                                            </a>
                                                        ))}
                                                </a>
                                            </div>
                                        </span>
                                    ) : null}
                                </div>
                            </ClickAwayListener>
                        ) : (
                            <div className="details-box-content">
                                {headerData?.isMultiAddress ? 'Multi Destination' : headerData?.destination ?? '-'}
                            </div>
                        )}

                        {/* <div className="details-box-content"> 
                                ? 'Multi Destination'
                                : headerData?.destinationPort
                                ? `${headerData?.destinationPortName}(${headerData?.destinationPort})`
                                : '-'}
                                </div> */}
                    </div>
                </div>
                <div className="card-detail-col col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Order Lines</h6>
                        <div className="details-box-content">{headerData?.orderLines || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">
                            {headerData?.totalCbms ? Math.ceil(headerData?.totalCbms) : 0}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">
                            {headerData?.totalKgs ? Math.ceil(headerData?.totalKgs) : 0}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Order Value</h6>
                        {/* <div className="details-box-content">{headerData?.totalOrderValue || 0} USD</div> */}
                        <div className="details-box-content">{headerData?.totalOrderValue || 0}</div>
                    </div>
                </div>
            </div>
            {showReasonModal ? (
                <CancellationDetailModal onClose={onClose} data={cancelledReason}></CancellationDetailModal>
            ) : null}
        </div>
    );
};
export default Index;
