/* eslint-disable no-undefined */
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
import './/product-master.scss';
// import './style.css';
import '../../../../assets/scss/components/_toggle-button.scss';
import ProductMasterModal from './ProductMasterModal';
import { Link, useLocation, useParams } from 'react-router-dom';
import TableFooter2 from '../../../../components/common/Table/tableFooter2';
import { useAppDispatch } from '../../../../hooks';
import ConfirmModal from './Components/confirmModal';
import {
    fetchData,
    // changeStatus,
    getVendorList,
    changeStatusValue,
    editProduct,
    downloadFile,
    // productAddError,
    productAddErrorStatus,
    productEditSuccess,
    productCreateSuccess,
    searchProductList,
    deactivateFailed,
    // productData,
} from '../../../../store/reducers/productReducer';
// import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import BackButton from '../../../../components/common/BackButton';
// import DropDown from '../../../../components/common/DropDown';
// import Toast from './Toast';
import { error, Position, success } from '../../../../utils/toast';
import ShowForPermissions from '../../../../ShowForPermissions';
import { useOutsideAlerter } from '../../../../hooks/useOutsideAlerter';
import SpecialFilter from 'src/components/common/SpecialFilter';
import { fetchHeaderDataClients } from 'src/store/reducers/profiles/clients';
interface CustomizedState {
    isSkuorUser: string;
}
const Index: React.FC = () => {
    const location: any = useLocation();
    const [productModal, setproductModal] = useState(false);
    const [viewModal, setviewModal] = useState(false);
    const [ProductDat, setProductDat] = useState({});
    const [vendorExp, setVendorExp] = useState(false);
    const [uomExp, setuomExp] = useState(false);
    const [currentPage, setcurrentPage] = useState(0);
    const [perPageCount, setperPageCount] = useState(10);
    const [columnStatus, setcolumnStatus] = useState('pm_sku');
    const [currentOrder, setcurrentOrder] = useState(true);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [statusFilter, setstatusFilter] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [editID, setEditid] = useState('');
    const [indexLine, setIndexLine] = useState(null);
    const [pdfview, setPdfView] = useState(false);
    const [addButtonView, setAddButtonView] = useState(false);
    const [productnameVal, setproductnameVal] = useState('');
    const [confirmModal, setconfirmModal] = useState({ status: false, data: ProductDat, value: '' });
    const [search, setSearch] = useState('');
    const firstUpdate = useRef(true);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const wrapperRefAdd = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setPdfView);
    useOutsideAlerter(wrapperRefAdd, setAddButtonView);

    const { id } = useParams();
    const dispatch = useAppDispatch();
    const {
        products,
        totalActiveProducts,
        totalInactiveProducts,
        linkedVendors,
        status,
        statusView,
        totalPages,
        totalNoOfItems,
        isLoading,
        createError,
        warning,
        productEdit,
        productCreate,
        productName,
        editFailed,
        editStared,
        deactivateErrorMessage,
        distinctValues,
    } = useSelector((state: any) => state.productData);

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnOrder: currentOrder,
        columnStatus: columnStatus,
        statusFilter: statusFilter,
        id: id,
    };
    const payloadVendor = {
        currentPage: 0,
        perPageCount: '',
        columnOrder: currentOrder,
        columnStatus: 'vn_vendorcode',
        id: id,
    };

    const getDefaultProductData = (isSkuorUser: string | null) => {
        let selectedProduct = [] as any;
        selectedProduct =
            products && products.length > 0 && products?.filter((val: any) => val.pm_PK === isSkuorUser && val);
        setProductDat(selectedProduct && selectedProduct.length > 0 ? selectedProduct[0] : {});
        if (selectedProduct.length > 0) {
            setviewModal(true);
        }
        window.history.replaceState({}, document.title);
    };

    useEffect(() => {
        const state = location.state as CustomizedState;

        if (state) {
            const { isSkuorUser } = state;
            if (isSkuorUser) {
                getDefaultProductData(isSkuorUser);
            } else {
                setviewModal(false);
            }
        } else {
            setviewModal(false);
        }
    }, [products, location]);

    useEffect(() => {
        dispatch(fetchData(payload));
        dispatch(
            getVendorList({
                payloadVendor,
            })
        );
    }, [dispatch, columnStatus, currentOrder, currentPage, perPageCount, createError]);
    const showSkuModal = localStorage.getItem('showSkuModal');
    useEffect(() => {
        if (showSkuModal === 'true') {
            setproductModal(true);
        }
    }, [showSkuModal]);
    useEffect(() => {
        if (!editFailed && editStared) {
            dispatch(fetchData(payload));
            dispatch(
                getVendorList({
                    payloadVendor,
                })
            );
        }
    }, [editFailed, editStared]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
        setTimeout(function () {
            dispatch(changeStatusValue(false));
            dispatch(productAddErrorStatus(false));
            dispatch(productCreateSuccess(false));
            dispatch(productEditSuccess(false));
        }, 3000);
    }, [products]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setTimeout(() => {
            setToggleDropdown(true);
        }, 100);
        setstatusFilter(data);
        dispatch(
            fetchData({
                currentPage: currentPage,
                perPageCount: perPageCount,
                columnOrder: currentOrder,
                columnStatus: columnStatus,
                statusFilter: data,
                id: id,
            })
        );
    };
    useEffect(() => {
        if (statusView) {
            success(`${status.name} is now ${status.status === 'ACTIVE' ? 'Active' : 'Inactive'}`, Position.TOP_RIGHT);
            dispatch(productAddErrorStatus(false));
        } else if (warning) {
            error(createError, Position.TOP_RIGHT);
        } else if (productEdit) {
            success(`Product details for ${productnameVal} have been updated`, Position.TOP_RIGHT);
            setTimeout(() => {
                dispatch(fetchData(payload));
            }, 1000);
        } else if (productCreate) {
            success(`${productName} has been added to Product Master`, Position.TOP_RIGHT);
            setTimeout(() => {
                dispatch(fetchData(payload));
            }, 1000);
        }
        dispatch(fetchHeaderDataClients(id));
        setSearch('');
    }, [statusView, warning, productEdit, createError, productCreate]);

    useEffect(() => {
        if (deactivateErrorMessage) {
            error(deactivateErrorMessage ?? 'Something went wrong', Position.TOP_RIGHT);
            // setconfirmModal({
            //     status: true,
            //     data: { message: deactivateErrorMessage },
            //     value: 'INACTIVE',
            // });
        }
        return () => {
            dispatch(deactivateFailed(null));
        };
    }, [deactivateErrorMessage]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (search?.trim()?.length > 2) {
            dispatch(searchProductList({ ...payload, keyword: search, statusFilter }));
        } else if (search?.trim()?.length === 0) {
            dispatch(fetchData(payload));
            dispatch(
                getVendorList({
                    payloadVendor,
                })
            );
        }
    }, [search, statusFilter]);

    return (
        <>
            {/*{createError === undefined && (
                <Toast
                    type="success"
                    closeBtnFn={productCreateSuccess}
                    view={productCreate}
                    text={`${productName} has been added to Product Master`}
                />
            )}*/}

            <div
                className="main-wrapper container"
                onClick={() => {
                    setVendorExp(false);
                    setPdfView(false);
                    setAddButtonView(false);
                    setuomExp(false);
                    setToggleDropdown(false);
                }}
            >
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />
                    <HeaderTabs />
                </div>
                <div className="grid-section product-master-grid grid-p-0-15">
                    {isLoading && (
                        <div className="app-loader loading" style={{ zIndex: '9999' }}>
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="search-entry-holder">
                                <input
                                    placeholder="Search Product Master"
                                    type="text"
                                    className="search-input input-text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                ></input>
                                <div
                                    className={`search-toolip ${
                                        search.length > 0 && search.length < 3 ? 'tooltip-show' : ''
                                    }`}
                                >
                                    Enter atleast 3 characters to search
                                </div>
                                <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button
                                    className="app-btn text-close-btn"
                                    onClick={() => {
                                        if (search?.trim()?.length) {
                                            setSearch('');
                                        }
                                    }}
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>

                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui success-status">
                                    <span className="count">{totalActiveProducts}</span>
                                    <span className="status-text">
                                        Active Product{totalActiveProducts > 1 ? 's' : ''}
                                    </span>
                                </div>
                                <div className="grid-item-count-status status-ui error-status">
                                    <span className="count">{totalInactiveProducts}</span>
                                    <span className="status-text">
                                        Inactive Product{totalInactiveProducts > 1 ? 's' : ''}
                                    </span>
                                </div>
                            </div>

                            <div className="grid-header-btn-holder">
                                <div
                                    className={
                                        pdfview
                                            ? 'dropdown-wrap dropdown-open download-dropdown'
                                            : 'dropdown-wrap download-dropdown'
                                    }
                                    ref={wrapperRef}
                                >
                                    <button
                                        className="app-btn export-btn icon-button app-btn-secondary"
                                        disabled={products?.length < 1 && statusFilter === null ? true : false}
                                        onClick={(e) => {
                                            setPdfView(!pdfview);
                                            e.stopPropagation();
                                        }}
                                        style={{ marginRight: '8px' }}
                                    >
                                        <svg className="svg-icon export-icon">
                                            <use xlinkHref="#exportIcon"></use>
                                        </svg>
                                        <span className="button-text">Export</span>
                                        <svg className="svg-icon arrow-icon">
                                            <use xlinkHref="#downArrow">
                                                <title>Expand row</title>
                                            </use>
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu">
                                        <a
                                            className="menu-item app-btn"
                                            onClick={() => dispatch(downloadFile({ type: 'pdf', profile: id }))}
                                        >
                                            Export as PDF
                                        </a>
                                        <a
                                            className="menu-item app-btn"
                                            style={{ marginLeft: '0' }}
                                            onClick={() => dispatch(downloadFile({ type: 'excel', profile: id }))}
                                        >
                                            Export as Excel
                                        </a>
                                    </div>
                                </div>
                                <ShowForPermissions permission="edit" type="client">
                                    <div
                                        className={
                                            addButtonView
                                                ? 'dropdown-wrap dropdown-open download-dropdown'
                                                : 'dropdown-wrap download-dropdown'
                                        }
                                        ref={wrapperRefAdd}
                                    >
                                        <button
                                            className="app-btn app-btn-secondary icon-button grid-top-btn add-product-btn"
                                            title="Add Product"
                                            onClick={(e) => {
                                                setAddButtonView(!addButtonView);
                                                e.stopPropagation();
                                            }}
                                            // onClick={() => {
                                            //     setproductModal(true);
                                            //     setProductDat({});
                                            // }}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Product</span>
                                        </button>
                                        <div className="dropdown-menu">
                                            <a
                                                className="menu-item app-btn"
                                                onClick={() =>
                                                    window.open(`/profile/clients/${id}/product_master/add`, '_blank')
                                                }
                                            >
                                                Product
                                            </a>
                                            <a
                                                className="menu-item app-btn"
                                                style={{ marginLeft: '0' }}
                                                onClick={() => {
                                                    setproductModal(true);
                                                    setProductDat({});
                                                }}
                                            >
                                                Commodity
                                            </a>
                                        </div>
                                    </div>
                                </ShowForPermissions>
                            </div>
                        </div>
                        <div className="grid-container" onScroll={() => setToggleDropdown(false)}>
                            {/* content when No data found in grid*/}
                            {products &&
                                products.length === 0 &&
                                (search?.length > 0 ? (
                                    <div className="grid-no-content ">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No records found</p>
                                    </div>
                                ) : (
                                    <div className="grid-no-content ">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No Product in product master</p>
                                        <ShowForPermissions permission="edit" type="client">
                                            <a
                                                className="add-content-link"
                                                onClick={() => {
                                                    setproductModal(true);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                Add Product
                                            </a>
                                        </ShowForPermissions>
                                    </div>
                                ))}

                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className=" grid-sticky-row">
                                            <th className="grid-cell-header grid-sticky-column sticky-left-aligned col-sku">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">SKU</span>
                                                    <button
                                                        className="sort-btn app-btn"
                                                        onClick={() => {
                                                            setcolumnStatus('pm_sku');
                                                            setcurrentOrder(!currentOrder);
                                                        }}
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === 'pm_sku'
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            {/* class to reverse order icon:  sort-desc  */}
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header  grid-sticky-column sticky-left-aligned col-description">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">description</span>
                                                    <button
                                                        className="sort-btn app-btn"
                                                        onClick={() => {
                                                            setcolumnStatus('pm_description');
                                                            setcurrentOrder(!currentOrder);
                                                        }}
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === 'pm_description'
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            {/* class to reverse order icon:  sort-desc  */}
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-hs-code ">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">Code</span>
                                                    <button
                                                        className="sort-btn app-btn"
                                                        onClick={() => {
                                                            setcolumnStatus('pm_purchasecode');
                                                            setcurrentOrder(!currentOrder);
                                                        }}
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === 'pm_purchasecode'
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            {/* class to reverse order icon:  sort-desc  */}
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-uoms">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">UOMS</span>
                                                    {/* <button className="sort-btn app-btn">
                                                        <svg className="svg-icon sort-icon sort-asc">
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button> */}
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-vendor ">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">vendor</span>
                                                    {/*<button className="sort-btn app-btn">
                                                        <svg className="svg-icon sort-icon sort-asc">
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>*/}
                                                    {/*<div className="filter-menu-wrap">
                                                        <button className="app-btn filter-menu-button">
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>*/}
                                                </div>
                                            </th>
                                            <th className="grid-cell-header">
                                                <div className="grid-cell-data">
                                                    <span>STATUS</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setcolumnStatus('pm_status');
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === 'pm_status'
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            toggleDropdown || statusFilter !== null
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            className="app-btn filter-menu-button"
                                                            onClick={(e) => {
                                                                setToggleDropdown(!toggleDropdown);
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                        {toggleDropdown && (
                                                            <SpecialFilter
                                                                closeToggle={closeToggle}
                                                                currentFilter={statusFilter}
                                                                dataList={distinctValues['pm_status']}
                                                            />
                                                            // <DropDown
                                                            //     currentFilter={statusFilter}
                                                            //     closeToggle={closeToggle}
                                                            //     data={distinctValues['pm_status']}
                                                            // />
                                                        )}
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-actions">
                                                <div className="grid-cell-data header-title">ACTIONS</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <ProductMasterModal
                                        id={id}
                                        setproductModal={setproductModal}
                                        productModal={productModal}
                                        viewModal={viewModal}
                                        setviewModal={setviewModal}
                                        data={ProductDat}
                                        vendors={linkedVendors}
                                        editFn={editProduct}
                                        editID={editID}
                                        warning={warning}
                                    />

                                    <tbody>
                                        {/* View details */}
                                        {products &&
                                            products.length > 0 &&
                                            products?.map((res: any, index: any) => (
                                                <tr className="has-child row-expanded">
                                                    <td className="grid-cell  grid-sticky-column sticky-left-aligned td-sku">
                                                        <div
                                                            title={res?.pm_sku}
                                                            className="grid-cell-data"
                                                            style={{
                                                                width: '200px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                        >
                                                            {res?.pm_sku}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell grid-sticky-column sticky-left-aligned td-description">
                                                        <div
                                                            title={res?.pm_description}
                                                            style={{
                                                                width: '280px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                            className="grid-cell-data"
                                                        >
                                                            {res?.pm_description}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.pm_purchasecode}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.pm_type === 'Product' || !res?.pm_type
                                                                ? res?.pm_packets[0]?.pp_quantity
                                                                : '-'}
                                                            {(res?.pm_type === 'Product' || !res?.pm_type) &&
                                                                res?.pm_packets?.length > 1 && (
                                                                    <div
                                                                        className={
                                                                            uomExp
                                                                                ? 'dropdown-wrap  menu-down dropdown-open info-dropdown'
                                                                                : 'dropdown-wrap  menu-down info-dropdown'
                                                                        }
                                                                    >
                                                                        <button
                                                                            className="menu-btn app-btn"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setuomExp(!uomExp);
                                                                                setIndexLine(index);
                                                                            }}
                                                                        >
                                                                            <span className="btn-text">
                                                                                +
                                                                                {res?.pm_packets?.length > 1 &&
                                                                                    res?.pm_packets?.length - 1}
                                                                            </span>
                                                                        </button>
                                                                        {indexLine === index && (
                                                                            <div className="dropdown-menu">
                                                                                {res?.pm_packets
                                                                                    ?.slice(1, res?.pm_packets.length)
                                                                                    ?.map((ven: any) => (
                                                                                        <a
                                                                                            href="#"
                                                                                            className="menu-item app-btn"
                                                                                        >
                                                                                            {ven.pp_quantity}
                                                                                        </a>
                                                                                    ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </td>
                                                    {/* <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.pm_totalweight}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.pm_weightuom}</div>
                                                    </td> */}
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.pm_vendors[0]?.vn_vendorname}
                                                            {res?.pm_vendors?.length > 1 && (
                                                                <div
                                                                    className={
                                                                        vendorExp
                                                                            ? 'dropdown-wrap  menu-down dropdown-open info-dropdown'
                                                                            : 'dropdown-wrap  menu-down info-dropdown'
                                                                    }
                                                                >
                                                                    <button
                                                                        className="menu-btn app-btn"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setVendorExp(!vendorExp);
                                                                            setIndexLine(index);
                                                                        }}
                                                                    >
                                                                        <span className="btn-text">
                                                                            +
                                                                            {res?.pm_vendors?.length > 1 &&
                                                                                res?.pm_vendors?.length - 1}
                                                                        </span>
                                                                    </button>
                                                                    {indexLine === index && (
                                                                        <div className="dropdown-menu">
                                                                            {res?.pm_vendors
                                                                                ?.slice(1, res?.pm_vendors.length)
                                                                                ?.map((ven: any) => (
                                                                                    <a
                                                                                        href="#"
                                                                                        className="menu-item app-btn"
                                                                                    >
                                                                                        {ven.vn_vendorname}
                                                                                    </a>
                                                                                ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="grid-cell centre-align">
                                                        <div
                                                            className={`grid-cell-data status-ui ${
                                                                res?.pm_status === 'ACTIVE'
                                                                    ? 'success-status'
                                                                    : 'error-status'
                                                            }`}
                                                        >
                                                            {res?.pm_status === 'ACTIVE' ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </td>

                                                    <td className="grid-cell centre-align grid-sticky-column sticky-right-aligned">
                                                        <div className="grid-cell-data ">
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="View details"
                                                                onClick={() => {
                                                                    if (res?.pm_type === 'Commodity') {
                                                                        setviewModal(true);
                                                                        setProductDat(res);
                                                                        setEditid(res.pm_PK);
                                                                        setproductnameVal(res?.pm_sku);
                                                                    } else {
                                                                        window.open(
                                                                            `/profile/clients/${id}/product_master/${res?.pm_PK}`,
                                                                            '_blank'
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <svg className="svg-icon unlink-icon ">
                                                                    <use xlinkHref="#eyeIcon"></use>
                                                                    <title>View details</title>
                                                                </svg>
                                                            </button>
                                                            <ShowForPermissions permission="edit" type="client">
                                                                {res?.pm_status !== 'ACTIVE' ? (
                                                                    <button
                                                                        className="action-btn app-btn app-btn-secondary "
                                                                        title="Activate"
                                                                        onClick={() =>
                                                                            setconfirmModal({
                                                                                status: true,
                                                                                data: res,
                                                                                value: 'ACTIVE',
                                                                            })
                                                                        }
                                                                    >
                                                                        <svg className="svg-icon activate-icon">
                                                                            <use xlinkHref="#activateIcon"></use>
                                                                            <title>Activate</title>
                                                                        </svg>
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="action-btn app-btn app-btn-secondary "
                                                                        title="Deactivate"
                                                                        onClick={() =>
                                                                            setconfirmModal({
                                                                                status: true,
                                                                                data: res,
                                                                                value: 'INACTIVE',
                                                                            })
                                                                        }
                                                                    >
                                                                        <svg className="svg-icon deactivate-icon">
                                                                            <use xlinkHref="#deactivateIcon"></use>
                                                                            <title>Deactivate</title>
                                                                        </svg>
                                                                    </button>
                                                                )}
                                                            </ShowForPermissions>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {confirmModal.status && (
                            <ConfirmModal
                                setconfirmModal={setconfirmModal}
                                confirmModal={confirmModal}
                                payload={payload}
                            />
                        )}
                        <TableFooter2
                            setcurrentPage={setcurrentPage}
                            setperPageCount={setperPageCount}
                            perPageCount={perPageCount}
                            currentPage={currentPage}
                            displayCountfn={setPageCountDisplay}
                            pageCountDisplay={PageCount}
                            totalItems={totalNoOfItems}
                            items={products}
                            totalPages={totalPages - 1}
                        />
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/profile/clients/${id}/vendor_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        {/* <button className="app-btn app-btn-secondary footer-btn" title="Previous"> */}
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <Link
                        to={`/profile/clients/${id}/auto_approval`}
                        className="app-btn app-btn-primary footer-btn"
                        title="Next"
                    >
                        {/* <button className="app-btn app-btn-primary footer-btn" title="Next"> */}
                        <span className="button-text footer-button-text">Next</span>
                        {/* </button> */}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Index;
