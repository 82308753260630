import moment from 'moment';
import React, { useEffect } from 'react';
import { useMeasure } from 'react-use';
import { downloadAgentCommercial } from '../../services/apis/profiles/agents/commercialDetailsApi';
import { downloadShippingLineContract } from '../../services/apis/profiles/shippingLine/contractDetailsApi';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { duplicateContract } from 'src/store/reducers/contractReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

interface TableProps {
    data: any;
    setData: any;
    sortFunction: any;
    columnStatus: string;
    currentOrder: boolean;
    toggleDropdown: boolean;
    columnName: string;
    filterFunction: any;
    tableProperties: any;
    setIsEdit: any;
    setModalView: any;
    name: string;
    selectedFilterItem: any;
    duplicateAction?: boolean;
}

const Index: React.FC<TableProps> = (props) => {
    const [refFirstColumn, { width: widthFirstColumn }] = useMeasure<HTMLTableCellElement>();
    const { duplicateAction = true } = props;
    const { id } = useParams();
    const dispatch = useAppDispatch();

    return (
        <table className="grid-table">
            <thead>
                <tr className="grid-sticky-row">
                    {props.tableProperties.map((tableProperty: any, index: number) => (
                        <th
                            key={index}
                            ref={index === 0 ? refFirstColumn : null}
                            className={tableProperty.headerClass}
                            style={{ left: index === 1 ? widthFirstColumn + 1 : '' }}
                            id={tableProperty.name}
                        >
                            <div className="grid-cell-data ">
                                <span className="header-title">{tableProperty.columnName}</span>
                                {tableProperty.hasSort && (
                                    <button
                                        onClick={() => props.sortFunction(tableProperty.name)}
                                        className="sort-btn app-btn"
                                    >
                                        <svg
                                            className={
                                                props.columnStatus === tableProperty.name
                                                    ? `svg-icon sort-icon ${
                                                          props.currentOrder ? 'sort-asc' : 'sort-desc'
                                                      }`
                                                    : 'svg-icon sort-icon'
                                            }
                                        >
                                            <use href="#sortIcon">
                                                <title>sort</title>
                                            </use>
                                        </svg>
                                    </button>
                                )}
                                {tableProperty.hasFilter && (
                                    <div
                                        className={
                                            (props.toggleDropdown || props.selectedFilterItem.length) &&
                                            props.columnName === tableProperty.name
                                                ? 'filter-menu-wrap open'
                                                : 'filter-menu-wrap'
                                        }
                                    >
                                        <button
                                            onClick={(e) => props.filterFunction(e, tableProperty.name)}
                                            className="app-btn filter-menu-button"
                                        >
                                            <svg className="svg-icon filter-icon">
                                                <use xlinkHref="#filterIcon">
                                                    <title>filter</title>
                                                </use>
                                            </svg>
                                            <span className="sr-only">header menu</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </th>
                    ))}
                    <th className="grid-cell-header col-actions grid-sticky-column sticky-right-aligned">
                        <div className="grid-cell-data ">
                            <span className="header-title">ACTIONS</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {props?.data?.length > 0 &&
                    props?.data.map((item: any, index: number) => (
                        <tr key={index} className="row-expanded">
                            {props.tableProperties.map((tableProperty: any, i: number) => (
                                <td
                                    style={{ left: i === 1 ? widthFirstColumn + 1 : '' }}
                                    key={i}
                                    className={props.tableProperties[i].bodyClass}
                                >
                                    {tableProperty.columnName === 'STATUS' ? (
                                        <div
                                            className={`grid-cell-data status-ui ${
                                                item[tableProperty.name] === 'Active'
                                                    ? 'success-status'
                                                    : 'error-status'
                                            }`}
                                        >
                                            {item[tableProperty.name]}
                                        </div>
                                    ) : tableProperty.columnName === 'START DATE' ||
                                      tableProperty.columnName === 'END DATE' ? (
                                        <div className="grid-cell-data">
                                            {moment(item[tableProperty.name]).format('DD-MM-YYYY')}
                                        </div>
                                    ) : (
                                        <div className="grid-cell-data">{item[tableProperty.name]}</div>
                                    )}
                                </td>
                            ))}
                            <td className="grid-cell td-actions grid-sticky-column sticky-right-aligned">
                                <div className="grid-cell-data">
                                    <button
                                        onClick={() => {
                                            if (props.name === 'contractDetails') {
                                                if (item['fileName']) {
                                                    downloadShippingLineContract(item['sd_sdpk'], item['fileName']);
                                                }
                                            } else {
                                                if (item['fileName']) {
                                                    downloadAgentCommercial(item['ad_adpk'], item['fileName']);
                                                }
                                            }
                                        }}
                                        className="action-btn app-btn app-btn-secondary "
                                        title="Download"
                                    >
                                        <svg className="svg-icon eye-icon ">
                                            <use xlinkHref="#downloadIcon"></use>
                                            <title>Download</title>
                                        </svg>
                                    </button>
                                    {props.name === 'contractDetails' && duplicateAction && (
                                        <button
                                            className="action-btn app-btn app-btn-secondary"
                                            title="duplicate"
                                            onClick={() => {
                                                dispatch(
                                                    duplicateContract({ carrierPK: id, contractPK: item?.sd_sdpk })
                                                );
                                                // setTimeout(() => {
                                                //     window.location.reload();
                                                // }, 1500);
                                            }}
                                        >
                                            <svg className="svg-icon eye-icon ">
                                                <use href="#duplicateIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                                    <button
                                        onClick={() => {
                                            props.setIsEdit(true);
                                            props.setModalView(true);
                                            props.setData(item);
                                        }}
                                        className="action-btn app-btn app-btn-secondary "
                                        title="View Details"
                                    >
                                        <svg className="svg-icon eye-icon ">
                                            <use xlinkHref="#eyeIcon"></use>
                                            <title>View</title>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default Index;
