import React from 'react';
import { useLottie } from 'lottie-react';
import containerShip from '../../../assets/animation/containerShip.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: containerShip,
    rendererSettings: {
        className: 'lottie-svg-class',
        id: 'lottie-svg-id',
    },
};

const Loader = () => {
    const { View } = useLottie(defaultOptions);
    return (
        <>
            {/*{loading && (*/}
            <div className="no-data-content checking-data-content">
                {View}
                <p className="no-content-message">Checking for available contracts</p>
            </div>
            {/*)}*/}
        </>
    );
};

export default Loader;
