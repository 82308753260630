import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { del, get, post } from '../HttpMethods';

export interface IData {
    id: number;
    name: string;
    email: string;
}
export interface ClientData {
    cl_pk: string;
    ve_pk: any;
}
// name: string;
// url: string;

// Get All Datas
export const getClientData = async () => {
    try {
        // const res = await get('api/mgt/vendors/clients/list?status=ACTIVE', null);
        // return res;
        const data = await get(
            `cix/scr/admin/organisation/clients?take=1000&filter[logic]=and&filter[filters][3][field]=OR_Status&filter[filters][3][operator]=eq&filter[filters][3][value]=Active&attributes=CL_PK,OR_OrgCode,OR_OrgName,OR_UserDefinedText4`,
            null,
            externalBaseUrl
        );
        const res = data?.data.map((res: any) => ({
            orgCode: res.OR_OrgCode,
            orgName: res.OR_OrgName,
            clientId: res.CL_PK,
        }));
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const getListClient = async (payload: {
    currentPage: any;
    perPageCount: any;
    columnOrder: any;
    columnStatus: any;
    id: string;
}) => {
    try {
        const res = await get(
            `api/mgt/vendors/clients?vn_pk=${payload?.id}&size=${payload?.perPageCount}&page=${
                payload?.currentPage
            }&sortBy=${payload?.columnStatus}&sortOrder=${!payload.columnOrder ? 'asc' : 'desc'}`,
            null
        );
        return res;
    } catch (err) {
        return [];
    }
};
export const unlinkClient = async (payload: { client_id: string; vendor_id: string }) => {
    try {
        const res = await del(`api/mgt/vendors/unlink?cl_pk=${payload.client_id}&vn_pk=${payload.vendor_id}`, null);
        return res;
    } catch (er) {
        return er;
    }
};

export const linkClientApi = async (payload: { clientId: string; vn_pk: any }) => {
    try {
        await post(`api/mgt/vendors/link?vn_pk=${payload.vn_pk}`, payload.clientId, null);
        //return console.log(res, 'payload in api');
    } catch (er) {
        return console.log(er);
    }
};

export const searchVendorsClients = async (payload: {
    keyword: string;
    id: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    columnOrder: boolean;
}) => {
    try {
        const res = await get(
            `/api/mgt/search/vendorclients?vn_pk=${payload.id}&keyword=${payload.keyword}&size=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortBy=${payload.columnStatus}&sortOrder=${
                payload.columnOrder ? 'DESC' : 'ASC'
            }`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};
