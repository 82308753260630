import React, { useEffect, useState } from 'react';
import BasicDetailsTab from '../Agent/BasicDetails';
import '../../lcsBookingDetails.scss';
import { useLocation } from 'react-router-dom';
import { selectAgentBasicDetails } from 'src/store/reducers/profiles/agents/basicDetailsReducer';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { filterValues, gridDetailsForTable } from 'src/utils/kendoGrid/lifecyclesearch';
import { useAppSelector } from 'src/hooks';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const baseURL = process.env.REACT_APP_BASE_URL;

const Index: React.FC = () => {
    const location = useLocation() as any;
    const { userName, profileType } = useUserProfile();
    const [lcsTab, setLcsTab] = useState('Basic Details');
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const basicDetails = useAppSelector(selectAgentBasicDetails);

    useEffect(() => {
        const orgCode = location?.state?.type || basicDetails?.data?.info?.ag_Org?.or_Orgcode;
        if (orgCode) {
            gridDetailsForTable.agentBooking.url = `${baseURL}/api/bkg/tracking/lifecycle/agentdata/bpdetails?orgAgtCode=${orgCode}`;
            gridDetailsForTable.agentPurchaseOrder.url = `${baseURL}/api/ord/tracking/lifecycle/agentdata/podetails?orgAgtCode=${orgCode}`;
            gridDetailsForTable.agentPortDetails.url = `${externalBaseUrl}/cix/scr/admin/port/agent/${orgCode}`;
        }
    }, [lcsTab, location?.state]);

    return (
        <div className="lcs-booking-details">
            {/* <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div> */}
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'Basic Details' ? 'active' : ''}`}
                        onClick={() => setLcsTab('Basic Details')}
                    >
                        Basic Details
                    </a>
                    <a className={`tab-nav ${lcsTab === 'po' ? 'active' : ''}`} onClick={() => setLcsTab('po')}>
                        Purchase Orders
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'bookings' ? 'active' : ''}`}
                        onClick={() => setLcsTab('bookings')}
                    >
                        Bookings
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'port-details' ? 'active' : ''}`}
                        onClick={() => setLcsTab('port-details')}
                    >
                        Port Details
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'Basic Details' && <BasicDetailsTab />}
                {lcsTab === 'po' && (
                    <KendoGridJquery
                        gridItem={gridDetailsForTable.agentPurchaseOrder}
                        // Btn={Btn}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                            profile: profileType,
                        }}
                    />
                )}
                {lcsTab === 'bookings' && (
                    <KendoGridJquery
                        gridItem={gridDetailsForTable.agentBooking}
                        // Btn={Btn}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                            profile: profileType,
                        }}
                    />
                )}
                {lcsTab === 'port-details' && (
                    <LayoutWithColumns
                        style={'report'}
                        content={'tracking-agent-port'}
                        customProp={{
                            apiUrl: `admin/port/agent/${
                                location?.state?.type || basicDetails?.data?.info?.ag_Org?.or_Orgcode
                            }`,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
