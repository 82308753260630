// fetchClientData
import { createSlice } from '@reduxjs/toolkit';
import { clientBasicDetails, getClientDetails } from '../../services/apis/clientApi';

interface ClientDetailsState {
    clientBasicDetails?: clientBasicDetails;
}

const initialState: ClientDetailsState = {
    // eslint-disable-next-line no-undefined
    clientBasicDetails: undefined,
};

export const dataSlice = createSlice({
    name: 'clientDetails',
    initialState,
    reducers: {
        fetchClientDetails: (state, action) => {
            state.clientBasicDetails = action.payload.clientDetails;
        },
    },
});

export const { fetchClientDetails } = dataSlice.actions;
export default dataSlice.reducer;
