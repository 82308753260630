/* eslint-disable no-undefined */
import React, { useRef, useState } from 'react';
import './booking-list.scss';
import { useNavigate } from 'react-router-dom';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
const Index: React.FC = () => {
    // const location = useLocation();
    const navigate = useNavigate();
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [addBookingVisible, setaddBookingVisible] = useState(false);
    const [kendoFilter, setKendoFilter] = useState('{}');
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setaddBookingVisible as any);

    const closeAllDropDown = () => {
        setDropDownOpen(false);
        setToggleDropdown(false);
        setShowExportDropDown(false);
        setPageCountDisplay(false);
    };

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                closeAllDropDown();
            }}
        >
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Integration Log</h2>

                <div className="grid-section">
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'booking'} content={'integrationLog'} filter={kendoFilter} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
