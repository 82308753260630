import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../POSelection.scss';
import BookingAttachments from '../BookingAttachment';
import { fetchFiles, setWarning } from 'src/store/reducers/bookingProposal/attachmentsReducer';
import {
    updateBP,
    clearFailure,
    selectEnrichBooking,
    selectHouseBillDetails,
} from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { fetchBpHeaderData } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import BookingHeaderSidebar from './../BookingHeaderSidebar';
import ShowForPermissions from 'src/ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { error, Position, success } from 'src/utils/toast';
import { getUserDetails } from 'src/utils/getUserDetails';
import { useAppSelector } from '../../../hooks';
import { selectContainers, fetchContainers } from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { fetchNacAdditionalDetails } from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import { fetchEnrichBooking, fetchHouseBills } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { fetchContractDetails } from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const Index: React.FC = () => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoadingHeader, gateInDateExpired } = useSelector((state: RootState) => state.bpHeaderData);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const { files, isLoading, errorMessage, errorStat } = useSelector((state: RootState) => state.attachmentsReducer);
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const { profileType } = useUserProfile();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const houseBillData = useAppSelector(selectHouseBillDetails);

    const containers = useAppSelector(selectContainers);
    const [nextBtn, setNextBtn] = useState(false);
    const [nextAddBtn, setNextAddBtn] = useState(false);
    const [validation, setvalidation] = useState(false);
    const { additionalDetails } = useSelector((state: RootState) => state.additionalDetailsNac);
    /* eslint no-var: off */

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (id) {
            dispatch(fetchEnrichBooking({ bpId: id }));
            dispatch(fetchHouseBills({ bpId: id }));
            dispatch(fetchContainers({ bpId: id }));
        }
        if (additionalDetails?.contractId) {
            dispatch(fetchContractDetails({ contractId: additionalDetails.contractId }));
        }
    }, [id, additionalDetails]);

    useEffect(() => {
        if (headerData.bpStatus === 'Draft_Booking' && profileType !== 'client') {
            if (
                enrichBooking &&
                houseBillData &&
                containers &&
                houseBillData.numbers?.length > 0 &&
                houseBillData.numbers[0]?.vendors?.length > 0 &&
                enrichBooking?.soNumber &&
                enrichBooking?.originCartageCompany &&
                houseBillData?.numbers[0].vendors[0].houseBillNumber &&
                // houseBillData?.masterBillNumber &&
                containers?.length > 0
            ) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
        }
    }, [enrichBooking, houseBillData, containers]);

    useEffect(() => {
        const headerDataForContainer = JSON?.parse(JSON?.stringify(headerData));
        if (headerData?.isTriangleShipment) {
            if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.destinationAgentName &&
                    additionalDetails?.sellQuotationName &&
                    additionalDetails?.sellTotOriginCost !== 0
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'FAK' && profileType === 'agent') {
                if (
                    additionalDetails?.savedFakFileName &&
                    additionalDetails?.totOriginCost &&
                    additionalDetails?.destinationAgentName
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'NAC' && additionalDetails?.destinationAgentName) {
                setNextAddBtn(true);
            }
        }
        if (headerData?.isTriangleShipment === false) {
            if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.sellQuotationName &&
                    additionalDetails?.sellTotOriginCost &&
                    additionalDetails?.sellTotOriginCost !== '0'
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'FAK' && profileType === 'agent') {
                if (additionalDetails?.savedFakFileName && additionalDetails?.totOriginCost) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'NAC') {
                setNextAddBtn(true);
            }
            if (headerDataForContainer?.tabValues?.containerDetails === false) {
                setNextAddBtn(false);
            }
        }
    }, [additionalDetails, headerData, profileType]);

    useEffect(() => {
        if (
            headerData.bpStatus === 'Booked' ||
            headerData.bpStatus === 'Draft_Booking' ||
            headerData.bpStatus === 'Ready_for_Booking'
        ) {
            dispatch(fetchEnrichBooking({ bpId: id }));
        }
    }, [headerData?.bpStatus]);

    useEffect(() => {
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
    }, [id]);

    //Show the toast after successful booking proposal approval
    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if ((submitBPSuccess || submitBPError) && validation) {
            dispatch(clearFailure([]));
            navigate('/booking_list');
        }
    }, [submitBPSuccess, submitBPError, validation]);
    useEffect(() => {
        dispatch(fetchFiles(id));
        dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, [id]);

    // var stat = false;

    useEffect(() => {
        dispatch(setWarning({ errorStat: false, message: '' }));
        if (errorStat) {
            error(errorMessage, Position.TOP_RIGHT);
        }
    }, [errorStat, errorMessage]);

    useEffect(() => {
        /* eslint no-var: off */
        /* eslint-disable no-undefined */
        if (!gateInDateExpired) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    }, [files, gateInDateExpired]);

    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: id ? `/bcnBookingList/${id}/POSelection` : '/bcnBookingList/POSelection',
            disabled: false,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/bcnBookingList/${id}/SailingSelectionDetails`,
            disabled: false,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/bcnBookingList/${id}/additional-details`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        },
        {
            title: 'Container Allocation',
            selected: 0,
            path: `/bcnBookingList/${id}/container-allocation`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        },
    ];
    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        navData
            .push
            // {
            //     title: 'Container Allocation',
            //     selected: 0,
            //     path: `/bcnBookingList/${id}/container-allocation`,
            //     disabled: false,
            // },

            // {
            //     title: 'Attachments',
            //     selected: 0,
            //     path: `/bcnBookingList/${id}/attachments`,
            //     disabled: false,
            // }
            ();
    }

    if (headerData?.bcnType === 'PAR') {
        navData.push({
            title: 'Linked Bookings',
            selected: 1,
            path: `/bcnBookingList/${id}/linked-bookings`,
            disabled: false,
        });
    }

    const navForChild = headerData?.bcnType === 'CLD' ? 'additional-details' : 'container-allocation';

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
        propagateBcnData(id, true);
    };
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                <BookingProcessTab navData={navData} />
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-additional-detail grid-p-0" style={{ marginTop: '35px' }}>
                {headerData?.bpNumber && (
                    <div className="main-wrapper">
                        <LayoutWithColumns
                            style={'lifecycle'}
                            content={'linked-bookings'}
                            customProp={headerData?.bpNumber}
                        />
                    </div>
                )}
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                {agentIntegrationStatus !== true && (
                                    <button
                                        title="Cancel Booking"
                                        className="app-btn app-btn-secondary footer-btn"
                                        onClick={() => {
                                            setShowBookingCancelModal(true);
                                        }}
                                    >
                                        <span className="button-text footer-button-text">Cancel Booking</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                        )} */}
                    {/* <Link title="Save" className="app-btn app-btn-secondary footer-btn" to={'/booking_list'}>
                        <span className="button-text footer-button-text">Save as Draft</span>
                    </Link> */}
                    <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/bcnBookingList/${id}/${navForChild}`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <ShowForPermissions type="bp" permission="create">
                        {profileType === 'agent' && bpStatus !== 'Booked' && headerData?.bcnType !== 'CLD' && (
                            <a
                                title="Ready for Shipment"
                                className={`app-btn app-btn-primary footer-btn ${
                                    validation && nextBtn && nextAddBtn ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    if (validation && nextBtn && nextAddBtn) {
                                        submitReadyForShipment();
                                    }
                                }}
                            >
                                <span className="button-text footer-button-text">Ready for Shipment</span>
                                {/* </button> */}
                            </a>
                        )}
                    </ShowForPermissions>
                </div>
            </div>
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
        </div>
    );
};
export default Index;
