import React, { useEffect, useState } from 'react';
import BookingProposalDetailsHeader from '../BookingProposalDetailsHeader';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import '../../BookingProposal/POSelection.scss';
import '../../BookingProposal/BookLineDetails/BookingDetailsGrid.scss';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks';
import SelectableGrid from 'src/components/common/selectableGrid/index';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import {
    fetchAvailablePoLineDataAir,
    resetAvailablePoLineListAir,
} from 'src/store/reducers/bookingProposalAir/availablePoLineReducer';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const { bpNumber, isLoading } = useSelector((state: RootState) => state.bpHeaderData);
    const { availablePoLineAir, poId, isHeaderOnlyPo } = useSelector((state: RootState) => state.availablePoLinesAir);
    const payload = JSON.parse(localStorage.getItem('polineAirKey') || '');
    const hasBookingId = id !== 'POSelection' && id !== 'POSelectionDetails';
    useEffect(() => {
        return () => {
            dispatch(resetAvailablePoLineListAir({}));
        };
    }, []);

    useEffect(() => {
        if (payload) {
            dispatch(fetchAvailablePoLineDataAir(payload));
        }
    }, [poId]);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder medium-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={hasBookingId ? 'Booking Proposal Details' : 'New Booking Proposal'}
                    secondUrl={
                        hasBookingId ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new'
                    }
                    thirdName={'PO Line Details'}
                    thirdUrl={'/air_booking_list/POSelectionDetails/new'}
                    backUrl={hasBookingId ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new'}
                />
                <BookingProposalDetailsHeader
                    setShowBookingSideBar={setShowBookingSideBar}
                    id={bpNumber ? bpNumber : ''}
                ></BookingProposalDetailsHeader>
            </div>
            <div
                className={`content-section main-wrapper ${
                    isHeaderOnlyPo ? 'po-selection-grid__bpdetails' : 'po-selection-grid'
                }`}
            >
                <div className="main-wrapper">
                    {isHeaderOnlyPo ? (
                        <span className="alert-msg">
                            <svg className="svg-icon">
                                <use xlinkHref="#errorCautionIcon"></use>
                            </svg>
                            This PO has been marked as Header Only with no PO lines included
                        </span>
                    ) : (
                        <div className="tab-with-action">
                            <div className="tab-with-search">
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        // value={searchText}
                                        className="search-input input-text"
                                        onChange={(_e: any) => {
                                            // setSearchText(e.target.value);
                                        }}
                                    ></input>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button
                                        className="app-btn text-close-btn"
                                        onClick={() => {
                                            // setSearchText('');
                                        }}
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="main-content-holder">
                        <div
                            className={`grid-section ${
                                isHeaderOnlyPo ? 'grid-bookpo-booking' : 'grid-details-booking'
                            }`}
                        >
                            {isHeaderOnlyPo && !isLoading ? (
                                <SelectableGrid tabName="poLinesHeaderOnly" data={availablePoLineAir} />
                            ) : null}
                            {!isHeaderOnlyPo && !isLoading ? (
                                <SelectableGrid tabName="poLinesAir" data={availablePoLineAir} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row"></div>
            {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )}
        </div>
    );
};
export default Index;
