import { get, post, patch } from '../HttpMethods';
import fileDownload from 'js-file-download';
import { getOrganisationData } from '../../utils';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
// import { useUserProfile } from '../../hooks/useUserProfile';

// const { profileType } = useUserProfile();
/* eslint-disable no-undefined */

export interface clientData {
    cl_PK: string;
    cl_Status: string;
    cl_Relationship: string;
    childClients: clientData[];
    cl_Org: {
        or_Orgname: string;
        or_Status: string;
        or_ParentOrgCode: string;
        or_Orgcode: string;
        or_Orgtype: string;
    };
    // name: string;
    // url: string;
}
export interface clientDataFull {
    clientDetailsResponseList: [
        {
            cl_PK: string;
            cl_Status: string;
            cl_Relationship: string;
            childClients: clientData[];
            cl_Org: {
                or_Orgname: string;
                or_Status: string;
                or_ParentOrgCode: string;
                or_Orgcode: string;
                or_Orgtype: string;
            };
        }
    ];
    totalNoOfPAges: number;
    clientDetailsNotAvailable: [];

    // name: string;
    // url: string;
}

export const getRoleWithPermission = async (payload: any) => {
    const res = await get(`/cix/scr/admin/role/lst/roles/${payload.profile}`, null, externalBaseUrl);

    return res?.data || [];
};

// Get All Datas
export const getUsersData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusValue: any;
    statusValueRoles: any;
    columnOrder: boolean;
    type: string;
    isForwarder: boolean;
}) => {
    try {
        const listOfUserOrganisations = getOrganisationData();
        const res = await post(
            `/api/mgt/user/list?profile=${payload.type}&sortColumn=${payload.columnStatus}&sortOrder=${
                payload.columnOrder ? 'DESC' : 'ASC'
            }&size=${payload.perPageCount}&page=${payload.currentPage}${
                payload.statusValue !== undefined && payload.statusValue !== null
                    ? `&filterProfileStatus=${payload.statusValue}`
                    : ''
            }${
                payload.statusValueRoles !== undefined && payload.statusValueRoles !== null
                    ? `&filterRoles=${payload.statusValueRoles}`
                    : ''
            }`,
            { ...listOfUserOrganisations, isForwarder: payload.isForwarder },
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const exportClientList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/clients/export?type=${type}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ClientProfiles.xlsx' : 'ClientProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const changeStatusUser = async (payload: any) => {
    try {
        const res = await post(`api/mgt/user/changestatus/${payload.id}`, payload.payload, null);
        return res;
    } catch (err) {
        console.log(err, 'errormessage');
        return (err as any)?.response;
    }
};

export const listAllClients = async (payload: any) => {
    try {
        const res = await post(`api/mgt/clients/list/${payload.profileType}`, payload.data, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const changeRoleOfUser = async (payload: any) => {
    try {
        const res = await patch(`api/mgt/user/role?userId=${payload.userId}&roleId=${payload.roleId}`, payload.payload);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const resendActivation = async (payload: any) => {
    try {
        const res = await post(`api/mgt/user/resendactivationemail/${payload?.userId}`, payload?.payload, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const listRoles = async (payload: any) => {
    try {
        if (payload?.type) {
            const res = await post(`api/mgt/user/roles?profile=${payload.type}`, payload.payload, null);
            return res;
        }
    } catch (err) {
        return console.error(err);
    }
};

export const linkClient = async (payload: any) => {
    try {
        payload.clients.forEach((element: any) => {
            const res = post(
                `/api/mgt/user/addorganisation?username=${payload.userName}&organisationId=${element}&roleId=${payload.roleId}`,
                payload?.payload,
                null
            );
            return res;
        });
    } catch (err) {
        return console.error(err);
    }
};
export const linkOrgClient = async (payload: any) => {
    const payloadsss = { ...payload.payload };
    try {
        const res = await post(`/api/mgt/user/update-user-acl`, payloadsss, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const removeClient = async (payload: any) => {
    try {
        payload.clients.forEach((element: any) => {
            const res = patch(
                `/api/mgt/user/organisation?username=${payload.userName}&organisationId=${element}&roleId=${payload.roleId}`,
                null
            );
            return res;
        });
    } catch (err) {
        return console.error(err);
    }
};

export const searchUsers = async (payload: {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    type: any;
    columnStatus: any;
    columnOrder: any;
    statusValue: any;
    statusValueRoles: any;
    statusFilter: any;
    isForwarder?: any;
}) => {
    const listOfUserOrganisations = getOrganisationData();
    try {
        if (payload.type.toLowerCase() === 'forwarder') {
            const res = await get(
                `api/mgt/search/forwarderusers?keyword=${payload.keyword}&sortColumn=${
                    payload.columnStatus
                }&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}&size=${payload.perPageCount}&page=${
                    payload.currentPage
                }${
                    payload.statusValue !== undefined && payload.statusValue !== null
                        ? `&filterProfileStatus=${payload.statusValue}`
                        : ''
                }${
                    payload.statusValueRoles !== undefined && payload.statusValueRoles !== null
                        ? `&filterRoles=${payload.statusValueRoles}`
                        : ''
                }`,
                null,
                null,
                true
            );
            return res;
        } else {
            const res = await post(
                `api/mgt/search/${payload.type === 'shipping' ? 'shippingline' : payload.type}users?profile=${
                    payload.type
                }&keyword=${payload.keyword}&sortColumn=${payload.columnStatus}&sortOrder=${
                    payload.columnOrder ? 'DESC' : 'ASC'
                }&size=${payload.perPageCount}&page=${payload.currentPage}${
                    payload.statusValue !== undefined && payload.statusValue !== null
                        ? `&filterProfileStatus=${payload.statusValue}`
                        : ''
                }${
                    payload.statusValueRoles !== undefined && payload.statusValueRoles !== null
                        ? `&filterRoles=${payload.statusValueRoles}`
                        : ''
                }`,
                { ...listOfUserOrganisations, isForwarder: payload.isForwarder },
                null,
                true
            );
            return res;
        }
    } catch (err) {
        return console.error(err);
    }
};

export const getHeaderDataUsers = async (payload: any) => {
    try {
        // const res = await post(`api/mgt/user/count?profile=${payload.profileType}`, payload.data, null);

        const result: any[] = [
            { profile: 'forwarder', inactive: 0, active: 0 },
            { profile: 'vendor', inactive: 0, active: 0 },
            { profile: 'agent', inactive: 0, active: 0 },
            { profile: 'shipping', inactive: 0, active: 0 },
            { profile: 'client', inactive: 0, active: 0 },
        ];

        const activeFwdResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeFwdRes = await get(
                    `cix/scr/admin/userProfile/FWD?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[0].active = activeFwdRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const inactiveFwdResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveFwdRes = await get(
                    `cix/scr/admin/userProfile/FWD?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[0].inactive = inactiveFwdRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const activeCltResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeCltRes = await get(
                    `cix/scr/admin/userProfile/CLT?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[4].active = activeCltRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const inactiveCltResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveCltRes = await get(
                    `cix/scr/admin/userProfile/CLT?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[4].inactive = inactiveCltRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const activeVndResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeVndRes = await get(
                    `cix/scr/admin/userProfile/VND?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[1].active = activeVndRes.total;
                resolve({ activeVndRes: activeVndRes });
            } catch (e) {
                reject(0);
            }
        });

        const inactiveVndResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveVndRes = await get(
                    `cix/scr/admin/userProfile/VND?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[1].inactive = inactiveVndRes.total;
                resolve({ inactiveVndRes: inactiveVndRes });
            } catch (e) {
                reject(0);
            }
        });

        const activeAgtResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeAgtRes = await get(
                    `cix/scr/admin/userProfile/AGT?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[2].active = activeAgtRes.total;
                resolve({ activeAgtRes: activeAgtRes });
            } catch (e) {
                reject(0);
            }
        });

        const inactiveAgtResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveAgtRes = await get(
                    `cix/scr/admin/userProfile/AGT?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[2].inactive = inactiveAgtRes.total;
                resolve({ inactiveAgtRes: inactiveAgtRes });
            } catch (e) {
                reject(0);
            }
        });

        const activeCarResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeCarRes = await get(
                    `cix/scr/admin/userProfile/CAR?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[3].active = activeCarRes.total;
                resolve({ activeCarRes: activeCarRes });
            } catch (e) {
                reject(0);
            }
        });

        const inactiveCarResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveCarRes = await get(
                    `cix/scr/admin/userProfile/CAR?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result[3].inactive = inactiveCarRes.total;
                resolve({ inactiveCarRes: inactiveCarRes });
            } catch (e) {
                reject(0);
            }
        });

        await Promise.all([
            activeFwdResPromise,
            inactiveFwdResPromise,
            activeCltResPromise,
            inactiveCltResPromise,
            activeVndResPromise,
            inactiveVndResPromise,
            activeAgtResPromise,
            inactiveAgtResPromise,
            activeCarResPromise,
            inactiveCarResPromise,
        ]);

        return result;
    } catch (err) {
        return console.error(err);
    }
};
