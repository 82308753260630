import React, { useEffect, useState } from 'react';
import './received-quantity.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    updateReceivedQty,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    selectReceiptHeaderError,
    clearFailure,
    fetchReceiptHeaderList,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, success, Position } from '../../../../utils/toast';
import BSTooltip from '../../../../components/common/BSTooltip';
interface ReceivedQtyModalProps {
    receivedQtyModalVisibility: boolean;
    setReceivedQtyModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
}
import ImageView from './ImageView';
import { useParams } from 'react-router-dom';
import { fetchPolineReceipt } from '../../../../store/reducers/receipt/poLine';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { poStatusPillList } from '../POLinesConfirmation/PoStatusList';
import { getFailedReasonImage } from '../../../../services/apis/receipts/receiptHeaderApi';
import imageLoader from '../../../../assets/images/image-loader.svg';
enum PoLineStatus {
    ReadyToReceive = 'Ready to Receive',
    ReceivedInFull = 'Received in Full',
    OverReceived = 'Over Received',
    ShortReceipt = 'Short Receipt',
    Cancelled = 'Cancelled',
}
const Index: React.FC<ReceivedQtyModalProps> = ({
    receivedQtyModalVisibility,
    setReceivedQtyModalVisibility,
    data,
}) => {
    const { id } = useParams();
    const { userName, fullName } = useUserProfile();
    const [isLoading, setIsLoading] = useState(false);
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [availableQty, setAvailableQty] = useState<any>(0);
    const [onHoldQty, setOnHoldQty] = useState<any>(0);
    const [totalReceivedQty, setTotalReceivedQty] = useState(0);
    const [status, setStatus] = useState('');
    const [comments, setComments] = useState('');
    const [imageUpdated, setImageUpdated] = useState<any>(null);
    const [markAsReceivedFull, setMarkAsReceivedFull] = useState(false);
    const [receivedQtyImages, setReceivedQtyImages] = useState<any>([]);
    const [files, setFiles] = useState([] as any);
    const [imageURLS, setImageURLs] = useState([]);
    const [removedImages, setRemovedImages] = useState<any>([]);
    const [images, setImages] = useState([] as any);

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);

    const onSubmit = () => {
        setIsLoading(true);
        const formData = new FormData();
        const uploadData = {
            availableQty,
            onHoldQty: onHoldQty > 0 ? +onHoldQty : 0,
            totalReceivedQty,
            status,
            comments,
            docType: 'EDIT_RECEIPT_PO_IMAGE',
            fullName,
            userName,
            removedImages,
            markAsReceivedFull,
        };

        formData.append('document', new Blob([JSON.stringify(uploadData)], { type: 'application/json' }));
        for (let index = 0; index < files.length; index++) {
            formData.append('files', files[index]);
        }
        // for (const file of files) {
        //     formData.append('files', file);
        // }

        dispatch(updateReceivedQty({ formData, receiptSubPoLineId: data?.rsId }));
    };

    const generateUrl = (base64Image: string, fileName: string) => {
        const [extension] = fileName.split('.').reverse();
        return `data:image/${extension};base64,${base64Image}`;
    };

    const getImage = (receivedQtyImages: any) => {
        if (receivedQtyImages?.length) {
            setReceivedQtyImages(receivedQtyImages);
            getFailedReasonImage({
                receiptSubPoLineId: data?.rsId,
                type: 'EDIT_RECEIPT_PO_IMAGE',
            })
                .then((res: any) => {
                    const urls = res?.data;
                    const images = JSON.parse(JSON.stringify(receivedQtyImages));
                    const imageWithUrl = images?.map((response: any) => ({
                        ...response,
                        fileUrl: generateUrl(
                            urls?.find((elem: any) => elem?.fileId === response?.fileId)?.base64,
                            response?.fileName
                        ),
                    }));
                    setReceivedQtyImages(imageWithUrl);
                })
                .catch((err: any) => console.log(err, 'err'));
        }
    };

    const resetCommentsAndImages = () => {
        setImages([]);
        setRemovedImages([]);
        setImageURLs([]);
        setFiles([]);
        setReceivedQtyImages([]);
        setCurrentImageIndex(0);
        setComments('');
        setImageUpdated(false);
        const fileElement = document.getElementById('receivedQtyImage');
        if (fileElement instanceof HTMLInputElement) {
            fileElement.value = '';
        }
    };

    const resetAllData = () => {
        setOnHoldQty(0);
        setAvailableQty(0);
        setTotalReceivedQty(0);
        setStatus('');
        setMarkAsReceivedFull(false);
        resetCommentsAndImages();
    };

    useEffect(() => {
        if (receivedQtyModalVisibility) {
            setAvailableQty(data?.availableQty ? data?.availableQty : 0);
            setOnHoldQty(data?.onHoldQty >= 0 && data?.onHoldQty != null ? data?.onHoldQty : '');
            setTotalReceivedQty(data?.totalReceivedQty ? data?.totalReceivedQty : 0);
            setStatus(data?.status ? data?.status : '');
            setComments(data?.comments ? data?.comments : '');
            setMarkAsReceivedFull(data?.markAsReceivedFull ? data?.markAsReceivedFull : false);
            getImage(data?.editImageDetailsList);
        } else {
            resetAllData();
        }
    }, [receivedQtyModalVisibility]);

    useEffect(() => {
        const totalQty = availableQty + (onHoldQty >= 0 && onHoldQty != null ? +onHoldQty : 0);
        setTotalReceivedQty(totalQty);
    }, [availableQty, onHoldQty]);

    const editingCount = (availableQuantity: number | string, onHoldQuantity: any) => {
        if (typeof availableQuantity === 'string') {
            if (onHoldQuantity === '') {
                setStatus(PoLineStatus.ReadyToReceive);
            }
            return;
        }
        let availableQty = availableQuantity;
        let onHoldQty = onHoldQuantity >= 0 && onHoldQuantity != '' ? +onHoldQuantity : 0;
        if (availableQty > data?.plannedReceiptQuantity) {
            availableQty = data?.plannedReceiptQuantity;
            onHoldQty = availableQuantity - data?.plannedReceiptQuantity;
            setAvailableQty(availableQty);
            setOnHoldQty(onHoldQty);
        }
        const totalQty = availableQty + onHoldQty;
        if (availableQty === 0 && onHoldQty === 0) {
            setMarkAsReceivedFull(false);
            setStatus(PoLineStatus.Cancelled);
        } else if (totalQty === data?.plannedReceiptQuantity) {
            setMarkAsReceivedFull(false);
            resetCommentsAndImages();
            setStatus(PoLineStatus.ReceivedInFull);
        } else if (totalQty < data?.plannedReceiptQuantity) {
            if (markAsReceivedFull === false && onHoldQty === 0) {
                resetCommentsAndImages();
            }
            setStatus(PoLineStatus.ShortReceipt);
        } else if (totalQty > data?.plannedReceiptQuantity) {
            if (markAsReceivedFull === false && onHoldQty === 0) {
                resetCommentsAndImages();
            }
            setMarkAsReceivedFull(false);
            setStatus(PoLineStatus.OverReceived);
        }
    };

    useEffect(() => {
        if (markAsReceivedFull) {
            setStatus(PoLineStatus.ReceivedInFull);
        } else {
            resetCommentsAndImages();
            editingCount(availableQty, onHoldQty);
        }
    }, [markAsReceivedFull]);

    useEffect(() => {
        if (isError && receiptHeaderError?.config?.url?.includes('receipts/edit')) {
            error(receiptHeaderError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'updateReceivedQty') {
                success('Received Quantity updated successfully', Position.TOP_RIGHT);
                setReceivedQtyModalVisibility(false);
                dispatch(clearFailure([]));
                dispatch(fetchPolineReceipt(id));
                dispatch(
                    fetchReceiptHeaderList({
                        id,
                    })
                );
            }
        }
        setIsLoading(false);
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    useEffect(() => {
        if (images.length < 1) {
            return;
        }
        const newImageUrls = images.map((image: any) => ({
            fileUrl: URL.createObjectURL(image),
            fileName: image.name,
            fileSize: image.size,
        }));
        setImageURLs(newImageUrls);
    }, [images]);

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            setFiles((prev: any) => [...prev, ...e.target.files]);
            // setFiles((prev: any) => e.target.files);
            setImages((prev: any) => [...prev, ...e.target.files]);
            setImageUpdated(true);
        }
    };

    const removeFile = (currentIndex: number) => {
        const newFileList = Array.from(files);
        newFileList.splice(currentIndex, 1);
        const imagesData = images?.filter((_: any, index: number) => index !== currentIndex);
        setFiles(newFileList);
        setImages(imagesData);
        setImageURLs(imagesData);
        setImageUpdated(true);
    };
    const removeExistingFile = (currentIndex: number) => {
        const images = receivedQtyImages?.filter((_: any, index: number) => index !== currentIndex);
        setReceivedQtyImages(images);
        const removed = [...removedImages, receivedQtyImages[currentIndex].fileId];
        setRemovedImages(removed);
        setImageUpdated(true);
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };

    const checkSubmit = () => {
        if (availableQty === '') {
            return true;
        }
        if (
            ((markAsReceivedFull === true || onHoldQty > 0 || status === PoLineStatus.Cancelled) && comments === '') ||
            (availableQty === data?.availableQty && onHoldQty === data?.onHoldQty && comments === data?.comments) ||
            (data?.availableQty == null && data?.onHoldQty == null && availableQty === 0 && onHoldQty === '')
        ) {
            if (
                imageUpdated === true &&
                (markAsReceivedFull === true || onHoldQty > 0 || status === PoLineStatus.Cancelled) &&
                comments !== ''
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    useEffect(() => {
        checkSubmit();
    }, [imageUpdated]);

    return (
        <>
            {receivedQtyModalVisibility ? (
                <div className="app-modal">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="modal-content-holder medium-popup received-modal"
                    >
                        <div className="modal-header">
                            <div className="title">Edit Received Quantity</div>
                            <button
                                onClick={() => setReceivedQtyModalVisibility(false)}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                {data?.currentComplianceCheckStatus === 'failed' && (
                                    <div className="warning-text">
                                        <div>
                                            <svg className="svg-icon error-icon">
                                                <use xlinkHref="#errorIcon">
                                                    <title>Warning</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="error-text">
                                            Compliance check has failed for this SKU and quantity can only be received
                                            On Hold
                                        </div>
                                    </div>
                                )}
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">SKU</h6>
                                        <div className="description-type-1">{data?.sku}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Description</h6>
                                        <div className="description-type-1">{data?.description}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Inbound PO</h6>
                                        <div className="description-type-1">{data?.inboundPoNumber}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Planned Qty</h6>
                                        <div className="description-type-1">{data?.plannedReceiptQuantity}</div>
                                    </div>
                                </div>
                                <h2 className="modal-layout-title">Received Quantity</h2>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1 input-heading">Available</h6>
                                        <div className="description-type-1">
                                            <div className="modal-grid-input-holder">
                                                <input
                                                    placeholder=""
                                                    disabled={
                                                        data?.currentComplianceCheckStatus === 'failed' ? true : false
                                                    }
                                                    type="text"
                                                    className="modal-grid-input input-text"
                                                    name="availableQty"
                                                    value={availableQty}
                                                    onChange={(e) => {
                                                        const value = e.target.value === '' ? '' : +e.target.value;
                                                        setAvailableQty(value);
                                                        editingCount(value, onHoldQty);
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className="heading-icon-holder">
                                            <h6 className="heading-type-1 input-heading">On Hold</h6>
                                            <BSTooltip
                                                id="onHold"
                                                title="On Hold quantity can include goods not ready for allocation/Qty received if compliance check has failed/ excess Qty received than planned. On Hold quantity can be adjusted after receipt"
                                                classList="info-btn"
                                                tooltipClassList="info-btn-text"
                                            >
                                                <button className="app-btn">
                                                    <svg className="svg-icon help-icon">
                                                        <use xlinkHref="#helpIcon">
                                                            <title>Warning</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </BSTooltip>
                                        </div>
                                        <div className="description-type-1">
                                            <div className="modal-grid-input-holder">
                                                <input
                                                    placeholder=""
                                                    type="text"
                                                    className="modal-grid-input input-text"
                                                    name="onHoldQty"
                                                    value={onHoldQty}
                                                    onChange={(e) => {
                                                        setOnHoldQty(+e.target.value);
                                                        editingCount(availableQty, +e.target.value);
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {status === PoLineStatus.OverReceived && (
                                    <div className="title-content">
                                        Since Available Qty cannot be more than the Planned, the extra Qty will be
                                        received On Hold
                                    </div>
                                )}
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Total Received Qty</h6>
                                        <div className="description-type-1">{totalReceivedQty}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Status</h6>
                                        <div className="description-type-1">{poStatusPillList(status)}</div>
                                    </div>
                                </div>
                                <form id="productForm" action="" className="modal-form">
                                    {(status === PoLineStatus.ShortReceipt || markAsReceivedFull) && (
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    checked={markAsReceivedFull}
                                                    onChange={() => setMarkAsReceivedFull((prev: boolean) => !prev)}
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Mark as Received in Full</div>
                                            </label>
                                        </div>
                                    )}
                                    {(markAsReceivedFull === true ||
                                        status === PoLineStatus.Cancelled ||
                                        onHoldQty > 0) && (
                                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                            <label htmlFor="name" className="modal-input-label">
                                                Comments<span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <textarea
                                                    name="reason"
                                                    className="input-text"
                                                    placeholder="Enter Comments"
                                                    value={comments}
                                                    onChange={(e) => setComments(e.target.value)}
                                                    maxLength={250}
                                                ></textarea>
                                            </div>
                                        </div>
                                    )}
                                </form>
                                {(markAsReceivedFull === true ||
                                    status === PoLineStatus.Cancelled ||
                                    onHoldQty > 0) && (
                                    <div className="layout-holder">
                                        {/* <div className="layout-item">
                                        <h6 className="heading-type-1">Comments</h6>
                                        <div className="description-type-1">
                                            Quantity has received in exces because of specified reason
                                        </div>
                                    </div> */}
                                        <div className="layout-item">
                                            <h6 className="header-title">Images</h6>
                                            <div className="description-type-1">
                                                <div className=" modal-input-holder">
                                                    <div className="modal-input-layout-item">
                                                        <div className="browse-btn-wrapper">
                                                            <input
                                                                type={'file'}
                                                                multiple
                                                                id={'complianceImage'}
                                                                name={'complianceImage'}
                                                                accept={'.png,.jpeg,.jpg'}
                                                                className="browse-input"
                                                                onClick={(e) => {
                                                                    (e.target as HTMLInputElement).value = '';
                                                                }}
                                                                onChange={(e) => {
                                                                    fileUpload(e);
                                                                }}
                                                            ></input>
                                                            <button
                                                                className="app-btn app-btn-secondary icon-button browse-btn"
                                                                title="Upload file"
                                                                type="button"
                                                            >
                                                                <svg className="svg-icon plus-icon">
                                                                    <use xlinkHref="#plusIcon">
                                                                        <title>Upload Image</title>
                                                                    </use>
                                                                </svg>
                                                                <span className="button-text upload-btn">
                                                                    Upload Image
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="heading-type-1 file-size">
                                                            <p>( Upload JPEG, PNG file less than 25 mb )</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {receivedQtyImages?.map((res: any, index: number) => (
                                                    <div key={index} className="attachment-items-holder">
                                                        <div className="attachment-icon-holder">
                                                            <div className="image-holder">
                                                                <a href="#">
                                                                    <img
                                                                        onClick={() => {
                                                                            setCurrentImageIndex(index);
                                                                            setShowImageViewer(true);
                                                                        }}
                                                                        className="logo-icon"
                                                                        src={res?.fileUrl ? res?.fileUrl : imageLoader}
                                                                        alt="Image"
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="filename-folder">
                                                                <div
                                                                    className="description-type-1 file-uploaded"
                                                                    title="File"
                                                                >
                                                                    {res?.fileName?.length > 20
                                                                        ? `${res?.fileName?.slice(0, 20)}...`
                                                                        : res?.fileName}
                                                                </div>
                                                                <div className="heading-type-1">
                                                                    {getFileSizeOfUploadedFile(res?.fileSize)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a
                                                            onClick={() => removeExistingFile(index)}
                                                            className="file-remove-link read-all-link"
                                                            title="Remove"
                                                        >
                                                            Remove
                                                        </a>
                                                    </div>
                                                ))}
                                                {imageURLS?.length > 0 &&
                                                    imageURLS?.map((res: any, index: number) => (
                                                        <div key={index} className="attachment-items-holder">
                                                            <div className="attachment-icon-holder">
                                                                <div className="image-holder">
                                                                    <a href="#">
                                                                        <img
                                                                            onClick={() => {
                                                                                if (receivedQtyImages?.length) {
                                                                                    setCurrentImageIndex(
                                                                                        receivedQtyImages?.length +
                                                                                            index
                                                                                    );
                                                                                } else {
                                                                                    setCurrentImageIndex(index);
                                                                                }
                                                                                setShowImageViewer(true);
                                                                            }}
                                                                            className="logo-icon"
                                                                            src={res?.fileUrl}
                                                                            alt="Image"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="filename-folder">
                                                                    <div
                                                                        className="description-type-1 file-uploaded"
                                                                        title="File"
                                                                    >
                                                                        {res?.fileName?.length > 20
                                                                            ? `${res?.fileName?.slice(0, 20)}...`
                                                                            : res?.fileName}
                                                                    </div>
                                                                    <div className="heading-type-1">
                                                                        {getFileSize(res?.fileSize)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a
                                                                onClick={() => removeFile(index)}
                                                                className="file-remove-link read-all-link"
                                                                title="Remove"
                                                            >
                                                                Remove
                                                            </a>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => setReceivedQtyModalVisibility(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                onClick={() => onSubmit()}
                                disabled={checkSubmit()}
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                title="Save"
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                    <ImageView
                        imageViewerModalVisibility={showImageViewer}
                        setImageViewerModalVisibility={setShowImageViewer}
                        originalData={[...receivedQtyImages, ...imageURLS]}
                        currentImageIndex={currentImageIndex}
                        setCurrentImageIndex={setCurrentImageIndex}
                    />
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
