import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

interface GridProps {
    rs?: any;
}

const GridLayout = (gridProps: GridProps) => {
    const token = `${localStorage.getItem('token') ?? ''}`;
    if (!token) {
        return null;
    }

    const { id } = useParams();

    return (
        <Helmet>
            <script>
                {`
                                function deleteRow(id) {
            var grid = $("#grid").data("kendoGrid");
            var dataSource = grid.dataSource;
            var item = dataSource.get(id);

                dataSource.remove(item);
                toast("info","Remember to save after deleting all your records")

            }

                    $("#grid").kendoGrid({
    dataSource: {
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        pageSize: 1000,
        //sort : { field: "RI_CD_Status", dir: "desc" },
        transport: {
            read: {
                url: '${externalBaseUrl}/cix/scr/utility/customFields/${id}',
                headers: {
                    Authorization: 'Bearer: ${token}'
                },
                dataType: 'json'
            },
            update: {
                url: '${externalBaseUrl}/cix/scr/utility/customFields/${id}',
                headers: {
                    Authorization: 'Bearer: ${token}'
                },
                dataType: 'json',
                type: "PATCH",
                contentType: 'application/json'
            },
            destroy: {
                url: '${externalBaseUrl}/cix/scr/utility/customFields/${id}',
                headers: {
                    Authorization: 'Bearer: ${token}'
                },
                dataType: 'json',
                type: "DELETE",
                contentType: 'application/json'
            },
            create: {
                url: '${externalBaseUrl}/cix/scr/utility/customFields/${id}',
                headers: {
                    Authorization: 'Bearer: ${token}'
                }
                ,
                dataType: 'json',
                type: "POST",
                contentType: 'application/json'
            },
            parameterMap: function (data, type) {
                if (type == 'update' || type == 'create' || type == 'destroy') {
                    data = JSON.stringify(data);
                }
                return data;
            }
        },
        schema: {
            total: function (response) {
                return response.total;
            },
            data: function (response) {
                return response.data;
            },
            model: {
                id: "UF_PK",
                fields: {
                    UF_PK: { type: "string" },
                    UF_Integer1: {type: "number",defaultValue : 0},
                    UF_FKID: { type: "string", defaultValue: "RA" },
                    UF_String1: { type: "string" },
                    UF_String1: { type: "string" }
                }
            }
        },
        batch: true
    },
    toolbar: [
        {
            name: "create",
            template:
                '<button class="k-button k-grid-add app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-pen fa-2xl" style="--fa-primary-color: \\\\#0e9fc4; --fa-secondary-color: \\\\#0e9fc4;"></i>New</button>',
        },
        {
            name: "save",
            template:
                '<button class="k-button k-grid-save-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk fa-2xl" style="--fa-primary-color: \\\\#003cb3; --fa-secondary-color: \\\\#003cb3;"></i>Save</button>',
        },
        {
            name: "cancel",
            template:
                '<button class="k-button k-grid-cancel-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-circle-xmark fa-2xl" style="--fa-primary-color: \\\\#ff004c; --fa-secondary-color: \\\\#ff004c;"></i>Cancel</button>',
        },
        {
            name: 'search',
            // template: '<input type="search" id="searchBox" class="k-textbox llama" style="width: 250px"/>',
        }
    ],
    selectable: "multiple row",
    navigatable: true,
    height: 500,
    groupable: true,
    resizable: true,
    sortable: true,
    pageable: {
        refresh: true,
        pageSizes: [10, 25, 100, 500, 1000, "all"],
        buttonCount: 5,
        alwaysVisible: true
    },
                        groupable: true,
                        editable: {confirmation: false, mode: "incell"},
                        pageSize: 100,
    columns: [
    { field: "UF_Integer1", title: "Order", width: 100},
    { field: "UF_String1", title: "Term"},
     {
        title: "Actions",
        width: 170 ,
        template: "<button type='button' class='action-btn app-btn app-btn-secondary' onclick='deleteRow(\\"#=UF_PK#\\")'><i class='fa-duotone fa-trash-xmark' title='Delete'></i></button>",
        attributes: { style: "text-align: center" },

        }
    ]

})
                    `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
