/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import Types from '../Types';
import '../../Users/users.scss';
import ProfileListing from 'src/components/common/ProfileListing';
import { coloaderTableHeaders } from 'src/utils/constants';
import {
    fetchColoaderData,
    setPageCount,
    setselectedFilter,
    searchColoaderData,
    setCurrentPage,
} from 'src/store/reducers/profiles/coloader';
import TableFooter from 'src/components/common/Table/tableFooter';
import { exportColoaderListProfiles } from 'src/services/apis/profiles/coloader';
import ExportButton from 'src/components/common/ExportButton';

import ShowForPermissions from 'src/ShowForPermissions';
// import { fetchCountData } from 'src/store/reducers/countReducer';
import { checkPermissions, checkPermissionsWrap } from 'src/utils/checkPermission';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        coloaders,
        currentPage,
        perPageCount,
        totalPages,
        columnStatus,
        totalItems,
        isLoading,
        isLoading2,
        filterValues,
        selectedFilter,
    } = useSelector((state: RootState) => state.coloader);
    const { permissions } = useSelector((state: RootState) => state.permissionData);

    const { count } = useSelector((state: RootState) => state);
    const [selectedHeader, setSelectedHeader] = useState(coloaderTableHeaders.status);
    // const [selectedFilter, setselectedFilter] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandId, setexpandId] = useState<string | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<number[] | null>(null);
    const [pageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(false);
    const [showExportDropDown, setShowExportDropDown] = useState(false);

    const { userName } = useUserProfile();
    const sortPayload = {
        columnOrder: currentOrder ? 'DESC' : 'ASC',
        columnStatus: columnStatus,
    };
    let payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        columnOrder: currentOrder,
        keyword: searchTerm,
    };

    React.useEffect(() => {
        document.title = 'CNF>ORG>CLD';
        return () => {
            dispatch(setCurrentPage(0));
            dispatch(setPageCount(10));
        };
    }, []);

    useEffect(() => {
        if (permissions?.length !== 0 && !checkPermissionsWrap(permissions, 'view', 'coloader')) {
            navigate('/notfound');
        }
    }, [permissions]);

    useEffect(() => {
        if (searchTerm?.trim()?.length > 2) {
            dispatch(searchColoaderData(payload));
        } else if (searchTerm?.trim()?.length === 0) {
            dispatch(fetchColoaderData(payload));
        }
        // dispatch(fetchCountData(payload));
        dispatch(fetchPermissions(userName));
    }, [dispatch, currentPage, setSelectedHeader, perPageCount, currentOrder, searchTerm, selectedFilter, location]);

    useLayoutEffect(() => {
        //dispatch(fetchCountData({}));
        if ((location as any)?.state?.reload !== true) {
            dispatch(setselectedFilter(null));
            payload = { ...payload, statusFilter: null };

            //setSelectedHeader(clientTableHeaders.status);
        }
    }, [location]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalPages]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        if (data) {
            setTimeout(() => {
                setToggleDropdown(true);
            }, 100);
        }
        dispatch(setselectedFilter(data));
        if (searchTerm?.trim()?.length > 1) {
            dispatch(searchColoaderData({ ...payload, keyword: searchTerm, currentPage: 0, statusFilter: data }));
        } else {
            dispatch(
                fetchColoaderData({
                    ...payload,
                    currentPage: 0,
                    statusFilter: data,
                })
            );
        }
    };

    const setExpandIdOnClick = (id: string) => {
        setexpandId(id);
    };

    const filterToggle = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const onSort = (sortKey: string) => {
        setSelectedHeader(sortKey);
        setcurrentOrder(!currentOrder);
    };

    const onSearchClose = () => {
        setSearchTerm('');
        dispatch(
            fetchColoaderData({
                ...payload,
                currentPage: 0,
            })
        );
    };

    return (
        <div className="main-wrapper" onClick={() => setShowExportDropDown(false)}>
            <div className="tab-nav-holder">
                {!checkPermissions(permissions) && (
                    <NavLink className="tab-nav active" to="/profile">
                        Organisations
                    </NavLink>
                )}
                <ShowForPermissions permission="view" type="users">
                    <NavLink className="tab-nav" to="/users/forwarder">
                        Users
                    </NavLink>
                </ShowForPermissions>
                <ShowForPermissions permission="view" type="roles">
                    <NavLink className="tab-nav" to="/rolespermissions/forwarder">
                        Roles & Permissions
                    </NavLink>
                </ShowForPermissions>
            </div>
            <div className={`app-loader ${isLoading2 ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-content-holder">
                <Types />
                <div className="grid-section user-profile-grid grid-p-0">
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'tab'} content={'profile-coloader'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
