import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, del } from '../HttpMethods';
import axios from 'axios';

export const getSavedGridLayouts = async (key: string) => {
    try {
        const response = await get(
            `cix/user/me/userdata/gridLayout/${key}?attributes=UD_PK,UD_UserDefinedText1,UD_UserDefinedText2`,
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            ...res,
            UD_UserDefinedText2: res?.UD_UserDefinedText2 === 'quicklink' ? true : false,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateSavedGridLayouts = async (id: string, payload: any) => {
    try {
        const response = await patch('cix/user/me/userdata/' + id, payload, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deleteSavedGridLayouts = async (id: string) => {
    try {
        const response = await del('cix/user/me/userdata/' + id, null, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
