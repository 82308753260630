import ShowForPermissions from '../../../../ShowForPermissions';

interface Table {
    setmodalView: any;
    data: any;
    totalActive: number;
    search: string;
    setSearch: any;
}
const TableHeaderVendor = ({ setmodalView, totalActive, search, setSearch }: Table) => {
    return (
        <div className="grid-header">
            <div className="search-entry-holder">
                {/* <input
                    placeholder="Search Client Details"
                    type="text"
                    className="search-input input-text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                ></input>
                <div className={`search-toolip ${search.length > 0 && search.length < 3 ? 'tooltip-show' : ''}`}>
                    Enter atleast 3 characters to search
                </div>

                <svg className="svg-icon search-icon">
                    <use xlinkHref="#searchIcon"></use>
                </svg>
                <button
                    className="app-btn text-close-btn"
                    onClick={() => {
                        if (search?.trim()?.length) {
                            setSearch('');
                        }
                    }}
                >
                    <svg className="svg-icon text-close-icon">
                        <use xlinkHref="#closeIcon"></use>
                    </svg>
                </button> */}
            </div>
            <div className="item-count-status-holder">
                {/* <div className="grid-item-count-status status-ui success-status">
                    <span className="count">{totalActive ? totalActive : '0'}</span>
                    <span className="status-text">
                        {totalActive && totalActive <= 1 ? 'Client ' : 'Clients '}linked
                    </span>
                </div> */}
            </div>
            <ShowForPermissions permission="create" type="vendor">
                <button
                    className="app-btn app-btn-secondary icon-button grid-top-btn"
                    title="Link Client"
                    onClick={() => setmodalView(true)}
                >
                    <svg className="svg-icon link-vendor-btn-icon">
                        <use xlinkHref="#linkIcon"></use>
                    </svg>
                    <span className="button-text vendor-button-text">Link Clients</span>
                </button>
            </ShowForPermissions>
        </div>
    );
};

export default TableHeaderVendor;
