import React, { useCallback, useEffect, useState } from 'react';
// import L from 'leaflet';
import useSupercluster from 'use-supercluster';
import { Marker, useMap, Tooltip } from 'react-leaflet';
import '../styles/popup-tooltip-style.scss';
import Icons from '../Icons/index';
import PopUps from './Popups/Index';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearSingleMapData,
    fetchAllMapData,
    // fetchSidebarData,
    saveSingleMapData,
    showSidebar,
    sideBarType,
} from 'src/store/reducers/dashboard/map';
import { BBox, GeoJsonProperties } from 'geojson';
import { PointFeature } from 'supercluster';
import { useMapClustering } from '../hooks';
import { RootState } from 'src/store/reducers';

interface Point extends PointFeature<GeoJsonProperties> {
    data: any;
}

const MapData: React.FC<{ haveToolTips?: boolean }> = ({ haveToolTips }) => {
    const { sideBarView, sideBarValue, hoveredTileKey, singleMapData, locationValue, clickedTileKey, isLoading } =
        useSelector((state: RootState) => state.map);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);
    const {
        // insightTab,
        insightsMode,
        insightsDestination,
        insightsSelectedType,
        insightsMainPage,
        insightsSelectedTypeFilter,
        // insightsForwarded,
        // favInsightsMode,
        // favInsightsDestination,
        // favInsightsForwarded,
        // favInsightsSelectedType,
        // favInsightsSelectedTypeFilter,
    } = useSelector((state: RootState) => state.dashboardInsights);

    const icons: any = {};
    const maxZoom = 11;
    // const [bounds, setBounds] = useState<BBox | undefined>([0, 0, 0, 0]);
    // const [zoom, setZoom] = useState(11);
    const [selectedPosition, setselectedPosition] = useState<number[]>([]);
    const [selectedClusterPosition, setselectedClusterPosition] = useState<number[]>([]);
    const [_clusterId, setclusterId] = useState(0 || '') as any;
    const [clusterArrayData, setclusterArrayData] = useState([]);
    const [mouseHovertedTooltip, setmouseHovertedTooltip] = useState(true); //to check if tooltip is hovered or not
    const [markerToolRip, setmarkerToolRip] = useState(true);
    // const [popupView, setpopupView] = useState(false);
    const [move, setOnMove] = useState(false);
    const map = useMap();

    const dispatch = useDispatch();

    const fetchIconClustered = (count: any, size: number, data: any, location: any) => {
        //clustering similar type icons
        let flag = '';
        let shippingType = 'SEA';
        let countVal = 0; //count of general clustered icons
        let totalCount = 0; //count of similar type clustered icons
        data?.forEach((element: any) => {
            if (element.data?.type !== flag) {
                countVal++;
                flag = element.data?.type;
                shippingType = element?.data?.shippingMode;
            }
            if (element.data?.type == flag) {
                totalCount = totalCount + countFunction(element?.data);
            }
        });

        //if (!icons[count]) {
        //clustered icon
        icons[count] = Icons(
            countVal > 1 ? 'cluster' : flag,
            countVal > 1 ? countVal : totalCount,
            location,
            selectedPosition,
            sideBarType,
            shippingType,
            clusterArrayData,
            selectedClusterPosition
        );
        //}
        return icons[count];
    };

    //Restured map data inorder to accomodate clustering
    const { mapClusterData } = useMapClustering();

    const onMove = useCallback(() => {
        setOnMove(!move);
    }, [map, move]);

    useEffect(() => {
        map.on('move', onMove);
        return () => {
            map.off('move', onMove);
        };
    }, [map, onMove]);
    const b = map.getBounds();
    const boundsArray = [
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat,
    ] as BBox;

    const points: Array<Point> = mapClusterData?.map((value: any, index: number) => ({
        type: 'Feature',
        properties: { cluster: false, valueId: index, data: value },
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(value?.lat), parseFloat(value?.lon)],
        },
        data: value,
    }));

    useEffect(() => {
        setselectedPosition([]);
    }, [locationValue]);

    const { clusters, supercluster } = useSupercluster({
        points: points,
        bounds: boundsArray,
        zoom: map.getZoom(),
        options: { radius: 85, maxZoom: 11 },
    });

    const countFunction = (data: any) => {
        switch (data?.type) {
            case 'bookingsIntransitArray':
                if (hoveredTileKey === 'eta') {
                    return data?.etaDelayedCount + data?.etaIn7DaysCount;
                }
                return data?.bookingCount;
            case 'bookingsAtOriginPort':
                if (hoveredTileKey === 'eta') {
                    return data?.etaDelayedCount + data?.etaIn7DaysCount;
                } else if (hoveredTileKey === 'etd') {
                    return data?.etdDelayedCount + data?.etdIn7DaysCount;
                }
                return data?.bookingCount;
            case 'bookingsAtDestinationPort':
                return data?.bookingCount;
            case 'posAtOriginCountry':
                return data?.poCount;
            case 'bookingsAtOriginCountry':
                if (hoveredTileKey === 'eta') {
                    return data?.etaDelayedCount + data?.etaIn7DaysCount;
                } else if (hoveredTileKey === 'etd') {
                    return data?.etdDelayedCount + data?.etdIn7DaysCount;
                }
                return data?.bookingCount;
            default:
                return data?.bookingCount;
        }
    };

    const fetchMapDatas = (value: any) => {
        switch (value.type) {
            case 'bookingsIntransitArray':
                dispatch(
                    fetchAllMapData({
                        tileType: value.type,
                        type: 'bp',
                        mode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        destinationType: !!insightsDestination?.length ? insightsDestination[0].toLowerCase() : '',
                        selectedType: insightsMainPage?.toLowerCase()?.includes('orders')
                            ? 'orders'
                            : insightsMainPage?.toLowerCase()?.includes('booking')
                            ? 'bookings'
                            : '',
                        selectedStatus: insightsSelectedTypeFilter,
                        selectedCategory:
                            insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : insightsSelectedType === 'ITPO'
                                ? 'international'
                                : insightsSelectedType === 'IBPO'
                                ? 'inbound'
                                : insightsSelectedType?.toLowerCase() === 'po'
                                ? 'po'
                                : insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : '',
                        selectedMode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        searchValue: selectedSearchItem?.bpNumber,
                    })
                );
                break;
            case 'bookingsAtOriginPort':
                dispatch(
                    fetchAllMapData({
                        tileType: value.type,
                        portSource: 'originPort',
                        type: 'bp',
                        portCode: value?.originPortCode,
                        mode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        destinationType: !!insightsDestination?.length ? insightsDestination[0].toLowerCase() : '',
                        selectedType: insightsMainPage?.toLowerCase()?.includes('orders')
                            ? 'orders'
                            : insightsMainPage?.toLowerCase()?.includes('booking')
                            ? 'bookings'
                            : '',
                        selectedStatus: insightsSelectedTypeFilter,
                        selectedCategory:
                            insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : insightsSelectedType === 'ITPO'
                                ? 'international'
                                : insightsSelectedType === 'IBPO'
                                ? 'inbound'
                                : insightsSelectedType?.toLowerCase() === 'po'
                                ? 'po'
                                : insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : '',
                        selectedMode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        searchValue: selectedSearchItem?.bpNumber,
                    })
                );
                break;
            case 'bookingsAtDestinationPort':
                dispatch(
                    fetchAllMapData({
                        tileType: value.type,
                        portSource: 'destinationPort',
                        type: 'bp',
                        portCode: value?.destinationPortCode,
                        mode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        destinationType: !!insightsDestination?.length ? insightsDestination[0].toLowerCase() : '',
                        selectedType: insightsMainPage?.toLowerCase()?.includes('orders')
                            ? 'orders'
                            : insightsMainPage?.toLowerCase()?.includes('booking')
                            ? 'bookings'
                            : '',
                        selectedStatus: insightsSelectedTypeFilter,
                        selectedCategory:
                            insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : insightsSelectedType === 'ITPO'
                                ? 'international'
                                : insightsSelectedType === 'IBPO'
                                ? 'inbound'
                                : insightsSelectedType?.toLowerCase() === 'po'
                                ? 'po'
                                : insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : '',
                        selectedMode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        searchValue: selectedSearchItem?.bpNumber,
                    })
                );
                break;
            case 'posAtOriginCountry':
                dispatch(
                    fetchAllMapData({
                        tileType: value.type,
                        type: 'po',
                        countryCode: value?.countryCode,
                        mode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        destinationType: !!insightsDestination?.length ? insightsDestination[0].toLowerCase() : '',
                        selectedType: insightsMainPage?.toLowerCase()?.includes('orders')
                            ? 'orders'
                            : insightsMainPage?.toLowerCase()?.includes('booking')
                            ? 'bookings'
                            : '',
                        selectedStatus: insightsSelectedTypeFilter,
                        selectedCategory:
                            insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : insightsSelectedType === 'ITPO'
                                ? 'international'
                                : insightsSelectedType === 'IBPO'
                                ? 'inbound'
                                : insightsSelectedType?.toLowerCase() === 'po'
                                ? 'po'
                                : insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : '',
                        selectedMode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        searchValue: selectedSearchItem?.bpNumber,
                    })
                );
                break;
            case 'bookingsAtOriginCountry':
                dispatch(
                    fetchAllMapData({
                        tileType: value.type,
                        type: 'bp',
                        countryCode: value?.countryCode,
                        mode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        destinationType: !!insightsDestination?.length ? insightsDestination[0].toLowerCase() : '',
                        selectedType: insightsMainPage?.toLowerCase()?.includes('orders')
                            ? 'orders'
                            : insightsMainPage?.toLowerCase()?.includes('booking')
                            ? 'bookings'
                            : '',
                        selectedStatus: insightsSelectedTypeFilter,
                        selectedCategory:
                            insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : insightsSelectedType === 'ITPO'
                                ? 'international'
                                : insightsSelectedType === 'IBPO'
                                ? 'inbound'
                                : insightsSelectedType?.toLowerCase() === 'po'
                                ? 'po'
                                : insightsSelectedType?.toLowerCase() === 'ao'
                                ? 'ao'
                                : '',
                        selectedMode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                        searchValue: selectedSearchItem?.bpNumber,
                    })
                );
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (clickedTileKey?.length) {
            setselectedPosition([]);
        }
    }, [clickedTileKey]);
    useEffect(() => {
        if (!haveToolTips) {
            if (!mouseHovertedTooltip) {
                if (!sideBarValue) {
                    setmouseHovertedTooltip(true);
                    dispatch(saveSingleMapData(null));
                }
            }
        }
    }, [mouseHovertedTooltip]);

    useEffect(() => {
        if (!mouseHovertedTooltip) {
            const data = localStorage.getItem('singleMapData');
            data && dispatch(saveSingleMapData(data));
        }
    }, [mouseHovertedTooltip]);

    useEffect(() => {
        //to centre the map to the first data
        if (points[0]?.data?.lat) {
            const defaultLat: any = {
                lng: points[0]?.data?.lat,
                lat: points[0]?.data?.lon,
            };
            map.setView(defaultLat, map.getZoom(), {
                animate: true,
            });
        }
    }, [isLoading]);

    const condtionForPop = (data: any) => {
        if (data?.vesselName?.length) {
            if (
                sideBarType !== data?.type &&
                data?.type === singleMapData?.type &&
                data?.vesselName === (singleMapData as any)?.vesselName &&
                !sideBarView &&
                mouseHovertedTooltip &&
                markerToolRip &&
                haveToolTips
            ) {
                if (data?.type === 'posAtOriginCountry' && data?.countryCode !== singleMapData?.countryCode) {
                    return false;
                }
                return true;
            }
        } else {
            if (
                sideBarType !== data?.type &&
                data?.type === singleMapData?.type &&
                !sideBarView &&
                mouseHovertedTooltip &&
                markerToolRip &&
                haveToolTips
            ) {
                if (data?.type === 'posAtOriginCountry' && data?.countryCode !== singleMapData?.countryCode) {
                    return false;
                }
                if (data?.type === 'bookingsAtOriginPort' && data?.lat !== singleMapData?.lat) {
                    return false;
                }
                return true;
            }
        }
        return false;
    };

    return (
        <>
            {clusters.map((cluster) => {
                // every cluster point has coordinates
                const [longitude, latitude] = cluster.geometry.coordinates; // the point may be a cluster
                const { cluster: isCluster, point_count: pointCount } = cluster.properties as any;
                const data: any = (cluster as any)?.data; // we have a cluster to render
                if (isCluster) {
                    return (
                        <Marker
                            key={`cluster-${cluster.id}`}
                            position={[latitude, longitude]}
                            icon={fetchIconClustered(
                                pointCount,
                                10 + (pointCount / points.length) * 40,
                                typeof cluster.id === 'number' && supercluster?.getLeaves(cluster?.id),
                                cluster?.geometry?.coordinates
                            )}
                            eventHandlers={{
                                click: (_event) => {
                                    const expansionZoom =
                                        typeof cluster.id === 'number' && supercluster
                                            ? Math.min(supercluster.getClusterExpansionZoom(cluster.id), maxZoom)
                                            : 1;
                                    map.setView([latitude, longitude], expansionZoom, {
                                        animate: true,
                                    });
                                    setselectedClusterPosition([]);
                                    if (!sideBarView) {
                                        setselectedPosition([]);
                                    }
                                },
                                mouseover: (_event) => {
                                    if (haveToolTips && !sideBarView) {
                                        //checking if the saved data is the same as mouseover data to stop the loop

                                        if (
                                            JSON.stringify(
                                                typeof cluster.id === 'number' && supercluster?.getLeaves(cluster?.id)
                                            ) != JSON.stringify(singleMapData)
                                        ) {
                                            setclusterId(cluster.id);
                                            const clusterArray: any = [];
                                            typeof cluster.id === 'number' &&
                                                supercluster?.getLeaves(cluster?.id)?.forEach((clust: any) => {
                                                    clusterArray.push(clust?.geometry.coordinates);
                                                });
                                            setselectedClusterPosition(cluster.geometry.coordinates);
                                            setclusterArrayData(clusterArray);
                                            typeof cluster.id === 'number' &&
                                                dispatch(saveSingleMapData(supercluster?.getLeaves(cluster?.id))); //save single data
                                        }
                                    }
                                }, //popup on hover
                                mouseout: (_event) => {
                                    if (haveToolTips && !sideBarView) {
                                        setTimeout(() => {
                                            dispatch(saveSingleMapData(null));
                                            setselectedClusterPosition([]);
                                        }, 200);
                                    }
                                },
                            }}
                        >
                            {!sideBarView && haveToolTips && <Tooltip interactive={true}>{PopUps('cluster')}</Tooltip>}
                        </Marker>
                    );
                }

                // we have a single point to render
                return (
                    <Marker
                        key={`value-${cluster?.properties?.valueId}`}
                        position={[latitude, longitude]}
                        zIndexOffset={1000}
                        icon={Icons(
                            data?.type, //type of icon
                            countFunction(data), //count for icon
                            [data.lat, data.lon], //position for checking selected and blur
                            selectedPosition,
                            !!sideBarValue,
                            data?.shippingMode,
                            clusterArrayData,
                            selectedClusterPosition
                        )} //icons according to the type
                        eventHandlers={{
                            mouseover: (_event) => {
                                if (!sideBarView) {
                                    //checking if the saved data is the same as mouseover data to stop the loop
                                    setmouseHovertedTooltip(true);
                                    if (JSON.stringify(data) != JSON.stringify(singleMapData)) {
                                        setselectedPosition([data.lat, data.lon]); //save selected position
                                        dispatch(saveSingleMapData(data)); //save single data
                                        localStorage.setItem('singleMapData', JSON.stringify(singleMapData));
                                    }
                                }
                            }, //popup on hover
                            mouseout: async (_event) => {
                                //event.target.closePopup(); //close popup
                                if (!sideBarView && !sideBarValue) {
                                    setTimeout(() => {
                                        setmouseHovertedTooltip(false);
                                        setselectedPosition([]);
                                    }, 500);
                                }
                            },
                            preclick: () => {
                                dispatch(clearSingleMapData({}));
                            },
                            click: (event) => {
                                event.target.closePopup(); //close popup
                                dispatch(saveSingleMapData(null)); //remove current saved data firsst
                                if (haveToolTips) {
                                    map.setView(event.latlng, map.getZoom(), {
                                        animate: true,
                                    });
                                    map.getZoom();
                                    fetchMapDatas(data);
                                    dispatch(showSidebar(true)); //expand drawer
                                    dispatch(sideBarType(data?.type)); //set drawer heading
                                    setselectedPosition([data.lat, data.lon]); //save selected position
                                    setTimeout(() => {
                                        dispatch(saveSingleMapData(data)); //save single data
                                    }, 100);
                                }
                            },
                        }}
                    >
                        {/*popup according to the type*/}
                        {condtionForPop(data) && (
                            <Tooltip
                                eventHandlers={{
                                    mouseover: (_e) => {
                                        setmouseHovertedTooltip(true);
                                        setmarkerToolRip(true);
                                        setTimeout(() => {
                                            setmouseHovertedTooltip(true);
                                        }, 500);
                                    },
                                    mouseout: (_e) => {
                                        if (!sideBarValue) {
                                            setmouseHovertedTooltip(true);
                                            dispatch(saveSingleMapData(null));
                                        }
                                        setselectedPosition([]); //clearing selected item
                                    },
                                }}
                                interactive={true}
                                permanent={
                                    mouseHovertedTooltip &&
                                    markerToolRip &&
                                    sideBarType !== data?.type &&
                                    data?.type === singleMapData?.type &&
                                    !sideBarView
                                }
                            >
                                {PopUps(data?.type)}
                            </Tooltip>
                        )}
                    </Marker>
                );
            })}
        </>
    );
};

export default MapData;
