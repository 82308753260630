import { createSlice } from '@reduxjs/toolkit';
import { clientBasicDetails, getClientDetails } from '../../services/apis/clientApi';

export const vendorDat = createSlice({
    name: 'vendors',
    initialState: {
        vendors: [],
        isLoading: false,
        currentPage: 1,
        success: false,
        linkedVendors: 0,
        unlinkDat: [],
        selectedList: [],
        activeVendors: [],
        vendorLoading: false,
        totalActiveVendors: 0,
        totalPages: 1,
        successFetch: false,
        loading: true,
        clientBasicDetails: undefined,
        linkToast: false,
        unlinkToast: { success: false, failed: false },
    },
    reducers: {
        fetchData: (state, _action) => {
            state.isLoading = true;
        },
        fetchClientDetails: (state, action) => {
            state.clientBasicDetails = action.payload.clientDetails;
        },
        saveFetchedData: (state, action) => {
            state.vendors = action.payload?.info?.vendorList;
            if (action.payload?.info?.vendorList?.length !== 0 && Array.isArray(action.payload?.info?.vendorList)) {
                state.successFetch = true;
            } else {
                state.successFetch = false;
            }
            state.isLoading = false;
            state.totalActiveVendors = action.payload?.info?.totalLinkedVendors;
            state.totalPages = action.payload?.info?.totalNoOfPages;
        },
        activeVendors: (state, _action) => {
            state.vendorLoading = true;
        },
        actionVendorsData: (state, action) => {
            state.activeVendors = action.payload;
            state.vendorLoading = false;
        },
        linkingVendors: (state, _action) => {
            state.success = true;
        },
        linkingVendorsData: (state, action) => {
            state.success = true;
            state.linkedVendors = action.payload.length;
            state.success = true;
        },
        unlinkData: (state, action) => {
            state.success = false;
            state.unlinkDat = action.payload;
            state.success = true;
        },
        unlinkVendor: (_state, _action) => {
            // console.log(action.payload, 'vendor_id');
        },
        isLoadingfn: (state, action) => {
            state.loading = action.payload;
        },
        clearVendors: (state, _action) => {
            state.vendors = [];
        },
        onUnlinking: (state, action) => {
            if (action.payload?.status === true) {
                state.unlinkToast.success = true;
                state.unlinkToast.failed = false;
            } else {
                state.unlinkToast.failed = true;
                state.unlinkToast.success = false;
            }
        },
        cancelWarning: (state, _action) => {
            state.unlinkToast.success = false;
            state.unlinkToast.failed = false;
        },
        linkingToast: (state, action) => {
            state.linkToast = action.payload;
        },
        searchClientVendorsData: (state, action) => {
            state.isLoading = true;
            state.vendors = action.payload?.vendorList;
            state.currentPage = action.payload.currentPage;
            state.totalActiveVendors = action.payload?.totalLinkedVendors;
            state.totalPages = action.payload?.totalNoOfPages;
            state.isLoading = false;
        },
        searchClientVendorsData2: (state, action) => {
            state.isLoading = true;
            state.vendors = action.payload?.vendorList;
            state.currentPage = action.payload.currentPage;
            state.totalActiveVendors = action.payload?.totalLinkedVendors;
            state.totalPages = action.payload?.totalNoOfPages;
            state.isLoading = false;
        },
    },
});

export const {
    fetchData,
    linkingVendors,
    unlinkData,
    unlinkVendor,
    linkingVendorsData,
    activeVendors,
    saveFetchedData,
    actionVendorsData,
    isLoadingfn,
    clearVendors,
    onUnlinking,
    linkingToast,
    cancelWarning,
    searchClientVendorsData,
    searchClientVendorsData2,
    fetchClientDetails,
} = vendorDat.actions;
export default vendorDat.reducer;
