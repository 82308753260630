import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { getVendorBasicDetails } from 'src/services/apis/createVendorApi';
import { get } from 'src/services/HttpMethods';

interface propsData {
    bookingData?: any;
    Btn?: any;
}
const Index: React.FC<propsData> = ({ Btn }) => {
    const { vendorDetails: vendorData } = useSelector((state: any) => state.lifeCycleSearch);
    // const { id } = useParams() as any;
    const [vendorDetails, setVendorDetails] = useState<any>({});
    const getVendorDeatils = async () => {
        try {
            const res = await get(`/api/mgt/vendors/profile?vn_pk=${vendorData.vendorID}`, null);
           // console.log(res, 'search');
            setVendorDetails(res);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (vendorData.vendorID) {
            getVendorDeatils();
        }
    }, [vendorData?.vendorID]);

    return (
        <>
            {Btn && <Btn />}
            <div className="lcs-basic-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Name</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_name || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Code *</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_org_code || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Category*</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_org_category || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Company Registration Number</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_abn || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">UNLOCO</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_unloco || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Country of Origin</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_origin_country || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Default Incoterm</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_default_incoterms || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Phone</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_phone || '-'}</div>
                </div>

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Website URL</div>
                    <div className="cm-key-pair-value">{vendorDetails?.vn_website_url || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Manufacturing Categories*</div>
                    {vendorDetails?.vn_manufacturing_categories?.length ? (
                        vendorDetails?.vn_manufacturing_categories?.map((category: any, index: number) => {
                            return (
                                <div className="cm-key-pair-value" key={index}>
                                    {category}
                                </div>
                            );
                        })
                    ) : (
                        <div className="cm-key-pair-value">-</div>
                    )}
                </div>
            </div>
        </>
    );
};
export default Index;
