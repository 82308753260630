import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isLoadingFn } from '../../reducers/inventory/inventoryListReducer';
import { getAdjustmentData, updateAdjustmentData } from '../../../services/apis/inventory/adjstmntApi';
import { dataLoader, saveAdjstData, successMessage } from '../../reducers/inventory/inventoryAdjstmntReducer';

function* getAdjustmentDataList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const inventories: AxiosResponse<any> = yield call(getAdjustmentData, payload);
        yield put({ type: saveAdjstData, payload: inventories });
        yield put({ type: dataLoader, payload: true });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* submitData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(updateAdjustmentData, payload);
        yield put({ type: successMessage, payload: true });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* InventoryAdjstSaga() {
    yield takeEvery('inventoryAdjstmnt/fetchAdjstMent', getAdjustmentDataList);
    yield takeEvery('inventoryAdjstmnt/confirmData', submitData);
}

export default InventoryAdjstSaga;
