import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    BpContainerDetail,
    updateDate,
    selectContainerAllocationIsError,
    selectContainerAllocationSuccess,
    selectContainerAllocationError,
    fetchContainerDetail,
    clearFailure,
    fetchContainerOnlyBasicDetail,
} from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { error, Position, success } from '../../../../utils/toast';
import DatePicker from '../../../../components/common/DatePicker';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TimePicker as TimePickerPack } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    containerData: BpContainerDetail;
    bpId: any;
    allocationId: any;
    containerDetailsId: any;
}
interface Dates {
    collectionDate: string;
    dropOffDate: string;
    pickUpDate: string;
    gateInDate: string;
    gateInTime: string;
}
const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    containerData,
    bpId,
    allocationId,
    containerDetailsId,
    setCancelModalView,
}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectContainerAllocationIsError);
    const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    const [isSaveClicked, setIsSaveClicked] = useState(false);

    const initialValues: Dates = {
        collectionDate: containerData?.collectionDate ? moment(containerData?.collectionDate).format('YYYY-MM-DD') : '',
        dropOffDate: containerData?.dropOffDate ? moment(containerData?.dropOffDate).format('YYYY-MM-DD') : '',
        pickUpDate: containerData?.pickUpDate ? moment(containerData?.pickUpDate).format('YYYY-MM-DD') : '',
        gateInDate: containerData?.gateInDate ? moment.utc(containerData?.gateInDate).local().format('YYYY-MM-DD') : '',
        gateInTime: containerData?.gateInDate ? moment.utc(containerData?.gateInDate).local().format('HH:mm') : '',
    };

    const BasicDetailsSchema = Yup.object().shape({
        collectionDate: Yup.date().required('Collection Date from Depot is required'),
        dropOffDate: Yup.date().required('Drop Off Date at BLP is required'),
        // .min(Yup.ref('collectionDate'), 'Drop Off Date at BLP must be greater than Collection Date from Depot')
        // .test({
        //     name: 'same',
        //     exclusive: false,
        //     params: {},
        //     message: 'Drop Off Date at BLP must be greater than Collection Date from Depot',
        //     test: function (value) {
        //         const collectionDate = moment(this.parent.collectionDate).format('YYYY-MM-DD');
        //         const dropOffDate = moment(value).format('YYYY-MM-DD');
        //         return !moment(collectionDate).isSame(moment(dropOffDate));
        //     },
        // }),
        pickUpDate: Yup.date().required('Pick Up Date from BLP is required'),
        // .min(Yup.ref('dropOffDate'), 'Pick Up Date from BLP must be greater than Drop Off Date at BLP')
        // .test({
        //     name: 'same',
        //     exclusive: false,
        //     params: {},
        //     message: 'Pick Up Date from BLP must be greater than Drop Off Date at BLP',
        //     test: function (value) {
        //         const dropOffDate = moment(this.parent.dropOffDate).format('YYYY-MM-DD');
        //         const pickUpDate = moment(value).format('YYYY-MM-DD');
        //         return !moment(dropOffDate).isSame(moment(pickUpDate));
        //     },
        // }),
        gateInDate: Yup.date().required('Gate In Date is required'),
        // .min(Yup.ref('collectionDate'), 'Gate In Date must be greater than Collection Date from Vendor'),
        // gateInTime: Yup.string().required('Gate In Time is required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: BasicDetailsSchema,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values: any) => {
            if (formik.isSubmitting) {
                const dateTime = values.gateInDate + ' ' + values.gateInTime + ':00';
                const formData: any = {
                    ...values,
                    gateInDate: moment(dateTime, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                };
                delete formData.gateInTime;
                setIsLoading(true);
                dispatch(
                    updateDate({
                        ...formData,
                        cdId: allocationId,
                        containerDetailsId: containerDetailsId ? containerDetailsId : null,
                        mode: containerData?.collectionDate ? 'edit' : 'add',
                        bpId,
                    })
                );
            }
        },
    });

    useEffect(() => {
        setIsLoading(false);
        if (isError && ContainerAllocationError?.data?.path?.includes('dates')) {
            error(ContainerAllocationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (containerAllocationSuccess?.status === 200 || containerAllocationSuccess?.status === 201) {
            if (containerAllocationSuccess?.data?.type === 'date') {
                success(
                    `Dates have been ${containerAllocationSuccess?.data?.mode === 'add' ? 'added' : 'updated'}`,
                    Position.TOP_RIGHT
                );
                if (containerAllocationSuccess?.data?.info?.containerDetailsId) {
                    dispatch(
                        fetchContainerDetail({
                            containerDetailsId: containerAllocationSuccess?.data?.info?.containerDetailsId,
                        })
                    );
                } else {
                    dispatch(fetchContainerOnlyBasicDetail({ containerId: allocationId }));
                }
                setModalView(false);
                formik.resetForm();
                dispatch(clearFailure([]));
                if (!containerDetailsId) {
                    navigate(`/Aobooking_list/${bpId}/container-allocation/${allocationId}/details`, {
                        state: { containerDetailsId: containerAllocationSuccess?.data?.info?.containerDetailsId },
                    });
                }
            }
        }
    }, [isError, containerAllocationSuccess, ContainerAllocationError]);

    useEffect(() => {
        if (!modalView) {
            setIsSaveClicked(false);
            formik.resetForm();
        }
    }, [modalView]);

    const showError = (field: keyof typeof initialValues) => (
        <>
            {formik.errors[field] && isSaveClicked ? (
                <div className="error-text">{formik.errors[field]?.toString()}</div>
            ) : null}
        </>
    );

    return (
        <>
            {modalView ? (
                <>
                    <div className="app-modal">
                        <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                        <div className="modal-content-holder basic-detail-content-holder medium-popup">
                            <div className="modal-header">
                                <div className="title">Dates</div>
                                <button
                                    type="reset"
                                    className="app-btn modal-close-btn"
                                    onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                                >
                                    <span>
                                        <svg className="svg-icon modal-header-close-icon">
                                            <use href="#closeIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div className="modal-content">
                                <div className="modal-main-content sop-modal-main-content">
                                    <form
                                        id="profile-form"
                                        action=""
                                        className="modal-form layout-holder one-column"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Collection Date from Depot
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="collectionDate"
                                                    portalId="date-portal"
                                                />
                                                {showError('collectionDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Drop Off Date at BLP
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={
                                                    //     formik.values.collectionDate
                                                    //         ? (() => {
                                                    //               const minDate = new Date(
                                                    //                   formik.values.collectionDate
                                                    //               );
                                                    //               minDate.setDate(minDate.getDate() + 1);
                                                    //               return minDate;
                                                    //           })()
                                                    //         : new Date()
                                                    // }
                                                    formik={formik}
                                                    fieldName="dropOffDate"
                                                    portalId="date-portal"
                                                />
                                                {showError('dropOffDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Pick Up Date from BLP
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={
                                                    //     formik?.values?.dropOffDate
                                                    //         ? (() => {
                                                    //               const minDate = new Date(formik?.values?.dropOffDate);
                                                    //               minDate.setDate(minDate.getDate() + 1);
                                                    //               return minDate;
                                                    //           })()
                                                    //         : new Date()
                                                    // }
                                                    formik={formik}
                                                    fieldName="pickUpDate"
                                                    portalId="date-portal"
                                                />
                                                {showError('pickUpDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Gate in Date and Time
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar date-time">
                                                <DatePicker
                                                    // minDate={
                                                    //     formik?.values?.pickUpDate
                                                    //         ? (() => {
                                                    //               const minDate = new Date(formik?.values?.pickUpDate);
                                                    //               minDate.setDate(minDate.getDate() + 1);
                                                    //               return minDate;
                                                    //           })()
                                                    //         : new Date()
                                                    // }
                                                    formik={formik}
                                                    fieldName="gateInDate"
                                                    portalId="date-portal"
                                                />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <TimePickerPack
                                                            onChange={(value: any) => {
                                                                formik.setFieldValue(
                                                                    'gateInTime',
                                                                    moment(value.$d).format('HH:mm')
                                                                );
                                                            }}
                                                            views={['hours', 'minutes']}
                                                            format="HH:mm"
                                                            ampm={false}
                                                            timeSteps={{ minutes: 1 }}
                                                            value={dayjs('0000-00-00T' + formik.values.gateInTime)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {showError('gateInDate')}
                                                <span
                                                    style={{
                                                        marginLeft: 'auto',
                                                        marginTop: '2px',
                                                        paddingRight: '26px',
                                                    }}
                                                >
                                                    {showError('gateInTime')}
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="app-btn app-btn-secondary modal-btn footer-btn text-close-btn"
                                    type="button"
                                    form="profile-form"
                                    onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn "
                                    type="submit"
                                    form="profile-form"
                                    onClick={() => {
                                        setIsSaveClicked(true);
                                    }}
                                    // disabled={Object.keys(formik.errors).length ? true : false}
                                >
                                    <span className="button-text vendor-button-text">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
