export const registerPageLoad = (pathname: string | null) => {
    if (pathname) {
        const _paq = ((window as any)._paq = (window as any)._paq || ([] as any));
        _paq.push(['setCookieDomain', pathname]);
        _paq.push(['setDocumentTitle', pathname]);
        _paq.push(['trackPageView']);
        (function () {
            //console.log(location);

            let siteId = '';

            switch (location.hostname) {
                case 'localhost':
                    return;
                case 'dev.cschub.net':
                    return;
                case 'qa.cschub.net':
                    return;
                case 'www.cschub.net':
                    siteId = '12';
                    break;
            }
            // eslint-disable-next-line no-var
            var u = 'https://cloudsupplychain.matomo.cloud/';
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', siteId]);
            if (localStorage?.user) {
                _paq.push(['setUserId', JSON.parse(localStorage.user)?.user?.us_Email]);
            }
            const d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.async = true;
            // g.src = '//cdn.matomo.cloud/cloudsupplychain.matomo.cloud/matomo.js';
            g.src = 'https://cdn.matomo.cloud/cloudsupplychain.matomo.cloud/container_NjYzi8d2.js';
            if (s && s.parentNode) {
                s.parentNode.insertBefore(g, s);
            }
        })();
    }
};
