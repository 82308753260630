import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchClientAndWarehouseData,
    fetchWareHouseData,
    saveInventoryData,
    isLoadingFn,
    saveInventoryDataDiscrepancy,
    fetchInventoryLogs,
} from '../../reducers/inventory/inventoryListReducer';
import {
    getClientAndWarehouseValues,
    getInventoryData,
    getInventoryDataDiscrepancies,
    getInventoryLog,
    getWarehouse,
    viewAllDiscrepancy,
} from '../../../services/apis/inventory/inventoryListApi';

function* getInventoryListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const inventories: AxiosResponse<any> = yield call(getInventoryData, payload);
        payload['inventories'] = inventories;
        yield put({ type: saveInventoryData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getInventoryListDataDiscrepancy({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const inventories: AxiosResponse<any> = yield call(getInventoryDataDiscrepancies, payload);
        payload['discrepancies'] = inventories;
        yield put({ type: saveInventoryDataDiscrepancy, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getClientAndWarehouseData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(getClientAndWarehouseValues, payload);
        payload['response'] = response;
        yield put({ type: fetchClientAndWarehouseData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getWareHouseData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any[]> = yield call(getWarehouse, payload);
        payload['response'] = response;
        yield put({ type: fetchWareHouseData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getInventoryLogData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const inventoryLog: AxiosResponse<any[]> = yield call(getInventoryLog, payload);
        payload['inventoryLog'] = inventoryLog;
        yield put({ type: fetchInventoryLogs, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* viewAllDiscrepancyValues({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(viewAllDiscrepancy, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* InventoryListSaga() {
    yield takeLatest('inventoryList/fetchInventoryData', getInventoryListData);
    yield takeEvery('inventoryList/fetchInventoryDataDiscrepancy', getInventoryListDataDiscrepancy);
    yield takeEvery('inventoryList/fetchClientAndWarehouseData', getClientAndWarehouseData);
    yield takeEvery('inventoryList/fetchWareHouseData', getWareHouseData);
    yield takeEvery('inventoryList/fetchInventoryLogs', getInventoryLogData);
    yield takeEvery('inventoryList/viewedDiscrepancy', viewAllDiscrepancyValues);
}

export default InventoryListSaga;
