import React, { useEffect, useState } from 'react';
// import BasicDetailsTab from '../BasicDetailsTab';
import '../lcsBookingDetails.scss';
// import ScheduleTab from '../ScheduleTab';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useParams } from 'react-router-dom';

const baseURL = process.env.REACT_APP_BASE_URL;

const Index: React.FC = () => {
    const [lcsTab, setLcsTab] = useState('poDetails');
    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };
    const { id }: any = useParams();
    const { userName } = useUserProfile();
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const poItem = gridDetails.filter((res) => res.name === 'purchase-order')[0];
    useEffect(() => {
        if (lcsTab === 'poDetails') {
            poItem.url = `${baseURL}/api/ord/tracking/lifecycle/search/po/podetailsgrid?poId=${id}`;
            // 000JHCTOOL,ea04bf00-972b-4f00-bbdb-0d6537b36638
        }
    }, [lcsTab, id]);

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        href="#"
                        className={`tab-nav ${lcsTab === 'poDetails' ? 'active' : ''}`}
                        onClick={() => setLcsView('poDetails')}
                    >
                        Purchase Order Details
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'poDetails' && (
                    <KendoGridJquery
                        gridItem={poItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
