import './sailing-schelude-detail-modal.scss';
import { getDateDifference } from 'src/utils';
import moment from 'moment';
interface PropsDisplay {
    displayFunc: any;
    data: any;
    mainData?: any;
}
const combineDateTime = (date: string, time: string) => {
    return moment(date)?.format('YYYY-MM-DD') + ' ' + time;
};
const Index: React.FC<PropsDisplay> = ({ displayFunc, data }) => {
    const startDateTime = combineDateTime(data?.originEtdDate, data?.originEtdTime);
    const endDateTime = combineDateTime(data?.destEtaDate, data?.destEtaTime);
    const dateDifference = getDateDifference(startDateTime, endDateTime);
    return (
        <div className="app-modal">
            {data?.originPortName ? (
                <div className="modal-content-holder additional-detail-modal large-popup">
                    <div className="modal-header">
                        <div className="title">Sailing Schedule Details</div>
                        <button className="app-btn modal-close-btn" onClick={() => displayFunc(false)}>
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use href="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>

                    <div className="modal-content">
                        <div className="modal-main-content">
                            <div className="sailing-detail">
                                <div className="sailing-icon-booking-detail">
                                    <div className="sailing-icon">
                                        <svg className="svg-icon clock-icon">
                                            <use xlinkHref="#clockIcon"></use>
                                        </svg>
                                    </div>
                                    <div className="sailing-detail-time">
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">Standard Booking Cut-Off</h6>
                                            {data?.standardBookingCutOff ? (
                                                <div className="details-box-content">
                                                    {moment(data?.standardBookingCutOff)?.format('DD-MM-YYYY')} ,
                                                    <span className="booking-time">
                                                        {moment(data?.standardBookingCutOff).utc()?.format('HH:mm')}
                                                    </span>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="sailing-dated">
                                    <div className="details-col box-content-holder">
                                        <h6 className="details-box-heading">
                                            {data?.plannedTransitDays} Day{data?.plannedTransitDays > 1 ? 's' : ''}
                                        </h6>
                                        <div className="details-box-content">
                                            {data?.sailingScheduleTransitPorts?.length}
                                            <span className="booking-time">Multi-Legged</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stepProgress-wrapper">
                                <ul className="StepProgress">
                                    <li className="StepProgress-item first-start">
                                        <div className="status-with-grid-holder">
                                            <div className="status-etd-date-wrapper">
                                                <div className="status-name">Origin</div>
                                                <div className="etd-date-holder">
                                                    <div className="etd-date-start-holder">
                                                        <span>
                                                            <svg className="svg-icon progress-start-icon">
                                                                <use xlinkHref="#progressStartIcon"></use>
                                                            </svg>
                                                        </span>
                                                        <div className="ets-name">
                                                            ETD:
                                                            <span className="ets-date">
                                                                {data?.plannedETD
                                                                    ? moment(data?.plannedETD)?.format('DD-MM-YYYY')
                                                                    : moment(data?.originEtdDate)?.format('DD-MM-YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="view-grid-container">
                                                <div className="cell list-more-view-items col-anchor">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon anchor-icon">
                                                            <use href="#anchorIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            {data?.originPortName ? (
                                                                <span
                                                                    className="tab-heading"
                                                                    style={{ wordBreak: 'break-word' }}
                                                                >
                                                                    {/*ZIM (Zim Integated shipping services) (ZIMU)*/}
                                                                    {data?.originPortName} ({data?.originPortCode})
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="tab-heading"
                                                                    style={{ wordBreak: 'break-word' }}
                                                                >
                                                                    {/*ZIM (Zim Integated shipping services) (ZIMU)*/}
                                                                    {data?.originAirPortName}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="terminal-content">
                                                                {data?.originPortTerminal}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cell list-more-view-items col-ship">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon ship-icon">
                                                            {data?.originPortName ? (
                                                                <use href="#shipIcon">
                                                                    <title>Icon</title>
                                                                </use>
                                                            ) : (
                                                                <use xlinkHref="#shippingModeAir">
                                                                    <title>Air</title>
                                                                </use>
                                                            )}
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span
                                                                className="tab-heading"
                                                                style={{ wordBreak: 'break-word' }}
                                                            >
                                                                {data?.voyageId
                                                                    ? data?.voyageId?.split('-')[0]
                                                                    : data?.flightNo}
                                                            </span>
                                                            <span className="modal-toast" style={{ width: 'auto' }}>
                                                                {data?.contractType}
                                                            </span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="detail-count-number">
                                                                {data?.voyageId?.split('-')[1]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cell list-more-view-items col-transhipment">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon ship-icon">
                                                            <use href="#transhipmentIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{data?.service}</span>
                                                        </div>
                                                        <div className="content-detail"></div>
                                                    </div>
                                                </div>
                                                <div className="cell col-vgm-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Gate in Open</h6>
                                                        {data?.gateInOpen ? (
                                                            <div className="details-box-content">
                                                                {moment(data?.gateInOpen)?.format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(data?.gateInOpen).utc()?.format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cell col-vgm-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">VGM Cut-Off</h6>
                                                        {data?.vgmCutOff ? (
                                                            <div className="details-box-content">
                                                                {moment(data?.vgmCutOff)?.format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(data?.vgmCutOff).utc()?.format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cell col-vgm-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Documentation Cut-Off</h6>
                                                        {data?.docCutOff ? (
                                                            <div className="details-box-content">
                                                                {moment(data?.docCutOff)?.format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(data?.docCutOff)?.utc()?.format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cell col-port-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Gate in Cut-Off</h6>
                                                        {data?.gateInCutOff ? (
                                                            <div className="details-box-content">
                                                                {moment(data?.gateInCutOff)?.format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(data?.gateInCutOff)?.utc()?.format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {data?.sailingScheduleTransitPorts?.map((res: any) => (
                                        <li className="StepProgress-item first-start half-done">
                                            <div className="status-with-grid-holder">
                                                <div className="status-etd-date-wrapper">
                                                    <div className="status-name">Via</div>
                                                    <div className="etd-date-holder">
                                                        <div className="etd-date-end-holder">
                                                            <span>
                                                                <svg className="svg-icon progress-end-icon">
                                                                    <use xlinkHref="#progressEndIcon"></use>
                                                                </svg>
                                                            </span>
                                                            <div className="ets-name">
                                                                ETA:
                                                                <span className="ets-dateplannedETA">
                                                                    {moment(res?.plannedETA)?.format('DD-MM-YYYY')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="etd-date-start-holder">
                                                            <span>
                                                                <svg className="svg-icon progress-start-icon">
                                                                    <use xlinkHref="#progressStartIcon"></use>
                                                                </svg>
                                                            </span>
                                                            <div className="ets-name">
                                                                ETD:
                                                                <span className="ets-date">
                                                                    {' '}
                                                                    {moment(res?.plannedETD)?.format('DD-MM-YYYY')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="view-grid-container">
                                                    <div className="cell list-more-view-items col-anchor">
                                                        <div className="ship-logo">
                                                            <svg className="svg-icon anchor-icon">
                                                                <use href="#anchorIcon">
                                                                    <title>Icon</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <span
                                                                    className="tab-heading"
                                                                    style={{ wordBreak: 'break-word' }}
                                                                >
                                                                    {res?.portName} ({res?.portCode})
                                                                </span>
                                                            </div>
                                                            <div className="content-detail">
                                                                <span className="terminal-content">
                                                                    {res?.terminal}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className="cell col-vgm-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Gate in Open</h6>
                                                        {res?.gateInOpen ? (
                                                            <div className="details-box-content">
                                                                {moment(res?.gateInOpen).format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(res?.gateInOpen).utc().format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cell col-vgm-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">VGM Cut-Off</h6>
                                                        {res?.vgmCutOff ? (
                                                            <div className="details-box-content">
                                                                {moment(res?.vgmCutOff).format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(res?.vgmCutOff).utc().format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cell col-vgm-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Documentation Cut-Off</h6>
                                                        {res?.docCutOff ? (
                                                            <div className="details-box-content">
                                                                {moment(res?.docCutOff).format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(res?.docCutOff).utc().format('HH:mm')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="cell col-port-cut-off">
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Gate In Cut-Off</h6>
                                                        {res?.gateInCutOff ? (
                                                            <div className="details-box-content">
                                                                {moment(res?.gateInCutOff).format('DD-MM-YYYY')} ,
                                                                <span className="booking-time">
                                                                    {moment(res?.gateInCutOff).utc().format('HH:mm')}
                                                                </span>{' '}
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>*/}
                                                    <div className="cell list-more-view-items col-ship">
                                                        <div className="ship-logo">
                                                            <svg className="svg-icon ship-icon">
                                                                <use href="#shipIcon">
                                                                    <title>Icon</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <span
                                                                    className="tab-heading"
                                                                    style={{ wordBreak: 'break-word' }}
                                                                >
                                                                    {res?.vessel}
                                                                </span>
                                                                <span className="modal-toast" style={{ width: 'auto' }}>
                                                                    {data?.contractType}
                                                                </span>
                                                            </div>
                                                            <div className="content-detail">
                                                                <span className="detail-count-number">
                                                                    {res?.voyage}
                                                                </span>
                                                                {/*<span className="detail-count-number-before">BB223344</span>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cell list-more-view-items col-transhipment">
                                                        <div className="ship-logo">
                                                            <svg className="svg-icon ship-icon">
                                                                <use href="#transhipmentIcon">
                                                                    <title>Icon</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <span className="tab-heading">{res?.route}</span>
                                                            </div>
                                                            <div className="content-detail"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="StepProgress-item current">
                                        <div className="status-with-grid-holder">
                                            <div className="status-etd-date-wrapper">
                                                <div className="status-name">Destination</div>
                                                <div className="etd-date-holder">
                                                    <div className="etd-date-end-holder">
                                                        <span>
                                                            <svg className="svg-icon progress-end-icon">
                                                                <use xlinkHref="#progressEndIcon"></use>
                                                            </svg>
                                                        </span>
                                                        <div className="ets-name">
                                                            ETA:
                                                            <span className="ets-date">
                                                                {/* {moment(data?.plannedETA).format('DD-MM-YYYY')} */}
                                                                {data?.plannedETA
                                                                    ? moment(data?.plannedETA)?.format('DD-MM-YYYY')
                                                                    : moment(data?.destEtaDate)?.format('DD-MM-YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="view-grid-container">
                                                <div className="cell list-more-view-items">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon anchor-icon">
                                                            <use href="#anchorIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            {data?.destinationPortName ? (
                                                                <span
                                                                    className="tab-heading"
                                                                    style={{ wordBreak: 'break-word' }}
                                                                >
                                                                    {data?.destinationPortName} (
                                                                    {data?.destinationPortCode})
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="tab-heading"
                                                                    style={{ wordBreak: 'break-word' }}
                                                                >
                                                                    {data?.destinationPort}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="terminal-content">
                                                                {data?.destinationPortTerminal}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cell list-more-view-items"></div>
                                                <div className="cell list-more-view-items"></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"></div>
                </div>
            ) : (
                <div className="modal-content-holder additional-detail-modal large-popup air-flight-modal">
                    <div className="modal-header">
                        <div className="title">Flight Schedule Details</div>
                        <button className="app-btn modal-close-btn" onClick={() => displayFunc(false)}>
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use href="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>

                    <div className="modal-content">
                        <div className="modal-main-content">
                            <div className="sailing-detail">
                                <div className="des-progress-bar">
                                    <div className="destination begin-journey">
                                        <span className="point1">ETD</span>
                                        <div className="date-time">
                                            {/* {moment(data?.originEtdDate).format('DD-MM-YYYY')},
                                        <span className="time-field">{data?.originEtdTime}</span> */}
                                            {moment(
                                                moment(data?.originEtdDate)?.format('YYYY-MM-DD') +
                                                    ' ' +
                                                    data?.originEtdTime
                                            )?.format('DD-MM-YYYY')}
                                            ,
                                            <span className="time-field">
                                                {moment(
                                                    moment(data?.originEtdDate)?.format('YYYY-MM-DD') +
                                                        ' ' +
                                                        data?.originEtdTime,
                                                    'YYYY-MM-DD HH:mm:ss'
                                                )?.format('HH:mm')}
                                            </span>
                                        </div>
                                        <span className="point2">{data?.originAirPortName}</span>
                                    </div>
                                    <div className="destination center-journey">
                                        <span className="center-point">
                                            {data?.bookingProposalAirSchedule?.length}{' '}
                                            {`Stop${data?.bookingProposalAirSchedule?.length > 1 ? 's' : ''}`}
                                        </span>
                                        <div className="point1">
                                            <div className="point1">
                                                {dateDifference?.days > 0 ? `${dateDifference?.days}d` : ''}
                                                {dateDifference?.hour > 0 ? (
                                                    <span className="time-field">{` ${dateDifference?.hour}h`}</span>
                                                ) : (
                                                    ''
                                                )}
                                                {dateDifference?.minute > 0 ? (
                                                    <span className="time-field">{` ${dateDifference?.minute}m`}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="destination end-journey">
                                        <span className="point1">ETA</span>
                                        <div className="date-time">
                                            {/* {moment(data?.destEtaDate).format('DD-MM-YYYY')},
                                        <span className="time-field">{data?.destEtaTime}</span> */}
                                            {moment(
                                                moment(data?.destEtaDate)?.format('YYYY-MM-DD') +
                                                    ' ' +
                                                    data?.destEtaTime
                                            )?.format('DD-MM-YYYY')}
                                            ,
                                            <span className="time-field">
                                                {moment(
                                                    moment(data?.destEtaDate)?.format('YYYY-MM-DD') +
                                                        ' ' +
                                                        data?.destEtaTime,
                                                    'YYYY-MM-DD HH:mm:ss'
                                                )?.format('HH:mm')}
                                                {/* {fs?.destEtaTime} */}
                                            </span>
                                        </div>
                                        <span className="point2">{data?.destinationPort}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="stepProgress-wrapper">
                                <ul className="StepProgress">
                                    <li className="StepProgress-item first-start">
                                        <div className="status-with-grid-holder">
                                            <div className="status-etd-date-wrapper">
                                                <div className="status-name">Origin - Leg 1</div>
                                                <div className="etd-date-holder">
                                                    <div className="etd-date-start-holder">
                                                        <span>
                                                            <svg className="svg-icon progress-start-icon">
                                                                <use xlinkHref="#progressStartIcon"></use>
                                                            </svg>
                                                        </span>
                                                        <div className="ets-name">
                                                            ETD:
                                                            <span className="ets-date">
                                                                {moment(data?.originEtdDate)?.format('DD-MM-YYYY')},
                                                                {moment(data?.originEtdTime, 'HH:mm:ss')?.format(
                                                                    'HH.mm'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="view-grid-container">
                                                <div className="cell list-more-view-items col-1">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon anchor-icon">
                                                            <use href="#anchorIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">
                                                                {data?.originAirPortName}
                                                            </span>
                                                        </div>
                                                        {/* <div className="content-detail">
                                                        <span className="terminal-content">
                                                            {data?.originPortTerminal}
                                                        </span>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div className="cell list-more-view-items col-2">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon ship-icon">
                                                            <use href="#transhipmentIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{data?.carrier}</span>
                                                        </div>
                                                        <div className="content-detail">{data?.flightNo}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {data?.bookingProposalAirSchedule?.map((res: any) => (
                                        <li className="StepProgress-item first-start half-done">
                                            <div className="status-with-grid-holder">
                                                <div className="status-etd-date-wrapper">
                                                    <div className="status-name">Via</div>
                                                    <div className="etd-date-holder">
                                                        <div className="etd-date-end-holder">
                                                            <span>
                                                                <svg className="svg-icon progress-end-icon">
                                                                    <use xlinkHref="#progressEndIcon"></use>
                                                                </svg>
                                                            </span>
                                                            <div className="ets-name">
                                                                ETA:
                                                                <span className="ets-dateplannedETA">
                                                                    {moment(res?.originEtdDate)?.format('DD-MM-YYYY')},
                                                                    {moment(res?.originEtdTime, 'HH:mm:ss')?.format(
                                                                        'HH:mm'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="etd-date-start-holder">
                                                            <span>
                                                                <svg className="svg-icon progress-start-icon">
                                                                    <use xlinkHref="#progressStartIcon"></use>
                                                                </svg>
                                                            </span>
                                                            <div className="ets-name">
                                                                ETD:
                                                                <span className="ets-date">
                                                                    {moment(res?.destEtaDate)?.format('DD-MM-YYYY')},
                                                                    {moment(res?.destEtaTime, 'HH:mm:ss')?.format(
                                                                        'HH:mm'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="view-grid-container">
                                                    <div className="cell list-more-view-items col-1">
                                                        <div className="ship-logo">
                                                            <svg className="svg-icon anchor-icon">
                                                                <use href="#anchorIcon">
                                                                    <title>Icon</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <span className="tab-heading">
                                                                    {res?.originAirPortName}
                                                                </span>
                                                            </div>
                                                            {/*<div className="content-detail">
                                                            <span className="terminal-content">
                                                                {res?.originAirPortName}
                                                            </span>
                                                        </div>*/}
                                                        </div>
                                                    </div>
                                                    <div className="cell list-more-view-items col-2">
                                                        <div className="ship-logo">
                                                            <svg className="svg-icon ship-icon">
                                                                <use href="#transhipmentIcon">
                                                                    <title>Icon</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <span className="tab-heading">{res?.flightNo}</span>
                                                            </div>
                                                            <div className="content-detail"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="StepProgress-item current">
                                        <div className="status-with-grid-holder">
                                            <div className="status-etd-date-wrapper">
                                                <div className="status-name">
                                                    Destination - {data?.bookingProposalAirSchedule?.length + 1}
                                                </div>
                                                <div className="etd-date-holder">
                                                    <div className="etd-date-end-holder">
                                                        <span>
                                                            <svg className="svg-icon progress-end-icon">
                                                                <use xlinkHref="#progressEndIcon"></use>
                                                            </svg>
                                                        </span>
                                                        <div className="ets-name">
                                                            ETA:
                                                            <span className="ets-date">
                                                                {moment(data?.destEtaDate)?.format('DD-MM-YYYY')},
                                                                {moment(data?.destEtaTime, 'HH:mm:ss')?.format('HH.mm')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="view-grid-container">
                                                <div className="cell list-more-view-items">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon anchor-icon">
                                                            <use href="#anchorIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{data?.destinationPort}</span>
                                                        </div>
                                                        {/* <div className="content-detail">
                                                        <span className="terminal-content">
                                                            {data?.destinationPortTerminal}
                                                        </span>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div className="cell list-more-view-items"></div>
                                                <div className="cell list-more-view-items"></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"></div>
                </div>
            )}
        </div>
    );
};
export default Index;
