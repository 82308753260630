import React, { useEffect } from 'react';
import FavouritesList from './FavouritesList';
import './lifecycleSearch.scss';
import { useDispatch } from 'react-redux';
import { fetchFavList } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import SearchOnly from './searchOnly';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { userName, profileType } = useUserProfile();
    const { favList, removeSuccess, isLoading } = useSelector((state: RootState) => state.lifecyclesearchFav);

    const getLatestFavData = () => {
        dispatch(fetchFavList({ userName, profileType }));
    };
    useEffect(() => {
        getLatestFavData();
    }, [removeSuccess]);
    return (
        <>
            {favList?.count === 0 ? (
                <>
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}{' '}
                    <SearchOnly />
                </>
            ) : (
                <>
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}{' '}
                    <FavouritesList />
                </>
            )}
        </>
    );
};
export default Index;
