import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../BookingProposal/POSelection.scss';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { airOptionStatus } from '.';
import RejectModal from './RejectModal';

interface Props {
    approveRejectOption: any;
    saving: string;
    currentSelected: string;
}

const Footer: React.FC<Props> = ({ approveRejectOption, saving, currentSelected }) => {
    const { profileType } = useUserProfile();
    const { id } = useParams();
    const navigate = useNavigate();

    const [modalView, setModalView] = useState(false);

    const { bpStatus, tabValues } = useSelector((state: RootState) => state.bpHeaderData);

    const profileWiseCondition = (type: string) => {
        switch (profileType) {
            case 'forwarder':
                if (type === 'approve') {
                    return (
                        bpStatus === 'Awaiting_Forwarder_Review' ||
                        bpStatus === 'Options_Rejected_By_Client' ||
                        bpStatus == 'Options_Expired'
                    );
                }
                if (type === 'button') {
                    return saving === 'approve' ? 'Submitting...' : 'Submit';
                }
                return false;
            case 'agent':
                if (type === 'approve') {
                    return bpStatus === 'Awaiting_Agent_Options';
                }
                if (type === 'button') {
                    return saving === 'approve' ? 'Submitting...' : 'Submit';
                }
                return false;
            case 'client':
                if (type === 'approve' || type === 'reject') {
                    return bpStatus === 'Awaiting_Client_Acceptance';
                }
                if (type === 'button') {
                    return saving === 'approve' ? 'Approving...' : 'Approve';
                }
                return false;
        }
    };

    return (
        <div className="footer-bootom-row backdrop-active">
            <div className="footer-button-holder ">
                <Link
                    title="Previous"
                    className="app-btn app-btn-secondary footer-btn"
                    to={`/air_booking_list/${id}/POSelection`}
                >
                    <span className="button-text footer-button-text">Previous</span>
                </Link>
                <button
                    title="Next"
                    className={`app-btn app-btn-primary footer-btn`}
                    disabled={
                        (tabValues as any)?.airOptions && !airOptionStatus?.includes(bpStatus ?? '') ? false : true
                    }
                    onClick={() => {
                        navigate(`/air_booking_list/${id}/FlightSelectionDetails`);
                    }}
                >
                    <span className="button-text footer-button-text">Next</span>
                </button>
                {profileWiseCondition('reject') && (
                    <button
                        onClick={() => setModalView(true)}
                        title={saving === 'reject' ? 'Rejecting...' : 'Reject'}
                        className="app-btn app-btn-secondary footer-btn"
                        disabled={saving === 'reject' ? true : false}
                    >
                        <span className="button-text footer-button-text">
                            {saving === 'reject' ? 'Rejecting...' : 'Reject'}
                        </span>
                    </button>
                )}
                {profileWiseCondition('approve') && (
                    <button
                        onClick={() => approveRejectOption('approve')}
                        title={profileWiseCondition('button') as string}
                        className="app-btn app-btn-primary footer-btn"
                        disabled={
                            saving === 'approve' ||
                            (profileType === 'client' && !currentSelected) ||
                            (!(tabValues as any)?.airOptions &&
                                (profileType === 'forwarder' || profileType === 'agent'))
                                ? true
                                : false
                        }
                    >
                        <span className="button-text footer-button-text">{profileWiseCondition('button')}</span>
                    </button>
                )}
            </div>
            {modalView ? <RejectModal setModalView={setModalView} /> : null}
        </div>
    );
};
export default Footer;
