import axios from 'axios';
import fileDownload from 'js-file-download';
import { get, patch, post } from '../../HttpMethods';

export const fetchAllQuotationApi = async (payload: { clientId: string }) => {
    try {
        const data = await get(`api/bkg/quotation/quotations/${payload.clientId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSingleQuotationApi = async (payload: { quotationId: string }) => {
    try {
        const data = await get(`api/bkg/quotation/${payload.quotationId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateQuotationApi = async (payload: any) => {
    const type = payload.type;
    delete payload.type;
    try {
        const data = await post('api/bkg/bp/quotation', payload, null);
        data.type = type;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const approveBookingProposalApi = async (payload: any) => {
    const bpId = payload.bpId;
    delete payload.bpId;
    let data;
    try {
        if (payload.profile === 'agent') {
            data = await post(`api/bkg/bp/submit?bookingProposalId=${bpId}`, null, null);
        } else {
            data = await patch(`api/bkg/bp/approve/${bpId}`, payload);
        }
        data.type = 'approved';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const rejectBookingProposalApi = async (payload: any) => {
    try {
        const data = await post('api/bkg/bp/reject', payload, null);
        data.type = 'rejected';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const assignBpToAgentApi = async (payload: any) => {
    const bpId = payload.bpId;
    delete payload.bpId;
    try {
        const data = await patch(`api/bkg/bp/assigntoagent/${bpId}`, payload);
        data.type = 'assignedToAgent';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCurrencyDetailsApi = async (_payload: any) => {
    try {
        const data = await get('api/bkg/bp/basicdetails/ordercurrency', null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadFile = async (contract_pk: any, fileName: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/cd/download/${contract_pk}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const updateViewStatusAPi = async (payload: any) => {
    try {
        const data = await patch(`api/bkg/bp/viewed/${payload.bpId}/${payload.profileType}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateModalStatusAPi = async (payload: any) => {
    try {
        const data = await patch(
            `api/bkg/bp/closemodal/${payload.bpId}/${payload.profileType}/${payload.modalType}`,
            null
        );
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchEnrichBookingApi = async (payload: any) => {
    try {
        const data = await get(`api/bkg/bp/enrich/${payload.bpId}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateEnrichBookingAPi = async (payload: any) => {
    try {
        const mode = payload.mode;
        delete payload.mode;
        const data = await post('api/bkg/bp/enrich', payload, null);
        data.type = 'enrichBooking';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
