/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import 'src/assets/scss/components/_modal.scss';
import 'src/assets/scss/components/_grid.scss';
import '../../Profiles/Clients/ProductMaster/product-master-modal.scss';
import './Options.scss';
import { success, error, Position } from 'src/utils/toast';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { debounce } from 'lodash';
import { carrierListAirApi } from 'src/services/apis/bookingProposalAir/addSchedule';
import { addAirBPOptions, editAirBPOptions } from 'src/services/apis/bookingProposalAir/optionsApi';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { getDefaultCurrency } from 'src/services/apis/quoteAPi';

type HeaderProps = {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    mode: string;
    refreshGrid: any;
    detailData: any;
    setDetailData: any;
};

const OptionsModal: React.FC<HeaderProps> = ({ setModalView, mode, refreshGrid, detailData, setDetailData }) => {
    const { profileType, userName } = useUserProfile();
    const [saving, setSaving] = useState(false);
    const [airLinesData, setAirLinesData] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const { id } = useParams();
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const onClose = () => {
        setModalView(false);
        setDetailData({});
    };

    const convertDataForSelect = () => {
        if (Array.isArray(airLinesData)) {
            const convertedData = airLinesData?.map((option: any) => {
                return { value: option.Or_orgcode, label: `${option.Or_orgcode} (${option.Or_orgname})` };
            });
            return convertedData;
        } else {
            return [];
        }
    };

    const getAirLine = (search?: string) => {
        carrierListAirApi({ type: 'AIR', search, key: 'Or_orgcode/Or_orgname' })
            ?.then((res: any) => setAirLinesData(res))
            ?.catch((err) => console.log(err));
    };

    const getCurrency = () => {
        getDefaultCurrency()
            ?.then((res: any) => setCurrencyData(res))
            ?.catch((err) => console.log(err));
    };

    const getAirLineData = debounce(async (search?: string) => {
        getAirLine(search);
    }, 500);

    useEffect(() => {
        getAirLine();
        getCurrency();
    }, []);

    const onSubmit = async () => {
        let response;
        setSaving(true);
        try {
            let payload;
            if (profileType === 'forwarder') {
                payload = {
                    ...detailData,
                    ZO_IsDirect: detailData.ZO_IsDirect ? true : false,
                    ZO_IFCPreferred: detailData.ZO_IFCPreferred ? true : false,
                    ZO_Publish: detailData.ZO_Publish ? true : false,
                    ZO_BP: id,
                };
            } else {
                payload = {
                    ZO_CarrierCode: detailData.ZO_CarrierCode,
                    ZO_CarrierName: detailData.ZO_CarrierName,
                    ZO_BuyCost: detailData.ZO_BuyCost,
                    ZO_BuyCurrency: detailData.ZO_BuyCurrency,
                    ZO_IsDirect: detailData.ZO_IsDirect ? true : false,
                    ZO_BP: id,
                    ZO_PK: detailData.ZO_PK,
                };
            }
            if (mode === 'add') {
                response = await addAirBPOptions(payload);
            } else {
                response = await editAirBPOptions(payload);
            }
            if (response?.status) {
                success(`Options ${mode === 'add' ? 'created' : 'updated'} successfully`, Position.TOP_RIGHT);
                setModalView(false);
                refreshGrid();
                setDetailData({});
            } else {
                error('Something went wrong', Position.TOP_RIGHT);
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } finally {
            setSaving(false);
        }
    };

    const submitButtonStatus = () => {
        if (profileType === 'forwarder') {
            return detailData?.ZO_CarrierCode &&
                detailData?.ZO_BuyCost &&
                detailData?.ZO_BuyCost > 0 &&
                detailData?.ZO_BuyCurrency &&
                detailData?.ZO_SellCost &&
                detailData?.ZO_SellCost > 0 &&
                detailData?.ZO_SellCurrency &&
                !saving
                ? false
                : true;
        } else {
            return detailData?.ZO_CarrierCode &&
                detailData?.ZO_BuyCost &&
                detailData?.ZO_BuyCost > 0 &&
                detailData?.ZO_BuyCurrency &&
                !saving
                ? false
                : true;
        }
    };

    const profileWiseCondition = (type: string) => {
        switch (profileType) {
            case 'forwarder':
                if (type === 'ZO_Publish') {
                    return detailData?.ZO_BuyCost > 0 &&
                        detailData?.ZO_SellCost > 0 &&
                        detailData?.ZO_BuyCurrency &&
                        detailData?.ZO_SellCurrency &&
                        (bpStatus === 'Awaiting_Forwarder_Review' ||
                            bpStatus === 'Options_Rejected_By_Client' ||
                            bpStatus === 'Options_Expired')
                        ? false
                        : true;
                }
                if (
                    (mode === 'add' &&
                        (bpStatus === 'Options_Expired' ||
                            // bpStatus === 'Awaiting_Forwarder_Review' ||
                            bpStatus === 'Options_Rejected_By_Client')) ||
                    userName === detailData?.ZO_CreatedByUser
                ) {
                    return false;
                }
                if (
                    type === 'ZO_CarrierCode' ||
                    type === 'ZO_BuyCost' ||
                    type === 'ZO_BuyCurrency' ||
                    type === 'ZO_IsDirect'
                ) {
                    return true;
                }
                return false;
            // return bpStatus === 'Awaiting_Forwarder_Review' || bpStatus === 'Options_Rejected_By_Client'
            //     ? false
            //     : true;

            case 'agent':
                if (
                    type === 'ZO_SellCost' ||
                    type == 'ZO_SellCurrency' ||
                    type === 'ZO_IFCPreferred' ||
                    type === 'ZO_Publish'
                ) {
                    return true;
                }
                return bpStatus === 'Awaiting_Agent_Options' ? false : true;
        }
    };

    return (
        <div className="app-modal medium-popup options-modal">
            <div className="modal-content-holder sop-content-holder medium-popup">
                <div className="modal-header">
                    <h2 className="title">{mode === 'add' ? 'Add' : 'Edit'} Options</h2>
                    <button onClick={() => onClose()} className="app-btn modal-close-btn" title="Close">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content details-box">
                        <div className="input-form-wrapper">
                            <div className="modal-col-top-1">
                                <div className=" modal-input-holder">
                                    <label htmlFor="hsCode" className="modal-input-label">
                                        Airline<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <SearchableSelectBox
                                            data={convertDataForSelect()}
                                            placeholder="Select Airline"
                                            setStateValue={(value: string) => {
                                                const filtered: any = airLinesData?.find(
                                                    (res: any) => res?.Or_orgcode === value
                                                );
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    ZO_CarrierCode: filtered?.Or_orgcode,
                                                    ZO_CarrierName: filtered?.Or_orgname,
                                                }));
                                            }}
                                            onInputChangeFn={(value: string, actionMeta: any) => {
                                                if (value?.length > 2) {
                                                    getAirLineData(value);
                                                }
                                            }}
                                            overrideValue={
                                                detailData.ZO_CarrierCode
                                                    ? {
                                                          label: detailData.ZO_CarrierCode,
                                                          value: detailData.ZO_CarrierCode,
                                                      }
                                                    : null
                                            }
                                            onFocusFn={() => {
                                                if (!detailData.ZO_CarrierCode) {
                                                    getAirLine();
                                                }
                                            }}
                                            stateValue={detailData.ZO_CarrierCode}
                                            fieldName="ZO_CarrierCode"
                                            isDisabled={profileWiseCondition('ZO_CarrierCode')}
                                            menuPortalTarget={document.getElementById('outside_div')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-form-wrapper">
                            <div className="modal-col-top-1 cost-holder">
                                <div className="modal-input-holder cost-holder-value">
                                    <label htmlFor="hsCode" className="modal-input-label">
                                        Rate<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <input
                                            placeholder="Enter Rate"
                                            type="number"
                                            className="modal-input input-text with-border"
                                            id="ZO_BuyCost"
                                            name="ZO_BuyCost"
                                            min={0}
                                            value={detailData.ZO_BuyCost}
                                            disabled={profileWiseCondition('ZO_BuyCost')}
                                            onChange={(e) =>
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    ZO_BuyCost: +e.target.value,
                                                }))
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="modal-input-holder cost-holder-currency">
                                    <label htmlFor="hsCode" className="modal-input-label">
                                        Rate Currency<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <SearchableSelectBox
                                            data={currencyData}
                                            placeholder="Select Rate Currency"
                                            setStateValue={(value: string) => {
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    ZO_BuyCurrency: value,
                                                }));
                                            }}
                                            overrideValue={
                                                detailData.ZO_BuyCurrency
                                                    ? {
                                                          label: detailData.ZO_BuyCurrency,
                                                          value: detailData.ZO_BuyCurrency,
                                                      }
                                                    : null
                                            }
                                            stateValue={detailData.ZO_BuyCurrency}
                                            fieldName="ZO_BuyCurrency"
                                            isDisabled={profileWiseCondition('ZO_BuyCurrency')}
                                            menuPortalTarget={document.getElementById('outside_div')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {profileType === 'forwarder' && (
                            <div className="input-form-wrapper sell-cost">
                                <div className="modal-col-top-1 cost-holder">
                                    <div className=" modal-input-holder cost-holder-value">
                                        <label htmlFor="hsCode" className="modal-input-label">
                                            Quote<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder text-area-holder">
                                            <input
                                                placeholder="Enter Quote"
                                                type="number"
                                                className="modal-input input-text with-border"
                                                id="ZO_SellCost"
                                                name="ZO_SellCost"
                                                min={0}
                                                value={detailData.ZO_SellCost}
                                                disabled={profileWiseCondition('ZO_SellCost')}
                                                onChange={(e) =>
                                                    setDetailData((prev: any) => ({
                                                        ...prev,
                                                        ZO_SellCost: +e.target.value,
                                                    }))
                                                }
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="modal-input-holder cost-holder-currency">
                                        <label htmlFor="hsCode" className="modal-input-label">
                                            Quote Currency<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder text-area-holder">
                                            <SearchableSelectBox
                                                data={currencyData}
                                                placeholder="Select Quote Currency"
                                                setStateValue={(value: string) => {
                                                    setDetailData((prev: any) => ({
                                                        ...prev,
                                                        ZO_SellCurrency: value,
                                                    }));
                                                }}
                                                overrideValue={
                                                    detailData.ZO_SellCurrency
                                                        ? {
                                                              label: detailData.ZO_SellCurrency,
                                                              value: detailData.ZO_SellCurrency,
                                                          }
                                                        : null
                                                }
                                                stateValue={detailData.ZO_SellCurrency}
                                                fieldName="ZO_SellCurrency"
                                                isDisabled={profileWiseCondition('ZO_SellCurrency')}
                                                menuPortalTarget={document.getElementById('outside_div')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="input-form-wrapper">
                            <div className="modal-col-bottom">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            name="isMandatory"
                                            checked={detailData.ZO_IsDirect}
                                            disabled={profileWiseCondition('ZO_IsDirect')}
                                            onChange={(e) => {
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    ZO_IsDirect: e.target.checked,
                                                }));
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon notification-circle-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Direct</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {profileType === 'forwarder' && (
                            <>
                                <div className="input-form-wrapper">
                                    <div className="modal-col-bottom">
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="isMandatory"
                                                    checked={detailData.ZO_IFCPreferred}
                                                    disabled={profileWiseCondition('ZO_IFCPreferred')}
                                                    onChange={(e) => {
                                                        setDetailData((prev: any) => ({
                                                            ...prev,
                                                            ZO_IFCPreferred: e.target.checked,
                                                        }));
                                                    }}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">IFC Preferred</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-form-wrapper">
                                    <div className="modal-col-bottom">
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="isMandatory"
                                                    checked={detailData.ZO_Publish}
                                                    disabled={profileWiseCondition('ZO_Publish')}
                                                    onChange={(e) => {
                                                        setDetailData((prev: any) => ({
                                                            ...prev,
                                                            ZO_Publish: e.target.checked,
                                                        }));
                                                    }}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Publish</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => onClose()}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Cancel"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        onClick={() => onSubmit()}
                        className="app-btn app-btn-primary footer-btn"
                        title={saving ? 'Saving...' : 'Submit'}
                        type="button"
                        disabled={submitButtonStatus() || profileWiseCondition('')}
                    >
                        <span className="button-text footer-button-text">{saving ? 'Saving...' : 'Submit'}</span>
                    </button>
                </div>
            </div>
            <div id="outside_div"></div>
        </div>
    );
};

export default OptionsModal;
