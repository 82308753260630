import React from 'react';
import moment from 'moment';
import useOutsideClickHandler from 'src/hooks/useOutsideClickHandler';

interface Props {
    dataObj?: any;
    isLoading?: boolean;
    type?: string;
    handleChangeInputCallback: (value: any, type: any) => void;
    isDate?: boolean;
    top?: string;
    bottom?: string;
    right?: string;
    left?: string;
}

//Faheem
const FilterCheckboxDropdown: React.FC<Props> = (props) => {
    /*
        Checkbox Filter for Tables
        => props
        1. dataObj = distinctColumnValues(Object)from the api response to list corresponding filter values for column.
        2. isLoading = whether to check listing api response is loaded or not.
        3. type = Corresponding column key.
        4. handleChangeInputCallback = Callback accepts 2 values:
            1.1 => selected value. 
            2.2 => column type.

        => Use useOutsideClickHandler Hook to toggle checkbox dropdown.
    */

    //===================== States =========================//
    const [searchName, setSearchName] = React.useState('');
    const [selected, setSelected] = React.useState([] as any);
    const [columnData, setColumnData] = React.useState<any>([]);
    //=====================================================//

    //===================== Hooks =========================//
    // outside click event
    const { ref, ref2, isComponentVisible, setIsComponentVisible } = useOutsideClickHandler(false);
    //=====================================================//

    //===== keeping currently active checkbox details in columnData useState ====//
    React.useEffect(() => {
        if (props.type) {
            if (props.dataObj) {
                if (props.dataObj[props.type]) {
                    setColumnData(props.dataObj[props.type]);
                }
            }
        }
    }, [props.dataObj, props.type]);

    //===========================================================================//

    //================== Checkbox input onChange function =======================//
    const handleInputChange = (e: any, item: any) => {
        const checkedStatus: boolean = e.target.checked;
        if (checkedStatus) {
            setSelected([...selected, props.isDate ? moment(item).format('DD-MM-YYYY') : item]);
        } else {
            setSelected(selected.filter((t: any) => t !== (props.isDate ? moment(item).format('DD-MM-YYYY') : item)));
        }
    };
    //===========================================================================//

    //===================== Callback trigger Onselecting items =================//
    React.useEffect(() => {
        if (props.type) {
            props.handleChangeInputCallback(selected, props.type);
        }
    }, [selected]);
    //===========================================================================//

    //==================== keyword filter function ==============================//
    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        if (keyword) {
            if (props.type) {
                const results = props.dataObj[props.type]?.filter((user: string) => {
                    return (
                        user &&
                        (props.isDate
                            ? moment(user).format('DD-MM-YYYY').toLowerCase()?.includes(keyword.toLowerCase())
                            : user.toLowerCase()?.includes(keyword.toLowerCase()))
                    );
                });
                setColumnData(results);
            }
        } else {
            if (props.type) {
                setColumnData(props.dataObj[props.type]);
            }
        }
        setSearchName(keyword);
    };
    //===========================================================================//

    //============== setting search to empty when closing dropdown ==============//
    React.useEffect(() => {
        if (!isComponentVisible && props.type) {
            setSearchName('');
            if (props.dataObj) {
                if (props.dataObj[props.type]) {
                    setColumnData(props.dataObj[props.type]);
                }
            }
        }
    }, [isComponentVisible]);
    //===========================================================================//

    return (
        <React.Fragment>
            <div className={isComponentVisible || selected?.length > 0 ? 'filter-menu-wrap open' : 'filter-menu-wrap'}>
                <button
                    onClick={(_e) => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                    ref={ref2}
                    className="app-btn filter-menu-button"
                >
                    <svg className="svg-icon filter-icon">
                        <use xlinkHref="#filterIcon">
                            <title>filter</title>
                        </use>
                    </svg>
                    <span className="sr-only">header menu</span>
                </button>
            </div>
            {isComponentVisible && (
                <div
                    style={{
                        top: `${props.top ? props.top : '70px'}`,
                        bottom: `${props.bottom ? props.bottom : 'auto'}`,
                        left: `${props.left ? props.left : 'auto'}`,
                        right: `${props.right ? props.right : 'auto'}`,
                    }}
                    className="filter-menu searchable-dropdown"
                    ref={ref}
                >
                    <div className="search-entry-holder">
                        <input
                            placeholder="Search"
                            type="text"
                            className="search-input input-text grid-search-field"
                            value={searchName}
                            onChange={filter}
                        ></input>
                        <svg className="svg-icon search-icon" style={{ top: ' 6px' }}>
                            <use xlinkHref="#searchIcon"></use>
                        </svg>
                        <button
                            onClick={(e) => {
                                setSearchName('');
                                filter(e);
                            }}
                            className="app-btn text-close-btn"
                            style={{ top: ' 6px' }}
                        >
                            <svg className="svg-icon text-close-icon">
                                <use xlinkHref="#closeIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <div className="checkbox-wrapper" style={{ maxHeight: 'calc(100vh - 470px)', overflow: 'visible' }}>
                        {columnData && columnData.length > 0
                            ? columnData.map(
                                  (value: any, index: number) =>
                                      value !== '' && (
                                          <div key={index} className="checkbox-item">
                                              <label className="app-check-wrapper">
                                                  <input
                                                      onChange={(e) => handleInputChange(e, value)}
                                                      checked={selected?.includes(
                                                          props.isDate ? moment(value).format('DD-MM-YYYY') : value
                                                      )}
                                                      type="checkbox"
                                                      className="checkbox-input"
                                                  />
                                                  <div className="checkmark">
                                                      <svg className="svg-icon tick-icon">
                                                          <use xlinkHref="#tickIcon">
                                                              <title>check mark</title>
                                                          </use>
                                                      </svg>
                                                  </div>
                                                  <div className="checkbox-label">
                                                      {props.isDate ? moment(value).format('DD-MM-YYYY') : value}
                                                  </div>
                                              </label>
                                          </div>
                                      )
                              )
                            : columnData.length === 0 &&
                              !props.isLoading && (
                                  <div className="no-data-content">
                                      <svg className="svg-icon grid-no-content-icon ">
                                          <use xlinkHref="#gridNoContentIcon"></use>
                                      </svg>
                                      <p className="no-content-message">No Matches Found</p>
                                  </div>
                              )}
                    </div>
                    {columnData && columnData?.length > 0 && (
                        <div className="clear-btn">
                            <button
                                onClick={(_e) => {
                                    setSelected([]);
                                    setSearchName('');
                                }}
                                className="app-btn"
                            >
                                <span className="button-text">Clear All</span>
                            </button>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};
export default FilterCheckboxDropdown;
