import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    fetchClient,
    fetchVendor,
    selectClientDetails,
    selectVendorDetails,
} from '../../../store/reducers/purchaseOrder/addPoReducer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { error, Position, success } from '../../../utils/toast';
import { saveReceipt, resetFlags } from '../../../store/reducers/receipts/addReceiptReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { saveReceiptStatus } from '../../../store/reducers/receipts/receiptAttachment';

interface AddReceiptForm {
    receiptNo: string;
    clientCode: string;
    clientName: string;
    vendorCode: string;
    vendorName: string;
    createdUser: string;
    createdUserId: string;
}

const Index: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const clientDetails = useAppSelector(selectClientDetails);
    const vendorDetails = useAppSelector(selectVendorDetails);
    const [disableVendor, setDisableVendor] = useState(false);
    const {
        success: addSuccess,
        error: addError,
        receiptDetails,
        errorMessage,
        isLoading,
    } = useSelector((state: RootState) => state.addReceipt);
    const { profileType } = useUserProfile();
    const [clientUid, setClientUid] = useState('');
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userName = '';
    let userFullName = '';
    let clientEmailId = '';
    if (userData) {
        const { user } = JSON.parse(userData);
        const { roles } = JSON.parse(userData);
        userName = user?.us_UserName;
        userFullName = user?.us_FirstName + ' ' + user?.us_LastName;
        if (roleIndex) {
            clientEmailId = roles[roleIndex].profile?.toLowerCase() === 'client' ? user?.us_Email : null;
        }
    }

    useEffect(() => {
        dispatch(fetchClient({ userName, profile: profileType }));
    }, [dispatch]);

    const initialValues: AddReceiptForm = {
        receiptNo: '',
        clientCode: '',
        clientName: '',
        vendorCode: '',
        vendorName: '',
        createdUser: userFullName,
        createdUserId: userName,
    };

    const AddReceiptFormSchema = Yup.object().shape({
        receiptNo: Yup.string().required('Receipt ID is required'),
        clientCode: Yup.string().required('Client Code is required'),
        vendorCode: Yup.string().required('Vendor Code is required'),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddReceiptFormSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                dispatch(saveReceipt({ ...values, clientId: clientUid, clientEmailId, type: 'BLP' }));
            }
        },
    });

    useEffect(() => {
        if (addSuccess && receiptDetails?.receiptNo) {
            success(`Receipt ${receiptDetails?.receiptNo} created successfully`, Position.TOP_RIGHT);
            localStorage.setItem('receiptNo', receiptDetails?.receiptNo);
            localStorage.setItem('receiptStatus', 'Draft');
            setTimeout(() => {
                formik.resetForm();
                navigate(`/blp/receipt/${receiptDetails?.receiptId}/receipt-header`);
                dispatch(saveReceiptStatus('draft'));
            }, 2000);
            dispatch(resetFlags({}));
        }
    }, [addSuccess]);

    useEffect(() => {
        if (addError) {
            error(errorMessage || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetFlags({}));
        }
    }, [addError]);

    useEffect(() => {
        formik.setFieldValue('vendorCode', '');
        formik.setFieldValue('vendorName', '');
        if (formik.values.clientCode) {
            const uid = clientDetails?.data?.find((element: any) => element.value === formik.values.clientCode);
            dispatch(fetchVendor({ clientCode: formik.values.clientCode }));
            setDisableVendor(false);
            setClientUid(uid.id);
            localStorage.setItem('clientName', uid.label);
        } else {
            setDisableVendor(true);
        }
    }, [formik.values.clientCode]);

    useEffect(() => {
        if (!formik.values.vendorCode) {
            formik.setFieldValue('vendorCode', '');
            formik.setFieldValue('vendorName', '');
        }
    }, [formik.values.vendorCode]);

    useEffect(() => {
        if (!vendorDetails?.data) {
            formik.setFieldValue('vendorCode', '');
            formik.setFieldValue('vendorName', '');
        }
    }, [vendorDetails]);

    useEffect(() => {
        if (clientDetails?.data?.length === 1) {
            formik.setFieldValue('clientCode', clientDetails?.data[0].value);
            formik.setFieldValue('clientName', clientDetails?.data[0].label);
        }
    }, [clientDetails]);

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found. <br />
                    Vendors linked to the client profile <br />
                    will only be available for selection
                </p>
                <br />
                {/* <Link className="redirection-link" to={`/profile/clients/${clientUid}/vendor_details`}>
                    Go to Vendor Details
                </Link> */}
            </div>
        );
    };
    const clientStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            minHeight: '34px',
            height: '34px',
            fontSize: '1.4rem',
            backgroundColor: '#FFF',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
    };
    const vendorStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            fontSize: '1.4rem',
            backgroundColor: disableVendor ? '#e2e3e7' : '#FFF',
            color: disableVendor ? '#737373' : 'hsl(0, 0%, 20%)',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
        noOptionsMessage: (base: any) => ({ ...base, minHeight: '210px' }),
    };
    return (
        <>
            <div className="main-wrapper container">
                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <div className="main-header-content-holder large-header">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/blp/receipt">
                                    Receipts
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <a href="javascript:void(0)" className="breadcrumb-item">
                                    Add New Receipt
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="purchase-order-header-fields">
                        <div className="pageheading-holder">
                            <div className="back-btn-holder">
                                <Link to="/blp/receipt">
                                    <button className="back-btn app-btn">
                                        <svg className="svg-icon back-icon">
                                            <use href="#previousArrowIcon">
                                                <title>Back</title>
                                            </use>
                                        </svg>
                                    </button>
                                </Link>
                                <h2 className="page-heading">Add New Receipt</h2>
                            </div>
                        </div>
                        {/* {isLoading && (
                            <div className="app-loader loading">
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                        )} */}
                        <div className="field-entries-added">
                            <div className="entry-fields-holder">
                                <form id="my-form" onSubmit={formik.handleSubmit} className="modal-form form-fields">
                                    <div className="modal-input-holder layout-item">
                                        <label className="modal-input-label">Receipt ID</label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Receipt ID"
                                                type="text"
                                                className="modal-input input-text"
                                                id="receiptNo"
                                                name="receiptNo"
                                                onChange={(event) => {
                                                    // eslint-disable-next-line no-undefined
                                                    formik.setFieldError(event.target.name, undefined);
                                                    formik.handleChange(event);
                                                }}
                                                value={formik.values.receiptNo}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">Client Name</label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={clientDetails?.data ? clientDetails?.data : []}
                                                placeholder="Enter Client Name to select"
                                                formik={formik}
                                                fieldName="clientCode"
                                                extraField="clientName"
                                                isClearable={clientDetails?.data?.length === 1 ? false : true}
                                                customStyle={clientStyle}
                                            />
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder  layout-item">
                                        <label className="modal-input-label">Vendor Name</label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={vendorDetails?.data ? vendorDetails?.data : []}
                                                placeholder="Enter Vendor Name to select"
                                                formik={formik}
                                                fieldName="vendorCode"
                                                extraField="vendorName"
                                                isDisabled={disableVendor}
                                                NoMessageComponent={NoMessageComponent}
                                                customStyle={vendorStyle}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-submit-btn">
                                        <button
                                            disabled={
                                                !formik.dirty ||
                                                formik.values.clientCode === '' ||
                                                formik.values.vendorCode === '' ||
                                                formik.values.receiptNo === ''
                                            }
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                            title="Submit"
                                            form="my-form"
                                            type="submit"
                                        >
                                            <span className="button-text footer-button-text">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
