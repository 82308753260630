/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';

export interface AttachmentHistoryDetailsType {
    attachmentType: string;
    fileName: string;
    userName: string;
    dateAndTime: string;
    activity: string;
}

interface AttachmentHistoryState {
    isLoading: boolean;
    attachmentHistory: AttachmentHistoryDetailsType[];
    distinctAttachment: any;
    isError?: boolean;
}

const initialState: AttachmentHistoryState = {
    isLoading: false,
    attachmentHistory: [],
    distinctAttachment: {},
    isError: false,
};

export const attachmentHistorySlice = createSlice({
    name: 'attachmentHistory',
    initialState,
    reducers: {
        fetchAttachmentHistoryList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchAttachmentHistoryListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.attachmentHistory = action.payload?.data?.info?.history ?? [];
        },
        fetchAttachmentHistoryListFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.attachmentHistory = action.payload;
        },
        fetchAttachmentHistoryDistinctList: (state, _action) => {
            state.isError = false;
        },
        fetchAttachmentHistoryDistinctListSuccess(state, action) {
            state.isError = false;
            state.distinctAttachment = action.payload?.data?.info ?? {};
        },
        fetchAttachmentHistoryDistinctListFailure(state, action) {
            state.isError = true;
            state.attachmentHistory = action.payload;
        },
        clearFailureAttachmentHistory: (state, _action) => {
            state.isError = false;
            state.attachmentHistory = [];
            state.distinctAttachment = {};
        },
    },
});

export const {
    fetchAttachmentHistoryList,
    fetchAttachmentHistoryListSuccess,
    fetchAttachmentHistoryListFailure,
    fetchAttachmentHistoryDistinctList,
    fetchAttachmentHistoryDistinctListSuccess,
    fetchAttachmentHistoryDistinctListFailure,
    clearFailureAttachmentHistory,
} = attachmentHistorySlice.actions;

export const selectAttachmentHistoryLoading = (state: RootState) => state.attachmentHistoryData.isLoading;
export const selectAttachmentHistory = (state: RootState) => state.attachmentHistoryData.attachmentHistory;
export const selectDistinctAttachmentHistory = (state: RootState) => state.attachmentHistoryData.distinctAttachment;
export const selectAttachmentHistoryIsError = (state: RootState) => state.attachmentHistoryData.isError;

export default attachmentHistorySlice.reducer;
