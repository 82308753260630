import React, { useState, useEffect } from 'react';
import '../../BookingProposal/BookingProposalHeader/booking-proposal-header.scss';
import '../../BookingProposal/POSelection.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
import RejectedBookingProposalModal from '../BookingAdditionalInfo/RejectedBookingProposalModal';
import RejectedBpForwarderModal from '../BookingAdditionalInfo/RejectedBpForwarderModal';
import ReapprovalBpClientModal from '../BookingAdditionalInfo/ReapprovalBpClientModal';
import RejectBookingProposalModal from '../BookingAdditionalInfo/RejectBookingProposalModal';
import {
    clearFailure,
    selectAdditionalDetailsSuccess,
    updateViewStatus,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import {
    fetchBpHeaderData,
    fetchLinkedBpHeader,
    agentIntegration,
} from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import CancellationDetailModal from '../../PO/BasicDetail/CancellationDetailModal';
import { Position, success } from '../../../utils/toast';
import { useAppSelector } from '../../../hooks';
import LinkedBookingModal from '../BookingAddedGrid/LinkedBookingModal';
import moment from 'moment';
import ClickAwayListener from 'react-click-away-listener';
import ReassignAgentReasonModal from '../../BookingProposal/BookingProposalHeader/ReassignAgentReasonModal';
import RejectReasonHistory from 'src/pages/LCLBookingProposal/BookingAdditionalInfo/RejectReasonHistory';
import { airOptionStatus } from '../Options';

interface HeaderProps {
    id?: string;
    title?: string;
    desc?: string;
    backButtonLink?: string;
    setShowBookingSideBar?: any;
    infoValues?: any;
    extraData?: any;
    setSelected?: any;
    isAdded?: any;
}

const Index: React.FC<HeaderProps> = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        clientName,
        vendorName,
        originPort,
        destinationPort,
        goodsReadyDate,
        poNumbers,
        totalCBM,
        totalKgs,
        totalTEU,
        bpStatus,
        isViewed,
        reason,
        reAssignmentReason,
        withdrawnPopUpViewed,
        reSubmit,
        reSubmitPopupClient,
        reSubmitPopupForwarder,
        showRejectModalAgent,
        showRejectModalForwarder,
        bpNumber,
        cancelDate,
        cancelReason,
        cancelledUser,
        linkedBp,
        linkedBpDetails,
        gateInDateExpired,
        agentCode,
        clientApproveCount,
        clientOptionApproveCount,
        rejectionReason,
    } = useSelector((state: RootState) => state.bpHeaderData);
    const dispatch = useDispatch();
    const { profileType, userName } = useUserProfile();
    const [reasonModalView, setReasonModalView] = useState(false);
    const [rejectionReasonModalView, setrejectionRasonModalView] = useState(false);
    const [rejectionReasonModalType, setRejectionReasonModalType] = useState('');
    const [agentReassignmentView, setAgentReassignmentView] = useState(false);
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    const [reapproveBpModalView, setReapproveBpModalView] = useState(false);
    const [rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [reasonRejection, setReasonRejection] = useState('');
    const [oneTimeModal, setOneTimeModal] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const [showLinkedModal, setShowLinkedModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showPurchaseOrderTooltip, setShowPurchaseOrderTooltip] = useState(false);
    const hasBookingId = id !== 'POSelection' && id !== 'POSelectionDetails';
    useEffect(() => {
        hasBookingId && dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, [id]);

    const onClose = () => {
        setShowReasonModal(false);
    };

    useEffect(() => {
        if (linkedBp) {
            dispatch(fetchLinkedBpHeader({ bpId: linkedBp, profileType }));
        }
    }, [linkedBp]);

    useEffect(() => {
        if (!bpStatus) {
            return;
        }
        if (
            (profileType === 'forwarder' && bpStatus === 'Awaiting_Forwarder_Approval' && !isViewed) ||
            (profileType === 'client' && bpStatus === 'Awaiting_Client_Approval' && !isViewed)
        ) {
            hasBookingId && dispatch(updateViewStatus({ bpId: id, profileType }));
        } else if (profileType === 'client' && 'Awaiting_Client_Acceptance' && !isViewed) {
            const isEventReq = airOptionStatus?.includes(bpStatus ?? '') ? true : false;
            hasBookingId && dispatch(updateViewStatus({ bpId: id, profileType, isEventReq }));
        }
        if (
            (reSubmitPopupClient === false &&
                profileType === 'client' &&
                reSubmit &&
                bpStatus === 'Awaiting_Client_Approval') ||
            (reSubmitPopupForwarder === false &&
                profileType === 'forwarder' &&
                reSubmit &&
                bpStatus === 'Awaiting_Forwarder_Approval')
        ) {
            setReapproveBpModalView(true);
        }
        if (showRejectModalAgent && profileType === 'agent') {
            setReasonModalView(true);
            setOneTimeModal(true);
        }
        if (!withdrawnPopUpViewed && reAssignmentReason && bpStatus === 'Withdrawn') {
            setAgentReassignmentView(true);
        }
        if (showRejectModalForwarder && profileType === 'forwarder') {
            setRejectedBpForwarderModalView(true);
            setReasonRejection(reason ? reason : '');
        }
        if (agentCode) {
            dispatch(agentIntegration({ agentCode }));
        }
        document.title = bpNumber ? `BKG>${bpNumber}` : '';
    }, [bpStatus]);

    useEffect(() => {
        if (additionalDetailsSuccess?.data?.type === 'assignedToAgent') {
            success(
                `Booking Proposal ${
                    additionalDetailsSuccess?.data?.info?.bpNumber ? additionalDetailsSuccess?.data?.info?.bpNumber : ''
                } assigned to agent`,
                Position.TOP_RIGHT
            );
            dispatch(clearFailure([]));
            setTimeout(() => navigate('/booking_list'), 2000);
        }
    }, [additionalDetailsSuccess]);

    const goodsReadyList = (data: any[]) => {
        const converted = data?.map((res: string) => moment(res).format('DD-MM-YYYY')?.toString());
        const listArray = [];
        const chunkSize = 4;
        for (let i = 1; i < converted.length; i += chunkSize) {
            const chunk = converted.slice(i, i + chunkSize);
            listArray.push(
                <a href="#" className="menu-item app-btn" key={`${i}_tooltip_goods_ready`}>
                    {chunk.toString()}
                </a>
            );
        }
        return listArray;
    };

    return (
        <div className="booking-proposal-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    {props?.extraData ? (
                        <>
                            <Link to={`/air_booking_list/${props?.extraData?.bpId}/booking_details`}>
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </Link>
                            <h2 className="page-heading">
                                {props?.extraData?.containerType ? (
                                    <>{props?.extraData?.containerType}</>
                                ) : (
                                    <>
                                        {props?.extraData?.description} ({props?.extraData?.code})
                                    </>
                                )}
                                <p className="container-number">
                                    Container Number : {props?.extraData?.containerNumber}
                                </p>
                            </h2>
                            <div className="transport-icon-wrapper">
                                <div className="item-count-status-holder">
                                    <div className="grid-item-count-status status-ui info-status">
                                        <span className="count">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use xlinkHref="#aeroplaneIcon">
                                                    <title>Aeroplace</title>
                                                </use>
                                            </svg>
                                        </span>
                                        <span className="status-text">Air</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : localStorage.getItem('changeVesselao') === id ? (
                        <>
                            <a onClick={() => props.setSelected(true)}>
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </a>
                            <h2 className="page-heading">{props.id?.length ? props.id : 'New Booking Proposal'}</h2>
                            <div className="transport-icon-wrapper">
                                <div className="item-count-status-holder">
                                    <div className="grid-item-count-status status-ui info-status">
                                        <span className="count">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use xlinkHref="#aeroplaneIcon">
                                                    <title>Aeroplace</title>
                                                </use>
                                            </svg>
                                        </span>
                                        <span className="status-text">Air</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* <Link to="/booking_list">
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </Link> */}
                            <h2 className="page-heading">{props.id?.length ? props.id : 'New Booking Proposal'}</h2>
                            <div className="transport-icon-wrapper">
                                <div className="item-count-status-holder">
                                    <div className="grid-item-count-status status-ui info-status">
                                        <span className="count">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use xlinkHref="#aeroplaneIcon">
                                                    <title>Aeroplace</title>
                                                </use>
                                            </svg>
                                        </span>
                                        <span className="status-text">Air</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="page-counter-wrapper">
                        {!props.id?.length ? (
                            <div className="page-heading__caption">
                                Select at least one PO line to start Booking Proposal Creation
                            </div>
                        ) : null}
                        {/* link-count-wrapper is used for linking the page available in added tab */}
                        {linkedBp ? (
                            <div
                                className="page-heading__caption link-count-wrapper"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setShowLinkedModal(true);
                                }}
                            >
                                <button className="back-btn app-btn" title="Linked Booking">
                                    <svg className="svg-icon chain-link-icon">
                                        <use href="#chainLinkIcon">
                                            <title>Linked Booking</title>
                                        </use>
                                    </svg>
                                </button>
                                <span className="chain-link-count">1</span>
                            </div>
                        ) : null}
                    </div>
                </div>
                {!props?.extraData && (
                    <div className="header-container-view-eye-reason-wrapper">
                        {(() => {
                            if (
                                profileType === 'agent' &&
                                (bpStatus === 'Awaiting_Forwarder_Approval' ||
                                    bpStatus === 'Awaiting_Client_Approval' ||
                                    bpStatus === 'Awaiting_Client_Acceptance')
                            ) {
                                return (
                                    <div className="header-container-view">
                                        <span className="btn-pills container-pill waiting-pill">
                                            <span className="pill-text">
                                                {bpStatus === 'Awaiting_Forwarder_Approval'
                                                    ? 'Awaiting Forwarder Approval'
                                                    : bpStatus === 'Awaiting_Client_Acceptance'
                                                    ? 'Awaiting Client Acceptance'
                                                    : 'Awaiting Client Approval'}
                                            </span>
                                        </span>
                                        {bpStatus === 'Awaiting_Client_Approval' && (
                                            <span
                                                style={{ marginLeft: '7px' }}
                                                className="btn-pills container-pill waiting-pill"
                                            >
                                                <span className="pill-text">
                                                    Prior Client Approvals : {clientApproveCount}
                                                </span>
                                            </span>
                                        )}
                                        {bpStatus === 'Awaiting_Client_Acceptance' && (
                                            <span
                                                style={{ marginLeft: '7px' }}
                                                className="btn-pills container-pill waiting-pill"
                                            >
                                                <span className="pill-text">
                                                    Options-Prior Client Approvals : {'  '}
                                                    {clientOptionApproveCount}
                                                </span>
                                            </span>
                                        )}
                                        <div className="eye-view-container">
                                            {isViewed ? (
                                                <button title="Viewed" className="app-btn eye-open-view-container">
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon"></use>
                                                    </svg>
                                                </button>
                                            ) : (
                                                <button title="Not Viewed" className="app-btn eye-close-view-container">
                                                    <svg className="svg-icon eye-strick-icon">
                                                        <use xlinkHref="#eyeStrickIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                        {(rejectionReason as any)?.normal?.length > 0 &&
                                            bpStatus !== 'Booked' &&
                                            bpStatus !== 'Draft_Booking' &&
                                            id && (
                                                <a
                                                    className="add-content-link"
                                                    onClick={() => {
                                                        setrejectionRasonModalView(true);
                                                        setRejectionReasonModalType('normal');
                                                    }}
                                                >
                                                    Reject Reason History
                                                </a>
                                            )}
                                        {(rejectionReason as any)?.options?.length > 0 &&
                                            bpStatus !== 'Booked' &&
                                            bpStatus !== 'Draft_Booking' &&
                                            id && (
                                                <a
                                                    className="add-content-link"
                                                    onClick={() => {
                                                        setrejectionRasonModalView(true);
                                                        setRejectionReasonModalType('options');
                                                    }}
                                                >
                                                    Options Rejection History
                                                </a>
                                            )}
                                    </div>
                                );
                            } else if (
                                profileType === 'forwarder' &&
                                (bpStatus === 'Awaiting_Client_Approval' || bpStatus === 'Awaiting_Client_Acceptance')
                            ) {
                                return (
                                    <div className="header-container-view">
                                        <span className="btn-pills container-pill waiting-pill">
                                            <span className="pill-text">
                                                {bpStatus === 'Awaiting_Client_Acceptance'
                                                    ? 'Awaiting Client Acceptance'
                                                    : 'Awaiting Client Approval'}
                                            </span>
                                        </span>
                                        {bpStatus === 'Awaiting_Client_Approval' && (
                                            <span
                                                style={{ marginLeft: '7px' }}
                                                className="btn-pills container-pill waiting-pill"
                                            >
                                                <span className="pill-text">
                                                    Prior Client Approvals : {clientApproveCount}
                                                </span>
                                            </span>
                                        )}
                                        {bpStatus === 'Awaiting_Client_Acceptance' && (
                                            <span
                                                style={{ marginLeft: '7px' }}
                                                className="btn-pills container-pill waiting-pill"
                                            >
                                                <span className="pill-text">
                                                    Options-Prior Client Approvals : {'  '}
                                                    {clientOptionApproveCount}
                                                </span>
                                            </span>
                                        )}
                                        <div className="eye-view-container">
                                            {isViewed ? (
                                                <button title="Viewed" className="app-btn eye-open-view-container">
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon"></use>
                                                    </svg>
                                                </button>
                                            ) : (
                                                <button title="Not Viewed" className="app-btn eye-close-view-container">
                                                    <svg className="svg-icon eye-strick-icon">
                                                        <use xlinkHref="#eyeStrickIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                        {(rejectionReason as any)?.normal?.length > 0 &&
                                            bpStatus !== 'Booked' &&
                                            bpStatus !== 'Draft_Booking' &&
                                            id && (
                                                <a
                                                    className="add-content-link"
                                                    onClick={() => {
                                                        setrejectionRasonModalView(true);
                                                        setRejectionReasonModalType('normal');
                                                    }}
                                                >
                                                    Reject Reason History
                                                </a>
                                            )}
                                        {(rejectionReason as any)?.options?.length > 0 &&
                                            bpStatus !== 'Booked' &&
                                            bpStatus !== 'Draft_Booking' &&
                                            id && (
                                                <a
                                                    className="add-content-link"
                                                    onClick={() => {
                                                        setrejectionRasonModalView(true);
                                                        setRejectionReasonModalType('options');
                                                    }}
                                                >
                                                    Options Rejection History
                                                </a>
                                            )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="header-container-view-reason">
                                        <div className="total-container-count">
                                            {(() => {
                                                if (bpStatus === 'Rejected_by_Client_Forwarder_to_Action') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">
                                                                    Rejected by Client :{' '}
                                                                    <span>Forwarder to Action</span>
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Rejected_by_Client_Agent_to_Action') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">
                                                                    Rejected by Client : <span>Agent to Action</span>
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Cancelled') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">Cancelled</span>
                                                            </span>
                                                            <a
                                                                className="add-content-link"
                                                                href="javascript:void(0)"
                                                                onClick={() => setShowReasonModal(true)}
                                                            >
                                                                View Reason
                                                            </a>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Rejected_by_Forwarder') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">Rejected by Forwarder</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Forwarder_Approval') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Forwarder Approval
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Client_Approval') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Client Approval
                                                                </span>
                                                            </span>
                                                            <span
                                                                style={{ marginLeft: '7px' }}
                                                                className="btn-pills container-pill waiting-pill"
                                                            >
                                                                <span className="pill-text">
                                                                    Prior Client Approvals : {clientApproveCount}
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Ready_for_Booking') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">Ready for Booking</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Draft_Booking_Proposal') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Draft Booking Proposal
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Agent_Options') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Agent Options
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Client_Acceptance') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Client Acceptance
                                                                </span>
                                                            </span>
                                                            {bpStatus === 'Awaiting_Client_Acceptance' && (
                                                                <span
                                                                    style={{ marginLeft: '7px' }}
                                                                    className="btn-pills container-pill waiting-pill"
                                                                >
                                                                    <span className="pill-text">
                                                                        Options-Prior Client Approvals : {'  '}
                                                                        {clientOptionApproveCount}
                                                                    </span>
                                                                </span>
                                                            )}
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Forwarder_Review') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Forwarder Review
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Options_Rejected_By_Client') {
                                                    return (
                                                        <span className="btn-pills container-pill error-pill">
                                                            <span className="pill-text">
                                                                Options Rejected By Client
                                                            </span>
                                                        </span>
                                                    );
                                                } else if (bpStatus === 'Options_Expired') {
                                                    return (
                                                        <span className="btn-pills container-pill error-pill">
                                                            <span className="pill-text">Options Expired</span>
                                                        </span>
                                                    );
                                                } else if (bpStatus === 'Draft_Booking') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">Draft Booking</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Booked') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill success-pill">
                                                                <span className="pill-text">Booked</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Pending_Reassignment') {
                                                    return (
                                                        <span className="btn-pills container-pill waiting-pill">
                                                            <span className="pill-text">Pending Reassignment</span>
                                                        </span>
                                                    );
                                                } else if (bpStatus === 'Withdrawn') {
                                                    return (
                                                        <span className="btn-pills container-pill error-pill">
                                                            <span className="pill-text">Withdrawn</span>
                                                        </span>
                                                    );
                                                }
                                            })()}
                                        </div>
                                        {reAssignmentReason && bpStatus === 'Withdrawn' && (
                                            <a
                                                className="add-content-link"
                                                onClick={() => setAgentReassignmentView(true)}
                                            >
                                                View Reason
                                            </a>
                                        )}
                                        {(rejectionReason as any)?.normal?.length > 0 &&
                                            bpStatus !== 'Booked' &&
                                            bpStatus !== 'Draft_Booking' &&
                                            id && (
                                                <a
                                                    className="add-content-link"
                                                    onClick={() => {
                                                        setrejectionRasonModalView(true);
                                                        setRejectionReasonModalType('normal');
                                                    }}
                                                >
                                                    Reject Reason History
                                                </a>
                                            )}
                                        {(rejectionReason as any)?.options?.length > 0 &&
                                            bpStatus !== 'Booked' &&
                                            bpStatus !== 'Draft_Booking' &&
                                            id && (
                                                <a
                                                    className="add-content-link"
                                                    onClick={() => {
                                                        setrejectionRasonModalView(true);
                                                        setRejectionReasonModalType('options');
                                                    }}
                                                >
                                                    Options Rejection History
                                                </a>
                                            )}
                                    </div>
                                );
                            }
                        })()}
                    </div>
                )}
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Client</h6>
                        <div className="details-box-content" title={clientName || '-'}>
                            {clientName || '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Vendor</h6>
                        <div className="details-box-content" title={vendorName || '-'}>
                            {vendorName || '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Origin</h6>
                        <div className="details-box-content" title={originPort || '-'}>
                            {originPort || '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Destination</h6>
                        <div className="details-box-content" title={destinationPort || '-'}>
                            {destinationPort || '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Goods Ready Dates</h6>
                        <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
                            <div className="details-box-content">
                                {goodsReadyDate?.length ? moment(goodsReadyDate[0]).format('DD-MM-YYYY') : '-'}
                                {goodsReadyDate?.length > 1 ? (
                                    <span
                                        className={`dropdown-wrap menu-down ${
                                            showTooltip ? 'dropdown-open' : ''
                                        } info-dropdown modal-toast header-count header-dp`}
                                    >
                                        <div
                                            className="toast-message hand-icon"
                                            onClick={() => {
                                                setShowTooltip(!showTooltip);
                                                if (showPurchaseOrderTooltip) {
                                                    setShowPurchaseOrderTooltip(false);
                                                }
                                            }}
                                        >
                                            +{goodsReadyDate?.length - 1}
                                        </div>
                                        <div className="dropdown-menu ">
                                            {/* <a href="#" className="menu-item app-btn">
                                                {goodsReadyDate
                                                    ?.slice(1, goodsReadyDate?.length)
                                                    .map((date: any, index: number) => (
                                                        <a
                                                            href="#"
                                                            className="menu-item app-btn"
                                                            key={`${index}_tooltip`}
                                                        >
                                                            {moment(date).format('DD-MM-YYYY')}
                                                        </a>
                                                    ))}
                                            </a> */}
                                            {goodsReadyList(goodsReadyDate)}
                                        </div>
                                    </span>
                                ) : null}
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
                <div className="card-detail-col col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Purchase Orders</h6>
                        <ClickAwayListener onClickAway={() => setShowPurchaseOrderTooltip(false)}>
                            <div className="details-box-content">
                                {poNumbers?.length ? poNumbers[0] : '-'}
                                {poNumbers?.length > 1 ? (
                                    <span
                                        className={`dropdown-wrap menu-down ${
                                            showPurchaseOrderTooltip ? 'dropdown-open' : ''
                                        } info-dropdown modal-toast header-count header-dp`}
                                    >
                                        <div
                                            className="toast-message hand-icon"
                                            onClick={() => {
                                                setShowPurchaseOrderTooltip(!showPurchaseOrderTooltip);
                                                if (showTooltip) {
                                                    setShowTooltip(false);
                                                }
                                            }}
                                        >
                                            +{poNumbers?.length - 1}
                                        </div>
                                        <div className="dropdown-menu ">
                                            <a href="#" className="menu-item app-btn">
                                                {poNumbers
                                                    ?.slice(1, poNumbers?.length)
                                                    .map((po: any, index: number) => (
                                                        <a
                                                            href="#"
                                                            className="menu-item app-btn"
                                                            key={`${index}_tooltip`}
                                                        >
                                                            {po}
                                                        </a>
                                                    ))}
                                            </a>
                                        </div>
                                    </span>
                                ) : null}
                            </div>
                        </ClickAwayListener>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">
                            {totalCBM
                                ? totalCBM?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 1,
                                  })
                                : totalCBM}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">
                            {totalKgs
                                ? totalKgs?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 1,
                                  })
                                : totalKgs}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Estimated TEU</h6>
                        <div className="details-box-content">
                            {totalTEU
                                ? totalTEU?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 1,
                                  })
                                : totalTEU}
                            {/* {totalCBM && Math.ceil(totalCBM / 28) ? Math.ceil(totalCBM / 28) : totalCBM === 0 ? 0 : '-'} */}
                        </div>
                    </div>
                </div>
            </div>
            <RejectedBookingProposalModal
                modalView={reasonModalView}
                setModalView={setReasonModalView}
                reason={reason != null ? reason : ''}
                oneTimeModal={oneTimeModal}
                bpId={hasBookingId ? id : ''}
                profileType={profileType}
            />

            <RejectReasonHistory
                modalView={rejectionReasonModalView}
                type={rejectionReasonModalType}
                setModalView={setrejectionRasonModalView}
                bpId={id}
                profileType={profileType}
            />

            <ReassignAgentReasonModal
                modalView={agentReassignmentView}
                setModalView={setAgentReassignmentView}
                reason={reAssignmentReason != null ? reAssignmentReason : ''}
                bpId={id}
                profileType={profileType}
                isViewed={!!withdrawnPopUpViewed}
            />
            <RejectedBpForwarderModal
                modalView={rejectedBpForwarderModalView}
                setModalView={setRejectedBpForwarderModalView}
                reason={reason != null ? reason : ''}
                bpId={hasBookingId ? id : ''}
                profileType={profileType}
                setAssignAgentModalView={setAssignAgentModalView}
                setReason={setReasonRejection}
            />
            <ReapprovalBpClientModal
                modalView={reapproveBpModalView}
                setModalView={setReapproveBpModalView}
                reason={reason != null ? reason : ''}
                bpId={hasBookingId ? id : ''}
                profileType={profileType}
            />
            <RejectBookingProposalModal
                modalView={assignAgentModalView}
                setModalView={setAssignAgentModalView}
                header={{
                    bpNumber: bpNumber != null ? bpNumber : '',
                    clientName: clientName != null ? clientName : '',
                    gateInDateExpired: gateInDateExpired != null ? gateInDateExpired : false,
                }}
                userProfile={{ profileType, userName }}
                reason={reasonRejection}
                setReason={setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={setRejectedBpForwarderModalView}
                gateInDateExpired={gateInDateExpired}
            />
            {showReasonModal ? (
                <CancellationDetailModal
                    onClose={onClose}
                    data={{ cancelDate, cancelReason, cancelledUser }}
                ></CancellationDetailModal>
            ) : null}
            {showLinkedModal ? (
                <LinkedBookingModal
                    linkedBpDetails={linkedBpDetails}
                    setShowLinkedModal={setShowLinkedModal}
                ></LinkedBookingModal>
            ) : null}
        </div>
    );
};
export default Index;
