import React, { useEffect } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
import './basic-details.scss';
import '../../../../assets/scss/components/_tooltip.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { fetchBasicData, OrganisationContact } from '../../../../store/reducers/basicDetailsReducer';
import { useLocation } from 'react-router-dom';
import ToolTip from '../../../../components/ToolTip';
import { Address } from '../../../../services/apis/clientViewApi';
import ToolTipContentBox from '../../../../components/ToolTipBoxContent';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackButton from '../../../../components/common/BackButton';
import BSTooltip from 'src/components/common/BSTooltip';

// import './style.css';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const location: any = useLocation();

    const { clientBasicDetails, isLoading } = useSelector((state: RootState) => state.basicDetails);
    useEffect(() => {
        const uuid = new URL(window.location.href).pathname.split('/')[3];
        const payload = { clientPK: uuid };
        dispatch(fetchBasicData(payload));
        if (clientBasicDetails?.cl_Org.or_Orgname) {
            localStorage.setItem('clientname', clientBasicDetails?.cl_Org.or_Orgname);
        }
        // eslint-disable-next-line
    }, [dispatch]);
    useEffect(() => {
        localStorage.setItem('clientStatus', (location as any)?.state?.status);
    }, [location]);

    // const getFullName = (user: ContactDetails | undefined) => {
    //     if (user) {
    //         return `${user.us_FirstName ? user.us_FirstName : ''} ${user.us_MiddleName ? user.us_MiddleName : ''} ${
    //             user.us_LastName ? user.us_LastName : ''
    //         }`;
    //     }
    //     return '';
    // };

    const showMandatoryFieldsIcon = () => {
        if (!clientBasicDetails?.cl_Org) {
            return false;
        }
        return clientBasicDetails?.cl_Org?.addressList?.some(
            (address: Address) =>
                address.addressline1 && address.city && address.state && address.postcode && address.countrycode
        )
            ? false
            : true;
    };

    const addressValidation = (value: any) => {
        let returnValue = '';
        if (!value.addressline1) {
            returnValue = ', Address';
        }
        if (!value.state) {
            returnValue = returnValue + ', State';
        }
        if (!value.postcode) {
            returnValue = returnValue + ', Postcode';
        }
        if (!value.city) {
            returnValue = returnValue + ', City';
        }
        if (!value.countrycode) {
            returnValue = returnValue + ', Country';
        }
        return returnValue.slice(1) + ' is missing';
    };

    return (
        <>
            <div className="main-wrapper container">
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton clientName={clientBasicDetails?.cl_Org.or_Orgname} />
                    <HeaderTabs disabled={showMandatoryFieldsIcon()} />
                </div>
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="content-section main-wrapper basic-detail-wrapper">
                    {showMandatoryFieldsIcon() && (
                        <div className="mandatory-warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">
                                Mandatory fields missed while integrating. Please integrate again with all mandatory
                                fields to proceed.
                            </div>
                        </div>
                    )}
                    <div className="card details-box custom">
                        <h6 className="details-box-heading-2">Profile Information</h6>
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Name<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{clientBasicDetails?.cl_Org.or_Orgname}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Organisation Code<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{clientBasicDetails?.cl_Org.or_Orgcode}</div>
                            </div>
                            <div className="details-col box-content-holder detail-error-row">
                                <h6 className="details-box-heading">Organisation Category</h6>
                                <div className="details-box-content">{clientBasicDetails?.cl_Org.or_Orgtype}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Company Registration Number</h6>
                                <div className="details-box-content">{clientBasicDetails?.cl_Org.or_ABN}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    UNLOCO<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{clientBasicDetails?.cl_Org.or_Unloco}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Phone</h6>
                                <div className="details-box-content">{clientBasicDetails?.cl_Org.or_Phone}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Website URL</h6>
                                <a
                                    href={clientBasicDetails?.cl_Org.or_WebSiteUrl}
                                    target="_blank"
                                    className="details-box-content link-url"
                                >
                                    {clientBasicDetails?.cl_Org.or_WebSiteUrl}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card details-box custom">
                        <div className="heading-with-error">
                            <h6 className="details-box-heading-2">
                                Address<span className="mandatory-field-text">*</span>
                            </h6>
                            {clientBasicDetails?.cl_Org?.addressList?.length === 0 && (
                                <div className="mandatory-warning-text">
                                    <div>
                                        <svg className="svg-icon error-icon">
                                            <use xlinkHref="#errorIcon">
                                                <title>Warning</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className="error-text">At least one address needs to be added</div>
                                </div>
                            )}
                        </div>
                        {!clientBasicDetails?.cl_Org?.addressList?.length && (
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No address added</p>
                            </div>
                        )}
                        <div className="card-detail-col">
                            {clientBasicDetails?.cl_Org?.addressList?.map((address: Address, index: number) => (
                                <div key={index} className="details-col box-content-holder address-details">
                                    <h6
                                        className={`details-box-heading ${
                                            showMandatoryFieldsIcon() ? 'mandatory-text' : ''
                                        }`}
                                    >
                                        {showMandatoryFieldsIcon() && (
                                            <BSTooltip
                                                id={`view-field-${index}`}
                                                title="Missing Mandatory Field"
                                                classList="tooltip-error-btn"
                                                tooltipClassList="mandatory-error-icon"
                                            >
                                                <button className="app-btn mandatory-btn">
                                                    <svg className="svg-icon error-icon">
                                                        <use xlinkHref="#errorIcon">
                                                            <title>Warning</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </BSTooltip>
                                        )}
                                        Address {index + 1}
                                        {showMandatoryFieldsIcon() && ' (' + addressValidation(address) + ' )'}{' '}
                                    </h6>
                                    <div className="details-box-content" key={index}>
                                        <p>{address.addressline1}</p>
                                        <p>{address.addressline2}</p>
                                        <p>{address.addressline3}</p>
                                        <p>{address.city}</p>
                                        <p>{address.countrycode}</p>
                                        <p>{address.postcode}</p>
                                        <p>{address.state}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="card details-box custom">
                        <h6 className="details-box-heading-2">Contacts</h6>
                        <div className="card-detail-col contact-details ">
                            <div className="details-col">
                                <div className="box-content-holder address-details">
                                    <h6 className="details-box-heading">Client Contacts</h6>

                                    <div className="details-box-content column-wrap-content">
                                        {clientBasicDetails?.cl_Org?.or_OrganisationContactList.map(
                                            (users: OrganisationContact, index: number) => (
                                                <React.Fragment key={index}>
                                                    <div className="contact-data">
                                                        <ToolTip tooltipClass={'inline-block'}>
                                                            <span>{users.oc_contactName}</span>
                                                            {users.oc_contactName ? (
                                                                <ToolTipContentBox
                                                                    data={{
                                                                        userName: users.oc_contactName,
                                                                        role: users.oc_title,
                                                                        email: users.oc_email,
                                                                        phoneNumber: users.oc_phonenumber,
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </ToolTip>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        )}
                                        {/* <p>Claire O'Kon</p> */}
                                        {/* <p>Chester Bechtelar</p>
                                        <p>Merle Terry</p>
                                        <p>Willie Barrows</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 className="details-box-heading-2">Forwarder Contacts</h6>
                        <div className="card-detail-col">
                            {clientBasicDetails?.cl_Org?.or_UsersList &&
                                Object.entries(clientBasicDetails?.cl_Org?.or_UsersList).map(
                                    ([key, val]: any[], index: number) => (
                                        <div key={index} className="details-col box-content-holder">
                                            <h6 className="details-box-heading">{key}</h6>
                                            <div className="details-box-content">
                                                {val &&
                                                    val?.map((res: any, index: number) => (
                                                        <React.Fragment key={index}>
                                                            <div className="contact-data">
                                                                <ToolTip tooltipClass={'inline-block'}>
                                                                    <span>
                                                                        {' '}
                                                                        {res.us_FirstName} {res.us_LastName}
                                                                    </span>
                                                                    {res.us_FirstName ? (
                                                                        <ToolTipContentBox
                                                                            data={{
                                                                                userName:
                                                                                    res.us_FirstName +
                                                                                    ' ' +
                                                                                    res.us_LastName,
                                                                                role: res.us_Role ? res.us_Role : null,
                                                                                email: res.us_Email,
                                                                                phoneNumber: res.us_PhoneNo,
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </ToolTip>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <Link
                            className={`app-btn app-btn-primary footer-btn${
                                showMandatoryFieldsIcon() ? ' disabled' : ''
                            }`}
                            to={showMandatoryFieldsIcon() ? '' : `/profile/clients/${id}/sop_details`}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
