import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import ActivityList from './ActivityList';
import ActivitySubList from './ActivitySubList';
import './workTrendsActivityList.scss';
const Index: React.FC = () => {
    const { isSubActivitiesVisible } = useSelector((state: RootState) => state.workTrendsData);
    return (
        <div className="work-trends-activities">
            {isSubActivitiesVisible ? <ActivitySubList></ActivitySubList> : <ActivityList></ActivityList>}
        </div>
    );
};
export default Index;
