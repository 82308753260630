import { createSlice } from '@reduxjs/toolkit';
import { inventoryHeaders } from '../../../utils/constants';

export const dataSlice = createSlice({
    name: 'inventoryList',
    initialState: {
        inventories: [],
        discrepancies: [],
        clients: [],
        warehouses: [],
        isLoading: true,
        currentPage: 0,
        columnStatus: inventoryHeaders.modifiedOn,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        error: null,
        success: false,
        logData: {},
        totalInventoryPages: 0,
        totalInventoryItems: 0,
        newDsPresent: false,
        newNotification: false,
        filterValues: {},
        filterValuesDiscrepancy: {},
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchInventoryData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.isLoading = false;
        },
        saveInventoryData: (state, action) => {
            state.isLoading = true;
            state.inventories = action.payload.inventories?.inventoryListingDto;
            state.totalInventoryPages = action.payload.inventories?.totalPages;
            state.totalInventoryItems = action.payload.inventories?.totalElements;
            state.filterValues = action.payload.inventories?.distinctColumnValues;
            state.isLoading = false;
        },
        fetchInventoryDataDiscrepancy: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.isLoading = false;
        },
        saveInventoryDataDiscrepancy: (state, action) => {
            state.isLoading = true;
            state.discrepancies = action.payload.discrepancies?.discrepancyListingDto;
            state.totalPages = action.payload.discrepancies?.totalPages;
            state.totalItems = action.payload.discrepancies?.totalElements;
            state.newNotification = action.payload.discrepancies?.newNotification;
            state.newDsPresent = action.payload.discrepancies?.newDsPresent;
            state.filterValuesDiscrepancy = action.payload.discrepancies?.distinctColumnValues;
            state.isLoading = false;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        fetchClientAndWarehouseData: (state, action) => {
            state.isLoading = true;
            if (action.payload?.response) {
                state.clients = action.payload?.response?.clients;
                state.warehouses = action.payload?.response?.warehouses;
            }
            state.isLoading = false;
        },
        fetchWareHouseData: (state, action) => {
            state.isLoading = true;
            if (action.payload?.response?.warehouses) {
                state.warehouses = action.payload?.response?.warehouses;
            }
            state.isLoading = false;
        },
        fetchInventoryLogs: (state, action) => {
            state.isLoading = true;
            if (action.payload?.inventoryLog) {
                state.logData = action.payload?.inventoryLog;
            }
            state.isLoading = false;
        },
        resetLogs: (state, _action) => {
            state.logData = {};
        },
        viewedDiscrepancy: (_state, _action) => {
            // console.log(action);
        },
    },
});

export const {
    fetchInventoryData,
    setPageCount,
    saveInventoryData,
    isLoadingFn,
    setDefault,
    fetchWareHouseData,
    fetchClientAndWarehouseData,
    saveInventoryDataDiscrepancy,
    fetchInventoryDataDiscrepancy,
    fetchInventoryLogs,
    resetLogs,
    viewedDiscrepancy,
} = dataSlice.actions;
export default dataSlice.reducer;
