import { useEffect } from 'react';

export default function useBrowserBackCheck(check: boolean, ifCallback: () => void) {
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);

        const formCheck = () => {
            if (check) {
                ifCallback?.();
                window.history.pushState(null, document.title, window.location.href);
            } else {
                history.back();
            }
        };

        window.addEventListener('popstate', formCheck);
        return () => {
            window.removeEventListener('popstate', formCheck);
        };
    }, [check]);
}
