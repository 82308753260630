import React from 'react';
import { ShipmentTabProps } from '.';
import { HelmetProvider } from 'react-helmet-async';
import GridLayout from 'src/components/KendoGridJquery/GridLayoutWithLocalData';
import { shipmentGridDetails } from './columns';
import moment from 'moment';
import { useTimeout } from 'react-use';

const MilestoneDates: React.FC<ShipmentTabProps> = ({ data }) => {
    const [isReady] = useTimeout(100);

    const getData = () => {
        return data?.milestoneCollection?.milestone?.map((res) => {
            return {
                milestone: res?.eventCode,
                description: res?.description,
                estimatedDate: '', // TODO
                actualDate: res?.actualDate ? moment(res?.actualDate).format('DD-MM-YYYY') : '',
            };
        });
    };
    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-content-holder">
                {isReady() ? (
                    <HelmetProvider>
                        <div className="insight-agent-list-data-trend">
                            <div className="container-fluid shipment-details">
                                <div className="k-kendo-row one-column">
                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                        <div id="grid"></div>
                                    </div>
                                </div>
                            </div>

                            <GridLayout
                                data={getData()}
                                columns={shipmentGridDetails['milestone-dates'].columns}
                                fileName={shipmentGridDetails['milestone-dates'].fileName}
                                fields={shipmentGridDetails['milestone-dates'].fields}
                            />
                        </div>
                    </HelmetProvider>
                ) : null}
            </div>
        </div>
    );
};

export default MilestoneDates;
