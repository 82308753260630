import { get, post, del, put } from '../../HttpMethods';
import fileDownload from 'js-file-download';
import { fileUploadForDocuments } from './additionalDetailsApi';

export const getAdditionalDetailsNac = async (payload: { bpId: string }) => {
    try {
        const res = await get(`api/bkg/bp/additionaldetails?bookingProposalId=${payload.bpId}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getDeliveryAndContainerModes = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/modes?bpType=${payload?.bpType}&coloaderCode=${payload?.coloaderCode}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const addRefNo = async (payload: {
    bpId: string;
    refNo: string;
    destinationAgentName?: string;
    destinationAgentCode?: string;
}) => {
    try {
        const res = await post(
            `api/bkg/bp/referenceno?bookingProposalId=${payload.bpId}&referenceNumber=${payload.refNo}
            ${
                payload.destinationAgentCode
                    ? `&destinationAgentCode=${payload.destinationAgentCode}
            &destinationAgentName=${payload.destinationAgentName}`
                    : ''
            }`,
            null,
            null
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const addFak = async (payload: any) => {
    try {
        const res = await post('/api/bkg/bp/fakadditionaldetails', payload, null);
        const file = payload?.formData?.get('file');
        if (file) {
            const document = payload?.formData?.get('document');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bookingProposalId;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        return res;
    } catch (err) {
        return err;
    }
};

export const addContainer = async (payload: {
    bookingProposalId: string;
    containerId: string;
    deliveryModeId: string;
    containerModeId: string;
    quantity: number;
    cbm: number;
}) => {
    try {
        const res = await post('api/bkg/bp/container', payload, null);
        return res;
    } catch (error) {
        console.log(error, 'error');
        throw error;
    }
};

export const editContainer = async (payload: {
    cdId: string;
    data: {
        bookingProposalId: string;
        containerId: string;
        deliveryModeId: string;
        containerModeId: string;
        quantity: number;
        cbm: number;
    };
}) => {
    try {
        const res = await put(`api/bkg/bp/container?cdId=${payload.cdId}`, payload.data);
        return res;
    } catch (err) {
        return err;
    }
};

export const deleteContainer = async (payload: { cdId: string; bookingProposalId: string }) => {
    try {
        const res = await del(
            `api/bkg/bp/container?bookingProposalId=${payload.bookingProposalId}&cdId=${payload.cdId}`,
            null
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const getContractDetails = async (payload: { contractId: string }) => {
    try {
        const res = await get(`api/bkg/bp/contracts/${payload.contractId}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const updateAdditionalDetailsFak = async (payload: any) => {
    try {
        const res = await put('api/bkg/bp/fakadditionaldetails', payload);
        const file = payload?.get('file');
        if (file) {
            const document = payload?.get('document');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bookingProposalId;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        return res;
    } catch (err) {
        throw err;
    }
};

export const addOrEditDestinationAgentApi = async (payload: {
    bpId: string;
    destinationAgentName: string;
    destinationAgentCode: string;
}) => {
    try {
        const res = await post('api/bkg/bp/destinationagent', payload, null);
        return res;
    } catch (err) {
        throw err;
    }
};

export const downloadFile = async (fakId: string, fileName: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/bkg/bp/fak/download/${fakId}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
