import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import LineGraph from 'src/components/common/LineGraph';
import { RootState } from 'src/store/reducers';

const Index: React.FC = (): ReactElement => {
    const { insightsDurationRange } = useSelector((state: RootState) => state.dashboardInsights);
    const { graphData } = useSelector((state: RootState) => state.favourites);

    return (
        <div className="graph-view-wrapper">
            <div className="tab-content-head">
                <h5>Trends</h5>
                <p>Showing trend for Last 12 {insightsDurationRange}</p>
            </div>
            <div className="graph-holder">
                {graphData && (
                    <LineGraph
                        drawn={graphData}
                        name="purchaseOrdersLarge2"
                        tooltip={true}
                        lineDots={true}
                        lineWidth={1}
                        pointSize={2}
                        graphAxis={true}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
