import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'addReceipt',
    initialState: {
        isLoading: false,
        receiptDetails: { receiptNo: null, receiptId: null },
        error: false,
        success: false,
        errorMessage: null,
        id: null,
    },
    reducers: {
        saveReceipt: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.response?.success;
            if (action.payload?.response && !action.payload?.response?.success) {
                state.errorMessage = action.payload?.response?.response?.data?.data;
                state.error = true;
            }
            state.receiptDetails = action.payload?.response?.info;
        },
        resetFlags: (state, _action) => {
            state.success = false;
            state.error = false;
            state.errorMessage = null;
            state.isLoading = false;
        },
    },
});

export const { saveReceipt, resetFlags } = dataSlice.actions;
export default dataSlice.reducer;
