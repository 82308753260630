import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../../store/reducers';
import ShowForPermissions from '../../../../ShowForPermissions';
import PoReview from '../../../BookingProposal/BookingAddedGrid/PurchaseOrderDetails';
import ProductDetailModal from '../../../BookingProposal/BookingAddedGrid/ProductDetailsModal';
import './po-line.scss';
import { removePoFromReceipt } from '../../../../store/reducers/receipt/poLine';

/* eslint no-var: off */

interface BioProps {
    bpID?: any;
    setaddedgrid: any;
    setSelectedItemsAdded: any;
    selectedItemsAdded: any;
    addPoList: any;
    setassignModal: any;
}
const Index: React.FC<BioProps> = ({
    // bpID,
    // setaddedgrid,
    setSelectedItemsAdded,
    selectedItemsAdded,
    addPoList,
    setassignModal,
}) => {
    const { prouctsList } = useSelector((state: RootState) => state.addedPos);
    const dispatch = useDispatch();
    // const [modalView, setModalView] = useState(true);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const [_productsAll, setproductsAll] = useState([]);
    const [VendorCode, setVendorCode] = useState('');
    // const [poLine, setpoLine] = useState(0);
    var poLineNum = 0;
    var poStatus = true;

    const selectedIds = (boid: string, rsId: any, index: any, type: boolean, val: any, res: any) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].poId === boid) {
                    var filterAr: any = [...selectedAr[i].receiptSubPoLineDtoList, ...val];
                    flag = true;

                    filterAr = filterAr.filter(function (item: any, pos: any) {
                        return filterAr.indexOf(item) == pos;
                    });
                    selectedAr[i].receiptSubPoLineDtoList = [...filterAr];
                }
            }
            if (!flag) {
                var newObj = {
                    poId: boid,
                    poNumber: res?.poNumber,
                    receiptSubPoLineDtoList: [...res?.receiptSubPoLineDtoList],
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].poId === boid) {
                    var delAr: any[] = [...selectedAr[i].receiptSubPoLineDtoList];
                    for (var j = 0; j < delAr.length; j++) {
                        for (var k = 0; k < rsId.length; k++) {
                            if (delAr[j].rsId === rsId[k]) {
                                delAr.splice(j, 1);
                                selectedAr[i].receiptSubPoLineDtoList.splice(j, 1);
                                if (selectedAr[i].receiptSubPoLineDtoList.length === 0) {
                                    selectedAr.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
    };

    const selectAll = (type: boolean) => {
        var selectallar: any[] = [];
        var newObj = {};
        if (type) {
            addPoList?.forEach((element: any) => {
                var singleVal: any = [];
                newObj = {
                    poId: element?.poId,
                    poNumber: element?.poNumber,
                    receiptSubPoLineDtoList: singleVal,
                };
                element.receiptSubPoLineDtoList.forEach((element2: object) => {
                    var val = element2;
                    singleVal.push(val);
                });
                selectallar.push(newObj);
            });
            setSelectedItemsAdded(selectallar);
        } else {
            setSelectedItemsAdded([]);
        }
    };
    const getAllIds = (val: any) => {
        var newArray: any[] = [];
        val.forEach((element: any) => {
            newArray.push(element.rsId);
        });
        return newArray;
    };

    const checkSelected = (poId: any, val: any) => {
        var flag = false;
        selectedItemsAdded.forEach((element: any) => {
            if (poId === element.poId) {
                element?.receiptSubPoLineDtoList?.forEach((element2: any) => {
                    if (element2.rsId === val) {
                        flag = true;
                    }
                });
            }
        });

        return flag;
    };
    const allSelected = () => {
        var totalAll = 0;
        var totalSelected = 0;
        var flag = false;
        addPoList?.forEach((element: any) => {
            element.receiptSubPoLineDtoList.forEach((element1: any) => {
                if (element1.length !== 0) {
                    totalAll++;
                }
            });
        });
        selectedItemsAdded?.forEach((element: any) => {
            element.receiptSubPoLineDtoList?.forEach((_element1: any) => {
                totalSelected++;
            });
        });
        if (totalAll === totalSelected && totalAll !== 0) {
            flag = true;
        }
        return flag;
    };
    const singleRowChecked = (id: any, length: any) => {
        var flag = false;
        selectedItemsAdded?.forEach((element: any) => {
            if (element?.receiptSubPoLineDtoList?.length === length && element.poId === id) {
                flag = true;
            }
        });
        return flag;
    };
    const selectSingle = (boId: any, rsId: any, index: any, type: boolean, value: any, singleVal: any) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boId) {
                    flag = true;
                    selectedAr[i].receiptSubPoLineDtoList = [...selectedAr[i].receiptSubPoLineDtoList, singleVal];
                }
            }
            if (!flag) {
                var newObj = {
                    receiptSubPoLineDtoList: [singleVal],
                    poNumber: value?.poNumber,
                    poId: boId,
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boId) {
                    var delAr: any[] = [...selectedAr[i].receiptSubPoLineDtoList];
                    for (var j = 0; j < delAr.length; j++) {
                        if (delAr[j].rsId === rsId) {
                            delAr.splice(j, 1);
                            selectedAr[i].receiptSubPoLineDtoList.splice(j, 1);
                            if (selectedAr[i].receiptSubPoLineDtoList.length === 0) {
                                selectedAr.splice(i, 1);
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
    };
    /* eslint-disable no-undefined */

    useEffect(() => {
        setproductsAll(prouctsList);
    }, [prouctsList]);

    const removePoLine = () => {
        var newItem = selectedItemsAdded;
        delete newItem.isFullPo;
        delete newItem.id;
        delete newItem.subPolineIds;
        const payload = {
            polines: newItem,
        };
        dispatch(removePoFromReceipt(payload));
        setSelectedItemsAdded([]);
    };

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let isAgent = false;
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     if (roleIndex) {
    //         isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
    //     }
    // }
    return (
        <div className="grid-section po-line-details-grid">
            <div className="grid-holder">
                <div className="grid-header">
                    <div className="grid-header--wrapper">
                        <div className="grid-header--title">PO Lines</div>
                        <div className="grid-header--status">
                            <div className="grid-header--count">{addPoList?.length}</div>
                        </div>
                    </div>
                    <div className="tab-with-proposal">
                        {selectedItemsAdded?.length !== 0 ? (
                            <div className="tab-grid-select-holder">
                                <span className="btn-pills info-pill" onClick={() => setSelectedItemsAdded([])}>
                                    <span className="pill-text">{selectedItemsAdded?.length} Selected</span>
                                    <button className="pill-action">
                                        <svg className="svg-icon close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </span>
                                <button onClick={() => removePoLine()} className="app-btn app-btn-primary footer-btn">
                                    Remove from Receipt
                                </button>
                            </div>
                        ) : (
                            <div className="btn-holder">
                                <ShowForPermissions permission="create" type="receipt">
                                    <button
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Assign PO Lines"
                                        onClick={() => setassignModal(true)}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Assign PO Lines</span>
                                    </button>
                                </ShowForPermissions>
                            </div>
                        )}
                    </div>
                </div>
                <div className="grid-container">
                    {addPoList?.length === 0 && (
                        <ShowForPermissions permission="create" type="receipt">
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No PO lines assigned to this receipt</p>
                            </div>
                        </ShowForPermissions>
                    )}
                    <div className="grid-scroll-content">
                        <table className="grid-table">
                            <thead>
                                <tr className="grid-sticky-row">
                                    <th className="grid-cell-header col-inbound-po grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data">
                                            <span className="header-title">INBOUND PO NUMBER</span>
                                        </div>
                                    </th>
                                    <ShowForPermissions permission="create" type="receipt">
                                        <th className="grid-cell-header col-checkbox">
                                            <div className="grid-cell-data centre-align" style={{ minWidth: '49px' }}>
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                        checked={allSelected()}
                                                        onChange={(e) => selectAll(e.currentTarget.checked)}
                                                    />
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use xlinkHref="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                </label>
                                            </div>
                                        </th>
                                    </ShowForPermissions>

                                    <th className="grid-cell-header col-line">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PO LINE No.</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">SKU</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESCRIPTION</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-planned-goods">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PLANNED GOODS READY DATE</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-grd">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ACTUAL GOODS READY DATE</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-prq">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PLANNED RECEIPT QTY</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-cbm">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CBM</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-converted">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CONVERTED QTY & UOM</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {addPoList?.map((res: any, i: any) => (
                                <tbody className="grouped-splited-row">
                                    {res?.receiptSubPoLineDtoList.map((val: any, i2: any) => {
                                        if (poLineNum === val?.poLineNumber && i2 !== 0) {
                                            poStatus = false;
                                        } else {
                                            poStatus = true;
                                        }
                                        poLineNum = val?.poLineNumber;
                                        return (
                                            <tr>
                                                <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                    <div className="grid-cell-data">
                                                        <div className="checkbox-data-wrapper">
                                                            <ShowForPermissions permission="create" type="receipt">
                                                                <label className="app-check-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={singleRowChecked(
                                                                            res?.poId,
                                                                            res?.receiptSubPoLineDtoList.length
                                                                        )}
                                                                        className="checkbox-input"
                                                                        onChange={(e) => {
                                                                            selectedIds(
                                                                                res?.poId,
                                                                                getAllIds(res?.receiptSubPoLineDtoList),
                                                                                i,
                                                                                e.currentTarget.checked,
                                                                                val,
                                                                                res
                                                                            );
                                                                        }}
                                                                    />
                                                                    {i2 === 0 && (
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use xlinkHref="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                    )}
                                                                </label>
                                                            </ShowForPermissions>
                                                            {i2 === 0 && (
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    className="booking-proposal-link"
                                                                    style={{ paddingLeft: '0', marginLeft: '28px' }}
                                                                    onClick={() =>
                                                                        setpoDisplay({
                                                                            status: true,
                                                                            id: res?.poId,
                                                                        })
                                                                    }
                                                                >
                                                                    {res?.poNumber}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                <ShowForPermissions permission="create" type="receipt">
                                                    <td className="grid-cell col-checkbox">
                                                        <div className="grid-cell-data centre-align">
                                                            <div className="checkbox-data-wrapper">
                                                                <label className="app-check-wrapper">
                                                                    <input
                                                                        checked={checkSelected(res?.poId, val?.rsId)}
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        onChange={(e) =>
                                                                            selectSingle(
                                                                                res?.poId,
                                                                                val?.rsId,
                                                                                i2,
                                                                                e.currentTarget.checked,
                                                                                res,
                                                                                val
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="checkmark">
                                                                        <svg className="svg-icon tick-icon">
                                                                            <use xlinkHref="#tickIcon">
                                                                                <title>check mark</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ShowForPermissions>

                                                <td className="grid-cell col-po-sku">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.poLineNumber}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="booking-proposal-link"
                                                            onClick={() => {
                                                                setVendorCode(res?.clientCode);
                                                                setModalViewproduct({
                                                                    status: true,
                                                                    value: res?.clientCode,
                                                                    id: val?.skuId,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {val?.sku}
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-qty">
                                                    <div className="grid-cell-data">{val?.description}</div>
                                                </td>
                                                <td className="grid-cell col-po-grd">
                                                    <div className="grid-cell-data">
                                                        {moment(val?.goodsReadyDate).format('DD-MM-YYYY')}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-prg">
                                                    <div className="grid-cell-data">
                                                        {res?.actualGoodsReadyDate
                                                            ? moment(res?.actualGoodsReadyDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-cbm">
                                                    <div className="grid-cell-data">
                                                        {val?.plannedReceiptQuantity
                                                            ? val?.plannedReceiptQuantity
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-weight">
                                                    <div className="grid-cell-data">{val?.cbm ? val?.cbm : '-'}</div>
                                                </td>
                                                <td className="grid-cell col-po-weight">
                                                    <div className="grid-cell-data">
                                                        {val?.convertedQuantity ? val?.convertedQuantity : ''}
                                                        {val?.subPoLineId?.uom && ' ' + val?.subPoLineId?.uom}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ))}
                        </table>
                    </div>
                    {ModalViewproduct.status && (
                        <div className="grid-section grid-added-booking product-details-wrapper">
                            <div className="grid-holder">
                                <div className="grid-container">
                                    <ProductDetailModal
                                        display={ModalViewproduct}
                                        displayFunction={setModalViewproduct}
                                        id={VendorCode}
                                        data={prouctsList}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}{' '}
        </div>
    );
};
export default Index;
