import React, { useEffect, useState } from 'react';
// import BasicDetailsTab from '../BasicDetailsTab';
import '../lcsBookingDetails.scss';
// import ScheduleTab from '../ScheduleTab';
// import SkuTab from '../SkuTab';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { filterValues, gridDetails, portPairDetails, sailingDetails } from 'src/utils/kendoGrid/carrier';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useLocation, useParams } from 'react-router';
const baseURL = process.env.REACT_APP_BASE_URL;
const Index: React.FC = () => {
    const [lcsTab, setLcsTab] = useState('portPair');
    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };

    const { id } = useParams() as any;
    const location: any = useLocation();
    const { name } = location.state || {};
    const nameOfPage = 'carrier';
    const gridItem = gridDetails.filter((res: any) => res.name === nameOfPage)[0];
    const portPairItem = portPairDetails.filter((res: any) => res.name === nameOfPage)[0];
    const sailingItem = sailingDetails.filter((res: any) => res.name === nameOfPage)[0];
    const { profileType, userName } = useUserProfile();
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    useEffect(() => {
        if (lcsTab === 'portPair') {
            gridItem.url = `${baseURL}/api/mgt/tracking/lifecycle/search/carrier/portpair?id=${id}&profile=${profileType}`;
        }
        portPairItem.url = `${process.env.REACT_APP_EXT_BASE_URL}/gr/schedules?SE_Carrier=${name}`;
        sailingItem.url = `${baseURL}/api/bkg/tracking/lifecycle/search/carrier/sailingschedule?id=${id}&profile=${profileType}`;
    }, [lcsTab, id, profileType, name]);

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'portPair' ? 'active' : ''}`}
                        onClick={() => setLcsView('portPair')}
                    >
                        Port Pair
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'sailing' ? 'active' : ''}`}
                        onClick={() => setLcsView('sailing')}
                    >
                        Sailing Schedule
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'portPairSchedule' ? 'active' : ''}`}
                        onClick={() => setLcsView('portPairSchedule')}
                    >
                        Port Pair Schedule
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'portPair' && (
                    <KendoGridJquery
                        gridItem={gridItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'sailing' && (
                    <KendoGridJquery
                        gridItem={sailingItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'portPairSchedule' && (
                    <KendoGridJquery
                        gridItem={portPairItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
