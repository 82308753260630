import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { searchBegin, searchSuccess, searchError } from 'src/store/reducers/dashboard/dashoboardSearchReducer';
import {
    dashboradSearchOrdListApi,
    dashboradSearchMgtListApi,
    dashboradSearchBkgListApi,
} from 'src/services/apis/dashboard/dashboardSearchApi';

function* getDashboardSearchList({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        const responseOrd: AxiosResponse<any> = yield call(dashboradSearchOrdListApi, payload);
        const responseMgt: AxiosResponse<any> = yield call(dashboradSearchMgtListApi, payload);
        const responseBkg: AxiosResponse<any> = yield call(dashboradSearchBkgListApi, payload);
        if (!responseOrd?.data?.success && !responseMgt?.data?.success && !responseBkg?.data?.success) {
            yield put({ type: searchError, payload: responseMgt.data.message });
        } else {
            yield put({
                type: searchSuccess,
                payload: {
                    ...(responseOrd?.data?.data ?? {}),
                    ...(responseMgt?.data?.data ?? {}),
                    ...(responseBkg?.data?.data ?? {}),
                },
            });
        }
    } catch (error: any) {
        yield put({ type: searchError, payload: error.message });
    } finally {
        yield put(hideLoading());
    }
}

function* dashBoardSearchSaga() {
    yield takeEvery(searchBegin, getDashboardSearchList);
}

export default dashBoardSearchSaga;
