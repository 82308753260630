import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import VesselSearchModal from './VesselSearchModal';
import { Position, error } from 'src/utils/toast';
import { getVesselList } from 'src/store/reducers/bookingProposalAir/addSchedule';

interface vesselDropdownPropsType {
    index?: number;
    updateVessel: any;
    getUpdatedVessel: any;
    vessel: string;
}

const Index: React.FC<vesselDropdownPropsType> = (props) => {
    const dispatch = useDispatch();

    const [vesselName, setVesselName] = useState('');
    const [selectedVesselName, setSelectedVesselName] = useState('');
    const [vesselData, setVesselData] = useState<any>([]);
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const currentEnv = () => {
        if (process.env.REACT_APP_BASE_URL?.includes('test')) {
            return 'TEST';
        } else if (process.env.REACT_APP_BASE_URL?.includes('dev')) {
            return 'DEV';
        } else if (process.env.REACT_APP_BASE_URL?.includes('qa')) {
            return 'QA';
        } else {
            return 'PROD';
        }
    };

    const vesselSubmit = async () => {
        try {
            const myHeaders = new Headers();
            const token = localStorage.getItem('token');
            myHeaders.append('Authorization', `Bearer ${token}`);
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('env', currentEnv());
            if (token) {
                setIsLoading(true);
                try {
                    const res = await fetch(
                        `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/searchVesselByName?name=${vesselName}`,
                        {
                            method: 'GET',
                            headers: myHeaders,
                            redirect: 'follow',
                        }
                    );

                    setIsLoading(false);
                    if (res.status !== 200) {
                        const body = await res.json();
                        error(body?.Error || 'Vessel Cannot be found (Error 400)', Position.TOP_RIGHT);
                    } else {
                        const body = await res.json();
                        setVesselData(body);
                        setModal(true);
                        // success(`Vessel has been added`, Position.TOP_RIGHT);
                    }
                } catch (e) {
                    setIsLoading(false);
                    error(`Vessel Cannot be found (Error 500)`, Position.TOP_RIGHT);
                }
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const onVesselConfirm = () => {
        const chosenVessel = vesselData?.find((res: any) => res?.imo === selectedVesselName)?.name;
        props?.getUpdatedVessel({ vesselName: chosenVessel }).then((res: any) => {
            if (typeof props?.index === 'number') {
                const option = {
                    label: chosenVessel,
                    value: selectedVesselName,
                };
                props.updateVessel(option, props?.index, 'vessel');
            } else {
                props.updateVessel(selectedVesselName);
            }
            setVesselName('');
            setModal(false);
        });
    };

    return (
        <div className="modal-input-holder layout-item">
            <label className="modal-input-label">Vessel Not Found - Search Here</label>
            <div style={{ display: 'flex' }}>
                <input
                    placeholder="Enter Vessel to search"
                    type="text"
                    className="modal-input input-text"
                    id="vesselName"
                    name="vesselName"
                    onChange={(e) => setVesselName(e.target.value)}
                    value={vesselName}
                    disabled={props?.vessel ? true : false}
                ></input>
                <button
                    style={{ marginLeft: '5px', padding: '5px' }}
                    className="action-btn app-btn app-btn-secondary "
                    title="Search"
                    onClick={() => vesselSubmit()}
                    disabled={vesselName?.trim() ? false : true}
                >
                    {isLoading ? 'Searching...' : 'Search'}
                </button>
            </div>
            {modal ? (
                <VesselSearchModal
                    setModal={setModal}
                    data={vesselData}
                    setSelectedVesselName={setSelectedVesselName}
                    onVesselConfirm={onVesselConfirm}
                />
            ) : null}
        </div>
    );
};

export default Index;
