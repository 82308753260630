import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
// import Breadcrumbs from '../../../../components/Breadcrumbs';
// import HeaderTabs from '../HeaderTabs';
// import './style.css';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import SOPModal from './SOPModal';
//import Modals from '../../../../components/common/Modals';
import HeaderTabs from '../HeaderTabs';
import '../../../../assets/scss/components/_grid.scss';
import '../../../../assets/scss/components/_textfield.scss';
import '../../../../assets/scss/components/_buttons.scss';
import '../../../../assets/scss/components/_dropdown.scss';
import '../../../../assets/scss/components/_toast.scss';
import '../../../../assets/scss/components/_status.scss';
import '../../../../assets/scss/components/_check-radio.scss';
import './/sop-details.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    // selectSopIsError,
    selectSopUser,
    fetchSOPList,
    fetchAuthorDetails,
    // fetchReviewedByDetails,
    // selectSopLoading,
    selectSopCurrentPage,
    selectSopColumnStatus,
    // selectSopPerPageCount,
    ClientDocumentsDtoList,
    selectSoptotalNoOfPages,
    selectSoptotalNoOfItems,
    selectSopLoading,
    clearSops,
    searchSOPData,
} from '../../../../store/reducers/sopReducer';
import moment from 'moment';
import { paginationLimit, sopTableHeaders } from '../../../../utils/constants';
// import DropDown from '../../../../components/common/DropDown';
import { Link } from 'react-router-dom';
import BackButton from '../../../../components/common/BackButton';
import CancelModal from './cancelModal';
import { useMeasure } from 'react-use';
import Pagination from '../../../../components/Pagination/Pagination';
import { downloadFile } from '../../../../services/apis/contractApi';
import ShowForPermissions from '../../../../ShowForPermissions';
import SpecialFilter from 'src/components/common/SpecialFilter';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { getSingleSopData } from 'src/services/apis/sopApi';
import { useSelector } from 'react-redux';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { Position, error } from 'src/utils/toast';

const Index: React.FC = () => {
    const { id } = useParams();
    const [modalView, setModalView] = useState(false);
    const [sopData, setSopData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isFromView, setIsFromView] = useState(false);
    const [mode, setMode] = useState('add');
    const dispatch = useAppDispatch();
    const sop = useAppSelector(selectSopUser);
    // const isError = useAppSelector(selectSopIsError);
    const isLoading = useAppSelector(selectSopLoading);
    const [selectedHeader, setSelectedHeader] = useState(sopTableHeaders.Status);
    const currentPage = useAppSelector(selectSopCurrentPage);
    const columnStatus = useAppSelector(selectSopColumnStatus);
    // const perPageCount = useAppSelector(selectSopPerPageCount);
    const totalNoOfPages = useAppSelector(selectSoptotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectSoptotalNoOfItems);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [_toastStat, settoastStat] = useState(false);
    const [_toastMsg, settoastMsg] = useState('SOP added');
    const [perPageCount, setPerPageCount] = useState(10);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [ref, { width }] = useMeasure<HTMLTableCellElement>();
    const [search, setSearch] = useState('');
    const firstUpdate = useRef(true);

    const payload = {
        id,
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
    };

    useEffect(() => {
        // dispatch(fetchSOPList({ ...payload, currentPage: 0, firstLoad: true }));
        dispatch(fetchAuthorDetails([]));
        // dispatch(fetchReviewedByDetails([]));
    }, [dispatch]);

    useEffect(() => {
        dispatch(clearSops([]));
    }, []);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalNoOfPages; i++) {
            pageNumbers.push(i);
        }

        setPagenumbers(pageNumbers);
    }, [totalNoOfPages, dispatch]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setTimeout(() => {
            setToggleDropdown(true);
        }, 100);
        setselectedFilter(data);
        if (search?.trim()?.length > 1) {
            dispatch(
                searchSOPData({
                    ...payload,
                    keyword: search,
                    currentPage: 0,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: data,
                    columnOrder: !currentOrder,
                })
            );
        } else {
            dispatch(
                fetchSOPList({
                    ...payload,
                    currentPage: 0,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: data,
                    columnOrder: !currentOrder,
                })
            );
        }
    };

    const [currentPageNumber, setCurrentPageNumber] = useState<null | number>(null);

    useEffect(() => {
        if (currentPageNumber) {
            dispatch(
                fetchSOPList({
                    ...payload,
                    currentPage: currentPageNumber - 1,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: selectedFilter,
                    columnOrder: !currentOrder,
                })
            );
        }
    }, [currentPageNumber]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (search?.trim()?.length > 2) {
            dispatch(searchSOPData({ ...payload, keyword: search }));
        } else if (search?.trim()?.length === 0) {
            // dispatch(fetchSOPList({ ...payload, currentPage: 0, firstLoad: true }));
            dispatch(fetchAuthorDetails([]));
            // dispatch(fetchReviewedByDetails([]));
        }
    }, [search]);

    const { roleName } = useUserProfile();
    const { permissions } = useSelector((state: any) => state.permissionData) as any;
    const checkPermission = (permission: any, type: any) => {
        let flag = true;
        if (roleName?.toLowerCase() === 'it admin') {
            return false;
        }
        for (let i = 0; i < permissions?.length; i++) {
            if (
                (permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    permissions[i].access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = false;
            }
        }
        return flag;
    };

    //@ts-ignore
    window.attachmentAction = (type: string, id: string, fileName: string) => {
        if (!checkPermission('download', 'commercial')) {
            downloadFile(id, fileName);
        } else {
            error('Download permission is not available', Position.TOP_RIGHT);
        }
    };
    //@ts-ignore
    window.viewContract = async (type: string, CD_PK: string) => {
        const res: any = await getSingleSopData({ CD_CL: id, CD_PK });
        setSopData(res);
        setIsEdit(true);
        setModalView(true);
    };

    return (
        <>
            {/* {toastStat && (
                <div
                    className="toast-holder"
                    onClick={() => {
                        settoastStat(false);
                    }}
                >
                    <svg className="svg-icon checkmark-icon">
                        <use xlinkHref="#checkmarkIcon">
                            <title>Vendors linked</title>
                        </use>
                    </svg>
                    <div className="toast-message">{toastMsg}</div>
                    <svg
                        className="svg-icon close-icon"
                        onClick={() => {
                            settoastStat(false);
                        }}
                    >
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </div>
            )} */}
            <div
                onClick={() => {
                    setPageCountDisplay(false);
                    setToggleDropdown(false);
                }}
                className="main-wrapper container"
            >
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />
                    <HeaderTabs />
                </div>
                <div className="grid-section sop-grid loader-container grid-p-0">
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    {/* {!sop?.info?.clientDocumentsDtoList?.length && (
                        <div className="grid-no-content " style={{ zIndex: '10' }}>
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            {search?.trim()?.length > 1 ? (
                                <p className="no-content-message">No records found</p>
                            ) : (
                                <>
                                    <p className="no-content-message">
                                        {selectedFilter === null
                                            ? 'No SOP added to this profile'
                                            : 'Filter does not return any results'}
                                    </p>
                                    <ShowForPermissions permission="edit" type="client">
                                        {!selectedFilter && (
                                            <a
                                                className="link-vendor"
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                    if (buttonClicked) {
                                                        setModalView(true);
                                                    } else {
                                                        setTimeout(() => {
                                                            setModalView(true);
                                                        }, 700);
                                                        setButtonClicked(true);
                                                    }
                                                    setIsEdit(false);
                                                    setMode('add');
                                                    setSopData({});
                                                }}
                                            >
                                                Add SOP
                                            </a>
                                        )}
                                    </ShowForPermissions>
                                </>
                            )}
                        </div>
                    )} */}
                    <div className="grid-holder">
                        <div className="grid-header grid-mr-20">
                            <div className="search-entry-holder">
                                {/* <input
                                    placeholder="Search SOP Details"
                                    type="text"
                                    className="search-input input-text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                ></input>
                                <div
                                    className={`search-toolip ${
                                        search.length > 0 && search.length < 3 ? 'tooltip-show' : ''
                                    }`}
                                >
                                    Enter atleast 3 characters to search
                                </div> */}
                                {/* <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button
                                    className="app-btn text-close-btn"
                                    onClick={() => {
                                        if (search?.trim()?.length) {
                                            setSearch('');
                                        }
                                    }}
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button> */}
                            </div>
                            <div className="item-count-status-holder">
                                {/* <div className="grid-item-count-status status-ui success-status"> */}
                                {/* <span className="count">{sop?.info?.totalActiveDocuments}</span>
                                    <span className="status-text">
                                        Active{' '}
                                        {sop?.info?.totalActiveDocuments && sop?.info?.totalActiveDocuments === 1
                                            ? 'SOP'
                                            : 'SOPs'}
                                    </span> */}
                                {/* </div> */}
                            </div>
                            <ShowForPermissions permission="edit" type="client">
                                <ShowForPermissions permission="upload" type="commercial">
                                    <button
                                        onClick={() => {
                                            if (buttonClicked) {
                                                setModalView(true);
                                            } else {
                                                setTimeout(() => {
                                                    setModalView(true);
                                                }, 700);
                                                setButtonClicked(true);
                                            }
                                            setIsEdit(false);
                                            setMode('add');
                                            setSopData({});
                                        }}
                                        className="app-btn app-btn-secondary icon-button grid-top-btn"
                                        title="Add SOP"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Add SOP</span>
                                    </button>
                                </ShowForPermissions>
                            </ShowForPermissions>
                        </div>
                        <SOPModal
                            display={modalView}
                            displayFunction={setModalView}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            data={sopData}
                            setData={setSopData}
                            isFromView={isFromView}
                            setIsFromView={setIsFromView}
                            mode={mode}
                            setMode={setMode}
                            settoastMsg={settoastMsg}
                            settoastStat={settoastStat}
                            selectedHeader={selectedHeader}
                            selectedFilter={selectedFilter}
                            perPageCount={perPageCount}
                            setDisplayCancel={setDisplayCancel}
                        />
                        <CancelModal
                            displayFunction={setModalView}
                            mode={mode}
                            displayCancel={displayCancel}
                            setDisplayCancel={setDisplayCancel}
                        />
                        <LayoutWithColumns style={'tab'} content={'client-sop'} />
                        {/* <div className="grid-container" onScroll={() => setToggleDropdown(false)}>
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className=" grid-sticky-row">
                                            <th
                                                ref={ref}
                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-name"
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">Name</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.Name);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Name,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Name,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.Name
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th
                                                style={{ left: width + 1 }}
                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-version"
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">VERSION</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.Version);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Version,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Version,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.Version
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-author">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">AUTHOR</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.Author);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Author,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Author,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.Author
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-reviewed-by">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">REVIEWED BY</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.ReviewedBy);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ReviewedBy,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ReviewedBy,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.ReviewedBy
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-client-signatory">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">CLIENT SIGNATORY</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.ClientSignatory);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ClientSignatory,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ClientSignatory,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.ClientSignatory
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-active-date">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">ACTIVE DATE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.ActiveDate);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ActiveDate,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ActiveDate,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.ActiveDate
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-expiry-date">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">EXPIRY DATE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.ExpiryDate);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ExpiryDate,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.ExpiryDate,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.ExpiryDate
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-client-acceptance-date">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">CLIENT ACCEPTANCE DATE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.ClientAcceptanceDate);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus:
                                                                            sopTableHeaders.ClientAcceptanceDate,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus:
                                                                            sopTableHeaders.ClientAcceptanceDate,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.ClientAcceptanceDate
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header centre-align col-status">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">STATUS</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(sopTableHeaders.Status);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchSOPData({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Status,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchSOPList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: sopTableHeaders.Status,
                                                                        columnOrder: currentOrder,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === sopTableHeaders.Status
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            toggleDropdown || selectedFilter !== null
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            className="app-btn filter-menu-button"
                                                            onClick={(e) => {
                                                                setToggleDropdown(!toggleDropdown);
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                        {toggleDropdown && (
                                                            <SpecialFilter
                                                                closeToggle={closeToggle}
                                                                currentFilter={selectedFilter}
                                                                dataList={
                                                                    (sop as any)?.info?.distinctColumnValues[
                                                                        'cd_status'
                                                                    ]
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-actions">
                                                <div className="grid-cell-data header-title">ACTIONS</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {sop?.info?.clientDocumentsDtoList.map(
                                            (detail: ClientDocumentsDtoList, index: number) => (
                                                <tr key={index} className="has-child row-expanded">
                                                    <td className="grid-cell grid-sticky-column sticky-left-aligned no-border">
                                                        <div className="grid-cell-data">{detail.cd_docname}</div>
                                                    </td>
                                                    <td
                                                        style={{ left: width + 1 }}
                                                        className="grid-cell grid-sticky-column sticky-left-aligned"
                                                    >
                                                        <div className="grid-cell-data">{detail.cd_version}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.cd_author}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.cd_reviewedby}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {detail.cd_clientsignatory}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(detail.cd_activedate).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(detail.cd_enddate).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(detail.cd_clientacceptancedate).format(
                                                                'DD-MM-YYYY'
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell centre-align">
                                                        {detail.cd_status === 'Expired' ? (
                                                            <div className="grid-cell-data status-ui warning-status">
                                                                Expired
                                                            </div>
                                                        ) : (
                                                            <div className="grid-cell-data status-ui success-status">
                                                                Active
                                                            </div>
                                                        )}
                                                    </td>

                                                    <td className="grid-cell centre-align grid-sticky-column sticky-right-aligned">
                                                        <div className="grid-cell-data ">
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="Download File"
                                                                onClick={() => {
                                                                    if (detail.fileName) {
                                                                        downloadFile(detail.cd_PK, detail.fileName);
                                                                    }
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#downloadIcon"></use>
                                                                    <title>Download</title>
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="View Details"
                                                                onClick={() => {
                                                                    setIsEdit(true);
                                                                    setModalView(true);
                                                                    setSopData(detail);
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#eyeIcon"></use>
                                                                    <title>View</title>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {totalNoOfItems > 0 ? (
                            <div className="grid-footer ">
                                <div className="grid-footer-left">
                                    <div className="footer-text">Showing</div>
                                    <div className="row-per-page">
                                        <div
                                            className={
                                                'dropdown-wrap pagination-dropdown menu-up ' +
                                                (PageCount ? 'dropdown-open' : '')
                                            }
                                        >
                                            <button
                                                className="menu-btn app-btn"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPageCountDisplay(!PageCount);
                                                }}
                                            >
                                                <span className="btn-text">
                                                    {sop?.info?.clientDocumentsDtoList &&
                                                    perPageCount > sop?.info?.clientDocumentsDtoList.length
                                                        ? sop?.info?.clientDocumentsDtoList.length
                                                        : perPageCount}
                                                </span>
                                                <span className="dropdown-arrow">
                                                    <svg className="svg-icon arrow-icon">
                                                        <use xlinkHref="#downArrow">
                                                            <title>dropdown</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                            </button>
                                            {PageCount && (
                                                <div className="dropdown-menu">
                                                    {paginationLimit.map((res, index: number) => (
                                                        <a
                                                            key={index}
                                                            onClick={(e) => {
                                                                setPageCountDisplay(false);
                                                                e.stopPropagation();
                                                                setPerPageCount(res);
                                                                if (search?.trim()?.length > 1) {
                                                                    dispatch(
                                                                        searchSOPData({
                                                                            ...payload,
                                                                            keyword: search,
                                                                            perPageCount: res,
                                                                            currentPage: 0,
                                                                            columnStatus: selectedHeader,
                                                                            statusFilter: selectedFilter,
                                                                            columnOrder: !currentOrder,
                                                                        })
                                                                    );
                                                                } else {
                                                                    dispatch(
                                                                        fetchSOPList({
                                                                            ...payload,
                                                                            currentPage: 0,
                                                                            perPageCount: res,
                                                                            columnStatus: selectedHeader,
                                                                            statusFilter: selectedFilter,
                                                                            columnOrder: !currentOrder,
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                            className="menu-item app-btn"
                                                        >
                                                            {res}
                                                        </a>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        <span>of</span>
                                        <span className="grid-total-row-count">
                                            {totalNoOfItems ? totalNoOfItems : 0}
                                        </span>
                                        <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                    </div>
                                </div>
                                <div className="grid-footer-right">
                                    <div className="grid-pagination">
                                        <Pagination
                                            currentPage={currentPage + 1}
                                            totalCount={totalNoOfItems}
                                            pageSize={perPageCount}
                                            onPageChange={(page: number) => setCurrentPageNumber(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null} */}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder">
                    <Link
                        to={`/profile/clients/${id}/basic_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        {/* <button className="app-btn app-btn-secondary footer-btn" title="Previous"> */}
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <Link
                        to={`/profile/clients/${id}/contract_details`}
                        className="app-btn app-btn-primary footer-btn"
                        title="Next"
                    >
                        {/* <button className="app-btn app-btn-primary footer-btn" title="Next"> */}
                        <span className="button-text footer-button-text">Next</span>
                        {/* </button> */}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Index;
