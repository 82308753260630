import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import * as Yup from 'yup';
import { RootState } from 'src/store/reducers';
import { Form, FormField, TextArea } from 'semantic-ui-react';
import {
    deleteContainerPacking,
    fetchAttachedPurchaseOrders,
    removeContainer,
    resetDeleteContainerPackingResult,
    resetRemoveResult,
    resetSaveContainerPacking,
    saveContainerPacking,
} from 'src/store/reducers/bookingProposal/containerAllocationReducer';
import { useFormik } from 'formik';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { InputActionMeta } from 'react-select';
import { Position, success } from 'src/utils/toast';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bdPk: string;
}

const Index: React.FC<ModalProps> = ({ modalView, setModalView, bdPk }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { attachedPurchaseOrders, saveContainerPackingResult, deleteContainerPackingResult } = useSelector(
        (state: RootState) => state.containerAllocation
    );

    const initialValues = {
        poNumber: '',
        commodity: '',
        quantity: '',
        packs: '',
        volume: '',
        weight: '',
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchAttachedPurchaseOrders({ bpPk: id }));
        }, 1000);
    }, []);

    const convertDataForSelect = (data: any, value: string, label: string) => {
        const convertedData = data?.map((option: any) => {
            return { value: option[value], label: option[label] };
        });
        return convertedData;
    };

    const PackingSchema = Yup.object().shape({
        poNumber: Yup.string().required('PO Number is required'),
        quantity: Yup.number().max(1000000, 'Maximum number is 1,000,000').required('Quantity is required'),
        packs: Yup.number().max(1000000, 'Maximum number is 1,000,000').required('Packs is required'),
        volume: Yup.number().max(1000000, 'Maximum number is 1,000,000').required('Volume is required'),
        weight: Yup.number().max(1000000, 'Maximum number is 1,000,000').required('Weight is required'),
    });

    useEffect(() => {
        if (saveContainerPackingResult.length > 0) {
            $('#grid2')
                .data('kendoGrid')
                .dataSource.read()
                .then(function () {
                    $('#grid').data('kendoGrid').dataSource.read();
                });
            success(`Packing Updated`, Position.TOP_RIGHT);
            dispatch(resetSaveContainerPacking({}));
        }
    }, [saveContainerPackingResult]);

    useEffect(() => {
        if (deleteContainerPackingResult.length > 0) {
            $('#grid2')
                .data('kendoGrid')
                .dataSource.read()
                .then(function () {
                    $('#grid').data('kendoGrid').dataSource.read();
                });
            success(`Container Allocation Deleted`, Position.TOP_RIGHT);
            dispatch(resetDeleteContainerPackingResult({}));
        }
    }, [deleteContainerPackingResult]);

    const formik = useFormik({
        initialValues,
        validationSchema: PackingSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                const payload = {
                    CA_CG: values.poNumber,
                    CA_Commodity: values.commodity || 'General (GEN)',
                    CA_Quantity: values.quantity,
                    CA_Packs: values.packs,
                    CA_Volume: values.volume,
                    CA_Weight: values.weight,
                };
                dispatch(
                    saveContainerPacking({
                        payload: payload,
                        bpPk: id,
                        bdPk: bdPk,
                    })
                );
            }
        },
    });

    useEffect(() => {
        if (formik?.values?.poNumber) {
            const confirmationGroup = attachedPurchaseOrders.filter((p) => p.CG_PK === formik.values.poNumber);
            formik.setValues({
                poNumber: formik?.values?.poNumber,
                commodity: '',
                quantity: confirmationGroup[0].BS_Qty,
                packs: confirmationGroup[0].CG_UserDefinedInt1,
                volume: confirmationGroup[0].CG_Cbm,
                weight: confirmationGroup[0].CG_Weight,
            });
        } else {
            formik.setValues({
                poNumber: '',
                commodity: '',
                quantity: '',
                packs: '',
                volume: '',
                weight: '',
            });
        }
    }, [formik?.values?.poNumber]);

    //@ts-ignore
    window.packingAction = (action: string, caPk: string) => {
        if (action === 'DEL') {
            dispatch(deleteContainerPacking({ bpPk: id, bdPk: bdPk, caPk: caPk }));
        }
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder basic-detail-content-holder large-popup">
                        <div className="modal-header">
                            <div className="title">Container Packing</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="field-entries-added">
                                    <div className="entry-fields-holder">
                                        <form
                                            id="my-form"
                                            onSubmit={formik.handleSubmit}
                                            className="modal-form form-fields"
                                        >
                                            <div className=" modal-input-holder layout-item">
                                                <label className="modal-input-label">
                                                    PO Number<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <SearchableSelectBox
                                                        // CG_PK
                                                        data={
                                                            attachedPurchaseOrders?.length
                                                                ? convertDataForSelect(
                                                                      attachedPurchaseOrders,
                                                                      'CG_PK',
                                                                      'PO_Number'
                                                                  )
                                                                : []
                                                        }
                                                        placeholder="Select PO Number"
                                                        formik={formik}
                                                        fieldName="poNumber"
                                                        isClearable={true}
                                                        onClear={() => {}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">Commodity</label>
                                                <div className="modal-input-layout-item">
                                                    <input
                                                        placeholder="Enter Commodity"
                                                        type="text"
                                                        className="modal-input input-text"
                                                        id="commodity"
                                                        name="commodity"
                                                        onChange={(event) => {
                                                            formik.setFieldError(event.target.name, undefined);
                                                            formik.setFieldValue('commodity', event.target.value);
                                                        }}
                                                        value={formik?.values?.commodity || 'General (GEN)'}
                                                        disabled={formik?.values?.poNumber ? false : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">
                                                    Quantity<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <input
                                                        placeholder="Enter Quantity"
                                                        type="number"
                                                        className="modal-input input-text"
                                                        id="quantity"
                                                        name="quantity"
                                                        onChange={(event) => {
                                                            formik.setFieldError(event.target.name, undefined);
                                                            if (
                                                                Number(event.target.value) <= 1000000 &&
                                                                Number(event.target.value) > 0
                                                            )
                                                                formik.setFieldValue('quantity', event.target.value);
                                                        }}
                                                        value={formik?.values?.quantity || ''}
                                                        disabled={formik?.values?.poNumber ? false : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">
                                                    Packs<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <input
                                                        placeholder="Enter Packs"
                                                        type="number"
                                                        className="modal-input input-text"
                                                        id="packs"
                                                        name="packs"
                                                        onChange={(event) => {
                                                            formik.setFieldError(event.target.name, undefined);
                                                            if (
                                                                Number(event.target.value) <= 1000000 &&
                                                                Number(event.target.value) > 0
                                                            )
                                                                formik.setFieldValue('packs', event.target.value);
                                                        }}
                                                        value={formik?.values?.packs || ''}
                                                        disabled={formik?.values?.poNumber ? false : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">
                                                    Volume<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <input
                                                        placeholder="Enter Volume"
                                                        type="number"
                                                        className="modal-input input-text"
                                                        id="volume"
                                                        name="volume"
                                                        onChange={(event) => {
                                                            formik.setFieldError(event.target.name, undefined);
                                                            if (
                                                                Number(event.target.value) <= 1000000 &&
                                                                Number(event.target.value) > 0
                                                            )
                                                                formik.setFieldValue('volume', event.target.value);
                                                        }}
                                                        value={formik?.values?.volume || ''}
                                                        disabled={formik?.values?.poNumber ? false : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">
                                                    Weight<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <input
                                                        placeholder="Enter Weight"
                                                        type="number"
                                                        className="modal-input input-text"
                                                        id="weight"
                                                        name="weight"
                                                        onChange={(event) => {
                                                            formik.setFieldError(event.target.name, undefined);
                                                            if (
                                                                Number(event.target.value) <= 1000000 &&
                                                                Number(event.target.value) > 0
                                                            )
                                                                formik.setFieldValue('weight', event.target.value);
                                                        }}
                                                        value={formik?.values?.weight || ''}
                                                        disabled={formik?.values?.poNumber ? false : true}
                                                    ></input>
                                                </div>
                                            </div>

                                            <div className="form-submit-btn">
                                                <button
                                                    disabled={
                                                        formik.values.weight === '' ||
                                                        formik.values.volume === '' ||
                                                        formik.values.poNumber === '' ||
                                                        formik.values.quantity === '' ||
                                                        formik.values.packs === ''
                                                    }
                                                    className="app-btn app-btn-primary modal-btn form-btn"
                                                    title="Submit"
                                                    form="my-form"
                                                    type="submit"
                                                >
                                                    <span className="button-text footer-button-text">Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <br />
                                <div className="grid-container">
                                    <div className="grid-scroll-content">
                                        <LayoutWithColumns
                                            content={'containerPacking'}
                                            customProp={bdPk}
                                            style="modal"
                                            overrideDiv="grid2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Close"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
