import React, { useState, useEffect } from 'react';

import { useAppDispatch } from '../../../../hooks';
import { getFailedReasonImage } from '../../../../services/apis/receipts/receiptHeaderApi';
import { downloadPoLinesImages } from '../../../../store/reducers/receipts/receiptHeaderReducer';
import ImageView from './ImageView';
import './view-compilance-modal.scss';
import imageLoader from '../../../../assets/images/image-loader.svg';
interface ViewComplianceProps {
    viewComplianceModalVisibility: boolean;
    setViewComplianceModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
}

const Index: React.FC<ViewComplianceProps> = ({
    viewComplianceModalVisibility,
    setViewComplianceModalVisibility,
    data,
}) => {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [complianceImages, setComplianceImages] = useState<any>([]);
    const dispatch = useAppDispatch();
    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };
    const generateUrl = (base64Image: string, fileName: string) => {
        const [extension] = fileName.split('.').reverse();
        return `data:image/${extension};base64,${base64Image}`;
    };

    useEffect(() => {
        if (viewComplianceModalVisibility && data?.complianceImages?.length) {
            setComplianceImages(data?.complianceImages);
            getFailedReasonImage({
                receiptSubPoLineId: data?.rsId,
                type: 'COMPLIANCE_CHECK_IMAGE',
            })
                .then((res: any) => {
                    const urls = res?.data;
                    const images = JSON.parse(JSON.stringify(data?.complianceImages));
                    const imageWithUrl = images?.map((response: any) => ({
                        ...response,
                        fileUrl: generateUrl(
                            urls?.find((elem: any) => elem?.fileId === response?.fileId)?.base64,
                            response?.fileName
                        ),
                    }));
                    setComplianceImages(imageWithUrl);
                })
                .catch((err: any) => console.log(err, 'err'));
        } else {
            setComplianceImages([]);
            setCurrentImageIndex(0);
        }
    }, [viewComplianceModalVisibility]);
    return (
        <>
            {viewComplianceModalVisibility ? (
                <div className="app-modal">
                    <div className="modal-content-holder medium-popup view-compliance-modal">
                        <div className="modal-header">
                            <div className="title">Compliance Check</div>
                            <button
                                onClick={() => setViewComplianceModalVisibility(false)}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Compliance Check Status</h6>
                                        <div className="description-type-1">
                                            {data?.complianceCheckStatus === 'pass' ? 'Passed' : 'Failed'}
                                        </div>
                                    </div>
                                    {data?.complianceCheckStatus === 'failed' && (
                                        <>
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Reason</h6>
                                                <div className="description-type-1">
                                                    {data?.complianceCheckFailReason}
                                                </div>
                                            </div>
                                            {complianceImages?.length > 0 && (
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Images</h6>
                                                    <div className="description-type-1">
                                                        {complianceImages?.map((res: any, index: number) => (
                                                            <div key={index} className="attachment-items-holder">
                                                                <div className="attachment-icon-holder">
                                                                    <div className="image-holder">
                                                                        <a href="#">
                                                                            <img
                                                                                onClick={() => {
                                                                                    setCurrentImageIndex(index);
                                                                                    setShowImageViewer(true);
                                                                                }}
                                                                                className="logo-icon"
                                                                                src={
                                                                                    res?.fileUrl
                                                                                        ? res?.fileUrl
                                                                                        : imageLoader
                                                                                }
                                                                                alt={res?.fileName}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="filename-folder">
                                                                        <div
                                                                            className="description-type-1 file-uploaded"
                                                                            title="File"
                                                                        >
                                                                            {res?.fileName?.length > 20
                                                                                ? `${res?.fileName?.slice(0, 20)}...`
                                                                                : res?.fileName}
                                                                        </div>
                                                                        <div className="heading-type-1">
                                                                            {getFileSizeOfUploadedFile(res?.fileSize)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="btn-holder">
                                                        <button
                                                            onClick={() =>
                                                                dispatch(
                                                                    downloadPoLinesImages({
                                                                        receiptSubPoLineId: data?.rsId,
                                                                        type: 'COMPLIANCE_CHECK_IMAGE',
                                                                    })
                                                                )
                                                            }
                                                            className="app-btn app-btn-secondary icon-button  add-product-btn "
                                                            title="Download All"
                                                        >
                                                            <svg className="svg-icon add-btn-icon">
                                                                <use xlinkHref="#downloadIcon"></use>
                                                                <title>Download</title>
                                                            </svg>
                                                            <span className="button-text footer-button-text">
                                                                Download All
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ImageView
                        imageViewerModalVisibility={showImageViewer}
                        setImageViewerModalVisibility={setShowImageViewer}
                        originalData={complianceImages}
                        currentImageIndex={currentImageIndex}
                        setCurrentImageIndex={setCurrentImageIndex}
                    />
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
