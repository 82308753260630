import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Position, success, error } from 'src/utils/toast';
import {
    activityFeedSelectedList,
    activityFeedSettingSave,
} from 'src/services/apis/dashboard/dashboardActivityFeedApi';
import { useUserProfile } from 'src/hooks/useUserProfile';
// import {
//     activityListCountFetch,
//     activityListCountFetchBkg,
//     activityListFetching,
// } from 'src/store/reducers/dashboard/dashboardActivityFeed';
import { RootState } from 'src/store/reducers';
import CancelModal from './cancelModal';
// import { getOrganisationData } from 'src/utils';
import '../../../pages/Dashboard/QuickLinks/quickLinks.scss';
import ClickAwayListener from 'react-click-away-listener';
import { setActivitySettingsMotion } from 'src/store/reducers/dashboard/dashoboardSearchReducer';

interface BioProps {
    activities?: boolean;
    setActivities?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    modalHandle: any;
    modalOpen: boolean;
    data: any[];
}

const Index: React.FC<BioProps> = ({ setActivities, data, modalHandle, modalOpen }): ReactElement => {
    const dispatch = useDispatch();
    const { activitySettingsBoolean } = useSelector((state: RootState) => state.dashboarddata);

    const { profileType, userName } = useUserProfile();
    const [selected, setSelected] = useState<any>({});
    const [selectedInitial, setSelectedInitial] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [cancelModalView, setCancelModalView] = useState(false);

    // const listOfUserOrganisations = getOrganisationData();
    function handleChangeSetting(id: string) {
        const selectedItems = { ...selected, [id]: !(selected?.[id] ?? false) };
        setSelected(selectedItems);
    }

    const buttonValidation = () => {
        let flag = false;
        const filteredArray1: any = [];
        const filteredArray2: any = [];

        Object.keys(selected).forEach(function (key) {
            if (selected[key] !== false) {
                filteredArray1.push(key);
            }
        });
        Object.keys(selectedInitial).forEach(function (key) {
            if (selectedInitial[key] !== false) {
                filteredArray2.push(key);
            }
        });

        if (JSON.stringify(filteredArray1) !== JSON.stringify(filteredArray2)) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    };

    const onCancel = () => {
        setCancelModalView(true);
    };

    async function handleSave() {
        setLoading(true);
        const response = await activityFeedSettingSave({
            profileType,
            userName,
            selected: {
                isNewOrdersCreated: selected.New_Orders_Created ?? false,
                isRecentDeparture: selected.Recent_Departures ?? false,
                isRecentArrival: selected.Recent_Arrival ?? false,
                isDelayedDeparture: selected.Delayed_Departure ?? false,
                isDelayedArrival: selected.Delayed_Arrival ?? false,
                isDepartingSoon: selected.Departing_Soon ?? false,
                isArrivalSoon: selected.Arriving_Soon ?? false,
                isVesselChange: selected.Vessel_Change ?? false,
            },
        });
        if (response?.data?.success) {
            success(`Activity Settings Updated`, Position.TOP_RIGHT);
            dispatch(setActivitySettingsMotion(!activitySettingsBoolean));
            setSelected(selectedInitial);
            modalHandle(false);
            setCancelModalView(false);
        } else {
            error(response?.data?.data, Position.TOP_RIGHT);
            modalHandle(false);
            setCancelModalView(false);
            setSelected({});
        }
        setLoading(false);
    }

    async function fetchSelectedList() {
        setLoading(true);
        const response = await activityFeedSelectedList({ profileType, userName });
        if (response?.data?.success) {
            const selectedList =
                response?.data?.data?.split(',').reduce((accumulator: any, value: any) => {
                    return { ...accumulator, [value]: true };
                }, {}) ?? {};
            setSelected(selectedList);
            setSelectedInitial(selectedList);
        } else {
            setSelectedInitial({});
            setSelected({});
        }
        setLoading(false);
    }

    useEffect(() => {
        if (profileType && userName) {
            // fetchSelectedList();
        }
    }, [modalOpen, profileType, userName]);

    useEffect(() => {
        const filteredArray: any = [];
        Object.keys(selected).forEach(function (key) {
            if (selected[key] !== false) {
                filteredArray.push(key);
            }
        });
        const includesKey = Object.keys(selected).some((key) => key.includes('No settings found')); //to check if there are no filteres selected

        if (filteredArray.length !== 0 && !includesKey) {
            setActivities && setActivities(true);
        } else {
            setActivities && setActivities(false);
        }
    }, [selected]);

    const onUnselectAll = () => {
        const activityFeedType = { ...selected };
        Object.keys(selected).forEach((feed: any) => (activityFeedType[feed] = false));
        setSelected(activityFeedType);
    };

    const onSelectAll = () => {
        const activityFeedType: any = {};
        data.forEach(({ id }: any) => (activityFeedType[id] = true));
        //console.log(activityFeedType, 'lll');
        // Object.keys(selected).forEach((feed: any) => (activityFeedType[feed] = true));
        setSelected(activityFeedType);
    };

    const showUnselectBtn = () => {
        const selectList = [];
        const activityFeedType = { ...selected };
        Object.keys(selected).forEach((feed: any) => {
            if (activityFeedType[feed] === true) {
                selectList.unshift(feed);
            }
        });
        const includesKey = Object.keys(selected).some((key) => key.includes('No settings found')); //to check if there are no filteres selected

        if (selectList?.length && !includesKey) {
            return true;
        } else {
            return false;
        }
    };

    const showSelectBtn = () => {
        const selectList = [];
        const activityFeedType = { ...selected };
        data.forEach(({ id }: any) => {
            if (!activityFeedType[id]) {
                selectList.unshift(id);
            }
        });
        if (selectList?.length) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    modalOpen ? (buttonValidation() ? onCancel() : modalHandle(false)) : modalHandle(false);
                }}
            >
                <div className={`side-bar lg quick-list-modal ${modalOpen ? 'active' : ''}`}>
                    <button
                        className="app-btn text-close-btn"
                        onClick={() => {
                            if (buttonValidation()) {
                                onCancel();
                            } else {
                                modalHandle(false);
                            }
                        }}
                        title="Close"
                    >
                        <svg className="svg-icon text-close-icon">
                            <use href="#closeIcon">
                                <title>Close</title>
                            </use>
                        </svg>
                    </button>
                    <h2 className="page-heading">Activity Feed</h2>

                    <div className="sidebar-reset-wrapper">
                        <p className="page-caption-ql">Select the activities to show in Activity Feed</p>
                        <div className="reset-link">
                            {showSelectBtn() && (
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    title="Select all"
                                    onClick={() => {
                                        onSelectAll();
                                    }}
                                >
                                    Select all
                                </a>
                            )}

                            {showUnselectBtn() && (
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    title="Unselect all"
                                    onClick={() => {
                                        onUnselectAll();
                                    }}
                                >
                                    Unselect all
                                </a>
                            )}

                            {/* <a
                                href="javascript:void(0)"
                                className="clear-filter-link"
                                title="Reset"
                                // onClick={() => {
                                //     reset();
                                // }}
                            >
                                Reset
                            </a> */}
                        </div>
                    </div>
                    <div className="page-body details-box custom quick-list">
                        <div className="card-detail-col col-1">
                            {data.map((element, index) => (
                                <div className="checkbox-item" key={element.id}>
                                    <label className="app-check-wrapper" key={`checkboxlist__${index}`}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            onClick={() => handleChangeSetting(element.id)}
                                            checked={selected[element.id]}
                                        />
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use xlinkHref="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">{element.name}</div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="side-page-footer">
                        <button
                            className="app-btn app-btn-secondary "
                            title="Cancel"
                            onClick={() => {
                                if (buttonValidation()) {
                                    onCancel();
                                } else {
                                    modalHandle(false);
                                }
                            }}
                        >
                            <span className="button-text footer-button-text">Cancel</span>
                        </button>
                        <button
                            className="app-btn app-btn-primary"
                            title="Save"
                            onClick={handleSave}
                            disabled={loading || !buttonValidation()}
                        >
                            <span className="button-text footer-button-text">Save</span>
                        </button>
                    </div>
                </div>
            </ClickAwayListener>
            <div className="sidebar-backdrop"></div>
            {cancelModalView && (
                <CancelModal
                    onCancel={() => {
                        setCancelModalView(false);
                    }}
                    onConfirm={() => {
                        setSelected(selectedInitial);
                        modalHandle(false);
                        setCancelModalView(false);
                    }}
                />
            )}
        </>
    );
};
export default Index;
