// fetchShippingData
import { createSlice } from '@reduxjs/toolkit';
import { shippingBasicDetails, getShippingDetails } from '../../services/apis/shippingApi';

interface ShippingDetailsState {
    shippingBasicDetails?: shippingBasicDetails;
}

const initialState: ShippingDetailsState = {
    // eslint-disable-next-line no-undefined
    shippingBasicDetails: undefined,
};

export const dataSlice = createSlice({
    name: 'shippingDetails',
    initialState,
    reducers: {
        fetchShippingDetails: (state, action) => {
            state.shippingBasicDetails = action.payload.shippingDetails;
        },
    },
});

export const { fetchShippingDetails } = dataSlice.actions;
export default dataSlice.reducer;
