const DropDown = (props: any) => {
    // const ref = useDetectClickOutside({ onTriggered: closeToggle });
    let customStatus = props.tabName === 'vendors' ? 'DRAFT' : 'INACTIVE';
    let customLabel = props.tabName === 'vendors' ? 'Draft' : 'Inactive';
    if (props?.customStatus && props?.customLabel) {
        customStatus = props.customStatus;
        customLabel = props.customLabel;
    }
    if (props.data) {
        return (
            <div className="filter-menu" style={{ width: '130px' }}>
                {props.data?.map((res: any) => (
                    <div className="filter-item">
                        <label
                            onClick={(e) => {
                                e.stopPropagation();
                                if (props.tabName === 'vendors') {
                                    props.currentFilter === 'SUBMITTED'
                                        ? props.closeToggle(null)
                                        : props.closeToggle('SUBMITTED');
                                } else {
                                    props.currentFilter === res ? props.closeToggle(null) : props.closeToggle(res);
                                }
                            }}
                            className="app-check-wrapper"
                        >
                            <input
                                type="checkbox"
                                defaultChecked={false}
                                className="checkbox-input"
                                checked={props.currentFilter === res || props.currentFilter === 'SUBMITTED'}
                            />
                            <div className="checkmark">
                                <svg className="svg-icon tick-icon">
                                    <use xlinkHref="#tickIcon">
                                        <title>check mark</title>
                                    </use>
                                </svg>
                            </div>
                            <div className="checkbox-label">{props.tabName === 'vendors' ? 'Submitted' : res}</div>
                        </label>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className="filter-menu" style={{ width: '130px' }}>
                <div className="filter-item">
                    <label
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.tabName === 'vendors') {
                                props.currentFilter === 'SUBMITTED'
                                    ? props.closeToggle(null)
                                    : props.closeToggle('SUBMITTED');
                            } else {
                                props.currentFilter === 'ACTIVE'
                                    ? props.closeToggle(null)
                                    : props.closeToggle('ACTIVE');
                            }
                        }}
                        className="app-check-wrapper"
                    >
                        <input
                            type="checkbox"
                            defaultChecked={false}
                            className="checkbox-input"
                            checked={props.currentFilter === 'ACTIVE' || props.currentFilter === 'SUBMITTED'}
                        />
                        <div className="checkmark">
                            <svg className="svg-icon tick-icon">
                                <use xlinkHref="#tickIcon">
                                    <title>check mark</title>
                                </use>
                            </svg>
                        </div>
                        <div className="checkbox-label">{props.tabName === 'vendors' ? 'Submitted' : 'Active'}</div>
                    </label>
                </div>
                <div className="filter-item">
                    <label
                        onClick={(e) => {
                            e.stopPropagation();
                            props.currentFilter === customStatus
                                ? props.closeToggle(null)
                                : props.closeToggle(customStatus);
                        }}
                        className="app-check-wrapper"
                    >
                        <input
                            defaultChecked={false}
                            type="checkbox"
                            className="checkbox-input"
                            checked={props.currentFilter === customStatus}
                        />
                        <div className="checkmark">
                            <svg className="svg-icon tick-icon">
                                <use xlinkHref="#tickIcon">
                                    <title>check mark</title>
                                </use>
                            </svg>
                        </div>
                        <div className="checkbox-label">{customLabel}</div>
                    </label>
                </div>
            </div>
        );
    }
};

export default DropDown;
