/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';

export interface ShipmentDetailsDataType {
    PS?: string
    _parentShipmentCount: number
    _totalParents: number
    _consolId?: string, 
    _shipmentId?: string, 
    _declarationId?: string,
    _consignor?: string, 
    _consignee?: string, 
    _company?: string,
    _sendingForwarder?: string,
    _recievingForwarder?: string,
    _shippingLine?: string,
    _orderNumbers?: string,
    _SCAC?: string,
    _containerNumbers?: string,
    _wayBillNumber?: string,
    _isParent?: boolean,
    _childShipments?: number,
    _parentShipmentId?: string,
    direction?: codeDescription,
    agg_TEU?: number,
    agg_Triangle?: boolean,
    containerBreakupCollection?: object,
    STA?: any,
    ETA?: any, 
    ATA?: any,
    STD?: any, 
    ETD?: any, 
    ATD?: any,
    dataContext? : dataContext,
    actualChargeable? : number,
    additionalTerms? : string,
    agentsReference? : string,
    aviationSecurityInspectionType? : codeDescription,
    awbServiceLevel? : codeDescription,
    bookingConfirmationReference? : string,
    branch?: codeName,
    carrierAccountBillingType? : string,
    carrierBookingLatestDate? : Date,
    carrierBookingLatestStatus? : codeDescription,
    carrierBookingOffice?: codeName,
    carrierContractnumber?: string, 
    carrierCorrectedChargeable?: number, 
    carrierCorrectedVolume?: number, 
    carrierCorrectedVolumeUnit?: codeDescription
    carrierCorrectedWeight?: number, 
    carrierCorrectedWeightUnit?: codeDescription,
    carrierServiceLevel?: serviceLevel,
    cartageWaybillNumber?: string, 
    cfsReference?: string, 
    chargeableRate? : number,
    coLoadBookingConfirmationReference?: string, 
    coLoadMasterBillnumber?: string, 
    commercialInfo?: commercialInfo,
    communityTransitStatus?: codeDescription, 
    consigneeBussinessnumber?: string, 
    ConsigneeIdentifier?: string, 
    ConsignorIdentifier?: string, 
    consolCosts? : any, //TODO
    consolidatedCargoStatus?: string 
    containerCount? : number,
    containerMode? : codeDescription,
    countryOfSupply?: codeName,
    customsBroker?: codeName,
    customsContainerMode?: codeDescription,
    customsDischargePort?: codeDescription,
    customsLoadPort?: codeDescription,
    customsOffice?: codeDescription,
    customsProfileIdentifier?: codeValue,
    customsValuationPort?: codeDescription,
    declarantType?: codeDescription,
    defermentAccountnumber?: string, 
    deliveryMode?: codeDescription,
    documentedChargeable?: number, 
    documentedVolume?: number, 
    documentedWeight?: number,
    eftMode?: number,
    entryStatus?: codeDescription,
    exportGoodsType?: codeDescription,
    facilityJobType?: codeDescription,
    firstBuyerContact?: string,
    folio?: string,
    freightRate?: number, 
    freightRateCurrency?: codeDescription,
    goodsDescription?: string,
    goodsOrigin?: codeDescription,
    goodsValue?: number,
    goodsValueCurrency?: codeDescription,
    hasProhibitedPackaging?: boolean,
    hblawbChargesDisplay?: codeDescription,
    hblContainerPackModeOverride?: string,
    houseBillOfLadingType?: codeDescription,
    insuranceValue?:number,
    insuranceValueCurrency?: codeDescription,
    interimReceiptnumber?:string,
    isAuthorizedToLeave?:boolean,
    isBooking?:boolean,
    isBuyersConsol?:boolean,
    isCancelled?:boolean,
    isCFSRegistered?:boolean,
    isDirectBooking?:boolean,
    isFinalManifest?:boolean,
    isForwardRegistered?:boolean,
    isHazardous?:boolean,
    isHighRisk?:boolean,
    isLastMileDeliverySelfBooked?:boolean,
    isNeutralMaster?:codeValue,
    isPersonalEffects?:boolean,
    isShipping?:boolean,
    isSignatureRequired?:boolean,
    isSplitShipment?:boolean,
    isTracked?:boolean,
    jobCosting?: any, //TODO
    lloydsIMO?: string,
    localTransportEquipmentNeeded?: codeDescription,
    localTransportJobType?: codeDescription,
    locationAtClearance?: codeDescription,
    manifestedChargeable?: number,
    manifestedVolume?: number,
    manifestedWeight?: number,
    manifestnumber?: number,
    marksAndnumbers?: number,
    maximumAllowablePackageHeight?: number,
    maximumAllowablePackageLength?: number,
    maximumAllowablePackageLengthUnit?: codeDescription,
    maximumAllowablePackageWidth?: number,
    mergeBy?: codeDescription,
    messageStatus?: codeDescription,
    messageSubType?: codeDescription,
    messageType?: codeDescription,
    messagingApplicationCode?: codeDescription,
    noCopyBills?: number,
    noOriginalBills?: number,
    operationalStatus?: codeDescription,
    outerPacks?: number,
    outerPacksPackageType?: codeDescription,
    ownerRef?: string,
    packingOrder?:string,
    paidBy?: codeDescription,
    paymentMethod?: codeDescription,
    pickupMode?: codeDescription,
    placeOfDelivery?: codeDescription,
    placeOfIssue?: codeDescription,
    placeOfReceipt?: codeDescription,
    portFirstForeign?: codeDescription,
    portLastForeign?: codeDescription,
    portMessaging?: any, //TODO
    portOfDestination?: codeDescription,
    portOfDischarge?: codeDescription,
    portOfFirstArrival?: codeDescription,
    portOfLoading?: codeDescription,
    portOfOrigin?: codeDescription,
    quotenumber?: string,
    ratingTransportMode?: codeDescription,
    receivingForwarderHandlingType?: codeDescription,
    releaseType?: codeDescription,
    requiredTemperatureMaximum?: number,
    requiredTemperatureMinimum?: number,
    requiredTemperatureUnit?: codeDescription,
    requiresRefrigeration?:boolean,
    requiresTemperatureControl?:boolean,
    screeningStatus?:codeDescription,
    sealInfo?: {
        quantity?: number,
        type?: string
    },
    secondBuyerContact?: string,
    sendingForwarderHandlingType?: codeDescription,
    serviceLevel?:serviceLevel,
    shipmentIncoTerm?: codeDescription,
    shipmentStatus?: codeDescription,
    shipmentSubType?: codeDescription,
    shipmentType?: codeDescription,
    shippedOnBoard?: codeDescription,
    shipperCODAmount?: number,
    shipperCODPayMethod?: codeDescription,
    slotDateTime?: Date
    slotReference?: string,
    subLocationAtClearance?: codeDescription,
    totalNoOfPacks?: number,
    totalNoOfPacksDecimal?: number,
    totalNoOfPacksPackageType?: codeDescription,
    totalNoOfPieces?: number,
    totalNoOfPiecesLanded?: number,
    totalPreallocatedChargeable?: number,
    totalPreallocatedVolume?: number,
    totalPreallocatedVolumeUnit?: codeDescription,
    totalPreallocatedWeight?: number,
    totalPreallocatedWeightUnit?: codeDescription,
    totalVolume?: number,
    totalVolumeUnit?: codeDescription,
    totalWeight?: number,
    totalWeightUnit?: codeDescription,
    transhipToOtherCFS?: boolean,
    transportBookingDirection?: codeDescription,
    transportMode?: codeDescription,
    transportNationality?: codeName,
    uniqueConsignmentReference?: string,
    vehicleRun?: any, //TODO
    vendorIdentifier?: string,
    vesselCountryOfRegistration?: codeName,
    vesselName?: string,
    voyageFlightNo?: string,
    warehouseLocation?: string,
    warehouseReleaseStatus?: codeDescription,
    wayBillNumber?: string,
    wayBillType?: codeDescription,
    carrierAccount?: carrierAccount
    carrierDocumentsOverride?: any, //TODO

    localProcessing?: localProcessing,
    order?: any, //TODO

    //collections
    addInfoCollection?: {addInfo?: addInfo[]},
    addInfoGroupCollection?: {addInfoGroup?: addInfoGroup[]}
    additionalBillCollection?: any, //TODO
    additionalCarrierAccountCollection?: {additionalCarrierAccount?: additionalCarrierAccount[]},
    additionalReferenceCollection?: {additionalReference?: additionalReference[]},

    containerCollection?: {container?: container[]},
    customizedFieldCollection?: {customizedField?: customizedField[]},
    customsReferenceCollection?: {customsReference?: customsReference[]},
    dateCollection?: {date?: date[]},
    entryHeaderCollection?: {entryHeader?: any}
    entryInstructionCollection?: any, //TODO
    entryNumberCollection?: {entrynumber?: entryNumber[]},
    gatewayInfoCollection?: any, //TODO
    guaranteeCollection?: any, //TODO
    inBondMoveHeaderCollection?: any, //TODO
    instructionCollection?: any, //TODO
    milestoneCollection?: {milestone?: milestone[]},
    noteCollection?: {note?: note[]},
    organizationAddressCollection?: {organizationAddress?: organizationAddress[]},
    packingLineCollection?: {packingLine?: packingLine[]},
    parentShipmentCollection?: {parentShipment?: ShipmentDetailsDataType[]},
    paymentHandlingInstructionCollection?: {paymentHandlingInstruction?: paymentHandlingInstruction[]}, //TODO
    portReferenceCollection?: {portReference?: portReference[]},
    postCarriageShipmentCollection?: {postCarriageShipment?: ShipmentDetailsDataType[]},
    preallocatedUNDGCollection?: any, //TODO
    preCarriageShipmentCollection?: {preCarriageShipment?: ShipmentDetailsDataType[]},
    relatedShipmentCollection?: {relatedShipment?: ShipmentDetailsDataType[]},
    specialHandlingCollection?: codeDescription,
    subShipmentCollection?: {subShipment?: ShipmentDetailsDataType[]}
    transportLegCollection?: {transportLeg?: transportLeg[]}

}

interface paymentHandlingInstruction{
    category?: codeDescription,
    paymentMethod?: codeDescription,
}

interface dataContext{
    dataSourceCollection? : {dataSource?: dataSource[]},
    company: company,
    dataProvider? : string, 
    eventBranch? : string, 
    eventDepartment? : string, 
    eventReference? : string, 
    enterpriseID? : string,
    serverID? : string,
    triggerCount? : string,
    triggerDate? : Date,
    triggerDescription? : string,
    triggerReference? : string,
    triggerType? : string,
    dataTargetCollection? : {dataTarget?: dataTarget[]},
    recipientRoleCollection? : {recipientRole?: recipientRole[]}
}

interface recipientRole{
    code?: string,
    description?: string, 
    serviceCode?: string, 
    serviceDescription?: string
}

interface dataSource{
    type?: string,
    key?: string, 
}

interface dataTarget{
    type?: string,
    key?: string, 
    owner?: organizationAddress
}

interface company{
    code? : string, 
    country? : {
        code? : string,
        name? : string
    }, 
    name? : string
}

interface codeDescription{
    code? : any, 
    description? : string,
    name?: string
}

interface portReference{
    reference?: string, 
    type?: codeDescription,
    country?: codeName,
    status?: codeDescription
}

interface codeName{
    code? : string, 
    name? : string, 
}

interface codeValue{
    code?: string, 
    value?: any
}

interface serviceLevel{
    code?: string, 
    carrierChargeCode?: string, 
    carrierProductCode?: string, 
    carrierProfileId? : string, 
    carrierServiceCode?: string
}

interface commercialInfo{
    name?:string, 
    dateOfLandedCostProcessing?: Date,
    addInfoCollection?: {addInfo?: addInfo[]}, 
    addInfoGroupCollection?: {addInfoGroup?: addInfoGroup[]},
    commercialChargeCollection?: {commercialCharge?: commercialCharge[]},
    commercialInvoiceCollection?: {commercialInvoice?: commercialInvoice[]},
    customsReferenceCollection?: {customsReference?: customsReference[]},
    subGroupCollection?: {commercialInfo?: commercialInfo[]},
    transportLogisticsCostCollection?: {transportLogisticsCost?: transportLogisticsCost[]}

}

interface commercialCharge{
    chargeType?: codeDescription, 
    adjustedCharge?: boolean,
    agreedExchangeRate?: number,
    amount?: number,
    apportionmentType?: codeDescription, 
    currency?: codeDescription,
    distributeBy?: codeDescription, 
    exchangeRateType?: codeDescription, 
    isApportionedCharge?: boolean,
    isDutiable?: boolean,
    isGSTApplicable?: boolean,
    isIncludedInITOT?: boolean,
    isNotIncludedInInvoice?: boolean,
    isStatisticalValueApplicable?: boolean,
    percentageOfLinePrice?: number,
    prepaidCollect?: codeDescription, 
}

interface commercialInvoice{
    invoiceNumber?: string,
    additionalTerms?: string,
    agreedExchangeRate?: number, 
    billNumber?: string, 
    billType?: codeDescription,
    buyer?: organizationAddress,
    exchangeRateType?: codeDescription, 
    incoTerm?: codeDescription, 
    invoiceAmount?: number, 
    invoiceCurrency?: codeDescription, 
    invoiceDate?: Date,
    landedCostExchangeRate?: number, 
    marksAndnumbers?: string,
    messageStatus?: codeDescription, 
    netWeight?: number, 
    netWeightUQ?: codeDescription,
    noOfPacks?: number, 
    paymentAmount?: number, 
    paymentDate?: Date,
    paymentExchangeRate?: number, 
    paymentNumber?: string,
    relatedIndicator?: codeDescription, 
    supplier?: organizationAddress,
    valuationCode?: codeDescription, 
    valuationDateOverride?: Date,
    volume?: number, 
    volumeUnit?: codeDescription,
    weight?: number, 
    weightUnit?: codeDescription,
    addInfoCollection?: {addInfo?: addInfo[]},
    addInfoGroupCollection?: {addInfoGroup?: addInfoGroup[]},
    commercialChargeCollection?: {commercialCharge?: commercialCharge[]},
    commercialInvoiceLineCollection?: {commercialInvoiceLine?: commercialInvoiceLine[]}
}

interface commercialInvoiceLine{
    lineNo?: number, 
    bondedWarehouseQuantity?: number,
    bondedWarehouseQuantityUnit?: codeDescription,
    brandName?: string,
    classificationCode?: string,
    classUsageComment?: string,
    classUsageCommentStaff?: codeName,
    commodity?: codeDescription,
    concessionOrder?: string,
    containerMode?: codeDescription,
    containernumber?: string,
    countryOfExport?: codeName,
    countryOfOrigin?: codeName,
    customsQuantity?: number,
    customsQuantityUnit?: codeDescription,
    customsSecondQuantity?: number,
    customsSecondQuantityUnit?: codeDescription,
    customsThirdQuantity?: number,
    customsThirdQuantityUnit?: codeDescription,
    customsValue?: number,
    dataImportMatchingKey?: string,
    description?: string,
    detailedDescription?: string,
    entryInstructionLink?: number,
    entryLinenumber?: number,
    entrynumber?: string,
    harmonisedCode?: string,
    hazardousMaterial?: any, //TODO
    invoiceQuantity?: number,
    invoiceQuantityUnit?: codeDescription,
    landedCostDetail?: landedCostDetail,
    linePrice?: number,
    link?: number,
    localDescription?: string, 
    mode?: string, 
    netWeight?: number, 
    netWeightUnit?: codeDescription,
    orderLineLink?: number, 
    ordernumber?: string, 
    parentLineNo?: number, 
    partNo?: string, 
    previousEntryLinenumber?: number, 
    previousEntrynumber?: string, 
    primaryPreference?: string, 
    procedure?: string, 
    relatedIndicator?: codeDescription,
    secondaryPreference?: string, 
    stateOfOrigin?: codeName,
    taxType?: codeDescription,
    unitPrice?: number,
    valuationCode?: codeDescription,
    valuationMarkup?: number,
    volume?: number, 
    volumeUnit?: codeDescription,
    weight?: number, 
    weightUnit?: codeDescription
    addInfoCollectiom?: {addInfo?: addInfo[]},
    additionalLineTariffDetailCollection?: {additionalLineTariffDetail?: additionalLineTariffDetail[]}
    commercialChargeCollection?: {commercialCharge?: commercialCharge[]},
    customizedFieldCollection?: {customizedField?: customizedField[]},
    customsReferenceCollection?: {customsReference?: customsReference[]},
    entryReferenceCollection?: {entryReference?: entryReference[]}
    organizationAddressCollection?: {organizationAddress?: organizationAddress[]},
    taxOrFeeCollection?: {taxOrFee?: taxOrFee[]},
    transportLogisticsCostCollection?: {transportLogisticsCost?: transportLogisticsCost[]}
}


interface taxOrFee{
    chargeCode?: chargeCode,
    chargeDescription?: string, 
    costAmount?: number, 
    costCurrency?: codeDescription,
    distributeCostBy?: codeDescription,
    landedCostGroup?: codeDescription,
    serviceExRate?: number
}
interface transportLogisticsCost{
    chargeCode?: chargeCode, 
    chargeDescription?: string, 
    costAmount?: number, 
    costCurrency?: codeDescription,
    distributeCostBy?: codeDescription,
    landedCostGroup?: codeDescription,
    serviceExRate?: number
}

interface chargeCode{
    code?: string, 
    chargeType?: codeDescription,
    class?: codeDescription, 
    description?: string
}

interface entryReference{
    linenumber?: number, 
    reference?: string, 
    type?: codeDescription
}

interface landedCostDetail{
    customsCostPerUnit?: number,
    goodsItemCostPerUnit?: number,
    markUp1?: number,
    markUp2?: number,
    markUp3?: number,
    transportAndLogisticsCostPerUnit?: number,
    landedLineCostItemCollection?: {landedLineCostItem?: landedLineCostItem[]},
}

interface additionalLineTariffDetail{
    customsQuantity?: number, 
    customsQuantityUnit?: codeDescription,
    tariff?: string, 
    type?: codeDescription,
    value?: number
}

interface landedLineCostItem{
    costType?: codeDescription,
    costAmount?: number
}

interface carrierAccount{
    accountnumber?: string,
    billToParty?: string,
    carrierAccountType?: string,
    depotId?: string,
    merchentnumber?: string
}

interface addInfo{
    key?: string,
    value?: string
}

interface localProcessing{
    arrivalCartageRef?: string,

    deliveryCartageAdvised?: Date,
    deliveryCartageCompleted?: Date,
    deliveryLabourCharge?: number,
    deliveryLabourTime?: Date,
    deliveryRequiredBy?: Date,
    deliveryRequiredFrom?: Date,
    deliveryTruckWaitCharge?: number,
    deliveryTruckWaitTime?: Date,
    demurrageOnDeliveryCharge?: number,
    demurrageOnDeliveryTime?: Date,
    demurrageOnPickupCharge?: number,
    demurrageOnPickupTime?: Date,
    estimatedDelivery?: Date,
    estimatedPickup?: Date,
    exportStatement?: codeDescription,
    fclAvailable?: Date,
    fclDeliveryDetentionCharge?: number,
    fclDeliveryDetentionDays?: number,
    fclDeliveryDetentionFreeDays?: number,
    fclDeliveryEquipmentNeeded?: codeDescription,
    fclPickupDetentionCharge?: number,
    fclPickupDetentionDays?: number,
    fclPickupDetentionFreeDays?: number,
    fclPickupEquipmentNeeded?: codeDescription,
    fclStorageCommences?: Date,
    hasProhibitedPackaging?: boolean,
    insuranceRequired?: boolean,
    isContingencyRelease?: boolean,
    lclAirStorageCharge?: number,
    lclAirStorageDaysOrHours?: number,
    lclAvailable?: Date,
    lclDatesOverrideConsol?: boolean,
    lclStorageCommences?: Date,
    pickupCartageAdvised?: Date,
    pickupCartageCompleted?: Date,
    pickupLabourCharge?: number,
    pickupLabourTime?: Date,
    pickupRequiredBy?: Date,
    pickupRequiredFrom?: Date,
    pickupTruckWaitCharge?: number,
    pickupTruckWaitTime?: Date,
    printOptionForPackagesOnAWB?: codeDescription,
    additionalServiceCollection?: {additionalService?: additionalService[]},
    orderNumberCollection?: {orderNumber?: orderNumber[]}
}

interface orderNumber{
    orderReference?: string,
    sequence?: number
}

interface addInfoGroup{
    type?: string,
    addInfoCollection?: {addInfo?: addInfo[]},
    addInfoGroupCollection?: {addInfoGroup?: addInfoGroup[]},
    customsReferenceCollection?: {customsReference?: customsReference[]},
    organizationAddressCollection?: {organizationAddress?: organizationAddress[]}
}

interface customsReference{
    type?: string,
    isOverridden?: boolean,
    order?: number,
    reference?: string,
    referencedEntityDescription?: string,
    subType?: codeDescription,
    dateCollection?: date
}

interface date{
    type?: string, 
    isEstimate?: boolean,
    value?: Date
}

interface organizationAddress{
    addressType?: string,
    additionalAddressInformation?: string,
    address1?: string,
    address2?: string,
    addressOverride: string,
    addressShortCode: string,
    city?: string,
    companyName?: string,
    contact?: string,
    country?: string,
    email?: string,
    fax?: string,
    govRegNum?: string,
    govRegNumType?: codeDescription,
    isResidential?: boolean,
    mobile?: string,
    organizationCode?: string,
    phone?: string,
    port?: codeDescription,
    postcode?: string,
    screeningStatus?: codeDescription,
    state?: string,
    suppressAddressValidationError?: boolean,
    universalNettingCode?: string,
    universalOfficeCode?: string,
    localAddressCollection?: organizationLocalAddress
    registrationNumberCollection?: {registrationNumber?: any[]}
}

interface organizationLocalAddress{
    address1?: string,
    address2?: string,
    city?: string, 
    companyName?: string,
    language?: codeDescription,
    postCode?: string,
    state?: string
}

interface additionalReference{
    type?: string,
    referencenumber?: string,
    contextInformation?: string,
    issueDate?: Date
}

interface additionalCarrierAccount{
    carrierAccount?: carrierAccount
}

interface container{
    airVentFlow?: number,
    airVentFlowRateUnit?: codeDescription,
    arrivalCarrierDetentionCost?: number,
    arrivalCarrierDetentionDays?: number,
    arrivalCartageAdvised?: Date,
    arrivalCartageComplete?: Date,
    arrivalCartageDemurrageCharge?: number,
    arrivalCartageDemurrageTime?: Date,
    arrivalCartageRef?: string,
    arrivalCTOStorageCost?: number,
    arrivalCTOStorageDays?: number,
    arrivalCTOStorageStartDate?: Date,
    arrivalDeliveryRequiredBy?: Date,
    arrivalEstimatedDelivery?: Date,
    arrivalPickupByRail?: boolean,
    arrivalSlotDateTime?: Date,
    arrivalSlotReference?: string,
    arrivalTruckWaitCost?: number,
    arrivalTruckWaitTime?: Date,
    carbonGasLevel?: number,
    carbonGasLevelUnit?: codeDescription,
    commodity?: codeDescription,
    containerCount?: number,
    containerDetentionCharge?: number,
    containerDetentionDays?: number,
    containerImportDORelease?: string,
    containerJobID?: string,
    containerNumber?: string,
    containerParkEmptyPickupGateOut?: Date,
    containerParkEmptyReturnGateIn?: Date,
    containerQuality?: codeDescription,
    containerStatus?: codeDescription,
    containerType? : containerType, 
    deliveryMode?: string,
    deliverySequence?: number,
    departureCartageAdvised?: Date,
    departureCartageComplete?: Date,
    departureCartageDemurrageCharge?: number,
    departureCartageDemurrageTime?: Date,
    departureCartageRef?: string,
    departureDeliveryByRail?: boolean,
    departureDockReceipt?: string,
    departureEstimatedPickup?: Date,
    departureSlotDateTime?: Date,
    departureSlotReference?: string,
    departureTruckWaitCost?: number,
    departureTruckWaitTime?: Date,
    dunnageWeight?: number,
    emptyReadyForReturn?: Date,
    emptyRequired?: Date,
    emptyReturnedBy?: Date,
    emptyReturnRef?: string,
    exportDepotCustomsReference?: string,
    fcl_lcl_air?: codeDescription,
    fclAvailable?: Date,
    fclHeldInTransitStaging?: boolean,
    fclOnBoardVessel?: Date,
    fclStorageArrivedUnderbond?: boolean,
    fclStorageCharge?: number,
    fclStorageCommences?: Date,
    fclStorageDays?: number,
    fclStorageModuleOnlyMaster?: string,
    fclStorageUnderbondCleared?: Date,
    fclUnloadFromVessel?: Date,
    fclWharfGateIn?: Date,
    fclWharfGateOut?: Date,
    gateInDate?: Date,
    gateOutDate?: Date,
    goodsDescription?: string,
    goodsValue?: number,
    goodsValueCurrency?: codeDescription,
    goodsWeight?: number,
    grossWeight?: number,
    grossWeightVerificationDateTime?: Date,
    grossWeightVerificationType?: codeDescription,
    harmonisedCode?: string,
    humidityPercent?: number,
    importDepotCustomsReference?: string,
    isCFSRegistered?: boolean,
    isChargeable?: boolean,
    isControlledAtmosphere?: boolean,
    isDamaged?: boolean,
    isEmptyContainer?: boolean,
    isGrossWeightOverridden?: boolean,
    isNonOperating?: boolean,
    isPalletised?: boolean,
    isSealOk?: boolean,
    isShipperOwned?: boolean,
    itemCount?: number,
    lclAvailable?: Date,
    lclStorageCommences?: Date,
    lclUnpack?: Date,
    lengthUnit?: codeDescription,
    link?: number,
    messageStatus?: codeDescription,
    nitrogenGasLevel?: number,
    nitrogenGasLevelUnit?: codeDescription,
    overhangBack?: number,
    overhangFront?: number,
    overhangHeight?: number,
    overhangLeft?: number,
    overhangRight?: number,
    overrideFCLAvailableStorage?: boolean,
    overrideLCLAvailableStorage?: boolean,
    oxygenGasLevel?: number,
    oxygenGasLevelUnit?: codeDescription,
    packDate?: Date,
    palletCount?: number,
    pivotBreak?: number,
    refrigGeneratorID?: string,
    releaseNum?: string,
    seal?: string,
    sealPartyType?: codeDescription,
    secondSeal?: string,
    secondSealPartyType?: codeDescription,
    setPointTemp?: number,
    setPointTempUnit?: string,
    stowagePosition?: string,
    tareWeight?: number,
    tempRecorderSerialNo?: string,
    thirdSeal?: string,
    thirdSealPartyType?: codeDescription,
    totalHeight?: number,
    totalLength?: number,
    totalWidth?: number,
    trainWagonNumber?: string,
    transportReference?: string,
    unpackGang?: string,
    unpackShed?: string,
    volumeCapacity?: number,
    volumeUnit?: codeDescription,
    weightCapacity?: number,
    weightUnit?: codeDescription,
    addInfoCollection?: {addInfo?: addInfo[]},
    addInfoGroupCollection?: {addInfoGroup?: addInfoGroup[]},
    additionalReferenceCollection?: {additionalReference?: additionalReference[]},
    additionalServiceCollection?: {additionalService?: additionalService[]},
    containerPenaltyCollection?: {containerPenalty?: containerPenalty[]},
    customizedFieldCollection?: {customizedField?: customizedField[]},
    customsReferenceCollection?: {customsReference?: customsReference[]},
    milestoneCollection?: {milestone?: milestone[]},
    organizationAddressCollection?: {organizationAddress?: organizationAddress[]},
    packingLineCollection?: {packingLine?: packingLine[]},
    transportLogisticsCostCollection?: {transportLogisticsCost?: transportLogisticsCost[]}
    undgCollection?: any // TODO
}

interface additionalService{
    serviceCode?: codeDescription,
    booked?: Date, 
    completed?: Date,
    contractor?: organizationAddress,
    duration?: Date, 
    location?: organizationAddress,
    measurementBasis?: string,
    references?: string, 
    serviceCount?: number,
    serviceNote?: string,
    serviceRate?: string,
    servicaRateCountry?: string
}

interface containerPenalty{
    creditor?: organizationAddress,
    creditorType?: codeDescription,
    currency?: codeDescription,
    duration?: Date, 
    freeTime?: Date, 
    location?: codeDescription,
    penaltyType?: codeDescription,
    perUnitCost?: number,
    timeUnit?: string,
    totalCode?: number
}

interface customizedField{
    dataType?: string, 
    key?: string, 
    value?: string
}

interface milestone{
    description?: string,
    eventCode?: string,
    sequence?: string, 
    actualDate?: Date,
    conditionReference?: string, 
    conditionType?: string, 
    estimatedDate?: Date
}

interface packingLine{
    barcode?: string,
    billnumber?: string,
    billType?: codeDescription,
    commodity?: codeDescription,
    containerLink?: number,
    containerNumber?: string,
    containerPackingOrder?: number,
    countryOfOrigin?: codeName,
    customsOuterPacks?: number,
    customsPackType?: codeDescription,
    detailedDescription?: string,
    dunnageWeight?: number,
    endItemNo?: number,
    entryType?: string,
    exemptionReason?: string,
    exportReferenceNumber?: string,
    goodsDescription?: string,
    harmonisedCode?: string,
    height?: number,
    importReferenceNumber?: string,
    inBondPackQty?: number,
    isFlammable?: boolean,
    isHVLVClearance?: boolean,
    isPerishable?: boolean,
    isPersonalEffects?: boolean,
    isSignatureRequired?: boolean,
    isTimber?: boolean,
    itemNo?: number,
    lastKnownCFSStatus?: codeDescription,
    lastKnownCFSStatusDate?: Date,
    length?: number,
    lengthUnit?: codeDescription,
    linePrice?: number,
    linePriceCurrency?: codeDescription,
    link?: number,
    loadingMeters?: number,
    manifestedVolume?: number,
    manifestedWeight?: number,
    marksAndNos?: string,
    mRN?: string,
    mRNComplete?: string,
    nmfc?: any,
    orderReference?: string,
    outturnComment?: string,
    outturnDamagedQty?: number,
    outturnDamagedReason?: codeDescription,
    outturnedHeight?: number,
    outturnedLength?: number,
    outturnedVolume?: number,
    outturnedWeight?: number,
    outturnedWidth?: number,
    outturnPillagedQty?: number,
    outturnQty?: number,
    packingLineID?: string,
    packQty?: number,
    packType?: codeDescription,
    portMessaging?: any, //TODO
    referenceNumber?: string,
    requiredTemperatureMaximum?: number,
    requiredTemperatureMinimum?: number,
    requiredTemperatureUnit?: codeDescription,
    requiresFumigationCertificate?: boolean,
    requiresTemperatureControl?: boolean,
    shippingSymbol?: string,
    status?: string,
    tareWeight?: number,
    transportReference?: string,
    vehicle?: vehicle,
    volume?: number,
    volumeUnit?: codeDescription,
    weight?: number,
    weightUnit?: codeDescription,
    width?: number
}

interface vehicle{
    color?: string, 
    make?: string, 
    model?: string, 
    numberOfDoors?: number,
    registration?: {
        number?: number
    },
    transmission?: codeDescription,
    vehicleType?: codeDescription,
    year?: number
}

interface entryHeader{
    type?: codeDescription,
    reference?: string,
    bondValidToDate?: Date,
    entryInstructionLink?: number,
    entryReleaseDate?: Date,
    entryStatus?: codeDescription,
    entrySubmittedDate?: Date, 
    messageStatus?: codeDescription,
    totalAmountPaid?: number,
    addInfoCollection?: {addInfo?: addInfo[]},
    addInfoGroupCollection?: {addInfoGroup?: addInfoGroup[]},
    customsReferenceCollection?: {customsReference?: customsReference[]},
    customsSupportingInformationCollection?: customsSupportingInformation[],
    entryHeaderChargeCollection?: {entryHeaderCharge?: entryHeaderCharge[]},
    entryLineCollection?: {entryLine?: entryLine[]},
    entryNumberCollection?: {entryNumber?: entryNumber[]},
    paymentInformationCollection?: {paymentInfo?: paymentInfo[]},
    relatedEntryHeaderCollection?: {entryHeader?: entryHeader[]}
}

interface paymentInfo{
    customsResponseReceived?: boolean,
    entryReleaseDate?: Date, 
    incomingPaymentResponsenumber?: string, 
    paymentAmount?: number, 
    paymentDate?: Date, 
    paymentParty?: codeDescription,
    paymentReference?: string, 
    paymentStatus?: codeDescription,
    remittanceAdviceReceived?: boolean,
    transactionType?: codeDescription
}

interface customsSupportingInformation{
    category?: codeDescription,
    country?: codeName,
    customsOffice?: codeDescription,
    dateOfExpiry?: Date,
    dateOfIssue?: Date,
    description?: string,
    lineNo?: number,
    procedure?: codeDescription,
    quantity?: number,
    quantity2?: number,
    referencenumber?: string,
    status?: codeDescription,
    subType?: codeDescription,
    tariff?: string,
    type?: codeDescription,
    unitOfQuantity?: codeDescription,
    unitOfQuantity2?: codeDescription,
    value?: number,
    valueCurrency?: codeDescription,
    referencenumberCollection?: {referencenumber?: reference[]}
}

interface reference{
    type?: string,
    referencenumber?: string
}

interface entryHeaderCharge{
    type?: string,
    amount?: string
}

interface entryLine{
    linenumber?: number,
    customsStatus?: codeDescription,
    customsValue?: number,
    description?: string, 
    dutyRateFlatAmount?: number,
    dutyRateFlatAmountUnit?: codeDescription,
    dutyRatePercent?: number,
    harmonisedCode?: string,
    addInfoCollection?: {addInfo?: addInfo[]},
    customsSupportingInformationCollection?: customsSupportingInformation[],
    entryHeaderChargeCollection?: {entryHeaderCharge?: entryHeaderCharge[]},
    entryLineChargeCollection?: entryLineCharge[]
}

interface entryLineCharge{
    type?: codeDescription,
    amount?: number, 
    baseValue?: number, 
    isLandedCostOnly?: boolean,
    methodOfCalculation?: codeDescription,
    methodOfPayment?: codeDescription,
    rate?: number,
    rateOverrideReason?: codeDescription
}

interface entryNumber{
    type?: codeDescription,
    entryNumber?: {
        type?: codeDescription,
        number?: string,
        entryIsSystemGenerated?: boolean,
        entryStatus?: codeDescription,
        expiryDate?: Date, 
        issueDate?: Date
    }
}

interface note{
    description?: string, 
    isCustomDescription?: boolean,
    noteText?: string, 
    noteContext?: codeDescription,
    visibility?: codeDescription,
    visibleCompany?: codeDescription
}

interface transportLeg{
    portOfDischarge?: codeDescription,
    portOfLoading?: codeDescription,
    legOrder?: number,
    actualArrival?: Date,
    actualArrivalInPortOfLoading?: Date,
    actualDeparture?: Date,
    aircraftType?: codeDescription,
    arrivalAt?: organizationAddress,
    arrivalBerth?: string, 
    arrivalCTO?: organizationAddress,
    arrivalReference?: string, 
    bookingStatus?: codeDescription,
    carrier?: organizationAddress,
    carrierBookingReference?: string, 
    carrierServiceLevel?: serviceLevel,
    creditor?: organizationAddress,
    departureBerth?: string, 
    departureCTO?: organizationAddress,
    departureFrom?: organizationAddress,
    departureReference?: string, 
    documentCutOff?: Date,
    emptyCutOff?: Date,
    emptyReceivalCommences?: Date,
    estimatedArrival?: Date,
    estimatedArrivalInPortOfLoading?: Date,
    estimatedDeparture?: Date,
    fclAvailability?: Date,
    fclCutOff?: Date,
    fclReceivalCommences?: Date,
    fclStorage?: Date,
    hazzardCutOffDate?: Date,
    hazzardReceivalCommences?: Date,
    isCargoOnly?: boolean,
    lclAvailability?: Date,
    lclCutOff?: Date,
    lclReceivalCommences?: Date,
    lclStorageDate?: Date,
    legNotes?: string, 
    legType?: string,
    link?: number, 
    reeferCutOff?: Date,
    reeferReceivalCommences?: Date,
    scheduledArrival?: Date,
    scheduledArrivalInPortOfLoading?: Date,
    scheduledDeparture?: Date,
    transportMode?: string, 
    vesselLloydsIMO?: string, 
    vesselName?: string, 
    vgmCutOff?: Date,
    voyageFlightNo?: string, 
    additionalTransportModeCollection?: {additionalTransportMode?: additionalTransportMode[]},
    customizedFieldCollection?: {customizedField?: customizedField[]}
}

interface additionalTransportMode{
    transportMode?: string
}

interface containerType{
    code?: string,
    category?: { 
        code?: string, 
        description?: string
    },
    description?: string, 
    isoCode?: string
}


interface ShipmentDetailsState {
    isLoading: boolean;
    shipmentDetails: ShipmentDetailsDataType[];
    shipmentDetailsSuccess: any;
    shipmentDetailsError: any;
    isError?: boolean;
}

const initialState: ShipmentDetailsState = {
    isLoading: false,
    shipmentDetails: [],
    shipmentDetailsSuccess: undefined,
    shipmentDetailsError: undefined,
    isError: false,
};

export const shipmentDetailsSlice = createSlice({
    name: 'shipmentDetails',
    initialState,
    reducers: {
        fetchShipmentDetailsList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchShipmentDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.shipmentDetails = action.payload?.data ?? [];
        },
        fetchShipmentDetailsListFailure(state, action) {
            state.shipmentDetails = action.payload;
        },
    },
});

export const { fetchShipmentDetailsList, fetchShipmentDetailsListSuccess, fetchShipmentDetailsListFailure } =
    shipmentDetailsSlice.actions;

export const selectShipmentDetailsLoading = (state: RootState) => state.shipmentDetailsData.isLoading;
export const selectShipmentDetails = (state: RootState) => state.shipmentDetailsData.shipmentDetails;
export const selectShipmentDetailsSuccess = (state: RootState) => state.shipmentDetailsData.shipmentDetailsSuccess;
export const selectShipmentDetailsError = (state: RootState) => state.shipmentDetailsData.shipmentDetailsError;
export const selectShipmentDetailsIsError = (state: RootState) => state.shipmentDetailsData.isError;

export default shipmentDetailsSlice.reducer;
