import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';

// Import all api's
import {
    vendorData,
    SopForm,
    getListProduct,
    createProduct,
    statusChange,
    editProductapi,
    deletePacket,
    createNewPacket,
    exportProductFile,
    searchProduct,
} from 'src/services/apis/productApi';
import {
    editingFailed,
    editingStarted,
    isLoadingfn,
    productAddError,
    productCreated,
    productCreateSuccess,
    productEditSuccess,
    saveFetchedData,
    statusValues,
    vendorList,
    searchProductList,
    deactivateFailed,
    productAddErrorStatus,
} from '../reducers/productReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getListVendor } from 'src/services/apis/vendorApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const products: AxiosResponse<vendorData[]> = yield call(getListProduct, payload);
        yield put({ type: saveFetchedData, payload: products });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingfn, payload: false });
    }
}
function* getVendors({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const vendors: AxiosResponse<vendorData[]> = yield call(getListVendor, payload.payloadVendor);
        yield put({ type: vendorList, payload: vendors });
    } finally {
        yield put(hideLoading());
    }
}
function* addNewProduct({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        const res: AxiosResponse<SopForm[]> = yield call(createProduct, payload);
        yield put({ type: editingStarted, payload: true });
        if ((res as any).success) {
            yield put({ type: editingFailed, payload: false });
            yield put({ type: productCreateSuccess, payload: true });
            yield put({ type: productCreated, payload: res });
        } else {
            yield put({ type: editingFailed, payload: true });
            yield put({ type: productAddErrorStatus, payload: true });
            yield put({ type: productAddError, payload: (res as any).message });
        }
        //const products: AxiosResponse<vendorData[]> = yield call(getListProduct, payload.reload);
        //yield put({ type: saveFetchedData, payload: products });
    } catch (er) {
        yield put({ type: productAddError, payload: er });
        //console.log(er, 'error message123 create erro1234');
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}

function* removeSinglePacket({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        // const res: AxiosResponse<vendorData[]> = yield call(deletePacket, payload);
        yield call(deletePacket, payload);
        yield put({ type: editingStarted, payload: true });
        //if (res) {
        yield put({ type: productEditSuccess, payload: true });
        yield put({ type: editingFailed, payload: false });
        //yield put({ type: isLoadingfn, payload: false });
        //const products: AxiosResponse<vendorData[]> = yield call(getListProduct, payload.payload);
        //yield put({ type: saveFetchedData, payload: products });
        //}
    } finally {
        //yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}
function* addSinglePacket({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(createNewPacket, payload);
        yield put({ type: editingStarted, payload: true });
        //if (res) {
        //yield put({ type: productEditSuccess, payload: true });
        yield put({ type: editingFailed, payload: false });
        //}
    } finally {
        yield put(hideLoading());
        //yield put({ type: isLoadingfn, payload: false });
    }
}

function* changeTheStatus({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(statusChange, payload);
        if (!response?.data?.success) {
            yield put({ type: deactivateFailed, payload: response?.data.message });
            return;
        } else {
            yield put({ type: statusValues, payload: payload });
            const products: AxiosResponse<vendorData[]> = yield call(getListProduct, payload);
            yield put({ type: saveFetchedData, payload: products });
        }
    } finally {
        // console.log('status change');
    }
}
function* productEdit({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        const res: AxiosResponse<vendorData[]> = yield call(editProductapi, payload);
        yield put({ type: editingStarted, payload: true });
        if ((res as any).success) {
            yield put({ type: productEditSuccess, payload: true });
            yield put({ type: editingFailed, payload: false });
            //yield put({ type: isLoadingfn, payload: false });
        } else {
            yield put({ type: editingFailed, payload: true });
            yield put({ type: productEditSuccess, payload: false });
            yield put({ type: productAddErrorStatus, payload: true });
            yield put({ type: productAddError, payload: (res as any).message });
            yield put({ type: productCreated, payload: res });
        }
        //yield put({ type: isLoadingfn, payload: false });
    } catch (er) {
        //console.error(er, 'error edit reducer');
        //yield put({ type: isLoadingfn, payload: false });
    }
}
function* fileDownload({ payload }: { payload: any; type: any }) {
    try {
        yield call(exportProductFile, payload);
    } finally {
        // console.log('first');
    }
}

function* searchProductData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const products: AxiosResponse = yield call(searchProduct, payload);
        yield put({ type: searchProductList, payload: products });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingfn, payload: false });
    }
}

function* productSaga() {
    yield takeEvery('products/fetchData', getListData);
    yield takeEvery('products/changeStatus', changeTheStatus);
    yield takeLatest('products/getVendorList', getVendors);
    yield takeLatest('products/createProduct', addNewProduct);
    yield takeLatest('products/editProduct', productEdit);
    yield takeLatest('products/removePacket', removeSinglePacket);
    yield takeLatest('products/createSinglePacket', addSinglePacket);
    yield takeLatest('products/downloadFile', fileDownload);
    yield takeLatest('products/searchProductList', searchProductData);
}

// Export the saga (data-saga)
export default productSaga;
