import { ReactNode, useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import Leaflet from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import {
    fetchSidebarData,
    saveSingleMapData,
    showSidebar,
    showSidebarForTile,
    updateClickedTileKey,
    updateHoveredTileKey,
} from 'src/store/reducers/dashboard/map';
import MapData from './MapData';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Tiles, TrackLines } from '.';
import { useGetTilesData } from '../hooks';

const queryClient = new QueryClient();

interface Props {
    haveTrackLines?: boolean;
    renderRightTopIcon: () => ReactNode;
    mapId: string;
    haveToolTips?: boolean;
}
const Map: React.FC<Props> = ({ mapId, haveTrackLines, renderRightTopIcon, haveToolTips }) => {
    const [activeTileKey, setActiveTileKey] = useState(''); //TODO: Uncomment and update tile click when it required
    const { hoveredTileKey, singleMapData, clickedTileKey, sideBarVewTiles } = useSelector(
        (state: RootState) => state.map
    );
    const [zoomLevel, setZoomLevel] = useState(5); // initial zoom level provided for MapContainer

    const dispatch = useDispatch();

    const { tilesData } = useGetTilesData();

    useEffect(() => {
        //to remove the tiles data when the data is empty
        if (clickedTileKey === null) {
            setActiveTileKey('');
        }
    }, [clickedTileKey]);

    useEffect(() => {
        if (tilesData?.length === 0) {
            dispatch(fetchSidebarData(null));
            dispatch(saveSingleMapData(null));
            dispatch(showSidebar(false)); //expand drawer
            dispatch(showSidebarForTile(true));
            dispatch(updateHoveredTileKey(''));
            dispatch(updateClickedTileKey(''));
            setActiveTileKey('');
        }
    }, [tilesData]);

    const handleClickTile = (key: any) => {
        if (activeTileKey === key) {
            setActiveTileKey('');
            dispatch(showSidebar(false)); //expand drawer
            dispatch(showSidebarForTile(false));
            dispatch(updateHoveredTileKey(''));
            dispatch(updateClickedTileKey(''));
        } else {
            dispatch(fetchSidebarData(null));
            dispatch(saveSingleMapData(null));
            dispatch(showSidebar(false)); //expand drawer
            dispatch(showSidebarForTile(true));
            setActiveTileKey(key);
            dispatch(updateHoveredTileKey(key));
            dispatch(updateClickedTileKey(key));
        }
        // if (key !== 'destination') {
        //     setShowTrackLine(true);
        // }
    };
    const handleMouseEnterTile = (key: string) => {
        dispatch(updateHoveredTileKey(key));
    };
    const handleMouseLeaveTile = (_key: string) => {
        if (!sideBarVewTiles) {
            dispatch(updateHoveredTileKey(''));
        }
    };
    function MyComponent() {
        const mapEvents = useMapEvents({
            zoomend: () => {
                setZoomLevel(mapEvents.getZoom());
            },
        });
        return null;
    }

    const corner1 = Leaflet.latLng(71.612904, 305.214231);
    const corner2 = Leaflet.latLng(-55.287465, -67.370055);
    const bounds = Leaflet.latLngBounds(corner1, corner2);
    return (
        <div className="map-holder">
            <div id={mapId}>
                {renderRightTopIcon()}

                <QueryClientProvider client={queryClient}>
                    <MapContainer
                        center={[-17.69534, 145.182527]}
                        zoom={2.5}
                        scrollWheelZoom={true}
                        style={{ width: '100%', height: '100%' }}
                        minZoom={3}
                        maxZoom={11}
                        attributionControl={false}
                        preferCanvas
                        worldCopyJump={true}
                        maxBoundsViscosity={1.0} // To make the map not scrollable
                        maxBounds={bounds} // To make the map not scrollable
                    >
                        <TileLayer
                            //maxNativeZoom={7}
                            minZoom={3}
                            url={
                                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                //process.env.REACT_APP_MAP_TILE_API || process.env.REACT_APP_BASE_URL?.includes('dev')
                                //    ? 'https://api.mapbox.com/styles/v1/vishnu7ur/clm4fridj00tx01pb6ei3basm/tiles/256/{z}/{x}/{y}@2x?access_token=sk.eyJ1IjoidmlzaG51N3VyIiwiYSI6ImNsbTRnMWt3aTFkaW4zZHM2Mjd1a3RxYTIifQ.hVrE0p9bvcU4P8zGsIoE6Q'
                                //    : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            }
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <MapData haveToolTips={haveToolTips} />
                        <MyComponent />
                        {haveTrackLines && (
                            <TrackLines
                                zoomlevel={zoomLevel}
                                showTrackLine={
                                    hoveredTileKey === 'etd' ||
                                    hoveredTileKey === 'eta' ||
                                    hoveredTileKey === 'transit' ||
                                    singleMapData?.type === 'bookingsAtOriginPort' ||
                                    singleMapData?.type === 'bookingsIntransitArray'
                                }
                            />
                        )}
                    </MapContainer>
                </QueryClientProvider>
            </div>

            <Tiles
                tiles={tilesData}
                onClick={handleClickTile}
                activeKey={activeTileKey} //TODO: Uncomment when handling tile selection
                onMouseEnter={handleMouseEnterTile}
                onMouseLeave={handleMouseLeaveTile}
            />
        </div>
    );
};

export default Map;
