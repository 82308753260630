import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchContractDetailsColumnListFailed,
    fetchContractDetailsColumnListSuccess,
    fetchContractDetailsList,
    fetchContractDetailsListFailed,
    fetchContractDetailsListSuccess,
    fetchContractDetailsColumnList,
    isLoadingFn,
    createContractDetailsSuccess,
    createContractDetailsFailure,
    updateContractDetailsSuccess,
    updateContractDetailsFailure,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    fetchAgentUsersDetails,
    fetchAgentUsersDetailsSuccess,
    fetchAgentUsersDetailsFailure,
    fetchIncoTermsDetails,
    fetchIncoTermsDetailsSuccess,
    fetchIncoTermsDetailsFailure,
    createContractDetails,
    updateContractDetails,
    fetchAuthorDetails,
    fetchPortDetails,
    fetchPortDetailsSuccess,
    fetchPortDetailsFailure,
    fetchAllClientsDetails,
    fetchAllClientsDetailsSuccess,
    fetchAllClientsDetailsFailure,
    searchContractDetailsData,
    searchContractDetailsDataSuccess,
    searchContractDetailsDataFailure,
} from '../../../reducers/profiles/shippingLine/ContractDetailsReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchContractDetailsListData,
    fetchContractDetailsColumnListData,
    createContractDetailsApi,
    updateContractDetailsApi,
    fetchContractDetailsAuthorApi,
    fetchContractDetailsAgentUsersApi,
    fetchContractDetailsIncoTermsApi,
    fetchContractDetailsPortApi,
    fetchAllClientsDetailsApi,
    searchContractDetailsApi,
} from '../../../../services/apis/profiles/shippingLine/contractDetailsApi';

// eslint-disable-next-line
function* getContractDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchContractDetailsListData, payload);
        yield put({ type: fetchContractDetailsListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContractDetailsListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getContractDetailsColumn({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchContractDetailsColumnListData, payload);
        yield put({ type: fetchContractDetailsColumnListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContractDetailsColumnListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createContractDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createContractDetailsApi, payload);
        yield put({ type: createContractDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: createContractDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateContractDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateContractDetailsApi, payload);
        yield put({ type: updateContractDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateContractDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getAuthorContractDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContractDetailsAuthorApi);
        yield put({ type: fetchAuthorDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAuthorDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* getAgentUsersContractDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContractDetailsAgentUsersApi);
        yield put({ type: fetchAgentUsersDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAgentUsersDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getIncoTermsContractDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContractDetailsIncoTermsApi);
        yield put({ type: fetchIncoTermsDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchIncoTermsDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getPortContractDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContractDetailsPortApi, payload);
        yield put({ type: fetchPortDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchPortDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getAllClientsContractDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchAllClientsDetailsApi);
        yield put({ type: fetchAllClientsDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAllClientsDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getContractDetailsSearchData({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(searchContractDetailsApi, payload);
        yield put({ type: searchContractDetailsDataSuccess, payload: response });
    } catch (error) {
        yield put({ type: searchContractDetailsDataFailure, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* shippingLineContractDetailsSaga() {
    yield takeEvery(fetchContractDetailsList, getContractDetails);
    yield takeEvery(fetchContractDetailsColumnList, getContractDetailsColumn);
    yield takeEvery(createContractDetails, createContractDetailsData);
    yield takeEvery(updateContractDetails, updateContractDetailsData);
    yield takeEvery(fetchAuthorDetails, getAuthorContractDetails);
    yield takeEvery(fetchAgentUsersDetails, getAgentUsersContractDetails);
    yield takeEvery(fetchIncoTermsDetails, getIncoTermsContractDetails);
    yield takeEvery(fetchPortDetails, getPortContractDetails);
    yield takeEvery(fetchAllClientsDetails, getAllClientsContractDetails);
    yield takeEvery(searchContractDetailsData, getContractDetailsSearchData);
}

export default shippingLineContractDetailsSaga;
