/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ProfileListing from '../../components/common/ProfileListing';
import './booking-list.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import { PlaceholderImage, Placeholder } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';

import {
    awaitingClientApproval,
    bookingProposalStatusAll,
    BookingType,
    bpActiveStatus,
    bpHeaders,
    clearFilterText,
    noRecordForAppliedFilters,
    noRecords,
    statusOfBps,
} from '../../utils/constants';
import {
    fetchBpData,
    setPageCount,
    fetchBpFilterData,
    fetchBpStatusCount,
    setFilter,
    setBookingList,
    setSelectedDropDown,
    setCurrentPage,
    closeLoading,
} from '../../store/reducers/bookingProposal/bookingProposalListReducer';
import { fetchBpData as fetchAoBpData } from '../../store/reducers/aobookingProposal/bookingProposalListReducer';
import TableFooter from '../../components/common/Table/tableFooter';
import ExportButton from '../../components/common/ExportButton';
import ShowForPermissions from '../../ShowForPermissions';
import { fetchPermissions } from '../../store/reducers/permissionReducer';
import { exportBpList } from '../../services/apis/bookingProposal/bookingProposalListingApi';
import {
    setDisabledFilterData,
    setFilterDispatch,
    setSelectedItems,
} from '../../store/reducers/bookingProposal/availablePoReducer';
import {
    setDisabledFilterData as airSetDisabledFilterData,
    setFilterDispatch as airSetFilterDispatch,
    setSelectedItems as airSetSelectedItems,
} from '../../store/reducers/bookingProposalAir/availablePosReducer';
import {
    setDisabledFilterData as aoSetDisabledFilterData,
    setFilterDispatch as aoSetFilterDispatch,
    setSelectedItems as aoSetSelectedItems,
} from '../../store/reducers/aobookingProposal/availablePoReducer';
import { resetHeader } from '../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { resetHeader as resetAoHeader } from 'src/store/reducers/aobookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from '../../hooks/useUserProfile';
import { getOrganisationData } from '../../utils';
import AoBooking from './AoBooking/index';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import PoBookingAir from './PoBookingAir/index';
import BookingTab from './BookingTab';
import { fetchBpDataAir } from 'src/store/reducers/bookingProposalAir/listReducer';
import ClickAwayListener from 'react-click-away-listener';
import { resetAllLclData } from 'src/store/reducers/bookingProposalLcl/availablePoReducer';
import {
    setDisabledFilterData as bcnSetDisabledFilterData,
    setFilterDispatch as bcnSetFilterDispatch,
    setSelectedItems as bcnSetSelectedItems,
} from 'src/store/reducers/bookingProposalBcn/availablePoReducer';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import BSTooltip from 'src/components/common/BSTooltip';
import FilledGraph from 'src/components/common/FilledGraph';
import moment from 'moment';
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const {
        filter,
        bookingProposals,
        filterValues,
        currentPage,
        perPageCount,
        totalPages,
        columnStatus,
        totalItems,
        isLoading,
        statusCount,
        isFilterLoading,
        isStatusLoading,
        bookingList,
        selectedDropDown,
    } = useSelector((state: RootState) => state.bookingProposalList);
    const { totalItems: totalAoItems, filter: aoFilter } = useSelector(
        (state: RootState) => state.aoBookingProposalList
    );
    const { submitBPSuccess } = useSelector((state: RootState) => state.additionalDetailsData);

    const { totalItems: totalAirItems, filter: airFilter } = useSelector(
        (state: RootState) => state.bookingProposalAirList
    );

    // const location = useLocation();
    const navigate = useNavigate();
    const [selectedHeader, setSelectedHeader] = useState(bpHeaders.createdDate);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandId, setexpandId] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [addBookingVisible, setaddBookingVisible] = useState(false);
    const { profileType, userName, roleName } = useUserProfile();
    const [kendoFilter, setKendoFilter] = useState('{}');
    const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data1?.user?.us_UserName;
    const firstUpdate = useRef(true);
    const organisationData = getOrganisationData();
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setaddBookingVisible as any);
    const [activeCount, setActiveCount] = useState(0);

    const loggedInUserName = userName;

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter, loggedInUserName, roleName },
        profileType,
    };
    const copiedFilter = JSON.parse(JSON.stringify(filter));

    const copyFilterPayload = copiedFilter?.bpType ? { ...filter } : { ...filter, bpType: [] };
    const sortPayload = {
        sortOrder: currentOrder ? 'DESC' : 'ASC',
        sortColumn: columnStatus ? columnStatus : 'createdDateTime',
        ...copyFilterPayload,
    };

    const payloadForFilterAndStatus = {
        ...payload,
        data: { ...organisationData, ...{ bpStatus: filter.bpStatus }, loggedInUserName, roleName },
    };
    const payloadForFilter = {
        ...payload,
        data: { ...organisationData, ...{ bpStatus: filter.bpStatus }, loggedInUserName, roleName, ...filter },
    };
    const getNoDataMessage = () => {
        if (filter && Object.keys(filter).length > 2) {
            return noRecordForAppliedFilters;
        } else {
            return noRecords;
        }
    };

    useEffect(() => {
        // let sum =
        //     statusCount &&
        //     (Object.values(statusCount) as number[])?.reduce((prev: number, current: number) => prev + current, 0);
        // if (sum) {
        //     sum = sum - ((statusCount as any)['Cancelled'] || 0) - ((statusCount as any)['Withdrawn'] || 0);
        // }
        // setActiveCount(sum);
        setActiveCount((statusCount as any)['Active BPs'] ?? 0);
        document.title = 'GRD>Bookings';
    }, [statusCount]);

    useEffect(() => {
        // dispatch(fetchBpData(payload));
        // dispatch(fetchBpFilterData({ ...payloadForFilter, profileType }));
        dispatch(fetchBpStatusCount(payloadForFilterAndStatus));
        dispatch(fetchPermissions(username));
    }, [dispatch, /* currentPage, */ setSelectedHeader, /* perPageCount, */ currentOrder, filter, submitBPSuccess]);

    useEffect(() => {
        // when user select booking status from the dropdown, kendo grid should rerender
        let status = '';
        if (selectedDropDown?.text) {
            switch (selectedDropDown.text) {
                case 'Awaiting Consolidation':
                    status = 'Awaiting Consolidation';
                    break;
                case 'Draft Booking Proposal':
                    status = 'Draft Booking Proposal';
                    break;
                case 'Awaiting Forwarder Approval':
                    status = 'Awaiting Forwarder Approval';
                    break;
                case 'Awaiting Client Approval':
                    status = 'Awaiting Client Approval';
                    break;
                case 'Rejected by Client: Forwarder to Action':
                    status = 'Rejected by Client Forwarder to Action';
                    break;
                case 'Rejected by Client: Agent to Action':
                    status = 'Rejected by Client Agent to Action';
                    break;
                case 'Rejected by Forwarder':
                    status = 'Rejected by Forwarder';
                    break;
                case 'Rejected by Client':
                    status = 'Rejected by Client';
                    break;
                case 'Ready for Booking':
                    status = 'Ready for Booking';
                    break;
                case 'Draft Booking':
                    status = 'Draft Booking';
                    break;
                case 'Booked':
                    status = 'Booked';
                    break;
                case 'Withdrawn':
                    status = 'Withdrawn';
                    break;
                case 'Pending Reassignment':
                    status = 'Pending Reassignment';
                    break;
                case 'Cancelled':
                    status = 'Cancelled';
                    break;
                default:
                    status = '';
            }
        }

        if (status.length > 0) setKendoFilter(`{field: "BP_Status", operator: "eq", value: "${status}"}`);
        else setKendoFilter('{}');
    }, [selectedDropDown]);

    const getTrendArray = (key: any) => {
        let index = 0;
        const trendGraghArray = [[0, index++, index++, '']];
        const trendHistoryArray: any[] = statusCount[`${key}_trendHistory`];
        for (let i = trendHistoryArray.length - 1; i >= 0; i--) {
            trendGraghArray.push([
                Number(trendHistoryArray[i].UD_Data),
                index++,
                index++,
                moment(trendHistoryArray[i].UD_CreatedDateTime).format('DD-MMM-YYYY'),
            ]);
        }

        return trendGraghArray;
    };

    const getTiles = () => {
        if (statusCount['Active BPs']) {
            const tilesArray = [
                <a
                    className="card insight-card"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                            setSelectedDropDown({
                                text: 'Active BPs',
                                count: statusCount['Active BPs'],
                            })
                        );
                    }}
                    href="#"
                    // style={{ width: '110px', height: '70px', padding: '5px' }}
                >
                    <>
                        <div
                            className="card-header"
                            // style={{ minHeight: '63px', position: 'relative' }}
                        >
                            <div className="title-wrapper">
                                <h5 className="card-title bold" style={{ fontSize: 11 }}>
                                    {'Active BPs'}
                                </h5>
                                <span
                                    className="counts"
                                    // style={{ fontSize: 11 }}
                                >
                                    {statusCount['Active BPs'] || 0}
                                </span>

                                <span
                                    className={`count trend-custom-color`}
                                    // style={{ marginLeft: '20px', color: 'black', fontSize: 11 }}
                                >
                                    {statusCount['Active BPs'] - statusCount['Active BPs_trend']?.value >= 0
                                        ? `+${statusCount['Active BPs'] - statusCount[`Active BPs_trend`]?.value}`
                                        : `${statusCount['Active BPs'] - statusCount[`Active BPs_trend`]?.value}`}
                                </span>
                            </div>
                        </div>
                    </>
                </a>,
            ];
            for (const key of statusOfBps) {
                if (statusCount && Object.prototype.hasOwnProperty.call(statusCount, key)) {
                    tilesArray.push(
                        <a
                            className="card insight-card"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                    setSelectedDropDown({
                                        text: key,
                                        count: statusCount[key],
                                    })
                                );
                            }}
                            href="#"
                            // style={{ width: '110px', height: '70px', padding: '5px' }}
                        >
                            <>
                                <div
                                    className="card-header"
                                    // style={{ minHeight: '63px', position: 'relative' }}
                                >
                                    <div className="title-wrapper">
                                        <h5
                                            className="card-title bold"
                                            style={{ width: '100%', fontSize: key.length <= 10 ? 10 : 11 }}
                                        >
                                            {key}
                                        </h5>
                                        <span
                                            className="counts"
                                            // style={{ fontSize: 16, position: 'absolute', bottom: '0px', left: '0px' }}
                                        >
                                            {statusCount[key] || 0}
                                        </span>

                                        <span
                                            className={`count trend-custom-color`}
                                            // style={{
                                            //     marginLeft: '20px',
                                            //     color: 'black',
                                            //     fontSize: 11,
                                            //     position: 'absolute',
                                            //     bottom: '5px',
                                            //     right: '5px',
                                            // }}
                                        >
                                            {statusCount[key] - statusCount[`${key}_trend`]['value'] >= 0
                                                ? `+${statusCount[key] - statusCount[`${key}_trend`]['value']}`
                                                : `${statusCount[key] - statusCount[`${key}_trend`]['value']}`}
                                        </span>
                                    </div>
                                </div>
                            </>
                        </a>
                    );
                }
            }
            return tilesArray;
        }

        return [];
    };

    const clearPreviousBookingData = () => {
        localStorage.removeItem('bpId');
        localStorage.removeItem('bpNumber');
        dispatch(setDisabledFilterData({}));
        dispatch(setSelectedItems([]));
        dispatch(resetHeader({}));
        dispatch(setFilterDispatch({}));
        setaddBookingVisible(!addBookingVisible);

        dispatch(aoSetDisabledFilterData({}));
        dispatch(aoSetSelectedItems([]));
        dispatch(resetAoHeader({}));
        dispatch(aoSetFilterDispatch({}));

        dispatch(airSetDisabledFilterData({}));
        dispatch(airSetSelectedItems([]));
        dispatch(airSetFilterDispatch({}));
    };

    const clearBcnBookingData = () => {
        dispatch(
            bcnSetDisabledFilterData({
                clientName: null,
                originPort: null,
                destinationPort: null,
                vendorName: null,
                isHeaderOnlyPo: null,
            })
        );
        dispatch(bcnSetFilterDispatch({}));
        dispatch(bcnSetSelectedItems([]));
    };

    const getPlaceHolder = () => {
        const placeholder = [];

        for (let i = 0; i < 6; i++) {
            placeholder.push(
                <>
                    <div className="layout-holder one-column">
                        <Placeholder style={{ height: 50, width: 50 }}>
                            <PlaceholderImage />
                        </Placeholder>
                    </div>
                    <div className="layout-holder one-column">
                        <Placeholder style={{ height: 50, width: 50 }}>
                            <PlaceholderImage />
                        </Placeholder>
                    </div>
                </>
            );
        }
        return placeholder;
    };

    return (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Bookings</h2>
                {bookingList === BookingType.poBooking && (
                    <div className="grid-section grid-p-0">
                        <div className="grid-holder">
                            <div className="grid-header grid-m-0-30">
                                <div className="search-field-booking-list">
                                    {isStatusLoading ? getPlaceHolder() : getTiles()}
                                </div>

                                <div className="add-btn-purchase">
                                    {/* added Reassign Agent button for sprint16 */}
                                    {profileType === 'forwarder' && (
                                        <ShowForPermissions
                                            permission="agent"
                                            type="reassignment"
                                            item="Booking Proposal"
                                        >
                                            <Link
                                                style={{ textDecoration: 'none' }}
                                                to="/reassign-agent/booking-proposal/sea"
                                            >
                                                <button className="app-btn app-btn-secondary" title="Reassign Agent">
                                                    <span className="button-text">Reassign Agent</span>
                                                </button>
                                            </Link>
                                        </ShowForPermissions>
                                    )}

                                    {(profileType === 'agent' || profileType === 'forwarder') && (
                                        <ShowForPermissions permission="create" type="bp">
                                            <div
                                                className={`dropdown-wrap grid-header-dropdown right-align ${
                                                    addBookingVisible ? 'dropdown-open' : ''
                                                }`}
                                                ref={wrapperRef}
                                            >
                                                <button
                                                    className="app-btn app-btn-secondary icon-button"
                                                    title="Add New Booking"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        clearPreviousBookingData();
                                                    }}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#plusIcon">
                                                            <title>Add Booking</title>
                                                        </use>
                                                    </svg>
                                                    <span className="button-text footer-button-text">
                                                        Add New Booking
                                                    </span>
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            navigate('/booking_list/POSelection', {
                                                                state: { reload: false },
                                                            });
                                                        }}
                                                    >
                                                        PO Booking - FCL
                                                    </a>
                                                    <a
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            dispatch(resetAllLclData({}));
                                                            dispatch(resetHeader({}));
                                                            navigate('/lclbooking_list/POSelection', {
                                                                state: { reload: false },
                                                            });
                                                        }}
                                                    >
                                                        PO Booking - LCL
                                                    </a>
                                                    <a
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            clearBcnBookingData();
                                                            navigate('/bcnBookingList/POSelection', {
                                                                state: { reload: false },
                                                            });
                                                        }}
                                                    >
                                                        PO Booking - BCN
                                                    </a>
                                                    {/* <a
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            clearBcnBookingData();
                                                            navigate('/land_booking_list/POSelection', {
                                                                state: { reload: false },
                                                            });
                                                        }}
                                                    >
                                                        PO Booking - LAND
                                                    </a> */}
                                                    <a
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            navigate('/air_booking_list/POSelection/new', {
                                                                state: { reload: false },
                                                            });
                                                        }}
                                                    >
                                                        PO Booking - Air
                                                    </a>
                                                    <a
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            navigate('/Aobooking_list/AoSelection', {
                                                                state: { reload: false },
                                                            });
                                                        }}
                                                    >
                                                        AO Booking
                                                    </a>
                                                </div>
                                            </div>
                                        </ShowForPermissions>
                                    )}
                                </div>
                            </div>
                            <LayoutWithColumns style={'booking'} content={'booking-list'} filter={kendoFilter} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
