import { createSlice } from '@reduxjs/toolkit';
import { ProfileOption, SkuOption, UserOption } from 'src/components/common/Modals/StatusModals/dropdownTypes';

interface Link {
    name: string;
    link: string;
    id: number;
}

interface QuickLinksState {
    quickLinks: Link[];
    userQuickLinks: ProfileOption | UserOption | SkuOption | any;
    clientId: string;
    isSaveError: boolean;
    isSaveSuccess: boolean;
    isLoading: boolean;
    error: any;
}

const initialState: QuickLinksState = {
    quickLinks: [],
    userQuickLinks: {},
    clientId: '',
    isSaveError: false,
    isSaveSuccess: false,
    isLoading: true,
    error: {},
};

export const quickLinkSlice = createSlice({
    name: 'quickLinks',
    initialState,
    reducers: {
        saveQuickLinksData(state, _action) {
            state.isLoading = true;
        },
        saveQuickLinksDataSuccess(state, action) {
            state.isLoading = false;
            state.isSaveSuccess = action.payload;
        },
        saveQuickLinksDataError(state, action) {
            state.isLoading = false;
            state.isSaveError = true;
            state.error = action.payload;
        },
        fetchQuickLinks: (state, _action) => {
            state.isLoading = true;
        },
        fetchQuickLinksSuccess: (state, action) => {
            state.isLoading = false;
            state.quickLinks = action.payload.quickLinks;
            state.clientId = action.payload.clientId;
        },
        fetchQuickLinksError: (state, _action) => {
            state.isLoading = false;
        },
        fetchUserQuickLinks: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchUserQuickLinksSuccess: (state, action) => {
            state.isLoading = false;
            state.userQuickLinks = action.payload.userQuickLinks;
        },
        fetchUserQuickLinksError: (state, _action) => {
            state.isLoading = false;
        },
        clearUserQuickLinks: (state, _action) => {
            state.isLoading = false;
            state.userQuickLinks = {};
        },
        resetSuccessFlag: (state, _action) => {
            state.isSaveSuccess = false;
        },
        resetErrorFlags: (state, _action) => {
            state.isSaveError = false;
            state.error = {};
        },
    },
});

export const {
    saveQuickLinksData,
    saveQuickLinksDataSuccess,
    saveQuickLinksDataError,
    fetchQuickLinks,
    resetSuccessFlag,
    resetErrorFlags,
    fetchQuickLinksSuccess,
    fetchQuickLinksError,
    fetchUserQuickLinks,
    fetchUserQuickLinksSuccess,
    fetchUserQuickLinksError,
    clearUserQuickLinks,
} = quickLinkSlice.actions;

export default quickLinkSlice.reducer;
