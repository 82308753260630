import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

//styles
import './auto-approval.scss';
import 'src/assets/scss/components/_tooltip.scss';

//custom components
import Breadcrumbs from 'src/components/Breadcrumbs';
import BackButton from 'src/components/common/BackButton';
import HeaderTabs from '../HeaderTabs';
import BSTooltip from 'src/components/common/BSTooltip';
import UnsavedModal from 'src/components/common/UnsaveModal';
import BookingAutoApprovalLogSidebar from './BookingAutoApprovalLogSidebar';
import AutoApprovalItem from './AutoApprovalItem';
import AuthorizationDropDown, { AuthorizationTypeOption } from './AuthorizationDropDown';
import { Position, success, error } from 'src/utils/toast';
import { useUserProfile } from 'src/hooks/useUserProfile';

//import store/reducers
import { RootState } from 'src/store/reducers';
import {
    fetchAutoApprovalData,
    resetFeedbackStatuses,
    // removeAttachment,
    saveAutoApprovalData,
    fetchAutoApprovalHistory,
} from 'src/store/reducers/autoApprovalReducer';

interface AutoApprovalOptions {
    key: 'nac' | 'fak' | 'permittedETD' | 'autoApproveAIR';
    title: string;
    description: string;
}
interface BuyerApprovalOptions {
    key: 'buyerApprove';
    title: string;
    description: string;
}

const autoApprovalOptions: AutoApprovalOptions[] = [
    {
        key: 'nac',
        title: 'Auto approve NAC',
        description:
            'Auto approve booking proposal if the sailing schedule selected for the booking is with a valid NAC contract',
    },
    {
        key: 'fak',
        title: 'Auto approve FAK',
        description:
            'Auto approve booking proposal if the sailing schedule selected for the booking is with FAK quotes',
    },
    {
        key: 'permittedETD',
        title: 'Enable auto approval condition if ETD is within shipwindow',
        description:
            'If this feature is enabled then ETD  must be in shipwindow as it overrides all other settings above',
    },
    {
        key: 'autoApproveAIR',
        title: 'Auto Approve AIR',
        description: 'Auto approve AIR Booking Proposal',
    },
];

const buyerApprovalOptions: BuyerApprovalOptions[] = [
    {
        key: 'buyerApprove',
        title: 'Enable Buyer Approval Flows',
        description: 'Enable additional approvals against POSs linked to a Booking',
    },
];

const authorizationOptions: AuthorizationTypeOption[] = [
    {
        key: 'CONTRACT',
        name: 'In Contract',
    },
    {
        key: 'SOP',
        name: 'In SOP',
    },
    {
        key: 'EMAIL',
        name: 'In Email',
    },
];

const Index: React.FC = () => {
    const [authorizationType, setAuthorizationType] = useState<AuthorizationTypeOption['key'] | null>(null);
    const [authorizationValidationError, setAuthorizationValidationError] = useState(false);
    const [fileMissingError, setFileMissingError] = useState('');
    const [showUnSaveModal, setShowUnSaveModal] = useState(false);
    //to control the open closed state authorization dropdown
    //keep it here to close the dropdown on form submission
    const [dropDownOpen, setDropDownOpen] = useState(false);
    // to handle temporary delete of attachment file, that is when attachment got deleted and changes discarded,
    // need to keep previous file
    const [removedFileId, setRemoveFileId] = useState<null | string>(null);
    const [showSideBar, setShowSideBar] = useState(false); // To show the change log (history)
    const fileRef = useRef({
        value: null,
    });
    const [tempFileAvailable, setTempFileAvailable] = React.useState<boolean>(false);
    const [formValues, setFormValues] = useState({
        nac: 'No',
        fak: 'No',
        permittedETD: 'NO',
        autoApproveAIR: 'No',
        buyerApprove: 'No',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { profileType } = useUserProfile();
    useEffect(() => {
        const payload = { clientId: id };
        dispatch(fetchAutoApprovalData(payload));
    }, [dispatch, id]);

    const { autoApprovalData, isLoading, isUpdateError, isUpdateSuccess } = useSelector(
        (state: RootState) => state.autoApproval
    );
    const { clientHeader } = useSelector((state: RootState) => state.profilesClients) as any;
    //Function reset form values
    const resetFieldValues = useCallback(() => {
        setFormValues({
            nac: autoApprovalData.autoApproveNAC ? 'Yes' : 'No',
            fak: autoApprovalData.autoApproveFAK ? 'Yes' : 'No',
            permittedETD: autoApprovalData.autoApproveETD ? 'Yes' : 'No',
            autoApproveAIR: autoApprovalData.autoApproveAIR ? 'Yes' : 'No',
            buyerApprove: autoApprovalData.buyerApprove ? 'Yes' : 'No',
        });

        setAuthorizationType(autoApprovalData.authorisationType);
    }, [
        autoApprovalData.autoApproveNAC,
        autoApprovalData.autoApproveFAK,
        autoApprovalData.autoApproveETD,
        autoApprovalData.autoApproveAIR,
        autoApprovalData.buyerApprove,
        autoApprovalData.authorisationType,
    ]);
    useEffect(() => {
        resetFieldValues();
    }, [resetFieldValues]);

    useEffect(() => {
        if (isUpdateSuccess) {
            success(`Auto approval options updated successfully`, Position.TOP_RIGHT);
            dispatch(resetFeedbackStatuses({}));
            //Reset form to keep dirty value unchanged and keeping current changes after submission
            setFormValues(formik.values);
            formik.resetForm();
            fileRefUpdateCallback(null);
        }
        if (isUpdateError) {
            error('Auto approval updation failed', Position.TOP_RIGHT);
            dispatch(resetFeedbackStatuses({}));
        }
    }, [isUpdateError, isUpdateSuccess, dispatch]);

    const resetFileError = () => {
        setFileMissingError('');
    };

    const completeRemoveAttachment = () => {
        setRemoveFileId(null);
        fileRefUpdateCallback(null);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        onSubmit: (values) => {
            if (buyerApprovalAllCondition()) {
                dispatch(
                    saveAutoApprovalData({
                        payload: {
                            document: {
                                clientId: id,
                                autoApproveNAC: values.nac === 'Yes' ? true : false,
                                autoApproveFAK: values.fak === 'Yes' ? true : false,
                                autoApproveETD: values.permittedETD === 'Yes' ? true : false,
                                autoApproveAIR: values.autoApproveAIR === 'Yes' ? true : false,
                                buyerApprove: values.buyerApprove === 'Yes' ? true : false,
                                // authorisationType: authorizationType,
                            },
                            // eslint-disable-next-line no-undefined
                            // file: authorizationType === 'EMAIL' ? fileRef.current.value : undefined,
                        },
                    })
                );
                return;
            }
            if (!authorizationType) {
                setAuthorizationValidationError(true);
            } else if (
                (authorizationType === 'EMAIL' && removedFileId) ||
                (authorizationType === 'EMAIL' && !fileRef.current.value)
            ) {
                // Set file missing error when form try submit with EMAIL type without attachment
                setFileMissingError('File is required');
            } else {
                if (
                    removedFileId ||
                    (!removedFileId && authorizationType !== 'EMAIL' && autoApprovalData.savedFileId)
                ) {
                    // Execute remove file function in two situations
                    // 1. Already fileId saved in removedFile Id when changing nac/fak/permitted value or remove file button
                    // 2. Saved authorisation type is EMAIL and chaning to another field
                    completeRemoveAttachment();
                }
                setAuthorizationValidationError(false);
                resetFileError();
                dispatch(
                    saveAutoApprovalData({
                        payload: {
                            document: {
                                clientId: id,
                                autoApproveNAC: values.nac === 'Yes' ? true : false,
                                autoApproveFAK: values.fak === 'Yes' ? true : false,
                                autoApproveETD: values.permittedETD === 'Yes' ? true : false,
                                autoApproveAIR: values.autoApproveAIR === 'Yes' ? true : false,
                                buyerApprove: values.buyerApprove === 'Yes' ? true : false,
                                authorisationType: authorizationType,
                                or_PK: clientHeader?.or_PK,
                            },
                            // eslint-disable-next-line no-undefined
                            file: authorizationType === 'EMAIL' ? fileRef.current.value : undefined,
                        },
                    })
                );
                //Close Unsave modal if the form submission triggered from unsave modal
                if (showUnSaveModal) {
                    setShowUnSaveModal(false);
                }
                //Close authorization dropdown if it open at the time of submission
                if (dropDownOpen) {
                    setDropDownOpen(false);
                }
            }
        },
    });

    useEffect(() => {
        //Preserve te authorization type value when the form reset to previous state
        if (!formik.dirty && autoApprovalData.authorisationType && !authorizationType) {
            setAuthorizationType(autoApprovalData.authorisationType);
        }
    }, [formik.dirty, authorizationType, autoApprovalData.authorisationType]);
    const handleClickCancel = () => {
        if (!authorizationType) {
            //show authorization type missing error and disable save button in the cancel modal
            setAuthorizationValidationError(true);
        } else if (
            (authorizationType === 'EMAIL' && removedFileId) ||
            (authorizationType === 'EMAIL' && !tempFileAvailable)
        ) {
            //show email attachment error and disable save button in the cancel modal
            setFileMissingError('File is required');
        }

        //Close authorization dropdown if it open at the time of submission
        if (dropDownOpen) {
            setDropDownOpen(false);
        }
        setShowUnSaveModal(true);
    };
    const handleDiscard = () => {
        formik.resetForm({});
        fileRefUpdateCallback(null);
        setTempFileAvailable(false);
        resetFieldValues();
        setShowUnSaveModal(false);
        setRemoveFileId(null);
        setAuthorizationValidationError(false);
        setFileMissingError('');
    };

    const fileRefUpdateCallback = (value: null) => {
        fileRef.current.value = value;
        if (value) {
            setTempFileAvailable(true);
        } else {
            setTempFileAvailable(false);
        }
        return;
    };

    const buyerApprovalCondition = () => {
        const buyerApproveStatus = formik.values.buyerApprove?.toLowerCase() === 'yes' ? true : false;
        return autoApprovalData.buyerApprove != buyerApproveStatus ? false : true;
    };

    const buyerApprovalAllCondition = () => {
        const buyerApproveStatus = formik.values.buyerApprove?.toLowerCase() === 'yes' ? true : false;
        const buyerApproveNac = formik.values.nac?.toLowerCase() === 'yes' ? true : false;
        const buyerApproveFak = formik.values.fak?.toLowerCase() === 'yes' ? true : false;
        const buyerApprovePermittedETD = formik.values.permittedETD?.toLowerCase() === 'yes' ? true : false;
        const buyerApproveAIR = formik.values.autoApproveAIR?.toLowerCase() === 'yes' ? true : false;
        if (autoApprovalData.buyerApprove != buyerApproveStatus) {
            if (
                buyerApproveNac === autoApprovalData.autoApproveNAC &&
                buyerApproveFak === autoApprovalData.autoApproveFAK &&
                buyerApprovePermittedETD === autoApprovalData.autoApproveETD &&
                buyerApproveAIR === autoApprovalData.autoApproveAIR
            ) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <div className="main-wrapper container" onClick={() => dropDownOpen && setDropDownOpen(false)}>
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />
                    <HeaderTabs />
                </div>
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="submit-btn-approval">
                    {formik.dirty && (
                        <div className="grid-header-btn-holder">
                            <button
                                className="app-btn app-btn-secondary footer-btn"
                                title="Cancel"
                                onClick={handleClickCancel}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary footer-btn "
                                title="Save"
                                type="submit"
                                form="auto-approval-option-selection"
                                disabled={
                                    buyerApprovalCondition() &&
                                    (!authorizationType ||
                                        authorizationValidationError ||
                                        (authorizationType === 'EMAIL' && !tempFileAvailable) ||
                                        !!fileMissingError)
                                }
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    )}
                </div>
                <div className="content-section main-wrapper auto-approval-wrapper">
                    <div className="card details-box custom">
                        <div className="header-icon-btn-wrapper">
                            <div className="header-icon-holder">
                                <h6 className="details-box-heading-2">Booking Auto Approval</h6>
                                <BSTooltip
                                    id="view-20"
                                    title="When multiple are enabled all conditions
                                    must be met for auto approval"
                                    tooltipClassList="product-warning-icon"
                                >
                                    <button className="app-btn inline-icon">
                                        <svg className="svg-icon error-icon">
                                            <use xlinkHref="#infoIconRound">
                                                <title>Warning</title>
                                            </use>
                                        </svg>
                                    </button>
                                </BSTooltip>
                                <a
                                    className="add-content-link"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        dispatch(fetchAutoApprovalHistory({ clientId: id }));
                                        setShowSideBar(true);
                                    }}
                                >
                                    Change log
                                </a>
                            </div>
                            {/* {formik.dirty && (
                                <div className="grid-header-btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary footer-btn"
                                        title="Cancel"
                                        onClick={handleClickCancel}
                                    >
                                        <span className="button-text footer-button-text">Cancel</span>
                                    </button>
                                    <button
                                        className="app-btn app-btn-primary footer-btn "
                                        title="Save"
                                        type="submit"
                                        form="auto-approval-option-selection"
                                        disabled={
                                            !authorizationType ||
                                            authorizationValidationError ||
                                            (authorizationType === 'EMAIL' && !tempFileAvailable) ||
                                            !!fileMissingError
                                        }
                                    >
                                        <span className="button-text footer-button-text">Save</span>
                                    </button>
                                </div>
                            )} */}
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                formik.handleSubmit(e);
                            }}
                            id="auto-approval-option-selection"
                        >
                            <div className="layout-holder-wrapper">
                                {autoApprovalOptions.map(({ description, key, title }) => (
                                    <AutoApprovalItem
                                        key={`auto-approval-${key}`}
                                        title={title}
                                        description={description}
                                        value={formik.values[key]}
                                        name={key}
                                        handleChange={(e) => {
                                            formik.handleChange(e);
                                            setAuthorizationType(null);
                                            setRemoveFileId(null);
                                            setDropDownOpen(false);
                                            if (fileMissingError) {
                                                setFileMissingError('');
                                            }
                                            if (authorizationValidationError) {
                                                setAuthorizationValidationError(false);
                                            }
                                        }}
                                        hasEditPermission={profileType === 'forwarder'}
                                    />
                                ))}
                            </div>
                            {formik.dirty && !buyerApprovalAllCondition() && (
                                <AuthorizationDropDown
                                    attachment={{
                                        fileName: autoApprovalData.fileName,
                                        savedFileName: autoApprovalData.savedFileName,
                                        savedFileId: autoApprovalData.savedFileId,
                                        savedFileSizeInKb: autoApprovalData.savedFileSizeInKb,
                                    }}
                                    clientId={id}
                                    completeRemoveAttachment={completeRemoveAttachment}
                                    dropDownOpen={dropDownOpen}
                                    handleSelection={(value) => {
                                        // Reset the file/error fields on authorization type change
                                        setAuthorizationType(value);
                                        if (value === 'EMAIL') {
                                            setRemoveFileId(autoApprovalData.savedFileId);
                                        }
                                        if (fileMissingError) {
                                            setFileMissingError('');
                                        }
                                        if (authorizationValidationError) {
                                            setAuthorizationValidationError(false);
                                        }
                                    }}
                                    fileMissingError={fileMissingError}
                                    hasEditPermission={profileType === 'forwarder' && formik.dirty}
                                    hasValidationError={authorizationValidationError}
                                    label="Client Authorization"
                                    options={authorizationOptions}
                                    placeholder=""
                                    removedFileId={removedFileId}
                                    saveRemovedFileId={setRemoveFileId}
                                    resetFileError={resetFileError}
                                    setDropDownOpen={setDropDownOpen}
                                    selectedOption={authorizationType}
                                    fileRefUpdateCallback={fileRefUpdateCallback}
                                    tempFileAvailable={tempFileAvailable}
                                />
                            )}
                        </form>
                    </div>
                </div>
                <div className="content-section main-wrapper auto-approval-wrapper">
                    <div className="card details-box custom">
                        <div className="header-icon-btn-wrapper">
                            <div className="header-icon-holder">
                                <h6 className="details-box-heading-2">Buyer Approval</h6>
                                {/* <BSTooltip
                                    id="view-20"
                                    title="When multiple are enabled all conditions
                                    must be met for auto approval"
                                    tooltipClassList="product-warning-icon"
                                >
                                    <button className="app-btn inline-icon">
                                        <svg className="svg-icon error-icon">
                                            <use xlinkHref="#infoIconRound">
                                                <title>Warning</title>
                                            </use>
                                        </svg>
                                    </button>
                                </BSTooltip>
                                <a
                                    className="add-content-link"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        dispatch(fetchAutoApprovalHistory({ clientId: id }));
                                        setShowSideBar(true);
                                    }}
                                >
                                    Change log
                                </a> */}
                            </div>
                            {/* {formik.dirty && (
                                <div className="grid-header-btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary footer-btn"
                                        title="Cancel"
                                        onClick={handleClickCancel}
                                    >
                                        <span className="button-text footer-button-text">Cancel</span>
                                    </button>
                                    <button
                                        className="app-btn app-btn-primary footer-btn "
                                        title="Save"
                                        type="submit"
                                        form="auto-approval-option-selection"
                                        disabled={
                                            !authorizationType ||
                                            authorizationValidationError ||
                                            (authorizationType === 'EMAIL' && !tempFileAvailable) ||
                                            !!fileMissingError
                                        }
                                    >
                                        <span className="button-text footer-button-text">Save</span>
                                    </button>
                                </div>
                            )} */}
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                formik.handleSubmit(e);
                            }}
                            id="auto-approval-option-selection"
                        >
                            <div className="layout-holder-wrapper">
                                {buyerApprovalOptions.map(({ description, key, title }) => (
                                    <AutoApprovalItem
                                        key={`auto-approval-${key}`}
                                        title={title}
                                        description={description}
                                        value={formik.values[key] as any}
                                        name={key}
                                        handleChange={(e) => {
                                            formik.handleChange(e);
                                            // setAuthorizationType(null);
                                            setRemoveFileId(null);
                                            setDropDownOpen(false);
                                            if (fileMissingError) {
                                                setFileMissingError('');
                                            }
                                            if (authorizationValidationError) {
                                                setAuthorizationValidationError(false);
                                            }
                                        }}
                                        hasEditPermission={profileType === 'forwarder'}
                                    />
                                ))}
                            </div>
                            {/* {formik.dirty && (
                                <AuthorizationDropDown
                                    attachment={{
                                        fileName: autoApprovalData.fileName,
                                        savedFileName: autoApprovalData.savedFileName,
                                        savedFileId: autoApprovalData.savedFileId,
                                        savedFileSizeInKb: autoApprovalData.savedFileSizeInKb,
                                    }}
                                    clientId={id}
                                    completeRemoveAttachment={completeRemoveAttachment}
                                    dropDownOpen={dropDownOpen}
                                    handleSelection={(value) => {
                                        // Reset the file/error fields on authorization type change
                                        setAuthorizationType(value);
                                        if (value === 'EMAIL') {
                                            setRemoveFileId(autoApprovalData.savedFileId);
                                        }
                                        if (fileMissingError) {
                                            setFileMissingError('');
                                        }
                                        if (authorizationValidationError) {
                                            setAuthorizationValidationError(false);
                                        }
                                    }}
                                    fileMissingError={fileMissingError}
                                    hasEditPermission={profileType === 'forwarder' && formik.dirty}
                                    hasValidationError={authorizationValidationError}
                                    label="Client Authorization"
                                    options={authorizationOptions}
                                    placeholder=""
                                    removedFileId={removedFileId}
                                    saveRemovedFileId={setRemoveFileId}
                                    resetFileError={resetFileError}
                                    setDropDownOpen={setDropDownOpen}
                                    selectedOption={authorizationType}
                                    fileRefUpdateCallback={fileRefUpdateCallback}
                                    tempFileAvailable={tempFileAvailable}
                                />
                            )} */}
                        </form>
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <button
                            className={`app-btn app-btn-secondary footer-btn${formik.dirty ? ' disabled' : ''}`}
                            title="Previous"
                            onClick={() => navigate(`/profile/clients/${id}/product_master`)}
                            disabled={formik.dirty}
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </button>
                        <button
                            className={`app-btn app-btn-primary footer-btn${formik.dirty ? ' disabled' : ''}`}
                            title="Next"
                            onClick={() => {
                                navigate(`/profile/clients/${id}/integration_details`);
                            }}
                            disabled={formik.dirty}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </button>
                    </div>
                </div>
                <BookingAutoApprovalLogSidebar
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                ></BookingAutoApprovalLogSidebar>
            </div>
            {showUnSaveModal && (
                <UnsavedModal
                    cancelText="Discard Changes"
                    description="There are unsaved changes in the page."
                    disableSave={
                        // Disable save button on cancel modal if authorisationType or errors
                        (formik.dirty && !authorizationType) ||
                        authorizationValidationError ||
                        (authorizationType === 'EMAIL' && !tempFileAvailable) ||
                        !!fileMissingError
                    }
                    formId="auto-approval-option-selection"
                    handleDiscard={handleDiscard}
                    handleClose={() => setShowUnSaveModal(false)}
                    submitText="Save Changes"
                    title="Unsaved changes"
                />
            )}
        </>
    );
};

export default Index;
