import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../store/reducers';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useLocation } from 'react-router-dom';

interface contractDropdownPropsType {
    contract: string;
    setContract: { (carrier: string): void };
    clientCode: any;
    isErrorMessageShow: boolean;
    additionalOption?: any;
}

const Index: React.FC<contractDropdownPropsType> = (props) => {
    const location = useLocation();
    const { contractList } = useSelector((state: RootState) => state.bookingproposalAirschedule);

    const convertDataForSelect = (data: any) => {
        if (Array.isArray(contractList)) {
            const clientCode = props.clientCode;
            const convertedData = data?.map((option: any) => {
                if (option.ClientsPrimary.includes(clientCode)) {
                    return { value: option.SC_PK, label: option.SC_ContractName + ' (Primary)' };
                } else if (option.ClientsSecondary.includes(clientCode)) {
                    return { value: option.SC_PK, label: option.SC_ContractName + ' (Secondary)' };
                }
            });

            if (props.additionalOption) {
                const mergedArray = props.additionalOption.concat(convertedData);
                return mergedArray;
            }

            return convertedData;
        } else {
            return [];
        }
    };

    return (
        <div className="modal-input-holder layout-item" style={{ width: 300 }}>
            <label
                className={`modal-input-label ${
                    location.pathname.includes('/additional-details') ? 'label-required' : ''
                }`}
            >
                Contract<span className="mandatory-field-text">*</span>
            </label>
            <SearchableSelectBox
                data={convertDataForSelect(contractList)}
                placeholder="Enter Contract Name to Select"
                setStateValue={props.setContract}
                stateValue={props.contract}
                fieldName="contract"
                menuPortalTarget={document.getElementById('outside_div')}
            />
            {props.isErrorMessageShow && (
                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                    contract is required
                </p>
            )}
        </div>
    );
};

export default Index;
