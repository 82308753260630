/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface ReassignApprovalDetails {
    poNumber: string[];
    currentAgentCode: string;
    currentAgentName: string;
    newAgentCode: string;
    newAgentName: string;
    clientName: string[];
    dateRequested: string;
    originPort: string;
    destinationPort: string;
    destinationType: string;
    mode: string;
    vendorCode: string[];
    vendorName: string[];
    eta: string;
    etd: string;
    incoTerm: string;
    releaseType: string;
    submittedBy: string;
    carrierFlights: string;
    bpNumber: string;
}

interface ReassignApprovalState {
    isLoading: boolean;
    reassignApproval: ReassignApprovalDetails[];
    distinctApproval: any;
    reassignApprovalSuccess: any;
    reassignApprovalError: any;
    isError?: boolean;
}

const initialState: ReassignApprovalState = {
    isLoading: false,
    reassignApproval: [],
    distinctApproval: {},
    reassignApprovalSuccess: undefined,
    reassignApprovalError: undefined,
    isError: false,
};

export const reassignApprovalSlice = createSlice({
    name: 'reassignApproval',
    initialState,
    reducers: {
        fetchReassignApprovalList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchReassignApprovalListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.reassignApproval = action.payload?.data?.response ?? [];
        },
        fetchReassignApprovalListFailure(state, action) {
            state.reassignApproval = action.payload;
        },
        fetchReassignApprovalDistinctList: (state, _action) => {
            state.isError = false;
        },
        fetchReassignApprovalDistinctListSuccess(state, action) {
            state.isError = false;
            state.distinctApproval = action.payload?.data?.distinct ?? {};
        },
        fetchReassignApprovalDistinctListFailure(state, action) {
            state.reassignApproval = action.payload;
        },
        agentApproval: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        agentApprovalSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.reassignApprovalSuccess = action.payload;
            state.reassignApprovalError = {};
        },
        agentApprovalFailure: (state, action) => {
            state.isLoading = false;
            state.reassignApprovalSuccess = {};
            state.reassignApprovalError = action.payload;
        },
        agentRejection: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        agentRejectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.reassignApprovalSuccess = action.payload;
            state.reassignApprovalError = {};
        },
        agentRejectionFailure: (state, action) => {
            state.isLoading = false;
            state.reassignApprovalSuccess = {};
            state.reassignApprovalError = action.payload;
        },
        clearFailureApproval: (state, _action) => {
            state.reassignApprovalError = undefined;
            state.reassignApprovalSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchReassignApprovalList,
    fetchReassignApprovalListSuccess,
    fetchReassignApprovalListFailure,
    fetchReassignApprovalDistinctList,
    fetchReassignApprovalDistinctListSuccess,
    fetchReassignApprovalDistinctListFailure,
    agentApproval,
    agentApprovalSuccess,
    agentApprovalFailure,
    agentRejection,
    agentRejectionSuccess,
    agentRejectionFailure,
    clearFailureApproval,
} = reassignApprovalSlice.actions;

export const selectReassignApprovalLoading = (state: RootState) => state.reassignApprovalData.isLoading;
export const selectReassignApproval = (state: RootState) => state.reassignApprovalData.reassignApproval;
export const selectDistinctApproval = (state: RootState) => state.reassignApprovalData.distinctApproval;
export const selectReassignApprovalSuccess = (state: RootState) => state.reassignApprovalData.reassignApprovalSuccess;
export const selectReassignApprovalError = (state: RootState) => state.reassignApprovalData.reassignApprovalError;
export const selectReassignApprovalIsError = (state: RootState) => state.reassignApprovalData.isError;

export default reassignApprovalSlice.reducer;
