import axios from 'axios';
import fileDownload from 'js-file-download';
import { get, patch, post, put } from '../../../HttpMethods';
import { fileUploadForDocuments } from '../../bookingProposal/additionalDetailsApi';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchCommercialDetailsListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/agents/ad/list?agentId=${payload.id}&limit=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus || ''}&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterby=${payload.statusFilter}`
                    : ''
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterByColumn=${payload.statusFilterColumn}`
                    : ''
            }`,
            null
        );

        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCommercialDetailsColumnListData = async (payload: { columnName: string; id: string }) => {
    try {
        const data = await get(`api/mgt/ad/distinct?columnName=${payload.columnName}&agentId=${payload.id}`, null);
        return { ...data, columnName: payload.columnName };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createCommercialDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/mgt/ad', payload, null);
        const file = payload?.get('file');
        if (file) {
            const document = payload?.get('document');
            const text = await document.text();
            const or_PK = JSON.parse(text)?.or_PK;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', or_PK);
            formData.append('DO_FKID', 'OR');
            formData.append('DO_CD_DocumentType', 'HCN');
            await fileUploadForDocuments(formData);
        }
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateCommercialDetailsApi = async (payload: any) => {
    try {
        // payload.ad_PK = '9d01b1e5-708a-44b0-8e34-99bba57c0736';
        // const data = await put(`api/mgt/ad/${payload.ad_PK}`, payload);
        const data = await post(`/cix/scr/admin/organisation/agent/commercials`, payload, null, false, externalBaseUrl);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCommercialDetailsAuthorApi = async () => {
    try {
        const data = await get('api/mgt/ad/forwardersignatory', null);
        const result = data.map((res: any) => ({ value: res.name, label: res.name }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchCommercialDetailsForwarderSignatoryApi = async () => {
    try {
        const data = await get('api/mgt/ad/forwardersignatory', null);
        const result = data.map((res: any) => ({ value: res.name, label: res.name }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchCommercialDetailsAgreementTermsApi = async () => {
    try {
        const data = await get('api/mgt/ad/agreementterms', null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadAgentCommercial = async (ad_pk: string, fileName: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/ad/download/${ad_pk}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const submitAndActivateAgentApi = async (payload: any) => {
    try {
        const data = await patch(`api/mgt/agents/status/${payload.agentId}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const searchCommercialDetails = async (payload: {
    keyword: string;
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/search/agentcommercials?agentId=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus || ''}&sortorder=${
                payload.columnOrder ? 'ASC' : 'DESC'
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterby=${payload.statusFilter}`
                    : ''
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterByColumn=${payload.statusFilterColumn}`
                    : ''
            }`,
            null
        );
        return { data };
    } catch (err) {
        return console.error(err);
    }
};

export const getSingleCommercialData = async (payload: any) => {
    try {
        const data = await get(
            `/cix/scr/admin/organisation/agent/${payload?.AD_AG}/commercial/${payload?.AD_PK}`,
            null,
            externalBaseUrl
        );
        const res = data?.data?.[0];
        const mapped = {
            ad_docname: res?.AD_DocName,
            ad_status: res?.AD_Status,
            ad_iteration: res?.AD_Iteration,
            ad_version: res?.AD_Version,
            ad_author: res?.AD_Author,
            ad_forwardersignatory: res?.AD_ForwarderSignatory,
            ad_agentsignatory: res?.AD_AgentSignatory,
            ad_startdate: res?.AD_StartDate,
            ad_enddate: res?.AD_EndDate,
            ad_renewal: res?.AD_Renewal,
            isAir: res?.AD_IsAir?.toString(),
            isSea: res?.AD_IsSea?.toString(),
            isRail: res?.AD_IsRail?.toString(),
            isRoad: res?.AD_IsRoad?.toString(),
            ad_reviewedby: res?.AD_ReviewedBy,
            ad_agreementtype: res?.AT_Type,
            ad_agreementterms: res?.AT_Term,
            ad_agreementtermsPk: res?.AD_AT,
            ad_agreementtermsvalue: res?.AD_AgreementTermsValue,
            ad_activedate: res?.AD_ActiveDate,
            fileName: res?.AD_FileName,
            savedFileSizeInKb: res?.AD_SavedFileSize,
            createdDateTime: res?.AD_CreatedDateTime,
            modifiedDateTime: res?.AD_ModifiedDateTime,
            createdByUser: res?.AD_CreatedByUser,
            modifiedByUser: res?.AD_ModifiedByUser,
            manuallyEntered: res?.AD_ManuallyEntered,
            ps: res?.AD_PS,
            ad_AG: res?.AD_AG,
            ad_adpk: res?.AD_PK,
            ad_AT: res?.AD_AT,
        };
        return mapped;
    } catch (err) {
        return console.error(err);
    }
};
