import { createSlice } from '@reduxjs/toolkit';
import { shippingTableHeaders } from '../../utils/constants';

export const dataSlice = createSlice({
    name: 'shippingLine',
    initialState: {
        shippingLines: [],
        headerTab: [],
        isLoading: true,
        isLoading2: true,
        currentPage: 0,
        columnStatus: shippingTableHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        filterValues: {},
        itemsInList: 10,
        selectedFilter: null,
        hasNacContract: false,
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchShippingLineData: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload?.columnStatus;
            state.currentPage = action.payload?.currentPage;
            state.shippingLines = action.payload.shippingLines?.shippingLineResponseList;
            const num = action.payload.shippingLines?.shippingLineResponseList?.reduce(
                (prev: number, current: any) => prev + current.childShippingLines?.length,
                0
            );
            state.itemsInList = action.payload.shippingLines?.shippingLineResponseList?.length + (num || 0);
            state.totalPages = action.payload.shippingLines?.totalNoOfPages;
            state.totalItems = action.payload.shippingLines?.totalNoOfItems;
            state.filterValues = action.payload.shippingLines?.distinctColumnValues;
            state.isLoading = false;
            state.isLoading2 = true;
        },
        fetchContract: (state, action) => {
            state.hasNacContract = action.payload.total > 0;
        },
        fetchHeaderData: (state, action) => {
            state.headerTab = action.payload.headerTab?.info;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading2 = action.payload;
        },
        searchShippingLineData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload?.columnStatus;
            state.shippingLines = action.payload.shippingLines?.content;
            state.totalPages = action.payload.shippingLines?.totalPages;
            state.totalItems = action.payload.shippingLines?.totalElements;
            state.filterValues = action.payload.shippingLines?.distinctColumnValues;
            const num = action.payload.shippingLines?.content?.reduce(
                (prev: number, current: any) => prev + current.childShippingLines?.length,
                0
            );
            state.itemsInList = action.payload.shippingLines?.content?.length + (num || 0);
            state.isLoading = false;
            state.isLoading2 = false;
        },
        setselectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});

export const {
    fetchShippingLineData,
    fetchHeaderData,
    setPageCount,
    isLoadingFn,
    setDefault,
    searchShippingLineData,
    setselectedFilter,
    setCurrentPage,
    fetchContract,
} = dataSlice.actions;
export default dataSlice.reducer;
