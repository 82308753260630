import React from 'react';
import './insightHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterList } from 'src/pages/Dashboard/InsightsExpanded/enums';
import { RootState } from 'src/store/reducers';
import { makeApiCall } from '../../Insights/makeApiCall';
import { insightTabList } from 'src/pages/Dashboard/Insights/enums';
import {
    // setInsightsMode,
    setInsightsDurationRange,
    // setInsightsMainPage,
    // setInsightsSelectedType,
    // setInsightsSelectedTypeFilter,
    // setSelectedTypeFilterIndex,
    // setInsightsForwarded,
} from 'src/store/reducers/dashboard/insightsReducer';

//Faheem
const Index = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //======================= ReduxValues ===========================//
    const {
        insightsDurationRange,
        insightsMode,
        insightsDestination,
        insightsSelectedType,
        insightTab,
        insightsMainPage,
    } = useSelector((state: RootState) => state.dashboardInsights);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);

    //===============================================================//

    const isActiveOrders = insightsMainPage === 'Active Orders' || insightsMainPage === '';
    const isActiveOrderDetails = insightsMainPage === 'Active Order Details';

    const isActiveBookings = insightsMainPage === 'Active Bookings';
    const isActiveBookingsDetails = insightsMainPage === 'Active Bookings Details';

    const findWhichApiTobeCalled = () => {
        let apiName = '';
        if (isActiveOrders) {
            apiName = 'activeOrder';
        } else if (isActiveOrderDetails) {
            apiName = 'activeOrdersDetails';
        } else if (isActiveBookings) {
            apiName = 'activeBooking';
        } else if (isActiveBookingsDetails) {
            apiName = 'activeBookingsDetails';
        } else {
            apiName = 'activeOrder';
        }
        return apiName;
    };
    const handleClick = (type: any) => {
        dispatch(setInsightsDurationRange(type));
        makeApiCall(
            dispatch,
            insightsMode,
            type,
            insightsDestination,
            findWhichApiTobeCalled(),
            insightsSelectedType,
            '',
            selectedSearchItem,
            selectedSearchItem?.type
        );
    };
    return (
        <div className="page-header">
            <div>
                <h4 className="page-title">Insights</h4>
            </div>
            <div className="page-filters">
                <div className="date-filter">
                    <button
                        className={
                            insightsDurationRange === FilterList.Days
                                ? 'app-btn calendar-set active'
                                : 'app-btn calendar-set'
                        }
                        onClick={() => {
                            handleClick(FilterList.Days);
                        }}
                    >
                        {FilterList.Days}
                    </button>
                    <button
                        className={
                            insightsDurationRange === FilterList.Weeks
                                ? 'app-btn calendar-set active'
                                : 'app-btn calendar-set'
                        }
                        onClick={() => {
                            handleClick(FilterList.Weeks);
                        }}
                    >
                        {FilterList.Weeks}
                    </button>
                    <button
                        className={
                            insightsDurationRange === FilterList.Months
                                ? 'app-btn calendar-set active'
                                : 'app-btn calendar-set'
                        }
                        onClick={() => {
                            handleClick(FilterList.Months);
                        }}
                    >
                        {FilterList.Months}
                    </button>
                </div>
                <button
                    className="view-nav grid-icon icon-nav "
                    title="Collapse"
                    onClick={() => {
                        navigate('/', {
                            state: {
                                fromAll: insightTab === insightTabList.all ? 'true' : 'false',
                                persistFavSelection: true,
                            },
                        });
                    }}
                >
                    <svg className="svg-icon maxi-icon ">
                        <use xlinkHref="#minimaxIcon">
                            <title>Collapse</title>
                        </use>
                    </svg>
                </button>
            </div>
        </div>
    );
};
export default Index;
