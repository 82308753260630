import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { fileTypesInfoText, fileUploadInfoText } from 'src/utils/constants';
import { cixUploadDoc, fetchDocumentTypes, resetUploadResult } from 'src/store/reducers/documentReducer';
import { RootState } from 'src/store/reducers';
import { Position, error } from 'src/utils/toast';

interface FileUploadProps {
    setShowFileUploadModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    referenceId: any;
    referenceType: string;
}

interface FileForm {
    fileType: string;
    fileName: string;
}

const Index = ({ setShowFileUploadModal, referenceId, referenceType }: FileUploadProps): ReactElement => {
    // const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [file, setFile] = useState(null as any);
    const { fileTypes, uploadResult } = useSelector((state: RootState) => state.document);

    useEffect(() => {
        dispatch(fetchDocumentTypes({ searchTerm }));
    }, [searchTerm]);

    useEffect(() => {
        if (uploadResult?.length > 0) {
            if (uploadResult[0].error?.status === 400) {
                error(uploadResult[0].stack, Position.TOP_RIGHT);
            }
            if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
            dispatch(resetUploadResult({}));
            setShowFileUploadModal(false);
        }
    }, [uploadResult]);

    const validationSchema = Yup.object().shape({
        fileType: Yup.string().required('File type is Required'),
        fileName: Yup.string().required('File upload is Required'),
    });

    const initialValues: any = {
        fileType: '',
        fileName: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('DO_FK', referenceId);
                formData.append('DO_FKID', referenceType);
                formData.append('DO_CD_DocumentType', values.fileType);
                const payload = {
                    data: formData,
                };

                // if (referenceType === 'RATE') dispatch(cixUploadRateDoc(payload));
                // else
                dispatch(cixUploadDoc(payload));
            }
        },
    });

    const removeFile = (fileId: any) => {
        setFile(null);
        formik.setFieldValue('fileName', '');
    };

    return (
        <div className="app-modal">
            <div className="modal-content-holder medium-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">File Upload</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowFileUploadModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <form id="cancel-form" action="" className="modal-form" onSubmit={formik.handleSubmit}>
                            <div className=" modal-input-holder layout-item">
                                <label className="modal-input-label">
                                    Type<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <SearchableSelectBox
                                        // CG_PK
                                        data={fileTypes?.length > 0 ? fileTypes : []}
                                        placeholder="Enter file type and search"
                                        formik={formik}
                                        fieldName="fileType"
                                        isClearable={true}
                                        onInputChangeFn={(input: any) => {
                                            // setSearchTerm(input?.toUpperCase());
                                        }}
                                    />
                                </div>
                                {formik.errors.fileType ? (
                                    <div className="error-text">{formik.errors.fileType as any}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    File<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    {file ? (
                                        <div className="content-with-icon  attachment-holder">
                                            <div
                                                className="attachment-icon-holder"
                                                // onClick={() => downloadFileSingle(file?.fileId, file?.fileName)}
                                            >
                                                <button type="button" className="app-btn " title="Attach file">
                                                    <svg className="svg-icon file-attachment-icon ">
                                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                                        <title>Attach file</title>
                                                    </svg>
                                                </button>
                                                <div className="filename-folder">
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="description-type-1 file-uploaded"
                                                        title="Download"
                                                    >
                                                        {file?.name}
                                                    </a>
                                                    <div className="heading-type-1">{file?.size}kb</div>
                                                </div>
                                            </div>

                                            <a
                                                href="javascript:void(0)"
                                                className="file-remove-link"
                                                title="Remove"
                                                onClick={() => removeFile(file?.name)}
                                            >
                                                Remove
                                            </a>
                                        </div>
                                    ) : (
                                        <div className="modal-input-layout-item">
                                            <div className="browse-btn-wrapper">
                                                <div className=" modal-input-holder upload-file-btn">
                                                    <input
                                                        type="file"
                                                        id={'ad_file'}
                                                        name={'ad_file'}
                                                        accept={
                                                            '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                        }
                                                        className="browse-input"
                                                        onChange={(e) => {
                                                            setFile((e.target.files as any[0])[0]);
                                                            formik.setFieldValue(
                                                                'fileName',
                                                                (e.target.files as any[0])[0].name
                                                            );
                                                        }}
                                                    ></input>
                                                    <button className="app-btn app-btn-secondary icon-button browse-btn">
                                                        <svg className="svg-icon plus-icon">
                                                            <use xlinkHref="#plusIcon">
                                                                <title>Upload File</title>
                                                            </use>
                                                        </svg>
                                                        <span className="button-text upload-btn">
                                                            Drag or Select File to Upload
                                                        </span>
                                                    </button>

                                                    {/* <div className="error-text">mk</div> */}
                                                </div>
                                                <div className="heading-type-1 file-size">
                                                    <p style={{ textAlign: 'left' }}>{fileUploadInfoText}</p>
                                                    <p
                                                        style={{ textAlign: 'left' }}
                                                        dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}
                                                    ></p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {formik.errors.fileName ? (
                                        <div className="error-text">{formik.errors.fileName as any}</div>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowFileUploadModal(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className={`app-btn app-btn-primary modal-btn footer-btn`}
                        type="submit"
                        form="cancel-form"
                        title="Confirm Cancellation"
                    >
                        <span className="button-text footer-button-text">Confirm Upload</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
