import React from 'react';
import '../../BookingProposal/BookingAdditionalInfo/no-data-additional-modal.scss';
// import Lottie from 'lottie-react';
import { useLottie } from 'lottie-react';
import containerShip from '../../../assets/animation/containerShip.json';
import { BpQuotationList } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { Link } from 'react-router-dom';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: containerShip,
    rendererSettings: {
        className: 'lottie-svg-class',
        id: 'lottie-svg-id',
    },
};
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: BpQuotationList[];
    loading: boolean;
    setShowNoData: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    header: { clientId: string; clientName: string };
}
const Index: React.FC<ModalProps> = ({ modalView, setModalView, data, loading, setShowNoData, header }) => {
    const { View } = useLottie(defaultOptions);
    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder no-data-additional-detail-modal">
                        <div className="modal-header">
                            <div className="title">Additional Details</div>
                            <button
                                onClick={() => {
                                    setModalView(false);
                                    setShowNoData(false);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                {data?.length === 0 && !loading ? (
                                    <div className="no-data-content">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No Quotations available</p>
                                        <p className="no-content-message-detail">
                                            Add a valid Quotation to the Client profile to continue
                                        </p>
                                        <Link
                                            className="add-content-link"
                                            to={`/profile/clients/${header.clientId}/contract_details`}
                                            onClick={() => localStorage.setItem('clientName', header.clientName)}
                                        >
                                            Go to Client Profile
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="no-data-content checking-data-content">
                                        {View}
                                        <p className="no-content-message">
                                            Checking for available Quotations in Client Profile
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    setModalView(false);
                                    setShowNoData(false);
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Save"
                                disabled
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
