import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchAttachmentHistoryList,
    fetchAttachmentHistoryListSuccess,
    fetchAttachmentHistoryDistinctList,
    fetchAttachmentHistoryDistinctListSuccess,
    fetchAttachmentHistoryListFailure,
    fetchAttachmentHistoryDistinctListFailure,
} from '../reducers/attachmentHistoryReducer';
import {
    fetchAttachmentHistoryListDetailsApi,
    fetchAttachmentHistoryDistinctListDetailsApi,
} from '../../services/apis/attachmentHistoryApi';

function* getAttachmentHistoryDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchAttachmentHistoryListDetailsApi, payload);
        yield put({ type: fetchAttachmentHistoryListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAttachmentHistoryListFailure, payload: error });
    }
}
function* getAttachmentHistoryDistinctDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchAttachmentHistoryDistinctListDetailsApi, payload);
        yield put({ type: fetchAttachmentHistoryDistinctListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAttachmentHistoryDistinctListFailure, payload: error });
    }
}

function* attachmentHistorySaga() {
    yield takeEvery(fetchAttachmentHistoryList, getAttachmentHistoryDetails);
    yield takeEvery(fetchAttachmentHistoryDistinctList, getAttachmentHistoryDistinctDetails);
}

export default attachmentHistorySaga;
