import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'additionalDetailsNacao',
    initialState: {
        additionalDetails: {
            referenceNumber: null,
            bookingContractNo: null,
            contractType: null,
            contractVersion: null,
            clientType: null,
            contractName: null,
            contractId: null,
            sellQuotationName: null,
            sellQuotationVersion: null,
            sellQuotationId: null,
            sellTotOriginCost: null,
            sellCurrency: {
                currencyId: null,
                currencyName: null,
                currencycode: null,
            },
            savedFakFileName: null,
            savedFakFileId: null,
            savedFakFileSizeInKb: null,
            totOriginCost: 0,
            totOriginCostCurrency: null,
            isBookingReset: false,
            bookingResetCode: '',
        },
        isLoading: true,
        error: false,
        success: false,
        containers: [],
        deliveryModes: [],
        containerModes: [],
        containerTypes: [],
        addOrEditContainerSuccess: false,
        addOrEditContainerError: false,
        containerDeleteError: false,
        containerDeleteSuccess: false,
        contractDetails: {},
        initialContainerValues: [],
    },
    reducers: {
        fetchNacAdditionalDetails: (state, action) => {
            state.isLoading = true;
            state.additionalDetails = action.payload?.data?.details;
            state.containers = action.payload?.data?.containers;
            state.initialContainerValues = action.payload?.data?.containers;
            state.isLoading = false;
        },
        fetchDeliveryAndContainerModes: (state, action) => {
            state.deliveryModes = action.payload?.data?.deliveryModes;
            state.containerModes = action.payload?.data?.containerModes;
            state.containerTypes = action.payload?.data?.containerTypes;
        },
        addRefNumberNac: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.data === 'Success';
            if (action.payload?.data) {
                state.error = action.payload?.data !== 'Success';
            }
            state.isLoading = false;
        },
        addContainerNac: (state, action) => {
            state.isLoading = true;
            state.addOrEditContainerSuccess = action.payload?.data?.cdId;
            if (action.payload?.data) {
                state.addOrEditContainerError = !action.payload?.data?.cdId;
            }
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        resetSuccessFlags: (state, _action) => {
            state.success = false;
            state.error = false;
            state.addOrEditContainerError = false;
            state.addOrEditContainerSuccess = false;
            state.containerDeleteSuccess = false;
            state.containerDeleteError = false;
        },
        setContainers: (state, action) => {
            state.containers = action.payload;
        },
        editContainerNac: (state, action) => {
            state.isLoading = true;
            state.addOrEditContainerSuccess = action.payload?.info === 'Success';
            if (action.payload?.info) {
                state.addOrEditContainerError = action.payload?.info !== 'Success';
            }
            state.isLoading = false;
        },
        deleteContainerNac: (state, action) => {
            state.isLoading = true;
            state.containerDeleteSuccess = action.payload?.data === 'Success';
            if (action.payload?.data) {
                state.containerDeleteError = action.payload?.data !== 'Success';
            }
            state.isLoading = false;
        },
        fetchContractDetails: (state, action) => {
            state.contractDetails = action.payload?.contractDetails;
        },
        addFakDetails: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.data?.success === true;
            if (action.payload?.data && !action.payload?.data?.success) {
                state.error = true;
            }
            state.isLoading = false;
        },
        editFakDetails: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.data?.success === true;
            if (action.payload?.data && !action.payload?.data?.success) {
                state.error = true;
            }
            state.isLoading = false;
        },
    },
});

export const {
    fetchNacAdditionalDetails,
    fetchDeliveryAndContainerModes,
    addContainerNac,
    addRefNumberNac,
    addFakDetails,
    isLoadingFn,
    resetSuccessFlags,
    setContainers,
    editContainerNac,
    deleteContainerNac,
    fetchContractDetails,
    editFakDetails,
} = dataSlice.actions;
export default dataSlice.reducer;
