import React, { useState } from 'react';

import '../BookingAdditionalInfo/reject-booking-proposal.scss';
import './PromptsModal.scss';
import { useParams } from 'react-router-dom';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { success, Position, error } from 'src/utils/toast';
import { InputActionMeta } from 'react-select';
import { saveColoaderDataApi } from 'src/services/apis/purchaseOrder/containerModePoApi';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    coloaderData: any;
    getColoader: any;
}

const Index: React.FC<ModalProps> = ({ setModalView, coloaderData, getColoader }) => {
    const { id } = useParams();
    const [coloader, setColoader] = useState({ coloaderCode: '', coloaderName: '' });
    const [isLoading, setIsLoading] = useState(false);
    const saveColoader = async () => {
        try {
            setIsLoading(true);
            const res: any = await saveColoaderDataApi({
                bpId: id,
                coloaderCode: coloader?.coloaderCode,
                coloaderName: coloader?.coloaderName,
            });
            setTimeout(() => {
                window.location.href = `/lclbooking_list/${id}/SailingSelectionDetails`;
            }, 2000);
            success(res?.message ?? 'Coloader added successfully', Position.TOP_RIGHT);
        } catch (err: any) {
            error(err?.response?.data?.message, Position.TOP_RIGHT);
            setIsLoading(false);
        }
    };

    return (
        <div className="app-modal">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder medium-popup reject-booking-modal">
                <div className="modal-header">
                    <div className="title">Add Coloader</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className=" modal-input-holder">
                            <label htmlFor="name" className="modal-input-label">
                                Coloader<span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-field-holder">
                                <SearchableSelectBox
                                    data={coloaderData}
                                    placeholder="Select Coloader"
                                    setStateValue={(value: string) => {
                                        const filtered = coloaderData?.find((res: any) => res?.value === value);
                                        setColoader({ coloaderCode: filtered?.value, coloaderName: filtered?.label });
                                    }}
                                    onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                                        if (value?.length > 2) {
                                            getColoader(value);
                                        }
                                    }}
                                    onFocusFn={() => {
                                        if (!coloader?.coloaderCode) {
                                            getColoader('');
                                        }
                                    }}
                                    onClear={() => getColoader('')}
                                    stateValue={coloader?.coloaderCode}
                                    fieldName="coloader"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer prompts-footer-modal">
                    <button
                        className="app-btn app-btn-secondary  modal-btn footer-btn "
                        type="button"
                        title="Cancel"
                        onClick={() => setModalView(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Submit"
                        onClick={() => {
                            saveColoader();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
        </div>
    );
};
export default Index;
