import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchHeaderData,
    saveClientData,
    isLoadingFn,
    searchClientData,
    getAllClientDataList,
} from '../reducers/clientListReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    getClientData,
    clientData,
    clientDataFull,
    // getHeaderData,
    searchClients,
    clientSearchData,
    getAllClientList,
} from '../../services/apis/clientListApi';
import { getClientDetails } from 'src/services/apis/clientApi';
import { fetchClientDetails } from '../reducers/clientReducer';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const clients: AxiosResponse<clientDataFull[]> = yield call(getClientData, payload);
        payload['clients'] = clients;
        yield put({ type: saveClientData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getAllClients({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse = yield call(getAllClientList, payload);

        yield put({ type: getAllClientDataList, payload: response });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getHeaderVal({ payload }: { payload: any; type: any }) {
    const header: AxiosResponse<clientData[]> = yield call;
    payload['headerTab'] = header;
    yield put({ type: fetchHeaderData, payload: payload });
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const clients: AxiosResponse<clientSearchData> = yield call(searchClients, payload);
        payload['clients'] = clients;
        yield put({ type: searchClientData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* fetcClientInfo({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getClientDetails, payload);
        payload['clientDetails'] = response?.data[0];
        yield put({ type: fetchClientDetails, payload: payload });
    } finally {
    }
}

function* clientSaga() {
    yield takeEvery('clients/fetchClientData', getListData);
    yield takeEvery('clients/fetchHeaderData', getHeaderVal);
    yield takeEvery('clients/searchClientData', getSearchData);
    yield takeEvery('clients/getAllClientDataList', getAllClients);
    yield takeEvery('clientDetails/fetchClientDetails', fetcClientInfo);
}

// Export the saga (data-saga)
export default clientSaga;
