import { createSlice } from '@reduxjs/toolkit';

export const integrationSlice = createSlice({
    name: 'integrationDetails',
    initialState: {
        integrationData: [{ ci_integrationtype: '', ci_functiontype: '', ci_PK: '' }],
        isLoading: true,
        response: {},
        clientPK: '',
        isError: false,
    },
    reducers: {
        addIntegrationData: (state, action) => {
            state.isLoading = true;
            state.integrationData = action.payload.clientintegration;
            state.clientPK = action.payload.cl_pk;
            state.isLoading = false;
        },
        fetchIntegrationData: (state, action) => {
            state.isLoading = true;
            state.integrationData = action.payload.integrationData;
            state.clientPK = action.payload.clientPK;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        submitAndActivate: (state, action) => {
            state.isLoading = true;
            state.clientPK = action.payload.clientPK;
            state.isLoading = false;
        },
    },
});

export const { addIntegrationData, fetchIntegrationData, isLoadingFn, submitAndActivate } = integrationSlice.actions;
export default integrationSlice.reducer;
