import { NotificationData } from 'src/store/reducers/authReducer';

export const notificationListIcon = (notificationType: string) => {
    switch (notificationType) {
        case 'CREATE_PO':
        case 'RELEASE_AO':
        case 'BP_CREATED':
        case 'APPROVED':
        case 'CG_SELECTION':
        case 'CG_PENDING_ACTION_FORWARDER':
            return (
                <svg className="svg-icon note-icon">
                    <use xlinkHref="#noteIcon">
                        <title>Notifications Note</title>
                    </use>
                </svg>
            );

        case 'CG_AWAITING_CLIENT_SELECTION':
            return (
                <svg className="svg-icon note-icon draft-note-icon">
                    <use xlinkHref="#noteIcon">
                        <title>Notifications Note</title>
                    </use>
                </svg>
            );

        case 'DISCREPANCY_CREATED':
        case 'FORWARDED_BY_AGENT':
        case 'UPDATE_QUANTITY_ADJUSTMENT':
        case 'ENQUIRY_RAISED':
        case 'RESPONDED_BY_AGENT':
            return (
                <svg className="svg-icon inventory-icon">
                    <use xlinkHref="#inventoryIcon">
                        <title>Notification inventory</title>
                    </use>
                </svg>
            );
        case 'UPDATE_PO':
        case 'UPDATE_DESTINATION':
        case 'ZERO_QUANTITY_PO_LINE':
        case 'CHANGE_VESSEL':
            return (
                <svg className="svg-icon notification-edit-icon">
                    <use xlinkHref="#notificationEditIcon">
                        <title>Notifications</title>
                    </use>
                </svg>
            );
        case 'AUTO_APPROVAL_FAILURE':
            return (
                <svg className="svg-icon notification-failed-icon">
                    <use xlinkHref="#notificationFailedIcon">
                        <title>Notifications</title>
                    </use>
                </svg>
            );
        case 'CANCEL_PO':
        case 'CG_REJECTED_BY_CLIENT':
        case 'BP_REJECTED':
            return (
                <svg className="svg-icon note-draft-icon">
                    <use xlinkHref="#noteDraftIcon">
                        <title>Notification Note</title>
                    </use>
                </svg>
            );

        default:
            return (
                <svg className="svg-icon note-icon">
                    <use xlinkHref="#noteIcon">
                        <title>Notifications Note</title>
                    </use>
                </svg>
            );
    }
};

const splitKey: Record<string, keyof NotificationData> = {
    ZERO_QUANTITY_PO_LINE: 'poLineNumber',
    UPDATE_DESTINATION: 'poNumber',
    CHANGE_VESSEL: 'bpNumber',
    AUTO_APPROVAL_FAILURE: 'bpNumber',
    ENQUIRY_RAISED: 'dsId',
    DISCREPANCY_CREATED: 'blpName',
    FORWARDED_BY_AGENT: 'blpName',
    RESPONDED_BY_AGENT: 'blpName',
    UPDATE_QUANTITY_ADJUSTMENT: 'blpName',
    CG_SELECTION: 'confirmationGroupNum',
    CG_PENDING_ACTION_FORWARDER: 'confirmationGroupNum',
    CG_AWAITING_CLIENT_SELECTION: 'confirmationGroupNum',
    CG_REJECTED_BY_CLIENT: 'confirmationGroupNum',
    BP_CREATED: 'bpNumber',
    BP_REJECTED: 'bpNumber',
    APPROVED: 'bpNumber',
};

export const notificationContentDetailsList = (notificationData: NotificationData) => {
    const keyToSplit = String(notificationData[splitKey[notificationData?.notificationType] ?? 'poNumber']);
    const notificationMessage = notificationData?.notification.split(keyToSplit);
    const firstPart = notificationMessage?.length ? notificationMessage[0] : '';
    const lastPart = notificationMessage?.length === 2 ? notificationMessage[1] : '';

    switch (notificationData?.notificationType) {
        case 'ZERO_QUANTITY_PO_LINE': {
            const redirectTo = `/${
                notificationData?.itemType === 'INBOUND'
                    ? 'inbound'
                    : notificationData?.itemType === 'AO'
                    ? 'allocation_orders'
                    : 'purchase_order'
            }/${notificationData?.poId}/product_details_confirmation`;
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
        case 'CHANGE_VESSEL': {
            let redirectTo = '';
            if (notificationData?.bpType === 'LCL') {
                redirectTo = `/lclbooking_list/${notificationData?.bpId}/SailingSelectionDetails`;
            } else if (notificationData?.bpType === 'AO') {
                redirectTo = `/Aobooking_list/${notificationData?.bpId}/SailingSelectionDetails`;
            } else if (notificationData?.bpType === 'BCN') {
                redirectTo = `/bcnBookingList/${notificationData?.bpId}/SailingSelectionDetails`;
            } else {
                redirectTo = `/booking_list/${notificationData?.bpId}/SailingSelectionDetails`;
            }
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
        case 'AUTO_APPROVAL_FAILURE': {
            const redirectTo = `/${notificationData?.bpType === 'AO' ? 'Aobooking_list' : 'booking_list'}/${
                notificationData?.bpId
            }/${notificationData?.bpType === 'AO' ? 'AoSelection' : 'POSelection'}`;
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
        case 'ENQUIRY_RAISED': {
            const redirectTo = '/inventory';
            const state = {
                blpname: notificationData?.blpName,
                discrepancy: true,
            };
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'navigate', state };
        }
        case 'DISCREPANCY_CREATED':
        case 'FORWARDED_BY_AGENT':
        case 'RESPONDED_BY_AGENT':
        case 'UPDATE_QUANTITY_ADJUSTMENT': {
            const redirectTo = '/inventory';
            const state = {
                blpname: notificationData?.blpName,
                discrepancy: notificationData?.notificationType !== 'UPDATE_QUANTITY_ADJUSTMENT' ? true : false,
            };
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'navigate', state };
        }

        case 'CG_SELECTION':
        case 'CG_PENDING_ACTION_FORWARDER':
        case 'CG_AWAITING_CLIENT_SELECTION':
        case 'CG_REJECTED_BY_CLIENT': {
            const redirectTo = `/purchase_order/${notificationData?.poId}/container_mode_selection/${notificationData?.confirmationGroupId}`;
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
        case 'BP_CREATED':
        case 'APPROVED':
        case 'BP_REJECTED': {
            let redirectTo = '';
            if (notificationData?.bpType === 'LCL') {
                redirectTo = `/lclbooking_list/${notificationData?.bpId}/POSelection`;
            } else if (notificationData?.bpType === 'AIR') {
                redirectTo = `/air_booking_list/${notificationData?.bpId}/POSelection`;
            } else if (notificationData?.bpType === 'BCN') {
                redirectTo = `/bcnBookingList/${notificationData?.bpId}/POSelection`;
            } else if (notificationData?.bpType === 'AO') {
                redirectTo = `/Aobooking_list/${notificationData?.bpId}/AoSelection`;
            } else {
                redirectTo = `/booking_list/${notificationData?.bpId}/POSelection`;
            }

            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
        case 'UPDATE_DESTINATION': {
            const redirectTo = `/purchase_order/${notificationData?.poId}/basic_details`;
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
        case 'AGENT_REASSIGNMENT': {
            const startsWith = notificationData?.notification.startsWith('Purchase orders')
                ? 'Purchase orders'
                : 'Booking Proposals';
            const redirectTo = startsWith === 'Purchase orders' ? '/purchase_order' : '/booking_list';
            const notificationMessage = notificationData?.notification.split(startsWith);
            const lastPart = notificationMessage?.length === 2 ? notificationMessage[1] : '';
            return {
                ...notificationData,
                firstPart: '',
                lastPart,
                redirectTo,
                keyToSplit: startsWith,
                type: 'link',
                state: {},
            };
        }
        default: {
            const redirectTo = `/${
                notificationData?.itemType === 'INBOUND'
                    ? 'inbound'
                    : notificationData?.itemType === 'AO'
                    ? 'allocation_orders'
                    : 'purchase_order'
            }/${notificationData?.poId}/basic_details`;
            return { ...notificationData, firstPart, lastPart, redirectTo, keyToSplit, type: 'link', state: {} };
        }
    }
};
