import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import './cancellation-modal.scss';

interface CancelModalProps {
    onClose: () => void;
    onConfirm: (reason: any) => void;
    id: string;
}

const Index = ({ onClose, onConfirm, id }: CancelModalProps): ReactElement => {
    const [reason, setReason] = useState('');
    const [error, setError] = useState(false);
    const headerData = useAppSelector(selectHeader);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            fetchHeader({
                poId: id,
            })
        );
    }, []);

    const onConfirmCancellation = () => {
        if (reason?.trim()?.length) {
            onConfirm(reason);
        } else {
            setError(true);
        }
    };

    return (
        <div className="app-modal">
            <div className="modal-content-holder medium-popup po-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Cancel PO</div>
                    <button className="app-btn modal-close-btn" onClick={onClose}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">
                                Once cancelled, this PO will be inactive and the action cannot be undone
                            </div>
                        </div>
                        <div className="layout-item">
                            <h6 className="heading-type-1">PO Number</h6>
                            <div className="description-type-1">{headerData?.poNumber}</div>
                        </div>
                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Client Name</h6>
                                <div className="description-type-1">{headerData?.clientName}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Vendor Name</h6>
                                <div className="description-type-1">{headerData?.vendorName}</div>
                            </div>
                        </div>
                        <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder="Enter the reason to cancel PO"
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                            if (error) {
                                                setError(false);
                                            }
                                        }}
                                        required
                                    ></textarea>
                                </div>
                                {error ? (
                                    <div className="error-text">Please provide a reason for PO cancellation</div>
                                ) : null}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back to PO"
                        onClick={onClose}
                    >
                        <span className="button-text footer-button-text">Back to PO</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="my-form"
                        title="Confirm PO Cancellation"
                        onClick={onConfirmCancellation}
                        disabled={!reason}
                    >
                        <span className="button-text footer-button-text">Confirm PO Cancellation</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
