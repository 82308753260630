import { createSlice } from '@reduxjs/toolkit';

interface lifecycleSearchFavType {
    favList: any;
    isError: boolean;
    isLoading: boolean;
    error: any;
    removeSuccess: boolean;
    addSuccess: boolean;
}

const initialState: lifecycleSearchFavType = {
    favList: [],
    isError: false,
    error: {},
    isLoading: false,
    removeSuccess: false,
    addSuccess: false,
};

export const lifecyclesearchFavSlice = createSlice({
    name: 'lifecyclesearchFav',
    initialState,
    reducers: {
        fetchFavList: (state, _action) => {
            state.isLoading = true;
        },
        fetchFavListSuccess: (state, action) => {
            state.isLoading = false;
            state.favList = action.payload;
        },
        fetchFavListError: (state) => {
            state.isLoading = false;
            state.isError = true;
            state.favList = {};
        },
        addToFavList: (state, _action) => {
            state.isLoading = true;
        },
        addToFavListSuccess: (state, _action) => {
            state.isLoading = false;
            state.addSuccess = true;
        },
        addToFavListError: (state, action) => {
            state.error = {
                message: action.payload?.data?.message,
                statusCode: action.payload?.status,
            };
            state.isLoading = false;
            state.isError = true;
        },
        resetAddSuccessFlag: (state) => {
            state.addSuccess = false;
        },
        removeFromFav: (state, _action) => {
            state.isLoading = true;
        },
        removeFromFavSuccess: (state, _action) => {
            state.isLoading = false;
            state.removeSuccess = true;
        },
        resetRemoveSuccessFlag: (state) => {
            state.removeSuccess = false;
        },
        removeFromFavError: (state) => {
            state.isLoading = false;
            state.isError = true;
            state.favList = {};
        },
        resetErrorFlags: (state) => {
            state.error = {};
            state.isLoading = false;
            state.isError = false;
        },
    },
});

export const {
    fetchFavList,
    fetchFavListSuccess,
    fetchFavListError,
    addToFavList,
    addToFavListSuccess,
    addToFavListError,
    resetAddSuccessFlag,
    removeFromFav,
    removeFromFavSuccess,
    removeFromFavError,
    resetRemoveSuccessFlag,
    resetErrorFlags,
} = lifecyclesearchFavSlice.actions;

export default lifecyclesearchFavSlice.reducer;
