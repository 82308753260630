import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteAirBPOptions } from 'src/services/apis/bookingProposalAir/optionsApi';
import { success, error, Position } from 'src/utils/toast';

interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    refreshGrid: any;
    detailData: any;
}

const DeleteModal = ({ setModalView, refreshGrid, detailData }: ModalProps): ReactElement => {
    const { id } = useParams();
    const [reason, setReason] = useState('');
    const [saving, setSaving] = useState(false);

    const onSubmit = async () => {
        setSaving(true);
        try {
            const response: any = await deleteAirBPOptions(detailData?.ZO_PK, id ?? '', reason);
            if (response?.status) {
                success(`Options deleted successfully`, Position.TOP_RIGHT);
                setModalView(false);
                refreshGrid();
            } else {
                error('Something went wrong', Position.TOP_RIGHT);
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="app-modal">
            <div className="app-loader">
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder small-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Delete Options</div>
                    <button className="app-btn modal-close-btn" onClick={() => setModalView(false)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder="Enter the reason to Delete Options"
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={() => setModalView(false)}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title={saving ? 'Deleting...' : 'Confirm Deletion'}
                        onClick={() => onSubmit()}
                        disabled={reason && !saving ? false : true}
                    >
                        <span className="button-text footer-button-text">
                            {saving ? 'Deleting...' : 'Confirm Deletion'}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default DeleteModal;
