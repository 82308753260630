import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { isLoadingFn, setWarning } from '../../reducers/bookingProposal/attachmentsReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    downloadAllFiles,
    downloadSingleFile,
    getAllFiles,
    removeFile,
    uploadFile,
} from '../../../services/apis/bookingProposal/attachmentApis';
import { saveFiles } from '../../reducers/bookingProposal/attachmentsReducer';

function* getFileList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const files: AxiosResponse<any> = yield call(getAllFiles, payload);

        yield put({ type: saveFiles, payload: files });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* uploadSingleFile({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const files: AxiosResponse<any> = yield call(uploadFile, payload);

        if ((files as any)?.code === 'ERR_BAD_REQUEST') {
            yield put({
                type: setWarning,
                payload: {
                    errorStat: true,
                    message: (files as any)?.response?.data?.data,
                },
            });
        }
    } catch (err) {
        console.log(err, 'fileserror123 err');
    } finally {
        const files: AxiosResponse<any> = yield call(getAllFiles, payload?.id);
        yield put({ type: saveFiles, payload: files });
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* removeSingleFile({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(removeFile, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        const files: AxiosResponse<any> = yield call(getAllFiles, payload?.id);
        yield put({ type: saveFiles, payload: files });
        yield put(hideLoading());
    }
}

function* downloadSingle({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(downloadSingleFile, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* downloadAll({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(downloadAllFiles, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* attachmentSaga() {
    yield takeEvery('attachments/fetchFiles', getFileList);
    yield takeEvery('attachments/uploadFiles', uploadSingleFile);
    yield takeEvery('attachments/removeSingleFile', removeSingleFile);
    yield takeEvery('attachments/downloadSingle', downloadSingle);
    yield takeEvery('attachments/downloadAll', downloadAll);
}

export default attachmentSaga;
