import React, { useEffect } from 'react';
import '../../BookingProposal/BookingProcessTab/booking-process-tab.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
interface HeaderProps {
    id?: string;
    navData?: any;
}

const Index: React.FC<HeaderProps> = (props) => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const { profileType } = useUserProfile();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate() as any;

    useEffect(() => {
        //navigate to booking list when trying to access other pages other than add po while no po is selected
        if (
            !location.pathname.includes('AoSelection') &&
            !location.pathname.includes('events') &&
            !location.pathname.includes('notes') &&
            !location.pathname.includes('attachments') &&
            headerData?.tabValues &&
            headerData?.bpId &&
            !headerData?.tabValues?.po
        ) {
            navigate('/booking_list');
        }
    }, [headerData]);

    const tabCheckFunction = (element: any) => {
        let flag = false;
        switch (element.title) {
            case 'AO Selection':
                if (element.selected === 0 && headerData?.tabValues?.po) {
                    return (flag = true);
                }
                break;
            case 'Sailing Schedules':
                if (element.selected === 0 && headerData?.tabValues?.sailing) {
                    return (flag = true);
                }
                break;
            case 'Additional Details':
                if (profileType === 'forwarder') {
                    if (element.selected === 0 && headerData?.tabValues?.additionalDetailsForForwarder) {
                        return (flag = true);
                    }
                } else {
                    if (element.selected === 0 && headerData?.tabValues?.additionalDetails) {
                        return (flag = true);
                    }
                }
                break;
            case 'Container Allocation':
                if (element.selected === 0 && headerData?.tabValues?.containerDetails) {
                    return (flag = true);
                }
                break;
        }
        return flag;
    };

    const extraTab = [
        {
            title: 'Attachments',
            selected: window.location.pathname.includes('/attachments') ? 1 : 0,
            path: `/Aobooking_list/${id}/attachments`,
            disabled: false,
        },
        {
            title: 'Notes',
            selected: window.location.pathname.includes('/notes') ? 1 : 0,
            path: `/Aobooking_list/${id}/notes`,
            disabled: false,
        },
        {
            title: 'Events',
            selected: window.location.pathname.includes('/events') ? 1 : 0,
            path: `/Aobooking_list/${id}/events`,
            disabled: false,
        },
    ];
    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder">
                {[...props.navData, ...extraTab]
                    ?.filter((res: any) => {
                        if (profileType === 'vendor') {
                            return res?.title !== 'Additional Details';
                        } else {
                            return res;
                        }
                    })
                    ?.map((element: any, index: number) => {
                        return (
                            <Link
                                key={index}
                                className={`arrow-tab-border booking-proposal-tab ${
                                    element.selected === 1
                                        ? ' active'
                                        : id && !element.disabled
                                        ? ' enabled'
                                        : ' disabled'
                                }`}
                                style={{
                                    justifyContent:
                                        element.title === 'Notes' ||
                                        element.title === 'Events' ||
                                        element.title === 'Attachments'
                                            ? 'center'
                                            : 'space-between',
                                }}
                                to={element.path || '/'}
                            >
                                {element.title === 'Notes' ? (
                                    <svg className="svg-icon eye-icon">
                                        <use href="#noteDraftEditIcon"></use>
                                    </svg>
                                ) : element.title === 'Events' ? (
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon calendar-icon">
                                        <use xlinkHref="#calendarIcon">
                                            <title>Calendar</title>
                                        </use>
                                    </svg>
                                ) : element.title === 'Attachments' ? (
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon eye-icon">
                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                        <title>Attach file</title>
                                    </svg>
                                ) : tabCheckFunction(element) ? (
                                    <div className="arrow-badge verify-badge">
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="arrow-badge">{index + 1}</div>
                                )}

                                <div className="arrow-tab">{element.title}</div>
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};
export default Index;
