import React from 'react';
import '../../assets/scss/components/_modal.scss';
import '../BookingProposal/BookingSailingSchelude/coloader-option-modal.scss';
const VesselSearchModal: React.FC<any> = ({ setModal, data, setSelectedVesselName, onVesselConfirm }) => {
    return (
        <div className="app-modal container option-modal">
            <div className="modal-content-holder medium-popup">
                <div className="modal-header">
                    <h2 className="title">Vessel Search</h2>
                    <button className="app-btn modal-close-btn" onClick={() => setModal(false)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="detail-options-wrapper" style={{ marginTop: '5px' }}>
                        <div className="option-list">
                            {data?.map((val: any) => {
                                return (
                                    <div className="option-item" key={val.imo}>
                                        <div className="layout-checkbox">
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="radio"
                                                        className="checkbox-input"
                                                        name="group1"
                                                        value={val.imo}
                                                        onChange={() => setSelectedVesselName(val.imo)}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon notification-circle-icon">
                                                            <use href="#circleIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label layout-badge-count">
                                                        <div className="badge-status">
                                                            <div className="content-list-wrapper">
                                                                <div className="content-heading-holder">
                                                                    <span className="tab-heading">
                                                                        {val?.name} (IMO: {val?.imo})
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={onVesselConfirm}
                        style={{ marginLeft: '8px' }}
                        className="app-btn app-btn-primary modal-btn footer-btn"
                    >
                        <span className="button-text vendor-button-text">Confirm</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default VesselSearchModal;
