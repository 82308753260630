import moment from 'moment';
import { TrendDetails } from 'src/pages/Tracking/InsightDetailsList';

export const sleep = (delayInMs?: number) => new Promise((resolve) => setTimeout(resolve, delayInMs));

export const getOrganisationData = () => {
    const organisationData =
        localStorage.getItem('listOfUserOrganisations') != null &&
        JSON.parse(localStorage.getItem('listOfUserOrganisations') || '');
    return organisationData;
};

export const getClientCode = async (profileType: string) => {
    const data = getOrganisationData();
    let orgCode = '';
    if (profileType && data[profileType]?.length) {
        const orgData = data[profileType];
        for (let index = 0; index < orgData?.length; index++) {
            if (orgData[index]?.relationship === 'parent') {
                orgCode = orgData[index]?.orgCode;
                break;
            } else {
                orgCode = orgData[index]?.orgCode;
            }
        }
    }
    return orgCode;
};

export const getDateDifference = (startDate: string, endDate: string) => {
    const start = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
    const ms = moment(end).diff(moment(start));
    const d = moment.duration(ms);

    return {
        days: d.days(),
        hour: d.hours(),
        minute: d.minutes(),
        second: d.seconds(),
    };
};

export const getCookie = (name: string) => {
    const cookieObj = new URLSearchParams(document.cookie.replaceAll('&', '%26').replaceAll('; ', '&'));
    return cookieObj.get(name);
};

export const setCookie = (name: string, value: string, days?: number) => {
    let expires;
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    } else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
};

export const deleteCookie = (name: string) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const decimalNumberValidation = (value: number, numberLength: number, decimalLength: number) => {
    let flag = true;
    let flag2 = true;
    if (value % 1 != 0) {
        if (value.toString().split('.')[1]?.length > decimalLength) {
            flag = false;
        }
    } else {
        if (value.toString().split('')?.length > numberLength) {
            flag2 = false;
        }
    }
    return flag && flag2;
};

export const convertDataForSelect = (data: any, value: string, label: string) => {
    const convertedData = data?.map((option: any) => {
        return { value: option[value], label: option[label] };
    });
    return convertedData;
};

export const getUserProfile = () => {
    let userName = '';
    let profile = '';
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    if (userData) {
        const { roles, user } = JSON.parse(userData);
        if (roleIndex) {
            userName = user?.us_UserName;
            profile = roles[roleIndex]?.profile?.toLowerCase();
        }
    }
    return { userName, profile };
};

export const todoOrgList = (type: string, code: string) => {
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const filteredListOfOrg: any = {};
    if (listOfUserOrganisations?.client?.length && type?.toLowerCase() === 'clients') {
        filteredListOfOrg.client = listOfUserOrganisations?.client?.filter((res: any) => res.orgCode === code);
    } else if (listOfUserOrganisations?.agent?.length && type?.toLowerCase() === 'agents') {
        filteredListOfOrg.agent = listOfUserOrganisations?.agent?.filter((res: any) => res.orgCode === code);
    } else if (listOfUserOrganisations?.vendor?.length && type?.toLowerCase() === 'vendors') {
        filteredListOfOrg.vendor = listOfUserOrganisations?.vendor?.filter((res: any) => res.orgCode === code);
    } else if (listOfUserOrganisations?.sl?.length && type?.toLowerCase() === 'carriers') {
        filteredListOfOrg.sl = listOfUserOrganisations?.sl?.filter((res: any) => res.orgCode === code);
    } else {
        return listOfUserOrganisations;
    }
    return filteredListOfOrg;
};
export const getOrganizationProfileData = (
    typeOfSearch: string | null | undefined,
    searchedItemCode: string | null | undefined
) => {
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    if (typeOfSearch) {
        if (typeOfSearch === 'Clients') {
            return {
                client: [{ orgCode: searchedItemCode }],
            };
        } else if (typeOfSearch === 'Agents') {
            return {
                agent: [{ orgCode: searchedItemCode }],
            };
        } else if (typeOfSearch === 'Carriers') {
            return {
                sl: [{ orgCode: searchedItemCode }],
            };
        } else if (typeOfSearch === 'Vendors') {
            return {
                vendor: [{ orgCode: searchedItemCode }],
            };
        }
    }
    return listOfUserOrganisations;
};

export const combineTrendGraph = (data: any, graph: any, type: string, keyToCheck: any) => {
    const getCount = (res: any, number: number) => {
        const month =
            number === 0
                ? moment().startOf('month').format('M')
                : moment().subtract(number, 'month').startOf('month').format('M');
        const year =
            number === 0
                ? moment().startOf('month').format('Y')
                : moment().subtract(number, 'month').startOf('month').format('Y');
        const date =
            number === 0
                ? moment().startOf('month').format('MMM YY')
                : moment().subtract(number, 'month').startOf('month').format('MMM YY');
        const countData = keyToCheck.key
            ? graph?.find(
                  (el: any) => el[keyToCheck.key] === res[keyToCheck.key] && el.MONTH === +month && el.YEAR === +year
              )?.[keyToCheck.field]
            : graph?.find((el: any) => el.MONTH === +month && el.YEAR === +year)?.[keyToCheck.field];

        return {
            count: Math.round(countData ?? 0),
            date,
        };
    };
    const graphData = (res: any) => {
        let graphObj = {};
        for (let index = 0; index < 13; index++) {
            graphObj = {
                ...graphObj,
                [`key${index + 1}`]: getCount(res, index),
            };
        }
        return graphObj;
    };

    const combinedData =
        type === TrendDetails.Compare
            ? data?.map((res: any, index: number) => ({
                  id: index + 1,
                  [res[keyToCheck.key]]: graphData(res),
              }))
            : graphData(graph);
    return combinedData;
};

export const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};
