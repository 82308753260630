import axios from 'axios';
import moment from 'moment';
import { getOrganisationData } from '../../../utils';
import { del, get, patch, post, put } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchProductPoListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/po/product/${payload.poId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSkuListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/clients/products?clientCode=${payload.clientCode}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createProductPoDetailsApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (const key in payload) {
            if (key === 'goodsReadyDate' || key === 'projWhouseETA') {
                payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
            }
        }
        const newPayload = { product: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/po/product', newPayload, null);
        data.type = 'create';
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateProductPoDetailsApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    for (const key in payload) {
        if (key === 'goodsReadyDate' || key === 'projWhouseETA') {
            payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
        }
    }
    try {
        const newPayload = { product: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await put(`api/ord/po/product?poProductId=${payload.poProductId}`, newPayload);
        data.type = 'update';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const removeProductPoDetailsApi = async (payload: any) => {
    try {
        const data = await del(`api/ord/po/product/${payload.poProductId}`, null);
        data.type = 'delete';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const linkVendorProductPoApi = async (payload: any) => {
    try {
        const data = await patch(`api/ord/po/linkvendorproduct/${payload.vendorCode}/${payload.pm_pk}`, null);
        data.type = 'link';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCountryDetailsApi = async (_payload: any) => {
    try {
        const data = await get('api/ord/po/country', null);
        const result = data?.info.map((res: any) => ({ value: res.countryCode, label: res.countryName }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCountryDetailsMgtApi = async (payload: any) => {
    try {
        // const data = await get('api/mgt/clients/country', null);
        const data = await get(
            `/cix/scr/admin/config/country/lst/cn_countrycode/cn_countryname${
                payload.search
                    ? '?filter[logic]=and&filter[filters][1][field]=cn_countryname&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
                      payload.search
                    : ''
            }`,
            null,
            externalBaseUrl
        );
        const result = data?.data?.map((res: any) => ({
            value: res.cn_countrycode,
            label: `${res.cn_countryname} (${res.cn_countrycode})`,
            extraField: res.cn_countryname,
        }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchCountryByCountryCode = async (payload: any) => {
    try {
        // const data = await get('api/mgt/clients/country', null);
        const data = await get(
            `/cix/scr/admin/config/country/lst/cn_countrycode/cn_countryname${
                payload.search
                    ? '?filter[logic]=and&filter[filters][1][field]=cn_countrycode&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
                      payload.search
                    : ''
            }`,
            null,
            externalBaseUrl
        );
        const result = data?.data?.map((res: any) => ({
            value: res.cn_countrycode,
            label: `${res.cn_countryname} (${res.cn_countrycode})`,
            extraField: res.cn_countryname,
        }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCurrencyDetailsApi = async (payload: any) => {
    try {
        let data = await get('api/bkg/bp/basicdetails/ordercurrency', null);
        let result: any;
        if (payload.currency) {
            data = data?.info.filter((res: any) => payload.currency === res.code);
            result = data?.map((res: any) => ({ value: res.code, label: res.code }));
        } else {
            result = data?.info.map((res: any) => ({ value: res.code, label: res.code }));
        }
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchPortDetailsApi = async (payload: any) => {
    try {
        const data = await get(
            `api/mgt/shippinglinecontract/portlist${payload?.portType ? `?portType=${payload?.portType}` : ''}`,
            null
        );
        const result = data?.info.map((res: any) => ({
            value: res.sp_portcode,
            label: `${res.sp_portname}(${res.sp_portId})`,
        }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchDestinationAddressDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/po/clientaddress?orgCode=${payload.orgCode}`, null);
        data?.info.map((res: any) => {
            let address = res.addressLine1;
            if (res.addressLine2) {
                address = address + ', ' + res.addressLine2;
            }
            if (res.addressLine3) {
                address = address + ', ' + res.addressLine3;
            }
            if (res.city) {
                address = address + ', ' + res.city;
            }

            if (res.state) {
                address = address + ', ' + res.state;
            }

            if (res.postcode) {
                address = address + ', ' + res.postcode;
            }
            return (res.fullAddress = address);
        });
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
