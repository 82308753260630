import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchAgentPortDetailsColumnListFailed,
    fetchAgentPortDetailsColumnListSuccess,
    fetchAgentPortDetailsList,
    fetchAgentPortDetailsListFailed,
    fetchAgentPortDetailsListSuccess,
    fetchAgentPortDetailsColumnList,
    isLoadingFn,
    searchAgentPortDetailsData,
    searchAgentPortDetailsDataFailure,
} from '../../../reducers/profiles/agents/portDetailsReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchAgentPortDetailsListData,
    fetchAgentPortDetailsColumnListData,
    searchAgentPortDetails,
} from '../../../../services/apis/profiles/agents/portDetailsApi';

// eslint-disable-next-line
function* getAgentPortDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchAgentPortDetailsListData, payload);
        yield put({ type: fetchAgentPortDetailsListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAgentPortDetailsListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getAgentPortDetailsColumn({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchAgentPortDetailsColumnListData, payload);
        yield put({ type: fetchAgentPortDetailsColumnListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAgentPortDetailsColumnListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getAgentPortDetailsSearchData({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(searchAgentPortDetails, payload);
        yield put({ type: fetchAgentPortDetailsListSuccess, payload: response });
    } catch (error) {
        yield put({ type: searchAgentPortDetailsDataFailure, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* agentPortDetailsSaga() {
    yield takeEvery(fetchAgentPortDetailsList, getAgentPortDetails);
    yield takeEvery(fetchAgentPortDetailsColumnList, getAgentPortDetailsColumn);
    yield takeEvery(searchAgentPortDetailsData, getAgentPortDetailsSearchData);
}

export default agentPortDetailsSaga;
