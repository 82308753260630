import React from 'react';

export const Actions = (props: any) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem[props.uniqueKey] === undefined;

    return (
        <>
            <td className="k-command-cell">
                {props.extraActionButtons?.length > 0 &&
                    props.extraActionButtons?.map((res: any) => (
                        <button
                            className="action-btn app-btn app-btn-secondary"
                            title={res?.title}
                            disabled={res?.disabled}
                            onClick={() => res?.onClick && res?.onClick(props)}
                        >
                            <svg className="svg-icon eye-icon icon-20">
                                <use xlinkHref={`#${res?.icon}`}></use>
                            </svg>
                        </button>
                    ))}
                {inEdit ? (
                    <>
                        <button
                            onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}
                            className="action-btn app-btn app-btn-secondary"
                            title={isNewItem ? 'Add' : 'Update'}
                        >
                            <svg className="svg-icon save-icon icon-20">
                                <use xlinkHref="#saveIcon"></use>
                                <title>Save</title>
                            </svg>
                        </button>
                        <button
                            onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}
                            className="action-btn app-btn app-btn-secondary"
                            title={isNewItem ? 'Discard' : 'Cancel'}
                        >
                            <svg className="svg-icon cancel-icon icon-20">
                                <use xlinkHref="#cancelIcon"></use>
                                <title>Remove</title>
                            </svg>
                        </button>
                    </>
                ) : (
                    <>
                        {props.permission?.edit && (
                            <button
                                onClick={() => props.edit(dataItem)}
                                className="action-btn app-btn app-btn-secondary"
                                title="Edit"
                                disabled={props.editMode || !!props?.systemCreatedKey}
                            >
                                <svg className="svg-icon edit-icon icon-20">
                                    <use xlinkHref="#editIcon">
                                        <title>Edit</title>
                                    </use>
                                </svg>
                            </button>
                        )}
                        {props.permission?.delete && (
                            <button
                                className="action-btn app-btn app-btn-secondary"
                                title="Remove"
                                disabled={!!props?.systemCreatedKey}
                                onClick={() => confirm('Confirm deleting') && props.remove(dataItem)}
                            >
                                <svg className="svg-icon eye-icon icon-20">
                                    <use xlinkHref="#deleteIcon"></use>
                                </svg>
                            </button>
                        )}
                    </>
                )}
            </td>
        </>
    );
};
