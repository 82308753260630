import React, { useEffect, useState } from 'react';
import './confirm-modal.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { submitPo, ConfirmationGroups } from '../../../store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import { get } from 'src/services/HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
interface ModalListProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    products: { poId: string; confirmationGroups: any[] };
    isLand: boolean;
}
const Index = ({ display, displayFunction, products, isLand }: ModalListProps) => {
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);
    const [showDropDown, setShowDropDown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [confirmationGroups, setConfirmationGroups] = useState<ConfirmationGroups[]>([]);

    const getConfirmationGroupName = async () => {
        const copy = JSON.parse(JSON.stringify(products?.confirmationGroups));
        for (let index = 0; index < copy.length; index++) {
            const response = await get('cix/cfg/nextNumber/CG', null, externalBaseUrl);
            copy[index].confirmationGroupName = response?.data?.[0]?.NN_V_Key;
            copy[index].type = headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'AIR' : copy[index].type;
        }
        setConfirmationGroups(copy);
        setIsLoading(false);
    };

    useEffect(() => {
        if (products && display) {
            setIsLoading(true);
            getConfirmationGroupName();
        } else {
            setConfirmationGroups(products?.confirmationGroups);
        }
    }, [products, display, isLand]);

    useEffect(() => {
        setConfirmationGroups(products?.confirmationGroups);
    }, [products?.confirmationGroups]);

    const onSelect = (index: number, type: string) => {
        const copiedConfirmationGroups = JSON.parse(JSON.stringify(confirmationGroups));
        copiedConfirmationGroups[index].type = type;
        setConfirmationGroups(copiedConfirmationGroups);
        setShowDropDown(false);
    };

    return (
        <>
            {display ? (
                <div onClick={() => setShowDropDown(false)} className="app-modal">
                    <div className="modal-content-holder confirmation-product-modal">
                        <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                        <div className="modal-header">
                            <div className="title">Confirm Products</div>
                            <button onClick={() => displayFunction(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="warning-text">
                                <div>
                                    <svg className="svg-icon error-icon">
                                        <use xlinkHref="#errorIcon">
                                            <title>Warning</title>
                                        </use>
                                    </svg>
                                </div>
                                <div className="error-text">
                                    If required Container mode change should be performed here. No further change is
                                    allowed in any other stage
                                </div>
                            </div>
                            <p className="content-title">
                                <b className="font-bold"> {confirmationGroups?.length}</b>
                                {confirmationGroups?.length === 1 ? ' group' : ' groups'} have been identified based on
                                the destination and goods ready date of the products to confirm. Are you sure to confirm
                                products ?
                            </p>
                            <div className="modal-main-content">
                                {confirmationGroups?.map((res: any, index: number) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <h3 className="heading-with-toast">
                                                <span style={{ minWidth: '70px' }}>{res?.confirmationGroupName}</span>
                                                {/* {res?.type === 'LCL' ? (
                                                    <div className="modal-toast light-blue">LCL</div>
                                                ) : (
                                                    <div className="modal-toast dark-blue">{res?.type}</div>
                                                )} */}
                                                <div
                                                    className={`dropdown-wrap ${
                                                        showDropDown && currentIndex === index ? 'dropdown-open' : ''
                                                    } search-toast-content-holder`}
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            setShowDropDown((prev) => !prev);
                                                            setCurrentIndex(index);
                                                            e.stopPropagation();
                                                        }}
                                                        className="menu-btn app-btn app-btn-secondary"
                                                    >
                                                        <span className="button-text">{res?.type}</span>

                                                        <span className="dropdown-arrow">
                                                            <svg className="svg-icon arrow-icon">
                                                                <use xlinkHref="#downArrow">
                                                                    <title>dropdown</title>
                                                                </use>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        {headerData?.shippingMode?.shippingModeCode === 'AIR' && (
                                                            <a
                                                                className={`menu-item app-btn ${
                                                                    res?.type === 'AIR' ? 'active' : ''
                                                                }`}
                                                                onClick={() => onSelect(index, 'AIR')}
                                                            >
                                                                AIR
                                                            </a>
                                                        )}
                                                        <a
                                                            className={`menu-item app-btn ${
                                                                res?.type === 'FCL' ? 'active' : ''
                                                            }`}
                                                            onClick={() => onSelect(index, 'FCL')}
                                                        >
                                                            FCL
                                                        </a>
                                                        <a
                                                            className={`menu-item app-btn ${
                                                                res?.type === 'LCL' ? 'active' : ''
                                                            }`}
                                                            onClick={() => onSelect(index, 'LCL')}
                                                        >
                                                            LCL
                                                        </a>
                                                        <a
                                                            className={`menu-item app-btn ${
                                                                res?.type === 'BCN' ? 'active' : ''
                                                            }`}
                                                            onClick={() => onSelect(index, 'BCN')}
                                                        >
                                                            BCN
                                                        </a>
                                                    </div>
                                                </div>
                                            </h3>
                                            <div className="layout-holder three-column">
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Destination</h6>
                                                    <div className="description-type-1">
                                                        {res?.destinationPortName} ({res?.destinationPort})
                                                        {/* ({res?.destinationPort}) */}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Goods Ready Date</h6>
                                                    <div className="description-type-1">{res?.goodsReadyDate}</div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">CBM</h6>
                                                    <div className="description-type-1">{res?.cbm}</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => displayFunction(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                            >
                                <span className="button-text footer-button-text">No</span>
                            </button>
                            <button
                                onClick={() => {
                                    displayFunction(false);
                                    dispatch(submitPo({ ...products, confirmationGroups }));
                                }}
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                            >
                                <span className="button-text footer-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
