import { useSelector } from 'react-redux';

import { RootState } from 'src/store/reducers';
import {
    ClusterData,
    MoreClusterData,
    BookingsAtDestinationPort,
    BookingsAtOriginCountry,
    BookingsAtOriginPort,
    POsAtOriginCountry,
    BookingsIntransit,
} from 'src/store/reducers/dashboard/map';

import { Coordinates, Props as TrackLineData } from '../components/TrackLines/TrackLine';

//Rerturn the data for map icons and als
export const useMapClustering = (): { mapClusterData: ClusterData[]; trackLinesData: TrackLineData[] } => {
    const {
        bookingsAtOriginCountry,
        bookingsAtOriginPort,
        bookingsIntransitArray,
        bookingsAtDestinationPort,
        posAtOriginCountry,
        hoveredTileKey,
        singleMapData,
    } = useSelector((state: RootState) => state.map);

    const bpsAtOriginPortWithMoreInfo: (BookingsAtOriginPort & MoreClusterData)[] = [];
    const bpsAtOriginCountryWithMoreInfo: (BookingsAtOriginCountry & MoreClusterData)[] = [];

    if (!hoveredTileKey || hoveredTileKey === 'etd' || hoveredTileKey === 'eta') {
        bookingsAtOriginPort.forEach((item: any) => {
            if (
                !hoveredTileKey ||
                (hoveredTileKey === 'etd' && (item.etdIn7DaysCount || item.etdDelayedCount)) ||
                (hoveredTileKey === 'eta' && (item.etaIn7DaysCount || item.etaDelayedCount))
            ) {
                bpsAtOriginPortWithMoreInfo.push({
                    ...item,
                    bookingCount:
                        hoveredTileKey === 'etd'
                            ? item.etdIn7DaysCount + item.etdDelayedCount
                            : hoveredTileKey === 'eta'
                            ? item.etaIn7DaysCount + item.etaDelayedCount
                            : item.bookingCount,
                    lat: item.originPortCoordinates[1],
                    lon: item.originPortCoordinates[0],
                    type: 'bookingsAtOriginPort' as const,
                });
            }
        });

        bookingsAtOriginCountry.forEach((item: any) => {
            if (
                !hoveredTileKey ||
                (hoveredTileKey === 'etd' && (item.etdIn7DaysCount || item.etdDelayedCount)) ||
                (hoveredTileKey === 'eta' && (item.etaIn7DaysCount || item.etaDelayedCount))
            ) {
                bpsAtOriginCountryWithMoreInfo.push({
                    ...item,
                    bookingCount:
                        hoveredTileKey === 'etd'
                            ? item.etdIn7DaysCount + item.etdDelayedCount
                            : hoveredTileKey === 'eta'
                            ? item.etaIn7DaysCount + item.etaDelayedCount
                            : item.bookingCount,
                    lat: item.countryCoordinates[1],
                    lon: item.countryCoordinates[0],
                    type: 'bookingsAtOriginCountry' as const,
                });
            }
        });
    }
    const bpsIntransitWithMoreInfo: (BookingsIntransit & MoreClusterData)[] = [];
    if (!hoveredTileKey || hoveredTileKey === 'eta' || hoveredTileKey === 'transit') {
        bookingsIntransitArray.forEach((item: any) => {
            if (
                !hoveredTileKey ||
                hoveredTileKey === 'transit' ||
                (hoveredTileKey === 'eta' && (item.etaIn7DaysCount || item.etaDelayedCount))
            ) {
                //Transit icon should show in last updated coordinate
                const currentStop = item.routeTravelled.length
                    ? item.routeTravelled[item.routeTravelled.length - 1]
                    : item.originPortCoordinates;
                bpsIntransitWithMoreInfo.push({
                    ...item,
                    bookingCount:
                        hoveredTileKey === 'eta' ? item.etaIn7DaysCount + item.etaDelayedCount : item.bookingCount,
                    lat: currentStop[1],
                    lon: currentStop[0],
                    type: 'bookingsIntransitArray' as const,
                });
            }
        });
    }

    const bpsAtDestinationPortWithMoreInfo: (BookingsAtDestinationPort & MoreClusterData)[] = [];
    if (!hoveredTileKey || hoveredTileKey === 'destination') {
        bookingsAtDestinationPort.forEach((item: any) => {
            if (!hoveredTileKey || hoveredTileKey === 'destination') {
                bpsAtDestinationPortWithMoreInfo.push({
                    ...item,
                    lat: item.destinationPortCoordinates[1],
                    lon: item.destinationPortCoordinates[0],
                    type: 'bookingsAtDestinationPort' as const,
                });
            }
        });
    }

    const posAtOriginCountryWithMoreInfo: (POsAtOriginCountry & MoreClusterData)[] = [];
    if (!hoveredTileKey) {
        posAtOriginCountry.forEach((item: any) => {
            posAtOriginCountryWithMoreInfo.push({
                ...item,
                lat: item.countryCoordinates[1],
                lon: item.countryCoordinates[0],
                type: 'posAtOriginCountry' as const,
            });
        });
    }

    const trackLinesData: TrackLineData[] = [];

    const isIncludedPoint = (data: TrackLineData[], point: Coordinates) => {
        const isIncluded = trackLinesData.some(
            (lines) => lines.originPoint[0] === point[0] && lines.originPoint[1] === point[1]
        );
        return isIncluded;
    };
    if (hoveredTileKey === 'transit' || hoveredTileKey === 'eta' || hoveredTileKey === 'etd') {
        bpsIntransitWithMoreInfo.forEach((item) => {
            trackLinesData.push({
                routePoints: item.routeTravelled,
                nextStop: item.nextStopCoordinates,
                shippingMode: item.shippingMode,
                originPoint: item.originPortCoordinates,
                destinationPoint: item.destinationPortCoordinates,
                hideOriginMarker: isIncludedPoint(trackLinesData, item.originPortCoordinates),
                hideDestinationMarker: isIncludedPoint(trackLinesData, item.destinationPortCoordinates),
                originPointName: item.originPortName,
                destinationPointName: item.destinationPortName,
                nextStopName: item.nextStopName,
            });
        });
        bpsAtOriginPortWithMoreInfo.forEach((item) => {
            item.destinations.forEach((destination) => {
                trackLinesData.push({
                    routePoints: [],
                    shippingMode: destination.shippingMode,
                    originPoint: item.originPortCoordinates,
                    destinationPoint: destination.destinationPortCoordinates,
                    hideOriginMarker: isIncludedPoint(trackLinesData, item.originPortCoordinates),
                    hideDestinationMarker: isIncludedPoint(trackLinesData, destination.destinationPortCoordinates),
                });
            });
        });
    } else {
        if (singleMapData) {
            if (singleMapData.type === 'bookingsIntransitArray') {
                const {
                    routeTravelled,
                    nextStopCoordinates,
                    shippingMode,
                    originPortCoordinates,
                    destinationPortCoordinates,
                    originPortName,
                    destinationPortName,
                    nextStopName,
                } = singleMapData as BookingsIntransit;
                trackLinesData.push({
                    routePoints: routeTravelled,
                    nextStop: nextStopCoordinates,
                    shippingMode: shippingMode,
                    originPoint: originPortCoordinates,
                    destinationPoint: destinationPortCoordinates,
                    hideOriginMarker: isIncludedPoint(trackLinesData, originPortCoordinates),
                    hideDestinationMarker: isIncludedPoint(trackLinesData, destinationPortCoordinates),
                    originPointName: originPortName,
                    destinationPointName: destinationPortName,
                    nextStopName: nextStopName,
                });
            } else if (singleMapData.type === 'bookingsAtOriginPort') {
                (singleMapData as BookingsAtOriginPort).destinations.forEach((destination) => {
                    trackLinesData.push({
                        routePoints: [],
                        shippingMode: destination.shippingMode,
                        originPoint: (singleMapData as BookingsAtOriginPort).originPortCoordinates,
                        destinationPoint: destination.destinationPortCoordinates,
                        hideOriginMarker: isIncludedPoint(
                            trackLinesData,
                            (singleMapData as BookingsAtOriginPort).originPortCoordinates
                        ),
                        hideDestinationMarker: isIncludedPoint(trackLinesData, destination.destinationPortCoordinates),
                    });
                });
            }
        }
    }
    return {
        mapClusterData: [
            ...bpsAtOriginCountryWithMoreInfo,
            ...bpsAtDestinationPortWithMoreInfo,
            ...bpsAtOriginPortWithMoreInfo,
            ...bpsIntransitWithMoreInfo,
            ...posAtOriginCountryWithMoreInfo,
        ],
        trackLinesData,
    };
};
