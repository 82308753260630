import React, { useRef, useState, useEffect } from 'react';
import Graph from './Graph';
import './workTrends.scss';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchWorkTrendsList,
    selectWorkTrends,
    selectCurrentFilter,
    updateFilter,
    fetchWorkTrendsListSuccess,
    isLoading,
    // setActiveActivityList,
} from 'src/store/reducers/dashboard/workTrendsReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
export enum FilterList {
    Days = 'Days',
    Weeks = 'Weeks',
    Months = 'Months',
}

type FilterListType = keyof typeof FilterList;

const Index: React.FC = () => {
    const navigate = useNavigate();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(dropDownRef, setDropDownOpen);
    const dispatch = useAppDispatch();
    const workTrends = useAppSelector(selectWorkTrends);
    const isLoadingVal = useAppSelector(isLoading);
    const currentFilter = useAppSelector(selectCurrentFilter);
    const { profileType, userName } = useUserProfile();
    const { userIds } = useSelector((state: RootState) => state.workTrendsData);
    // const dispatchReducer = useDispatch();

    useEffect(() => {
        if (profileType && userName) {
            dispatch(fetchWorkTrendsList({ filter: currentFilter, profile: profileType, userName, userIds }));
        }
    }, [profileType, userName]);

    const onFilterChange = (key: FilterListType) => {
        dispatch(updateFilter(FilterList[key]));
        dispatch(fetchWorkTrendsListSuccess({ data: [] }));
        dispatch(fetchWorkTrendsList({ filter: FilterList[key], profile: profileType, userName, userIds }));
        setDropDownOpen(false);
    };

    return (
        <div className="card bar-graph">
            <div className="card-header">
                <div className="title-wrapper">
                    <h4 className="card-title">Work Trends</h4>
                    <span className="card-sub-title">
                        Showing trend for Last {currentFilter === FilterList.Months ? 13 : 8} {currentFilter}
                    </span>
                </div>
                {isLoadingVal ? (
                    <div className="app-loader inner-loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                ) : null}
                <div className="graph-filters">
                    <div
                        ref={dropDownRef}
                        className={`dropdown-wrap right-align ${dropDownOpen ? 'dropdown-open' : ''}`}
                    >
                        <button
                            onClick={() => setDropDownOpen((prev) => !prev)}
                            className="app-btn app-btn-secondary grey-shade"
                        >
                            <div className="purchase-dropdown-content">
                                <div className="dropdown-content-heading">{currentFilter} &nbsp;</div>
                            </div>
                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <button
                            onClick={() => navigate('/dashboard/work-trends')}
                            className="view-nav grid-icon icon-nav max-work-trend"
                            title="Expand"
                        >
                            <svg className="svg-icon maxi-icon">
                                <use xlinkHref="#maxiIcon">
                                    <title>Expand</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu work-trend-dropdown">
                            {(Object.keys(FilterList) as (keyof typeof FilterList)[]).map((key) => (
                                <a
                                    key={FilterList[key]}
                                    onClick={() => onFilterChange(key)}
                                    className="menu-item app-btn"
                                >
                                    {FilterList[key]}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Graph data={workTrends} />
        </div>
    );
};
export default Index;
