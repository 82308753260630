import axios from 'axios';
import { get, post, del } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { fileUploadForDocuments } from '../bookingProposal/additionalDetailsApi';

export const existingAirSchedulesApi = async (_payload: any) => {
    try {
        const res = await get('api/bkg/bp/air/schedules', null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const selectedSchedulesAir = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/air/schedule?bpId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const selectedSchedulesMBL = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/sailing/schedule/mbl?mbl=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const contractListApi = async (payload: any) => {
    try {
        const data = await get(
            `/cix/scr/admin/carrier/${payload.slPk}/contracts?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B1%5D%5Bfield%5D=SC_Status&filter%5Bfilters%5D%5B1%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B1%5D%5Bvalue%5D=ACTIVE&filter%5Bfilters%5D%5B2%5D%5Bfield%5D=PortPairs&filter%5Bfilters%5D%5B2%5D%5Boperator%5D=contains&filter%5Bfilters%5D%5B2%5D%5Bvalue%5D=${payload.portPair}&filter%5Bfilters%5D%5B3%5D%5Bfield%5D=Clients&filter%5Bfilters%5D%5B3%5D%5Boperator%5D=contains&filter%5Bfilters%5D%5B3%5D%5Bvalue%5D=${payload.clientId}`,
            null,
            externalBaseUrl
        );
        return data?.data;
    } catch (err) {
        return console.error(err);
    }
};

export const allContractListApi = async (payload: any) => {
    try {
        const data = await get(
            `/cix/scr/admin/carrier/contracts?take=1000&skip=0&page=1&pageSize=1000`,
            null,
            externalBaseUrl
        );
        return data?.data;
    } catch (err) {
        return console.error(err);
    }
};

export const carrierListAirApi = async (payload: any) => {
    try {
        let url = '';
        // if (payload.type === 'LCL') {
        if (payload?.type === 'AIR') {
            const key = payload?.key ? payload?.key : 'Or_orgname/Or_UserDefinedText4';
            url =
                typeof payload?.search === 'string'
                    ? `/cix/scr/admin/organisation/carrier/lst/${key}?filter[logic]=and&filter[filters][3][field]=SL_IsAirline&filter[filters][3][operator]=eq&filter[filters][3][value]=1&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active&filter[filters][1][field]=Or_UserDefinedText4&filter[filters][1][operator]=contains&filter[filters][1][value]=` +
                      payload?.search
                    : `/cix/scr/admin/organisation/carrier/lst/${key}?filter[logic]=and&filter[filters][3][field]=SL_IsAirline&filter[filters][3][operator]=eq&filter[filters][3][value]=1&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active`;
        } else {
            url =
                typeof payload?.search === 'string'
                    ? '/cix/scr/admin/organisation/carrier/lst/Or_orgname/Or_UserDefinedText4?filter[logic]=and&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active&filter[filters][1][field]=Or_UserDefinedText4&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
                      payload?.search
                    : '/cix/scr/admin/organisation/carrier/lst/Or_orgname/Or_UserDefinedText4?filter[logic]=and&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active';
        }
        // } else {
        // url =
        //     typeof payload?.search === 'string'
        //         ? '/cix/scr/admin/organisation/carrier/lst/Or_orgname/Or_UserDefinedText4?filter[logic]=and&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active&filter[filters][1][field]=Or_orgname&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
        //           payload?.search
        //         : '/cix/scr/admin/organisation/carrier/lst/Or_orgname/Or_UserDefinedText4?filter[logic]=and&filter[filters][1][field]=or_status&filter[filters][1][operator]=eq&filter[filters][1][value]=Active';
        // }

        // const res = await get(`api/bkg/bp/air/carriers/${payload}`, null);
        const res = await get(url, null, externalBaseUrl);
        if (payload?.editFirstLoad) {
            const data = await get(
                `/cix/scr/admin/organisation/carrier/lst/Or_orgname/Or_UserDefinedText4?filter[logic]=and&filter[filters][3][field]=SL_IsAirline&filter[filters][3][operator]=eq&filter[filters][3][value]=1&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active`,
                null,
                externalBaseUrl
            );
            let newData = [];
            for (let index = 0; index < res.data.length; index++) {
                if (!data?.data?.find((el: any) => el?.SL_PK === res.data[index]?.SL_PK)) {
                    newData?.push(res.data[index]);
                }
            }
            return [...data.data, ...newData];
        }
        return res.data;
    } catch (err) {
        return console.error(err);
    }
};

export const vesselListAirApi = async ({ vesselName }: { vesselName?: string }) => {
    const currentEnv = () => {
        if (process.env.REACT_APP_BASE_URL?.includes('test')) {
            return 'TEST';
        } else if (process.env.REACT_APP_BASE_URL?.includes('dev')) {
            return 'DEV';
        } else if (process.env.REACT_APP_BASE_URL?.includes('qa')) {
            return 'QA';
        } else {
            return 'PROD';
        }
    };
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('env', currentEnv());
        if (token) {
            try {
                let search = '';
                if (vesselName) {
                    search = `?name=${vesselName}`;
                }
                const res = await fetch(
                    `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/getVessels${search}`,
                    {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow',
                    }
                );

                if (res.status !== 200) {
                    const body = await res.json();
                    return console.error(body?.Error);
                } else {
                    const body = await res.json();
                    return body;
                }
            } catch (e) {
                return console.error(e);
            }
        }
    } catch (error) {
        return console.error(error);
    }
    // try {
    //     const res = await get(`api/bkg/search/vessels`, null);
    //     console.log('vesselListAirApi ~ res:', res);
    //     return res;
    // } catch (err) {
    //     return console.error(err);
    // }
};

export const newScheduleApis = async (payload: any) => {
    //console.log(payload, 'sailingpaylioad');
    try {
        const res = await post('api/bkg/bp/air/airsailing/add', payload, null);
        const fileBuy = payload?.get('buyfile');
        if (fileBuy) {
            const document = payload?.get('request');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bookingProposalId;
            const formData = new FormData();
            formData.append('file', fileBuy, fileBuy.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        const fileSell = payload?.get('sellfile');
        if (fileSell) {
            const document = payload?.get('request');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bookingProposalId;
            const formData = new FormData();
            formData.append('file', fileSell, fileSell.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HSQ');
            await fileUploadForDocuments(formData);
        }
        return res;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw err.response;
        }
        return err;
    }
};

export const buyContract = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/air/buy', payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const deleteSchedule = async (id: string) => {
    try {
        const res = await del(`api/bkg/bp/air/delete?baId=${id}`, null);
        return { success: true, data: res.info };
    } catch (err: any) {
        console.error(err);
        return { success: false, message: err.message };
    }
};
