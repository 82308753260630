import React, { useEffect, useState } from 'react';
import '../../BookingProposal/SelectedSailingSchelude/selected-sailing-schelude.scss';
// import UnavailableModal from '../SelectedSailingSchelude/UnavailableModel';
import SailingScheduleViewModal from '../SelectedSailingSchelude/SailingScheduleViewModal';
import SailingDetailModal from '../BookingSailingSchelude/SailingScheduleDetailModal';
import { useDispatch } from 'react-redux';
import { removeSailing, sailingUpdated } from '../../../store/reducers/bookingProposal/sailingReducer';
import moment from 'moment';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Position, success } from '../../../utils/toast';
import { fetchAddedPoData } from '../../../store/reducers/aobookingProposal/addedPoReducer';
import ShowForPermissions from 'src/ShowForPermissions';
/* eslint no-var: off */
interface Props {
    data: any;
    id: any;
    setselectedView: any;
    selectedView: any;
    setselectedContract: any;
    bpStatus: any;
}
const Index: React.FC<Props> = ({ data, id, setselectedView, setselectedContract, bpStatus }) => {
    var statusNotification = false;
    const newData = data && data[0];
    const { profileType } = useUserProfile();
    const [_displayModal, setdisplayModal] = useState(false);
    // const [buttonExpand, setbuttonExpand] = useState(false);
    const [viewModal, setviewModal] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { gateInDateExpired } = useSelector((state: RootState) => state.bpHeaderData);
    const { sailingUpdate } = useSelector((state: RootState) => state.sailingRed);
    useEffect(() => {
        localStorage.removeItem('changeVesselao');
        if (newData?.gateInDateExpired && !location.pathname.includes('SailingSelectionDetails')) {
            setdisplayModal(true);
        } else {
            setdisplayModal(false);
        }
        localStorage.setItem('selectedShipping', newData?.shippingLineCode);
    }, [newData]);
    useEffect(() => {
        if (sailingUpdate && !statusNotification) {
            success('Selected Sailing Schedule has been updated', Position.TOP_RIGHT);
            dispatch(fetchAddedPoData(id));
            dispatch(sailingUpdated(false));
            statusNotification = true;
        }
    }, [sailingUpdate]);
    return (
        <div className="selection-schelude-wrapper">
            <div className="selection-schelude-header-wrapper">
                <div className="selection-heading-holder">
                    <h2 className="selection-heading">Selected Sailing Schedule</h2>
                    {((profileType === 'forwarder' && (bpStatus as any)?.includes('FORWARDER')) ||
                        (profileType === 'client' && (bpStatus as any)?.toLowerCase().includes('client'))) &&
                    gateInDateExpired &&
                    id ? (
                        <div className="warning-text">
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>warning message</title>
                                </use>
                            </svg>
                            <div className="error-text">
                                Approval expired. The selected schedule is no longer available, Please reject the
                                booking to change the schedule.
                            </div>
                        </div>
                    ) : (
                        newData?.gateInDateExpired &&
                        profileType === 'agent' &&
                        bpStatus !== 'Booked' &&
                        bpStatus !== 'Ready_for_Booking' &&
                        bpStatus !== 'Draft_Booking' &&
                        id && (
                            <div className="warning-text">
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>warning message</title>
                                    </use>
                                </svg>
                                <div className="error-text">
                                    The selected schedule is no longer available. Please select a different schedule to
                                    continue Booking
                                </div>
                            </div>
                        )
                    )}
                </div>
                <ShowForPermissions type="bp" permission="create">
                    {(bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' || bpStatus === 'Booked') &&
                        (profileType === 'agent' || profileType === 'forwarder') && (
                            <button
                                className="app-btn export-btn icon-button  app-btn-secondary"
                                onClick={() =>
                                    navigate(
                                        `/Aobooking_list/${id}/bookingproposal/SailingSchedule/change-vessel/manual`
                                    )
                                }
                            >
                                <span className="button-text">Change Vessel</span>
                            </button>
                        )}
                </ShowForPermissions>
                <ShowForPermissions type="bp" permission="create">
                    {(bpStatus === 'Rejected_by_Forwarder' ||
                        bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                        bpStatus === 'Draft_Booking_Proposal' ||
                        bpStatus === 'Rejected_by_Client_Forwarder_to_Action') &&
                        (profileType === 'agent' || profileType === 'forwarder') && (
                            <button
                                className="app-btn export-btn icon-button  app-btn-secondary"
                                onClick={() => {
                                    localStorage.removeItem('selectedShipping');
                                    localStorage.removeItem('changeVesselao');
                                    localStorage.setItem('itemSelectedSailing', 'false');
                                    setdisplayModal(false);
                                    dispatch(removeSailing(id));
                                    setselectedContract({});
                                    setTimeout(() => {
                                        setselectedView(false);
                                    }, 1000);
                                }}
                            >
                                <span className="button-text">Change Selection</span>
                            </button>
                        )}
                </ShowForPermissions>
            </div>
            <div className="view-list-grid">
                <div className="list-grid">
                    <div className="grid-child-item col-name">
                        <div className="grid-child-data list-view-items">
                            <div className="ship-logo">
                                <svg className="svg-icon ship-icon">
                                    <use href="#shipIcon">
                                        <title>Icon</title>
                                    </use>
                                </svg>
                            </div>
                            <div className="content-list-wrapper">
                                <div className="content-heading-holder">
                                    <span className="tab-heading">{newData?.shippingline}</span>
                                    {/* <span className="modal-toast">FAK</span> */}
                                </div>
                                <div className="content-detail">
                                    <span>{newData?.vesselVoyage}</span>
                                </div>
                                <div className="content-detail-main-wrapper">
                                    {newData?.contractType === 'NAC' ? (
                                        <div
                                            className={`status-ui nac-status${
                                                newData.isPrimaryClient ? ' nac-p' : ' nac-s'
                                            }`}
                                        >
                                            <div className="status-nac-left">{newData?.contractType} </div>
                                            <div
                                                className={`status-nac-right${
                                                    newData.isPrimaryClient ? ' p-status' : ' s-status'
                                                }`}
                                            >
                                                {newData?.isPrimaryClient ? 'P' : 'S'}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="status-ui confirmed-status contract-pill">
                                            <div className="status-nac-left">{newData?.contractType} </div>
                                        </div>
                                    )}
                                    {/* Icon-info and edit link will be appear once the Nac primary or secondary badge displays */}
                                    {/* <BSTooltip
                                        id="info"
                                        classList="action-btn-wrapper"
                                        position="top"
                                        tooltipClassList="info-list-grid"
                                        title={
                                            <>
                                                <div className="grid-tooltip layout-badge-count">
                                                    <div className="badge-status">
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <span className="tab-heading">Just Jeans</span>
                                                                <div className="status-ui nac-status nac-s">
                                                                    <div className="status-nac-left">NAC</div>
                                                                    <div className="status-nac-right s-status">S</div>
                                                                </div>
                                                            </div>
                                                            <div className="content-count">13</div>
                                                        </div>
                                                        <div className="content-list-wrapper">
                                                            <div className="box-content-holder">
                                                                Contract Name
                                                                <span className="contract-count">2.1</span>
                                                            </div>
                                                            <div className="box-content-holder">TEU of 30</div>
                                                        </div>
                                                    </div>
                                                    <div className="badge-count"></div>
                                                </div>
                                            </>
                                        }
                                    >
                                        <button className="app-btn grid">
                                            <span className="reset-unit-icon">
                                                <svg className="svg-icon error-icon">
                                                    <use xlinkHref="#infoIconRound">
                                                        <title>Infprmation</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                    </BSTooltip>
                                    <a className="more-link" href="javascript:void(0)">
                                        View More
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child-item col-direction">
                        <div className="grid-child-data list-view-items">
                            <div className="ship-logo">
                                <svg className="svg-icon ship-icon">
                                    <use href="#transhipmentIcon">
                                        <title>Icon</title>
                                    </use>
                                </svg>
                            </div>
                            <div className="content-list-wrapper">
                                <div className="content-heading-holder">
                                    <span className="tab-heading">
                                        {newData?.serviceType === 'Direct' ? 'Direct' : 'Multi-Legged'}
                                    </span>
                                </div>
                                <div className="content-detail">
                                    <span>{newData?.service}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="grid-child-item col-plan">
                        <div className="grid-child-data">
                            <ul className="events">
                                <li>
                                    <span className="event-content">Planned</span>
                                    <span className="test">{moment(newData?.plannedETD).format('DD/MM/YYYY')}</span>
                                </li>

                                <li className="event-items-day-list">
                                    <span className="event-content">{newData?.plannedTransitDays} days</span>
                                    <span className="test">{moment(newData?.plannedETA).format('DD/MM/YYYY')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="grid-child-item col-predicate">
                        <div className="grid-child-data">
                            <ul className="events">
                                <li>
                                    <span className="event-content">Predicted</span>
                                    <span className="test">{moment(newData?.predictedETD).format('DD/MM/YYYY')}</span>
                                </li>

                                <li className="event-items-day-list">
                                    <span className="event-content">{newData?.predictedTransitDays} days</span>
                                    <span className="test">{moment(newData?.predictedETA).format('DD/MM/YYYY')}</span>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="grid-child-item -progress-section">
                        <div className="des-wrapper">
                            <div className="des-progress-bar -labelling">
                                <div className="destination begin-journey">
                                    <div className="point12">
                                        <div
                                            className="point1"
                                            title={moment(newData?.plannedETD).format('DD-MM-YYYY')}
                                        >
                                            ETD
                                        </div>
                                    </div>
                                </div>
                                <div className="destination end-journey">
                                    <span className="point1">ETA</span>
                                </div>
                            </div>
                            <div className="des-progress-bar">
                                <div className="destination begin-journey">
                                    <div className="point12">
                                        <div
                                            className="point1"
                                            title={moment(newData?.plannedETD).format('DD-MM-YYYY')}
                                        >
                                            {moment(newData?.plannedETD).format('DD-MM-YYYY')}
                                        </div>
                                    </div>
                                </div>
                                <div className="destination center-journey ">
                                    <span className="center-point planned-label">
                                        Planned, {newData?.plannedTransitDays} day
                                        {newData?.plannedTransitDays > 1 && 's'}
                                    </span>
                                </div>
                                <div className="destination end-journey">
                                    <span className="point1">{moment(newData?.plannedETA).format('DD-MM-YYYY')}</span>
                                </div>
                            </div>
                            <div className="des-progress-bar">
                                <div className="destination begin-journey">
                                    <div className="point12">
                                        <div
                                            className="point1"
                                            title={moment(newData?.predictedETD).format('DD-MM-YYYY')}
                                        >
                                            {moment(newData?.predictedETD).format('DD-MM-YYYY')}
                                        </div>
                                    </div>
                                </div>
                                <div className="destination center-journey">
                                    <span className="center-point">
                                        Predicted, {newData?.predictedTransitDays} day
                                        {newData?.predictedTransitDays > 1 && 's'}
                                    </span>
                                </div>
                                <div className="destination end-journey">
                                    <span className="point1">{moment(newData?.predictedETA).format('DD-MM-YYYY')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                        {/*<ShowForPermissions type="bp" permission="edit">
                            {(bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') &&
                                profileType === 'agent' && (
                                    <Link
                                        className="more-link edit-detail"
                                        to={`/Aobooking_list/${id}/bookingproposal/SailingSchedule/change-vessel/manual`}
                                    >
                                        Edit Details
                                    </Link>
                                )}
                        </ShowForPermissions>*/}
                        <a
                            className="more-link grid-icon icon-nav app-btn-secondary app-btn action-btn"
                            href="javascript:void(0)"
                            onClick={() => setviewModal(true)}
                        >
                            <svg className="svg-icon eye-icon ">
                                <use xlinkHref="#eyeIcon">
                                    <title>View</title>
                                </use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            {/*{displayModal && <UnavailableModal setdisplayModal={setdisplayModal} />}*/}
            {newData?.isManuallyEntered === 1 && viewModal && (
                <SailingScheduleViewModal data={newData} displayFunc={setviewModal} />
            )}
            {newData?.isManuallyEntered === 0 && viewModal && (
                <SailingDetailModal data={newData} displayFunc={setviewModal} />
            )}

            {/*{displayModal && <UnavailableModal setdisplayModal={setdisplayModal} />}*/}
        </div>
    );
};
export default Index;
