import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export const dataSlice = createSlice({
    name: 'attachmentsReceipt',
    initialState: {
        isLoading: false,
        files: {},
        errorStat: false,
        errorMessage: '',
        successSubmitName: '',
        successSubmitStat: false,
        receiptStat: '',
    },
    reducers: {
        fetchFiles: (_state, _action) => {
            // console.log(action);
        },
        saveFiles: (state, action) => {
            state.files = action.payload;
        },
        uploadFiles: (_state, _action) => {
            // console.log(action);
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        removeSingleFile: (_state, _action) => {
            // console.log(action);
        },
        downloadSingle: (_state, _action) => {
            // console.log(action);
        },
        downloadAll: (_state, _action) => {
            // console.log(action);
        },
        setWarning: (state, action) => {
            state.errorMessage = action.payload.message;
            state.errorStat = action.payload.errorStat;
        },
        submitReceipt: (_state, _action) => {
            // console.log(action);
        },
        submitReceiptSucces: (state, action) => {
            state.successSubmitStat = action.payload.stat;
            state.successSubmitName = action.payload.name;
        },
        saveReceiptStatus: (state, action) => {
            state.receiptStat = action.payload;
        },
    },
});

export const {
    isLoadingFn,
    fetchFiles,
    saveFiles,
    uploadFiles,
    removeSingleFile,
    downloadSingle,
    downloadAll,
    setWarning,
    submitReceipt,
    submitReceiptSucces,
    saveReceiptStatus,
} = dataSlice.actions;
export const saveFilesSuccess = (state: RootState) => state.receiptAttachmentReducer.files;

export default dataSlice.reducer;
