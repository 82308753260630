/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import { useFormik } from 'formik';
import './planned-receipt-modal.scss';
import * as Yup from 'yup';
import DatePicker from '../../../../components/common/DatePicker';

import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    fetchReceiptHeaderList,
    receiptHeaderData,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    selectReceiptHeaderError,
    clearFailure,
    updateActualReceipt,
    selectReceiptHeader,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, Position, success } from '../../../../utils/toast';
interface ModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: receiptHeaderData;
}

type ActualReceiptData = Pick<
    receiptHeaderData,
    'actualCustomsClearance' | 'actualGoodsReceivedDate' | 'qcCompleted' | 'actualCustomsInspectionDate'
>;
const Index: React.FC<ModalProps> = ({ display, displayFunction, setDisplayCancel, data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [qcCompletedDropDown, setQcCompletedDropDown] = useState<boolean>(false);
    const { id } = useParams();
    const receiptHeader = useAppSelector(selectReceiptHeader);

    const ReceiptHeaderSchema = Yup.object().shape({
        actualCustomsClearance: Yup.date().required('Actual Customs Clearance is required'),
        actualGoodsReceivedDate: Yup.date().required('Actual Goods Received Date is required'),
        qcCompleted: receiptHeader?.qcInspectionRequired
            ? Yup.string().required('Shipping Terms is required')
            : Yup.string().notRequired(),
        actualCustomsInspectionDate: Yup.string().notRequired(),
    });

    const initialValues: ActualReceiptData = {
        actualCustomsClearance: data?.actualCustomsClearance
            ? moment(data?.actualCustomsClearance).format('YYYY-MM-DD')
            : '',
        actualGoodsReceivedDate: data?.actualGoodsReceivedDate
            ? moment(data?.actualGoodsReceivedDate).format('YYYY-MM-DD')
            : '',
        qcCompleted: typeof data?.qcCompleted === 'boolean' ? data?.qcCompleted : '',
        actualCustomsInspectionDate: data?.actualCustomsInspectionDate
            ? moment(data?.actualCustomsInspectionDate).format('YYYY-MM-DD')
            : '',
    };

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ReceiptHeaderSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                if (!receiptHeader?.qcInspectionRequired) {
                    delete values.qcCompleted;
                }
                dispatch(
                    updateActualReceipt({
                        ...values,
                        receiptId: id,
                        type: data?.actualCustomsClearance ? 'editActual' : 'addActual',
                    })
                );
            }
        },
    });

    useEffect(() => {
        setIsLoading(false);
        if (isError && receiptHeaderError?.config?.url?.includes('header')) {
            error(receiptHeaderError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'addActual' || receiptHeaderSuccess?.data?.type === 'editActual') {
                success(
                    `Actual Receipt have been ${
                        receiptHeaderSuccess?.data?.type === 'addActual' ? 'added' : 'updated'
                    } successfully`,
                    Position.TOP_RIGHT
                );
                dispatch(fetchReceiptHeaderList({ id }));
                displayFunction(false);
                formik.resetForm();
                dispatch(clearFailure([]));
            }
        }
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    useEffect(() => {
        if (!display) {
            formik.resetForm();
        }
    }, [display]);

    const showError = (field: keyof typeof initialValues) => (
        <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>
    );

    const changeDropDown = () => {
        setTimeout(() => {
            formik.validateForm();
        }, 100);
        setQcCompletedDropDown(true);
    };
    // const checkValidation = () => {
    //     const isEdit = data?.plannedCustomsClearance;
    //     const baseCondition =
    //         formik.errors && formik.errors.constructor === Object && Object.keys(formik.errors).length === 0
    //             ? false
    //             : true;
    //     if (isEdit) {
    //         return !formik.dirty || baseCondition;
    //     } else {
    //         return baseCondition;
    //     }
    // };
    return (
        <>
            {display ? (
                <>
                    <div>
                        <div className="app-modal">
                            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                            <div
                                onClick={(e) => {
                                    setQcCompletedDropDown(false);
                                    e.stopPropagation();
                                }}
                                className="modal-content-holder medium-popup receipt-modal"
                            >
                                <div className="modal-header">
                                    <div className="title">Actual Receipt</div>
                                    <button
                                        onClick={() => {
                                            {
                                                formik.dirty ? setDisplayCancel(true) : displayFunction(false);
                                            }
                                        }}
                                        className="app-btn modal-close-btn"
                                    >
                                        <span>
                                            <svg className="svg-icon modal-header-close-icon">
                                                <use href="#closeIcon">
                                                    <title>Close</title>
                                                </use>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-content">
                                    <div className="modal-main-content sop-modal-main-content">
                                        <form
                                            id="my-form-actual"
                                            action=""
                                            onSubmit={formik.handleSubmit}
                                            className="modal-form layout-holder one-column"
                                        >
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Actual Customs Clearance
                                                    <span className="mandatory-field-text">*</span>
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        minDate={new Date()}
                                                        formik={formik}
                                                        fieldName="actualCustomsClearance"
                                                    />
                                                    {showError('actualCustomsClearance')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Actual Goods Received Date
                                                    <span className="mandatory-field-text">*</span>
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        minDate={new Date()}
                                                        formik={formik}
                                                        fieldName="actualGoodsReceivedDate"
                                                    />
                                                    {showError('actualGoodsReceivedDate')}
                                                </div>
                                            </div>
                                            {receiptHeader?.qcInspectionRequired ? (
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        QC Completed<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div
                                                            className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                                qcCompletedDropDown ? 'dropdown-open' : ''
                                                            } `}
                                                        >
                                                            <button
                                                                type="button"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setQcCompletedDropDown((prev: boolean) => !prev);
                                                                }}
                                                                className="menu-btn app-btn select-modal"
                                                            >
                                                                {typeof formik.values.qcCompleted === 'boolean' ? (
                                                                    <span className="button-text selected-text">
                                                                        {formik.values.qcCompleted ? 'Yes' : 'No'}
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <span className="placeholder-text">
                                                                            Select QC Completed
                                                                        </span>
                                                                        <span className="button-text"></span>
                                                                    </>
                                                                )}

                                                                <span className="dropdown-arrow">
                                                                    <svg className="svg-icon arrow-icon">
                                                                        <use xlinkHref="#downArrow">
                                                                            <title>dropdown</title>
                                                                        </use>
                                                                    </svg>
                                                                </span>
                                                            </button>

                                                            <div className="dropdown-menu">
                                                                <a
                                                                    onClick={() => {
                                                                        formik.setFieldError('qcCompleted', undefined);
                                                                        formik.setFieldValue('qcCompleted', true);
                                                                        changeDropDown();
                                                                    }}
                                                                    className={`menu-item app-btn ${
                                                                        formik.values.qcCompleted === true
                                                                            ? 'active'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <span className="main-content">Yes</span>
                                                                </a>
                                                                <a
                                                                    onClick={() => {
                                                                        formik.setFieldError('qcCompleted', undefined);
                                                                        formik.setFieldValue('qcCompleted', false);
                                                                        changeDropDown();
                                                                    }}
                                                                    className={`menu-item app-btn ${
                                                                        formik.values.qcCompleted === false
                                                                            ? 'active'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <span className="main-content">No</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">Customs Inspection Date</label>
                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        minDate={new Date()}
                                                        formik={formik}
                                                        fieldName="actualCustomsInspectionDate"
                                                    />
                                                    {showError('actualCustomsInspectionDate')}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        onClick={() => {
                                            formik.dirty ? setDisplayCancel(true) : displayFunction(false);
                                        }}
                                        className="app-btn app-btn-secondary modal-btn footer-btn"
                                    >
                                        <span className="button-text footer-button-text">Cancel</span>
                                    </button>
                                    <button
                                        type="submit"
                                        form="my-form-actual"
                                        // disabled={checkValidation()}
                                        className="app-btn app-btn-primary modal-btn footer-btn"
                                    >
                                        <span className="button-text vendor-button-text">Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
