import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchVendorListData, isLoadingFn, isLoadingFnVendor, searchVendorData } from '../reducers/vendorListReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    vendorListResponse,
    getVendorListData,
    searchVendors,
    vendorSearchData,
} from '../../services/apis/vendorListApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getVentorData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put({ type: isLoadingFnVendor, payload: true });
        yield put(showLoading());
        const vendors: AxiosResponse<vendorListResponse> = yield call(getVendorListData, payload);
        payload['vendors'] = vendors;
        yield put({ type: fetchVendorListData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put({ type: isLoadingFnVendor, payload: false });
        yield put(hideLoading());
    }
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const vendors: AxiosResponse<vendorSearchData> = yield call(searchVendors, payload);
        payload['vendors'] = vendors;
        yield put({ type: searchVendorData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* vendorListSaga() {
    yield takeEvery('vendorList/fetchVendorListData', getVentorData);
    yield takeEvery('vendorList/searchVendorData', getSearchData);
}

// Export the saga (data-saga)
export default vendorListSaga;
