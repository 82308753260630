import React, { useEffect, useState } from 'react';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
// import BasicDetailsTab from '../BasicDetailsTab';
import '../lcsBookingDetails.scss';
import ScheduleTab from '../ScheduleTab';
// import SkuTab from '../SkuTab';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useLocation, useParams } from 'react-router-dom';
import { fetchSelectedSailing } from 'src/store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
const baseURL = process.env.REACT_APP_BASE_URL;
const Index: React.FC = () => {
    const [lcsTab, setLcsTab] = useState('bookings');
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams() as any;
    const location: any = useLocation();
    const { name } = location.state || {};
    const nameOfPage = 'vessels';
    const gridItem = gridDetails.filter((res) => res.name === nameOfPage)[0];

    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };
    const { profileType, userName } = useUserProfile();

    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    useEffect(() => {
        if (lcsTab === 'bookings') {
            gridItem.url = `${baseURL}/api/bkg/tracking/lifecycle/search/vessel/bookings?id=${id}&vessel=${name}&profile=${profileType}`;
        }
    }, [lcsTab, name, profileType]);

    useEffect(() => {
        dispatch(fetchSelectedSailing(id));
    }, [id, lcsTab]);

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'bookings' ? 'active' : ''}`}
                        onClick={() => setLcsView('bookings')}
                    >
                        Bookings
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'Schedule' ? 'active' : ''}`}
                        onClick={() => setLcsView('Schedule')}
                    >
                        Schedule
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content vessel-detail-tab">
                {lcsTab === 'bookings' && (
                    <KendoGridJquery
                        gridItem={gridItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'Schedule' && <ScheduleTab />}
            </div>
        </div>
    );
};
export default Index;
