import moment from 'moment';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from 'src/services/apis/autoApprovalApi';
import { RootState } from 'src/store/reducers';
import { AutoApprovalLog, resetHistory } from 'src/store/reducers/autoApprovalReducer';
import { clientAuthorization } from 'src/utils/constants';
import BSTooltip from '../../../../components/common/BSTooltip';
import './auto-approval-sidebar.scss';

interface SideBarProps {
    showSideBar: boolean;
    setShowSideBar: (value: boolean) => void;
}
const SideBar = ({ showSideBar, setShowSideBar }: SideBarProps): ReactElement => {
    const dispatch = useDispatch();
    const { autoApprovalHistory, isHistoryLoading } = useSelector((state: RootState) => state.autoApproval);

    const getValue = (value: boolean) => {
        return (
            <div className="description-type-1">
                <div className="unit-process-wrapper">
                    <span className="first-unit">{value ? 'YES' : 'NO'}</span>
                </div>
            </div>
        );
    };

    const getValueTransition = (oldValue: boolean, newValue: boolean) => {
        return (
            <div className="description-type-1">
                <div className="unit-process-wrapper">
                    <span className="first-unit">{oldValue ? 'YES' : 'NO'}</span>
                    <span className="unit-process-icon">
                        <svg className="svg-icon straight-arrow-pointer-icon">
                            <use href="#straightArrowPointerIcon">
                                <title>Close</title>
                            </use>
                        </svg>
                    </span>
                    <span className="second-unit">{newValue ? 'YES' : 'NO'}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="auto-approval-sidebar">
            <div className={`side-bar ${showSideBar ? 'active' : ''}`}>
                <div className={`app-loader ${isHistoryLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <button
                    className="app-btn text-close-btn"
                    onClick={() => {
                        dispatch(resetHistory({}));
                        setShowSideBar(false);
                    }}
                >
                    <svg className="svg-icon text-close-icon">
                        <use href="#closeIcon"></use>
                    </svg>
                </button>
                <h2 className="page-heading">Booking Auto Approval Change Log</h2>
                <div className="details-content">
                    <div className="layout-content-holder">
                        {autoApprovalHistory?.map((log: AutoApprovalLog, indx: number) => (
                            <div className="layout-item-content-wrapper" key={indx}>
                                <div className="layout-field-inline">
                                    <h6 className="description-type-1">
                                        Updated by <span className="desc-content">{log.userName}.</span>
                                    </h6>
                                    <div className="date-type">{moment(log.createdDate).format('DD-MM-YYYY')}</div>
                                </div>
                                <div className="layout-holder four-column">
                                    <div className="layout-item col-field-1">
                                        {log.newAutoApproveNAC !== null ? (
                                            <>
                                                <h6 className="heading-type-1">NAC</h6>
                                                {log.newAutoApproveNAC === log.oldAutoApproveNAC ||
                                                log.oldAutoApproveNAC === null
                                                    ? getValue(log.newAutoApproveNAC)
                                                    : getValueTransition(log.oldAutoApproveNAC, log.newAutoApproveNAC)}
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="layout-item">
                                        {log.newAutoApproveFAK !== null ? (
                                            <>
                                                {' '}
                                                <h6 className="heading-type-1">FAK</h6>
                                                {log.newAutoApproveFAK === log.oldAutoApproveFAK ||
                                                log.oldAutoApproveFAK === null
                                                    ? getValue(log.newAutoApproveFAK)
                                                    : getValueTransition(log.oldAutoApproveFAK, log.newAutoApproveFAK)}
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="layout-item">
                                        {log.newAutoApproveETD !== null ? (
                                            <>
                                                <h6 className="heading-type-1">Permitted ETD</h6>
                                                {log.newAutoApproveETD === log.oldAutoApproveETD ||
                                                log.oldAutoApproveETD === null
                                                    ? getValue(log.newAutoApproveETD)
                                                    : getValueTransition(log.oldAutoApproveETD, log.newAutoApproveETD)}
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="layout-item">
                                        {log.newAutoApproveAIR !== null ? (
                                            <>
                                                <h6 className="heading-type-1">Auto Approve AIR</h6>
                                                {log.newAutoApproveAIR === log.oldAutoApproveAIR ||
                                                log.oldAutoApproveAIR === null
                                                    ? getValue(log.newAutoApproveAIR)
                                                    : getValueTransition(log.oldAutoApproveAIR, log.newAutoApproveAIR)}
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Client Authorization</h6>
                                        <div className="description-type-1">
                                            <div className="unit-process-wrapper">
                                                <span className="first-unit">
                                                    {(clientAuthorization as any)[log.newAuthorisationType]}
                                                </span>
                                                {log.newAuthorisationType === 'EMAIL' ? (
                                                    <BSTooltip
                                                        id={indx}
                                                        title="Download Attachment"
                                                        tooltipClassList="product-download-icon"
                                                        position="bottom"
                                                    >
                                                        <div
                                                            className="link-count-wrapper"
                                                            onClick={() => {
                                                                if (log.fileName && log.savedFileId) {
                                                                    downloadFile({
                                                                        fileName: log.fileName,
                                                                        fileId: log.savedFileId,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <button className="back-btn app-btn">
                                                                <svg className="svg-icon file-attachment-icon ">
                                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                                </svg>
                                                            </button>
                                                            {/* <span className="chain-link-count">1</span> */}
                                                        </div>
                                                    </BSTooltip>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {!isHistoryLoading && !autoApprovalHistory?.length ? (
                    <div className="no-data-box-holder">
                        <div className="no-data-box h-center">
                            <span className="no-data-text">No Booking Auto Approval Change Logs</span>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="sidebar-backdrop"></div>
        </div>
    );
};
export default SideBar;
