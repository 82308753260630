import React from 'react';
import { clearSelectedSearch } from 'src/store/reducers/dashboard/dashoboardSearchReducer';
import {
    clearDashboardActiveBookingsExpanded,
    clearDashboardActiveOrders,
    clearDashboardActiveOrdersExpanded,
    clearDashboardBookingsOrders,
    clearInsightDestination,
    clearInsightMode,
    setInsightsDurationRange,
    setInsightsForwarded,
    setInsightsMainPage,
    setInsightsSelectedType,
    setInsightsSelectedTypeFilter,
    setSelectedTypeFilterIndex,
} from 'src/store/reducers/dashboard/insightsReducer';
import { FilterList } from '../InsightsExpanded/enums';

export const clearDashboardInsightsFilters = (dispatch: React.Dispatch<any>, from?: string) => {
    dispatch(setInsightsSelectedTypeFilter(''));
    dispatch(clearInsightMode());
    dispatch(clearInsightDestination());
    dispatch(setInsightsDurationRange(FilterList.Days));
    dispatch(setSelectedTypeFilterIndex(0));
    dispatch(setInsightsSelectedType(''));
    dispatch(setInsightsMainPage(''));
    dispatch(setInsightsForwarded(''));
    if (from !== 'from-todo') {
        dispatch(clearSelectedSearch());
    }
    dispatch(clearDashboardActiveOrders());
    dispatch(clearDashboardActiveOrdersExpanded());
    dispatch(clearDashboardBookingsOrders());
    dispatch(clearDashboardActiveBookingsExpanded());
};
