import { post, get, patch } from '../HttpMethods';

interface details {
    po: boolean;
    blp: boolean;
    shipment: boolean;
}
export interface integrationDetails {
    cl_pk: string;
    integration: [
        {
            none: details;
            webservices: details;
            sftp: details;
        }
    ];
    submit: boolean;
}

export const addIntegrationDetails = async (payload: any) => {
    try {
        const res = await post('api/mgt/clients/integration', payload, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const getIntegrationDetails = async (payload: { clientPK: string }) => {
    try {
        const res = await get(`api/mgt/clients/integration?UUID=${payload.clientPK}`, null);
        return res.info;
    } catch (err) {
        return err;
    }
};

export const submitIntegrationDetailsAndActivate = async (payload: any) => {
    try {
        const res = await patch(`api/mgt/clients/activate/${payload.clientPK}`, null);
        return res;
    } catch (err) {
        return err;
    }
};
