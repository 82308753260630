import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get } from '../HttpMethods';
import fileDownload from 'js-file-download';

export interface clientData {
    orgId: string;
    orgName: string;
    orgCode: string;
    orgStatus: string;
    parentOrgCode: string;
    clientId: string;
    clientStatus: string;
    relationship: string;
    childClients: clientData[];
}
export interface clientDataFull {
    clientResponseLists: clientData[];
    totalNoOfPages: number;
    totalNoOfItems: number;
    clientDetailsNotAvailable: [];
}

export interface clientSearchData {
    content: clientData[];
    totalPages: number;
    totalElements: number;
}

// Get All Datas
export const getClientData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    profile: string;
    orgCode: string;
    vId: any;
}) => {
    try {
        const res = await get(
            `api/mgt/clients?limit=${payload.perPageCount}&page=${payload.currentPage}&sortby=${
                payload.columnStatus
            }&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }&profile=${payload.profile ?? ''}&orgCode=${payload.orgCode ?? ''}&vId=${payload.vId ?? ''}`,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const searchClients = async (payload: {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    columnOrder: boolean;
    statusFilter: string;
    isForwarder: boolean;
    profile: string;
}) => {
    try {
        const res = await get(
            `api/mgt/search/client?keyword=${payload.keyword}&size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus}&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }&isForwarder=${payload?.isForwarder}&profile=${payload?.profile}`,
            null,
            null,
            true
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getAllClientList = async (payload: any) => {
    let result: any[] = [];
    try {
        // const res = await post(`api/mgt/user/count?profile=${payload.profileType}`, payload.data, null);

        const activeClientRes = await get(
            `/cix/scr/admin/organisation/lst/OR_PK/Or_OrgCode?take=200&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B2%5D%5Bfield%5D=OR_IsClient&filter%5Bfilters%5D%5B2%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B2%5D%5Bvalue%5D=true&filter%5Bfilters%5D%5B3%5D%5Bfield%5D=OR_Status&filter%5Bfilters%5D%5B3%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B3%5D%5Bvalue%5D=Active`,
            null,
            externalBaseUrl
        );
        result = activeClientRes?.data;

        return result;
    } catch (err) {
        return console.error(err);
    }
};

export const exportClientList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/clients/export?type=${type}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ClientProfiles.xlsx' : 'ClientProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const exportClientListProfiles = async (type: string, organisationData: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        const selectedFilter = organisationData?.selectedFilter;
        const searchTerm = organisationData?.searchTerm;
        const columnOrder = organisationData?.sortPayload['columnOrder'];
        const columnStatus = organisationData?.sortPayload['columnStatus'];
        const profile = organisationData?.profile;
        const orgCode = organisationData?.orgCode;
        const vId = organisationData?.vId;
        const isForwarder = organisationData?.isForwarder;
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/clients/export?type=${type}${
                selectedFilter ? '&filterby=' + selectedFilter : ''
            }${
                searchTerm ? '&keyword=' + searchTerm : ''
            }&sortColumn=${columnStatus}&sortOrder=${columnOrder}&profile=${profile}&orgCode=${orgCode}&vId=${vId}&isForwarder=${isForwarder}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ClientProfiles.xlsx' : 'ClientProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
export const getHeaderData = async () => {
    try {
        const res = await get('api/mgt/clients/statuscount ', null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
