import { post } from '../../HttpMethods';

export const removePoLine = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/remove/subpolines?type=lcl', payload, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
